import React, { useState } from 'react'
import Modal from '../Modal'
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { EItemType, UserItem } from '../../shared/item.interface';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import { createErrorToast } from '../Toasts/ErrorToasts';
import { createSuccessToast } from '../Toasts/SuccessToasts';
import { checkIfAPIImage, fetchAPI } from '../../utils';
import { selectProfile, updateItemInInventory } from '../../redux/profile/profileSlice';
import { useDispatch, useSelector } from 'react-redux';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Tooltip from '@mui/material/Tooltip';

type ModalProps = {
    open: boolean;
    handleClose: () => void;
    item: UserItem | null;
}

const ItemRedemptionModal = ({ open, handleClose, item }: ModalProps) => {

    const dispatch = useDispatch();

    const profile = useSelector(selectProfile);

    const [note, setNote] = useState<{
        slot?: string,
        eth_address?: string
    }>({
        eth_address: profile?.eth_address
    });

    const redeemItem = async (userItemId: string) => {

        try { 
            const data: any = await fetchAPI('/item/redeem', 'POST', {
                useritemid: userItemId,
                notes: note
            })
    
            if(data.success) {
                createSuccessToast(data.success);
                dispatch(updateItemInInventory(data.data))
            }
    
            return true;
        } catch (e) {
            return false;
        }
    }

    async function redeem() {
        if(!item) return ;
        if(item.item.type === EItemType.BONUS_HUNT_TICKET) {
            if(!note.slot) {
                return createErrorToast('Please enter a slot suggestion')
            }
            if(!note.eth_address) {
                return createErrorToast('Please enter an ETH address')
            }

            const itemRedemptionSuccess =  await redeemItem(item._id)
            if(itemRedemptionSuccess) handleClose();
        }
    }

    if(!item) return (<></>)
    

    return (
        <React.Fragment>
            <Modal
                open={open}
                handleClose={handleClose}
                closeButton={true}
                width="600px"
                style={{ padding: '0' }}
                contentStyle={{
                    background: 'linear-gradient(135deg, rgba(0,0,0,0) 0%, rgba(49, 33, 160, .1) 100%)',
                    padding: '1rem'
                }}
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    rowGap="1rem"
                >
                    <Typography variant="h5">Redeem Item</Typography>
                    <Box display="flex" alignItems="center" padding=".5rem" width="fit-content"  sx={{ backgroundColor: 'var(--primary-bg-soft)', padding: '.5rem 1rem', borderRadius: '.5rem' }}>
                        <img src={checkIfAPIImage(item.item.image)} alt="" style={{ height: '48px', width: '48px', marginRight: '1rem' }} />
                        <Typography>{item.item.name}</Typography>
                    </Box>
                    <Typography marginBottom="1rem">
                        This item needs additional info.
                    </Typography>


                    <FormControl fullWidth>
                        <InputLabel sx={{ marginBottom: '.25rem', display: 'flex', alignItems: 'flex-end', 'svg': { height: '24px', width: '24px', marginLeft: '.5rem' } }}>
                            <Typography>Slot Name</Typography>
                            <Tooltip title="Your slot suggestion for the bonus hunt">
                                <HelpOutlineIcon />
                            </Tooltip>
                        </InputLabel>
                        <OutlinedInput
                            id="redemption-slot"
                            value={note.slot}
                            onChange={(e) => setNote(prevState => ({ ...prevState, slot: e.target.value }))}
                            placeholder='Tome of Madness...'
                        />
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel sx={{ marginBottom: '.25rem', display: 'flex', alignItems: 'flex-end', 'svg': { height: '24px', width: '24px', marginLeft: '.5rem' } }}>
                            <Typography>ETH Address</Typography>
                            <Tooltip title="Your ETH address where we should send your cut to.">
                                <HelpOutlineIcon />
                            </Tooltip>
                        </InputLabel>
                        <OutlinedInput
                            id="redemption-eth-address"
                            value={note.eth_address}
                            onChange={(e) => setNote(prevState => ({ ...prevState, eth_address: e.target.value }))}
                            placeholder='0x1234...'
                        />
                    </FormControl>


                    <Button variant="contained" sx={{ alignSelf: 'flex-start', marginTop: '1rem' }}
                        onClick={() => redeem()}
                    >
                        Redeem Item
                    </Button>
                </Box>
            </Modal>
        </React.Fragment>
    )
}

export default ItemRedemptionModal