import { Box, Button, Skeleton, Tab, Tabs, Tooltip, Typography, styled } from '@mui/material'
import React, { useEffect, useState } from 'react'
import useSWR from 'swr'
import { SlotBattle, SlotBattleBet, SlotBattleBetStatus, SlotBattleStatus, SlotBattleTeamBet } from './types'
import { fetcher, formatDate, isAPIImage } from '../../utils'
import { ReactComponent as CoinIcon } from '../../icons/assets/coin.svg'
import BetDrawer from './BetDrawer'
import { Check, Close, EmojiEvents } from '@mui/icons-material'
import { Link, Route, Routes, useLocation } from 'react-router-dom'
import { socket } from '../../socket'
import { useSelector } from 'react-redux'
import { selectProfile } from '../../redux/profile/profileSlice'
import SlotBattleBetLeaderboard from './SlotBattleBetLeaderboard'
import ResourceEmpty from '../../components/ResourceEmpty'

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
    status: "live" | "previous",
    isDrawerOpen: boolean,
    addBet?: (bet: SlotBattleTeamBet) => void
    betIds?: string[]
  }

interface UserTabProps {
    children?: React.ReactNode;
    index: number;
    value: number;
    isDrawerOpen: boolean,
}
  

const SlotButton = styled(Button)(({ theme }) => ({
    display: 'flex', 
    alignItems: 'center', 
    gap: '.5rem', 
    backgroundColor: 'var(--primary-bg-light)', 
    borderRadius: '.5rem', 
    padding: '.5rem',
    transition: "background-color .2s ease-in-out",
    color: 'var(--text-primary) !important',
    border: '1px solid var(--primary-border)',

    '&:hover': {
        backgroundColor: 'var(--primary-main)',
        
    }
}))

const SlotBattleSkeleton = () => {
    return (
        <Box display="flex" flexDirection="column" gap="1rem" 
            sx={{ p: '1rem', backgroundColor: 'var(--primary-bg-soft)', border: '1px solid var(--primary-border)', borderRadius: '.5rem' }}
        >
            <Box display="flex">
                <Box display="flex" gap=".5rem" flexGrow={1}>
                    <Skeleton variant="circular" sx={{ height: '24px', width: '24px' }} />
                    <Skeleton variant="rounded" sx={{ width: '64px', height: '24px' }} />
                </Box>
                <Skeleton variant="rounded" sx={{ width: '64px', height: '24px' }} />
            </Box>

            <Box display="grid" gap="1rem" sx={{ gridTemplateColumns: 'repeat(2, minmax(0, 1fr))' }}>
                <Box display="flex" alignItems="center" gap=".5rem" width="100%" sx={{ padding: '.5rem', borderRadius: '.5rem', backgroundColor: 'var(--primary-bg-light)' }}>
                    <Box display="flex" alignItems="center" gap=".5rem" flexGrow={1}>
                        <Skeleton variant="rounded" sx={{ height: '32px',  width: '48px'}} />
                        <Skeleton variant="rounded" sx={{ height: '24px', width: '64px' }} />
                        
                    </Box>
                    <Skeleton variant="rounded" sx={{ height: '24px', width: '32px' }} />
                </Box>
                <Box display="flex" alignItems="center" width="100%" sx={{ padding: '.5rem', borderRadius: '.5rem', backgroundColor: 'var(--primary-bg-light)' }}>
                    <Box display="flex" alignItems="center" gap=".5rem" flexGrow={1}>
                        <Skeleton variant="rounded" sx={{ height: '32px',  width: '48px'}} />
                        <Skeleton variant="rounded" sx={{ height: '24px', width: '64px' }} />
                        
                    </Box>
                    <Skeleton variant="rounded" sx={{ height: '24px', width: '32px' }} />
                </Box>
                <Box display="flex" alignItems="center" width="100%" sx={{ padding: '.5rem', borderRadius: '.5rem', backgroundColor: 'var(--primary-bg-light)' }}>
                    <Box display="flex" alignItems="center" gap=".5rem" flexGrow={1}>
                        <Skeleton variant="rounded" sx={{ height: '32px',  width: '48px'}} />
                        <Skeleton variant="rounded" sx={{ height: '24px', width: '64px' }} />
                        
                    </Box>
                    <Skeleton variant="rounded" sx={{ height: '24px', width: '32px' }} />
                </Box>
                <Box display="flex" alignItems="center" width="100%" sx={{ padding: '.5rem', borderRadius: '.5rem', backgroundColor: 'var(--primary-bg-light)' }}>
                    <Box display="flex" alignItems="center" gap=".5rem" flexGrow={1}>
                        <Skeleton variant="rounded" sx={{ height: '32px',  width: '48px'}} />
                        <Skeleton variant="rounded" sx={{ height: '24px', width: '64px' }} />
                        
                    </Box>
                    <Skeleton variant="rounded" sx={{ height: '24px', width: '32px' }} />
                </Box>
            </Box>

        </Box>
    )
}

const SlotBattleBetStatusContainer = ({ status }: { status: SlotBattleBetStatus }) => {

    switch(status) {
        case SlotBattleBetStatus.WON:
            return (
                <Box display="flex" alignItems="center" gap=".25rem">
                    <Check />
                    <Typography>Won</Typography>
                </Box>
            )
        case SlotBattleBetStatus.LOST:
            return (
                <Box display="flex" alignItems="center" gap=".25rem">
                    <Close />
                    <Typography>Lost</Typography>
                </Box>
            )
        case SlotBattleBetStatus.CANCELLED:
            return (
                <Box display="flex" alignItems="center" gap=".25rem">
                    <Close />
                    <Typography>Cancelled</Typography>
                </Box>
            )
        case SlotBattleBetStatus.OPEN:
            return (
                <Box display="flex" alignItems="center" gap=".25rem">
                    <Typography>Pending</Typography>
                </Box>
            )
        default:
            return <></>
    }
}

const SlotBattleBetCard = ({ slotBattleBet }: { slotBattleBet: SlotBattleBet  }) => {
    return (
        <Box display="flex" flexDirection="column" gap="1rem" 
            sx={{ p: '1rem', backgroundColor: 'var(--primary-bg-soft)', border: '1px solid var(--primary-border)', borderRadius: '.5rem' }}
        >

        
             <Box display="flex" justifyContent="space-between">
                <SlotBattleBetStatusContainer status={slotBattleBet.status} />
                <Typography sx={{ fontSize: '14px', color: 'var(--text-secondary)' }}>{formatDate(slotBattleBet.createdAt, "time")}</Typography>
             </Box>
            <Box display="grid" gap="1rem" sx={{ gridTemplateColumns: 'repeat(2, minmax(0, 1fr))' }}>
                {slotBattleBet.bets.map((bet) => (
                    <SlotButton 
                        key={`slot-battle-${slotBattleBet._id}-${bet.teamId}`}
                        disabled
                        sx={{ borderColor: bet.team.isWinner ? 'rgb(var(--rgb-success))' : bet.team.isLoser ? "rgb(var(--rgb-error))" : "", backgroundColor: bet.team.isWinner ? 'rgba(var(--rgb-success), .2)' : bet.team.isLoser ? "rgba(var(--rgb-error), .2)" : ""}}
                    >
                        <Box component="img" src={isAPIImage(bet.team.image)} alt={bet.team.name} sx={{ height: '32px', width: 'auto', borderRadius: '.25rem' }}/>
                        <Typography sx={{ 
                                fontSize: '12px',
                                maxWidth: '75%',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                            }}
                        >
                            {bet.team.name}
                        </Typography>
                        <Typography sx={{ marginLeft: 'auto', fontSize: '14px', fontWeight: 'bold'}}>{Intl.NumberFormat("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2}).format((bet.team.odds || 0.01) * 100)}%</Typography>
                    </SlotButton>
                ))}
            </Box>
            <Box display="flex" justifyContent="space-between" mt="auto">
                <Box display="flex" flexDirection="column">
                    <Typography sx={{ fontSize: '14px', color: 'var(--text-disabled)', fontWeight: 'bold' }}>Bet</Typography>
                    <Box display="flex" alignItems="center" gap=".5rem">
                        <CoinIcon style={{ height: '14px',  width: '14px' }} />
                        {slotBattleBet.status === SlotBattleBetStatus.LOST ? (
                            <Typography sx={{ color: 'var(--error)' }}>-{slotBattleBet.bet}</Typography>
                        ) : (
                            <Typography>{slotBattleBet.bet}</Typography>
                        )}
                        
                    </Box>
                    
                </Box>  
                {slotBattleBet.payout ? (
                    <Box display="flex" flexDirection="column" alignItems="flex-end">
                        <Typography sx={{ fontSize: '14px', color: 'var(--text-disabled)', fontWeight: 'bold' }}>Payout</Typography>
                        
                        <Box display="flex" alignItems="center" gap=".5rem">
                            <CoinIcon style={{ height: '14px',  width: '14px' }} />
                            <Typography sx={{ color: 'var(--success)' }}> +{slotBattleBet.payout}</Typography>
                        </Box>              
                    </Box>  
                ) : slotBattleBet.status === SlotBattleBetStatus.OPEN && (
                    <Box display="flex" flexDirection="column" alignItems="flex-end">
                        <Typography sx={{ fontSize: '14px', color: 'var(--text-disabled)', fontWeight: 'bold' }}>Current Payout</Typography>
                        
                        <Box display="flex" alignItems="center" gap=".5rem">
                            <CoinIcon style={{ height: '14px',  width: '14px' }} />
                            <Typography>{Intl.NumberFormat("en-US", { minimumFractionDigits: 0, maximumFractionDigits: 0}).format(Math.min(100000, slotBattleBet.bet * (1 / slotBattleBet.bets.reduce((prev, curr) => prev * (curr.team.odds || 0.01), 1))))}</Typography>
                        </Box>
                        
                    </Box>  
                )}
         
            </Box>
           
        </Box>  
    )
}

const SlotBattleCard = ({ betIds, slotBattle, addBet }: { betIds?: string[], slotBattle: SlotBattle, addBet?: (teamBet: SlotBattleTeamBet) => void }) => {
    return (
        <Box display="flex" flexDirection="column" gap="1rem" 
            sx={{ p: '1rem', backgroundColor: 'var(--primary-bg-soft)', border: '1px solid var(--primary-border)', borderRadius: '.5rem' }}
        >
            <Box display="flex" alignItems="center" justifyContent="space-between">
                {slotBattle.streamer && (
                    <Box display="flex" gap=".5rem">
                        <Box component="img" src={slotBattle.streamer.image} alt={slotBattle.streamer.display_name} sx={{ height: '24px', width: '24px', borderRadius: '100%' }} />
                        <Typography sx={{ fontWeight: 'bold', letterSpacing: '-.3px' }}>{slotBattle.streamer.display_name}</Typography>
                    </Box>
                )}
                {slotBattle.status === SlotBattleStatus.LIVE ? (
                    <Typography sx={{ color: 'var(--primary-main)' }}>LIVE</Typography>
                ) : (
                    <Typography sx={{ fontSize: '14px', color: 'var(--text-secondary)' }}>{formatDate(slotBattle.eventAt)}</Typography>
                )}
            </Box>
            <Box display="grid" gap="1rem" sx={{ gridTemplateColumns: 'repeat(2, minmax(0, 1fr))' }}>
                {slotBattle.teams.map((team) => (
                    <SlotButton 
                        key={`slot-battle-${slotBattle._id}-${team._id}`}
                        disabled={slotBattle.status !== SlotBattleStatus.OPEN}
                        onClick={() => addBet && addBet({ slotbattleId: slotBattle._id, teamId: team._id, name: team.name, image: team.image, eventAt: slotBattle.eventAt, odds: team.odds })}
                        sx={{ borderColor: team.isWinner ? 'rgb(var(--rgb-success))' : "", backgroundColor: team.isWinner ? 'rgba(var(--rgb-success), .2)' : betIds && betIds.includes(team._id) ? "var(--primary-bg)" : ""}}
                    >
                        <Box component="img" src={isAPIImage(team.image)} alt={team.name} sx={{ height: 'auto', width: '40px', borderRadius: '.5rem', border: '1px solid var(--primary-border)' }}/>
                        <Tooltip title={team.name}>
                            <Typography sx={{ 
                                textAlign: 'start', 
                                fontSize: '12px', 
                                maxWidth: '75%',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                            }}>
                                {team.name}
                            </Typography>
                         </Tooltip>
             
                        <Typography sx={{ marginLeft: 'auto', fontSize: '14px', fontWeight: 'bold'}}>{Intl.NumberFormat("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2}).format((team.odds || 0.01) * 100)}%</Typography>
                    </SlotButton>
                ))}
            </Box>
        </Box>
    )
}

enum SlotBattleEvent {
    BET_CREATED = "slotBattleBetCreated",
    BATTLE_FINISHED = "slotBattleFinished",
    BATTLE_CREATED = "slotBattleBetsOpened",
    BATTLE_STARTED = "slotBattleStarted",
}

const SlotBattleTab = (props: TabPanelProps) => {

    const { children, value, index, addBet, status, betIds, isDrawerOpen, ...other } = props

    const [page, setPage] = useState(1)
    const { data, isLoading, mutate } = useSWR<{ count: number, countPerPage: number, data: SlotBattle[]}>(`/slot-battles?page=${page}&status=${status}`, fetcher)

    useEffect(() => {
        function slotBattleEvent(event: SlotBattleEvent, slotBattleId: string, data: any) {
            if(!slotBattleId || !data) return
            switch (event) {
                case SlotBattleEvent.BET_CREATED:
                    mutate(prevState => {

                        if(!prevState) return prevState

                        const slotBattle = prevState.data.find(slotBattle => slotBattle._id === slotBattleId)
                        if(!slotBattle) return prevState

                        const slotBattleIndex = prevState.data.findIndex(slotBattle => slotBattle._id === slotBattleId)
                        if(slotBattleIndex === -1) return prevState

                        const teams = prevState.data[slotBattleIndex].teams

                        for(const team of teams) {
                            const teamIndex = data.teams.findIndex((t: { teamId: string, totalBet: number, odds: number }) => t.teamId === team._id)

                            if(teamIndex === -1) continue

                            team.odds = data.teams[teamIndex].odds
                            team.totalBet = data.teams[teamIndex].totalBet

                        }


                        slotBattle.teams = teams

                        return { ...prevState, data: prevState.data.map((battle) => battle._id === slotBattle._id ? slotBattle : battle) }
                    }, {
                        revalidate: false
                    })
                    break
                case SlotBattleEvent.BATTLE_FINISHED:
                    mutate(prevState => {
                        if(!prevState) return prevState

                        return { ...prevState, data: prevState.data.filter(slotBattle => slotBattle._id !== slotBattleId) }
                    }, {
                        revalidate: false
                    })
                    break     
                    
                case SlotBattleEvent.BATTLE_CREATED:
                    mutate(prevState => {
                        if(!prevState) return prevState

                        return { ...prevState, data: [data, ...prevState.data] }
                    }, {
                        revalidate: false
                    })
                    break
                case SlotBattleEvent.BATTLE_STARTED:
                    mutate(prevState => {
                        if(!prevState) return prevState

                        return { ...prevState, data: prevState.data.map((slotBattle) => slotBattle._id === slotBattleId ? { ...slotBattle, status: SlotBattleStatus.LIVE } : slotBattle)}
                    }, {
                        revalidate: false
                    })
                    break  
                default:
                    break
            
            }
        }

        socket.on('slotBattles', slotBattleEvent)
    
        return function cleanup() {
            socket.emit('unsubscribe', 'slotBattles')
          socket.off('slotBattles', slotBattleEvent) 
        }
    }, [])

    useEffect(() => {
       
        if(data) {
            socket.emit('subscribe', 'slotBattles') 
        }
        
       
    }, [data])

    return (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`slot-battle-tabpanel-${index}`}
          aria-labelledby={`slot-battle-label-${index}`}
          {...other}
        >
            {value === index && (
                <Box display="flex" flexDirection="column" gap="1rem" py="1rem">
                    {isLoading ? (
                        <Box display="grid" gap="1rem" sx={{ gridTemplateColumns: isDrawerOpen ? { sm: 'repeat(1, minmax(0, 1fr))', lg: 'repeat(2, minmax(0, 1fr))', xl: 'repeat(2, minmax(0, 1fr))' } : { xs: 'repeat(1, minmax(0, 1fr))', lg: 'repeat(2, minmax(0, 1fr))', xl: 'repeat(3, minmax(0, 1fr))' }}}>
                            <SlotBattleSkeleton />
                            <SlotBattleSkeleton />
                            <SlotBattleSkeleton />
                            <SlotBattleSkeleton />
                            <SlotBattleSkeleton />
                            <SlotBattleSkeleton />
                        </Box>
                    ) : !data ? (
                        <Typography>Something went wrong</Typography>
                    ) : data.data.length === 0 ? (
                        <ResourceEmpty text={"Currently there are no active slot battles."}/>
                    ) : (
                        <Box display="grid" gap="1rem"  sx={{ gridTemplateColumns: isDrawerOpen ? { sm: 'repeat(1, minmax(0, 1fr))', lg: 'repeat(2, minmax(0, 1fr))', xl: 'repeat(2, minmax(0, 1fr))' } : { xs: 'repeat(1, minmax(0, 1fr))', lg: 'repeat(2, minmax(0, 1fr))', xl: 'repeat(3, minmax(0, 1fr))' }}}>
                            {data.data.map(slotBattle => (
                                <SlotBattleCard betIds={betIds} slotBattle={slotBattle} addBet={addBet} key={`slot-battle-${slotBattle._id}`} />
                            ))}
                        </Box>
                    )}
                        
                    {data && (
                        <Box display="flex" alignItems="center" justifyContent="flex-end" gap="1rem" marginTop="1rem">
                            <Typography sx={{ color: 'var(--text-secondary)' }}>
                                Showing 
                                {" "}
                                <Typography component="span" sx={{ color: 'var(--text-primary)', fontWeight: 'bold' }}>{(page - 1) * data.countPerPage + data.data.length}</Typography>
                                {" "}
                                of
                                {" "}
                                <Typography component="span" sx={{ color: 'var(--text-primary)', fontWeight: 'bold' }}>{data.count}</Typography> 
                            </Typography>
                            <Button disabled={page === 1} onClick={() => setPage(prevState => Math.max(0, --prevState))} variant="contained" color="soft" sx={{ border: '1px solid var(--primary-border)' }}>
                                Previous
                            </Button>   
                            <Button onClick={() => setPage(prevState => ++prevState)} disabled={data.count <= ((page - 1) * data.countPerPage) + data.data.length} variant="contained" color="soft" sx={{ border: '1px solid var(--primary-border)' }}>
                                Next
                            </Button>   
                        </Box>
                    )}
            </Box>
          )}
        </div>
      );
}

const SlotBattleUserTab = (props: UserTabProps) => {

    const { children, value, index, isDrawerOpen, ...other } = props

    const [page, setPage] = useState(1)
    const { data, isLoading } = useSWR<{ count: number, countPerPage: number, data: SlotBattleBet[]}>(`/slot-battles/bets?page=${page}`, fetcher)

    return (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`slot-battle-tabpanel-${index}`}
          aria-labelledby={`slot-battle-label-${index}`}
          {...other}
        >
            {value === index && (
                <Box display="flex" flexDirection="column" gap="1rem" py="1rem">
                    {isLoading ? (
                        <Box display="grid" gap="1rem" sx={{ gridTemplateColumns: isDrawerOpen ? { sm: 'repeat(1, minmax(0, 1fr))', lg: 'repeat(2, minmax(0, 1fr))', xl: 'repeat(2, minmax(0, 1fr))' } : { xs: 'repeat(1, minmax(0, 1fr))', lg: 'repeat(2, minmax(0, 1fr))', xl: 'repeat(3, minmax(0, 1fr))' }}}>
                            <SlotBattleSkeleton />
                            <SlotBattleSkeleton />
                        </Box>
                    ) : !data ? (
                        <Typography>Something went wrong</Typography>
                    ) : data.data.length === 0 ? (
                        <Typography sx={{ fontStyle: 'italic' }}>No Slot Battles found</Typography>
                    ) : (
                        <Box display="grid" gap="1rem" sx={{ gridTemplateColumns: isDrawerOpen ? { sm: 'repeat(1, minmax(0, 1fr))', lg: 'repeat(2, minmax(0, 1fr))', xl: 'repeat(2, minmax(0, 1fr))' } : { xs: 'repeat(1, minmax(0, 1fr))', lg: 'repeat(2, minmax(0, 1fr))', xl: 'repeat(3, minmax(0, 1fr))' }}}>
                            {data.data.map((slotBattleBet) => (
                                <SlotBattleBetCard key={`slot-battle-bet-${slotBattleBet._id}`} slotBattleBet={slotBattleBet} />
                            ))}
                        </Box>
                    )}
                        
                    {data && (
                        <Box display="flex" alignItems="center" justifyContent="flex-end" gap="1rem" marginTop="1rem">
                            <Typography sx={{ color: 'var(--text-secondary)' }}>
                                Showing 
                                {" "}
                                <Typography component="span" sx={{ color: 'var(--text-primary)', fontWeight: 'bold' }}>{(page - 1) * data.countPerPage + data.data.length}</Typography>
                                {" "}
                                of
                                {" "}
                                <Typography component="span" sx={{ color: 'var(--text-primary)', fontWeight: 'bold' }}>{data.count}</Typography> 
                            </Typography>
                            <Button disabled={page === 1} onClick={() => setPage(prevState => Math.max(0, --prevState))} variant="contained" color="soft" sx={{ border: '1px solid var(--primary-border)' }}>
                                Previous
                            </Button>   
                            <Button onClick={() => setPage(prevState => ++prevState)} disabled={data.count <= ((page - 1) * data.countPerPage) + data.data.length} variant="contained" color="soft" sx={{ border: '1px solid var(--primary-border)' }}>
                                Next
                            </Button>   
                        </Box>
                    )}
            </Box>
          )}
        </div>
      );
}

const SlotBattleTabs = ({ bets, addBet, isDrawerOpen }: { bets: SlotBattleTeamBet[], addBet: (team: SlotBattleTeamBet) => void, isDrawerOpen: boolean }) => {

    const location = useLocation()
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
    }

    useEffect(() => {
        if(location.pathname === '/slot-battles/history') {
            setValue(1)
        } else if(location.pathname === '/slot-battles/bets') {
            setValue(2)
        } else if(location.pathname === '/slot-battles/bets/leaderboard') {
            setValue(3)
        } else {
            setValue(0)
        }
    }, [location])

    return (
        <Box>
            <Tabs 
                value={value} 
                onChange={handleChange} 
                aria-label="slot battle tabs"
                sx={{ py: '1rem', borderBottom: '1px solid var(--primary-border)', '.MuiTabs-flexContainer': { gap: '1rem' } }}
                TabIndicatorProps={{
                    style: { display: "none" }
                }}
            >
                <Tab component={Link} to="/slot-battles/" disableRipple label="Live" id="slot-battle-tab-live" 

                    sx={{ 
                        backgroundColor: 'var(--primary-bg)',
                        borderRadius: '.5rem',
                        border: '1px solid var(--primary-border)',
                        transition: 'background-color .2s ease-in-out',
                        color: 'var(--text-primary) !important',
                        '&.Mui-selected': {
                            backgroundColor: 'var(--primary-bg-light)'
                        },
                        '&:hover': {
                            backgroundColor: 'var(--primary-bg-soft)'
                        }
                    }} 
                />
                <Tab component={Link} to="/slot-battles/history" disableRipple label="Previous" id="slot-battle-tab-previous"
                    sx={{ 
                        backgroundColor: 'var(--primary-bg)',
                        borderRadius: '.5rem',
                        border: '1px solid var(--primary-border)',
                        transition: 'background-color .2s ease-in-out',
                        color: 'var(--text-primary) !important',
                        '&.Mui-selected': {
                            backgroundColor: 'var(--primary-bg-light)'
                        },
                        '&:hover': {
                            backgroundColor: 'var(--primary-bg-soft)'
                        }
                    }} 
                />    

                <Tab component={Link} to="/slot-battles/bets" disableRipple label="My Bets" id="slot-battle-tab-my-bets"
                    sx={{ 
                        backgroundColor: 'var(--primary-bg)',
                        borderRadius: '.5rem',
                        border: '1px solid var(--primary-border)',
                        transition: 'background-color .2s ease-in-out',
                        color: 'var(--text-primary) !important',
                        '&.Mui-selected': {
                            backgroundColor: 'var(--primary-bg-light)'
                        },
                        '&:hover': {
                            backgroundColor: 'var(--primary-bg-soft)'
                        }
                    }} 
                />   

                
                <Tab component={Link} to="/slot-battles/bets/leaderboard" disableRipple id="slot-battle-tab-my-bets"
                    label={
                        <Box display="flex" alignItems="center" gap=".5rem" sx={{ fontSize: '12px' }}>
                            <EmojiEvents sx={{ color: 'goldenrod', height: '20px', width: '20px' }} />
                            Biggest Wins
                        </Box>
                    }
                    sx={{ 
                        backgroundColor: 'var(--primary-bg)',
                        borderRadius: '.5rem',
                        border: '1px solid var(--primary-border)',
                        transition: 'background-color .2s ease-in-out',
                        color: 'var(--text-primary) !important',
                        '&.Mui-selected': {
                            backgroundColor: 'var(--primary-bg-light)'
                        },
                        '&:hover': {
                            backgroundColor: 'var(--primary-bg-soft)'
                        }
                    }} 
                />   
            
            </Tabs>
        
                <Routes>
                    <Route path="/" element={<SlotBattleTab betIds={bets.map((bet) => bet.teamId)} value={value} index={0} status="live" addBet={addBet} isDrawerOpen={isDrawerOpen} />} />
                    <Route path="/history" element={<SlotBattleTab value={value} index={1} isDrawerOpen={isDrawerOpen} status="previous" />} />
                    <Route path="/bets" element={<SlotBattleUserTab value={value} isDrawerOpen={isDrawerOpen} index={2} />} />
                    <Route path="/bets/leaderboard" element={<SlotBattleBetLeaderboard />} />
                </Routes>
     
            
            
            
        </Box>

    )
}

const SlotBattlePage = () => {

    const profile = useSelector(selectProfile)
    const [isBetDrawerOpen, setIsBetDrawerOpen] = useState<boolean>(false)
    const [bets, setBets] = useState<SlotBattleTeamBet[]>([])

    function addBet(teamBet: SlotBattleTeamBet) {
        const betIndex = bets.findIndex(bet => bet.slotbattleId === teamBet.slotbattleId)
        if(betIndex !== -1) 
            setBets(prevState => prevState.map((bet, index) => index === betIndex ? teamBet : bet))
        else 
            setBets([...bets, teamBet])

        setIsBetDrawerOpen(true)
    }

    function removeBet(index: number) {
        setBets(bets.filter((bet, i) => i !== index))
    }

  return (
    <Box
        display="flex"
        gap="2rem"
        width="100%"
    >
        <Box display="flex" flexDirection="column" className="page" maxWidth="1600px" flexGrow={1} sx={{ px: { xs: '8px', md: '1rem' }}}>
            <Box display="flex" flexDirection="column" gap=".25rem" alignItems="center" mb="2rem">
                <Typography variant="h3">Slot Battles</Typography>
                {profile && (
                    <Typography sx={{ color: 'var(--text-secondary)' }}>You have  <CoinIcon style={{ height: '14px', width: '14px' }} /> <Typography component="span" sx={{ color: 'var(--text-primary)', fontWeight: 'bold' }}>{Intl.NumberFormat("en-US").format(profile.points)} Points</Typography></Typography>
                    
                )}
            </Box>
            
            <SlotBattleTabs bets={bets} addBet={addBet} isDrawerOpen={isBetDrawerOpen} />
        </Box>



        <BetDrawer bets={bets} removeBet={removeBet} isOpen={isBetDrawerOpen} clearBets={() => setBets([])} handleOpen={() => setIsBetDrawerOpen(true)} handleClose={() => setIsBetDrawerOpen(false)} />
    </Box>
  )
}

export default SlotBattlePage