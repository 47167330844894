import { createSlice } from "@reduxjs/toolkit";
import { IBattle } from "../../shared/battle.interface";
import { createBattleStartedToast } from "../../components/Toasts/BattleToasts";

const initialState: {
    battle: IBattle | null;
} = {
    battle: null
}

export const battleSlice = createSlice({
    name: 'battle',
    initialState,
    reducers: {
        setBattle: (state, action) => {
            state.battle = action.payload
        },
        battleStarted: (state, action) => {
            if(state.battle && state.battle.battleId === action.payload.battleId) {
                state.battle.status = 1 
            } else {
                const playerId = action.payload.userId;
                if(playerId) {
                    const playerinBattle = action.payload.data?.players.find((player: any) => player?.user._id === playerId)
                    if(playerinBattle) {
                        console.log('Go to Battle!!!')
                        createBattleStartedToast(action.payload.battleId)
                    } else {
                        console.log('Not In Battle!!')
                    }

                }
                
                
            }
        },
        playerAdded: (state, action) => {
            if(state.battle && state.battle.battleId === action.payload.battleId) state.battle.players.push({ user: action.payload.player})
        },
        setActiveRound: (state, action) => {
            if(state.battle && state.battle.battleId === action.payload.battleId) {
                state.battle.activeRound = action.payload.round
                state.battle.currentRound = action.payload.round.order - 1
            }
        },
        battleFinished: (state, action) => {
            if(state.battle && state.battle.battleId === action.payload.battleId) {
                state.battle = action.payload.data
            }
            
        }
    }
})


export const { setBattle, playerAdded, setActiveRound, battleStarted, battleFinished } = battleSlice.actions

export const selectBattle = (state: any) => state.battle.battle

export default battleSlice.reducer