import { styled } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import React from 'react'
import Logo from '../../icons/prodigylogo.png';
import { useSelector } from 'react-redux';
import { selectProfile } from '../../redux/profile/profileSlice'
import { ReactComponent as BoxIcon } from '../../icons/box.svg'
import { ReactComponent as VSIcon } from '../../icons/assets/vs.svg'
import { ReactComponent as GiftIcon } from '../../icons/gift.svg'
import { ReactComponent as TargetIcon } from '../../icons/assets/circular_target.svg'
import { ReactComponent as TargetXIcon } from '../../icons/assets/target_x.svg'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Link, useLocation } from 'react-router-dom';
import NotificationMenu from './NotificationMenu';
import TrophyIcon from '@mui/icons-material/EmojiEvents';
import ProfileMenu from './ProfileMenu';
import NavSheet from './NavSheet';
import { API_URL } from '../../utils';
import { StarOutline } from '@mui/icons-material'


const NavButton = styled(Button)(( { theme }) => ({
  textTransform: 'initial',
  color: 'var(--text-primary)',
  fontWeight: 'bold',
  gap: '.5rem',

  svg: {
    height: '24px',
    width: '24px',
    filter: 'drop-shadow(0 0 6px rgba(255, 0, 0, .5))',
    opacity: .8
  },

  '&.active': {
    color: 'var(--primary-main)',
    fontWeight: 'bold',

    svg: {
      filter: 'drop-shadow(0 0 10px rgba(255, 0, 0, .75))',
      opacity: 1
    }
  },

  [theme.breakpoints.down('xl')]: {
    padding: '.5rem',
    borderRadius: '.5rem',
    border: '1px solid var(--primary-border)',
    transition: 'background-color .2s',
  }

}))

export enum Route {
  HOME = "/",
  STORE = "/store",
  GIVEAWAYS = "/giveaways",
  HIGHLIGHTS = "/highlights",
  BONUS_HUNTS = "/hunts",
  SLOT_BATTLES = "/slot-battles",
  CASES = "/cases",
  FREE = "/casino",
  LEADERBOARD = "/leaderboard",
  PROFILE = "/profile",
  VIP = "/vip",
  CHALLENGES = "/challenges"
}

const Navbar = () => {

  const location = useLocation()

  const profile = useSelector(selectProfile)

  function isActive(path: Route) {
    return location.pathname.startsWith(path)
  }

  return (
    <Box 
      component="main"
      position="sticky"
      top="0"
      zIndex={20}
      sx={{
        backgroundColor: 'var(--primary-bg)',
        px: { xs: '1rem', md: '3rem'},
        py: '20px',
        borderBottom: '2px solid var(--primary-border)',
        boxShadow: '0 0 .5rem rgba(0, 0, 0, .25)',
      }}
    >
      <Box alignItems="center" justifyContent="space-between" sx={{ display: { xs: 'flex', md: 'none' }}}>
        <Box component={Link} to={"/"} sx={{ color: 'inherit' }} display="flex" alignItems="center" width="100%" position="relative" gap="1rem">
            <Box component="img" src={Logo} alt="Logo" sx={{ height: '36px', width: '36px' }} />
            <Typography sx={{ letterSpacing: '-.2px', fontSize: '20px', }}>ProdigyDDK</Typography>
        </Box>
        <Box display="flex" alignItems="center" gap="1rem">
          {profile && (
            <NotificationMenu />
          )}
          <NavSheet />

        </Box>
      </Box>
      <Box display="flex" alignItems="center" width="100%" position="relative" sx={{ gap: { xs: '1rem', xl: '2rem' }, display: { xs: 'none', md: 'flex' }}}>
        <Box component={Link} to={"/"} sx={{ color: 'inherit' }}>
          <Button disableRipple sx={{ fontSize: '20px' }} startIcon={<Box component="img" src={Logo} alt="Logo" sx={{ height: '36px', width: '36px' }} />}>
            ProdigyDDK
          </Button>
        </Box>

        <Box component={Link} to={Route.FREE} sx={{ color: 'inherit' }}>
          <NavButton 
            disableRipple  
            sx={{ textShadow: '0 0 .5rem rgba(255, 215, 0, .5)', opacity: '.9', '&:hover': { color: 'gold' }, color: 'gold', 'svg': { fill: 'gold' }}}
            
          >
            <StarOutline sx={{ filter: 'drop-shadow(0 0 .5rem rgba(255, 215, 0, .5))' }} />
            <Typography sx={{ display: { sm: 'none', xl: 'inline' }}}>Free</Typography>
          </NavButton>
        </Box>


        <Link to={Route.CHALLENGES} style={{ color: 'inherit', marginRight: 'auto' }}>
            <NavButton 
              disableRipple 
              sx={{ 'svg': { fill: 'var(--primary-main)' }}}
              className={isActive(Route.CHALLENGES) ? "active" : ""}
            >
              <TargetXIcon />
              <Typography sx={{ display: { sm: 'none', xl: 'inline' }}}>Challenges</Typography>
            </NavButton>
        </Link>  
      
        <Box display="flex" alignItems="center" position="absolute"  sx={{ gap: { xs: '1rem', xl: '2rem' }, left: '50%', top: '50%', transform: 'translate(-50%, -50%)'  }}>

  
        <Link to={Route.BONUS_HUNTS} style={{ color: 'inherit' }}>
            <NavButton 
              disableRipple 
              sx={{ 'svg': { fill: 'var(--primary-main)' }}}
              className={isActive(Route.BONUS_HUNTS) ? "active" : ""}
            >
              <TargetIcon />
              <Typography sx={{ display: { sm: 'none', xl: 'inline' }}}>Hunts</Typography>
            </NavButton>
          </Link>          
          <Link to={Route.SLOT_BATTLES} style={{ color: 'inherit' }}>
            <NavButton 
              disableRipple 
              className={isActive(Route.SLOT_BATTLES) ? "active" : ""} 
              sx={{ 'svg': { fill: 'var(--primary-main)' }}}           
            >
              <VSIcon />
              <Typography sx={{ display: { sm: 'none', xl: 'inline' }}}>Slot Battles</Typography>
            </NavButton>
          </Link>
        
          <Link to={Route.CASES} style={{ color: 'inherit' }}>
            <NavButton
              disableRipple 
              className={isActive(Route.CASES) ? "active" : ""} 
              sx={{ 'svg': { fill: 'var(--primary-main)' }}}
            >
              <BoxIcon />
              <Typography sx={{ display: { sm: 'none', xl: 'inline' }}}>Cases</Typography>
            </NavButton>
          </Link>
        
          <Link to={Route.STORE} style={{ color: 'inherit' }}>
            <NavButton 
              disableRipple 
              className={isActive(Route.STORE) ? "active" : ""} 
              sx={{ 'svg': { fill: 'var(--primary-main)' }}}
            >
              <ShoppingCartIcon />
              <Typography sx={{ display: { sm: 'none', xl: 'inline' }}}>Store</Typography>
            </NavButton>
          </Link>
          
          <Link to={Route.GIVEAWAYS} style={{ color: 'inherit' }}>
            <NavButton 
              disableRipple 
              sx={{ 'svg': { fill: 'var(--primary-main)' }}}
              className={isActive(Route.GIVEAWAYS) ? "active" : ""}
            >
              <GiftIcon />
              <Typography sx={{ display: { sm: 'none', xl: 'inline' }}}>Giveaways</Typography>
            </NavButton>
          </Link>

                
        </Box>

        <Box component={Link} to={Route.LEADERBOARD} sx={{ color: 'inherit' }}>
          <NavButton 
            disableRipple 
            sx={{ 'svg': { fill: 'var(--primary-main)' }}}
            className={isActive(Route.LEADERBOARD) ? "active" : ""}
          >
            <TrophyIcon />
            <Box flexDirection="column" alignItems="flex-start" sx={{ display: { sm: 'none', xl: 'flex' }}}>
              <Typography>Leaderboard</Typography>
              <Typography sx={{ fontSize: '.75em', fontWeight: 'bold', textTransform: 'uppercase' }}>$195,000</Typography>
            </Box>
            
          </NavButton>
        </Box>

        <Box display="flex" alignItems="center" gap="1rem">
            {profile ? (
              <>
                <NotificationMenu />
                <ProfileMenu profile={profile} />
              
              </>
            ) : (
              <>
                <Button disableRipple component="a" href={API_URL + "/auth/twitch/user"} sx={{ fontWeight: 'bold' }}>
                  Sign In
                </Button>
              </>
            )}
        </Box>
      </Box>
    </Box>
  )
}

export default Navbar   