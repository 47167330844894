export interface HypedropLeaderboardWinner {
    _id: string;
    displayName: string;
    wagered: number;
    deposited: number;
    prize?: number;
    placement?: number;
}  

export interface CSGOBIGLeaderboardWinner {
    _id: string;
    name: string;
    avatar: string;
    wagered: number;
    prize?: number;
    placement?: number;
}  

export interface GamdomLeaderboardWinner {
    name: string
    wagered: number
    prize: number
    placement: number
}

export interface LeaderboardWager {
    name: string
    placement: number
    prize: number
    wagered: number
}
export enum LeaderboardSite {
    STAKE = "stake",
    PACKDRAW = "packdraw",
    CASES = "cases_gg",
    RAIN = "rain_gg"
}