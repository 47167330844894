import React, { useState, useEffect} from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Button, styled } from '@mui/material'
import { useSelector } from 'react-redux'
import { selectContentCreators } from '../../redux/contentCreators/contentCreatorsSlice'
import { API_URL } from '../../utils'
import VideoBox from '../../components/HomePage/VideoBox'

import { selectProfile } from '../../redux/profile/profileSlice'
import LoadingCircle from '../../components/LoadingCircle'
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom'
import StreamerSection from './StreamerSection'
import Stats from '../../components/HomePage/Stats'
import { Tv } from '@mui/icons-material'

const FadeInContainer = styled(Box)(({ theme }) => ({
    
    //animationName: "streamerFadeIn",
    animationDuration: "900ms",
    //opacity: 0,

    animationFillMode: 'forwards',
    //transform: "translateY(200px)",
    animationDelay: '0',

    '@keyframes streamerFadeIn': {
        "0%": {
            opacity: 0,
            transform: "translateY(200px)"
        },
        "100%": {
            opacity: 1,
            transform: "translateY(0)"
        }

    }

}))

const HomePage = () => {

    const contentCreators = useSelector(selectContentCreators)
    const profile = useSelector(selectProfile);

    const [siteStats, setSiteStats] = useState<any>(null);
    const [loadingSiteStats, setLoadingSiteStats] = useState(true);
    const [youtubeVideos, setYoutubeVideos] = useState([]);
    const [loadingYoutubeVideos, setLoadingYoutubeVideos] = useState(true);

    const isSmallDevice = useMediaQuery({ query: '(max-width: 800px)' });

    useEffect(() => {
        fetch(`${API_URL}/highlights/videos`, {
            method: 'GET',
            credentials: 'include'
        })
            .then((response) => {
                if (!response.ok) return false
                else return response.json()
            }).then((data) => {
                if (data && !data.error) {
                    setYoutubeVideos(data);
                    setLoadingYoutubeVideos(false);
                }
            }).catch((err) => {
                setLoadingYoutubeVideos(false);
            });
        
        fetch(`${API_URL}/stats`, {
            method: 'GET',
            credentials: 'include'
        })           
            .then((response) => {
            if (!response.ok) return false
            else return response.json()
        }).then((data) => {
            if (data && !data.error) {
                setSiteStats(data);
                setLoadingSiteStats(false);
            }
        }).catch((err) => {
            setLoadingSiteStats(false);
        });


    }, [])

    return (
        <Box
            display="flex"
            flexDirection="column"
            className="page"
        >
            <Box display="flex" alignItems="center" justifyContent="center" marginTop="2rem">
                {profile ? (
                    <Typography textAlign="center" component="span" sx={{ fontSize: '48px'}}>Welcome back, <Typography component="span"
                        sx={{
                            fontSize: '48px',
                            textShadow: '0 0 16px #c8354f',
                            background: 'linear-gradient(140deg, rgba(200,53,79,1) 0%, rgba(255,133,139,0.9457983877144608) 50%, rgba(200,53,79,1) 100%)',
                            backgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                        }}>
                            {profile.display_name}</Typography> </Typography>
                ) : (
                    <Typography variant="h3" textAlign="center">Welcome to <Typography component="span" 
                            sx={{
                                fontSize: '48px',
                                textShadow: '0 0 16px #c8354f',
                                background: 'linear-gradient(140deg, rgba(200,53,79,1) 0%, rgba(255,133,139,0.9457983877144608) 50%, rgba(200,53,79,1) 100%)',
                                backgroundClip: 'text',
                                WebkitTextFillColor: 'transparent',
                            }}>
                            ProdigyDDK
                            </Typography> 
                    </Typography>
                )}
            </Box>
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                sx={{
                    padding: '7rem 0 0 0',
                }}
            >
                <StreamerSection />

                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    textAlign="center"
                    rowGap=".25rem"
                    sx={{ marginTop: '1.5rem', opacity: 0, animation: 'fadeInSmall .5s ease-in-out .6s forwards', color: 'var(--text-secondary)' }}
                >
                    <Typography fontSize="18px">
                        Watching our streamers rewards you with <Typography component="span" sx={{ color: 'primary.main' }}>Points!</Typography>
                    </Typography>
                    <Typography className='link' component={Link} to="/faq" fontSize="16px" sx={{ color: 'primary.main'}}>
                        Learn more
                    </Typography>
                </Box>

            </Box>

            <Box display="flex" flexDirection="column" gap="1rem" sx={{ p: '3.5rem 0 0 0'}}>
                <Typography component="span" sx={{ fontSize: '1.8em', fontWeight: 'bold'}}>Stream Highlights</Typography>
                <Typography sx={{ color: 'var(--text-secondary)' }}>Check out our recent stream Highlights</Typography>
                <Box component={Link} to="/highlights" className='link' sx={{ mr: 'auto', display: 'flex', gap: '1rem', alignItems: 'center' }}>
                    <Tv />
                    Highlights
                </Box>
            </Box>

            <Box sx={{ marginBottom: '7rem', marginTop: '7rem'}}>
                <Stats />
            </Box>

            <FadeInContainer
                display="flex"
                flexDirection="column"
                sx={{ animationDelay: '1500ms'}}
            >
                <Typography variant="h4" margin="2rem auto 2rem auto">Latest Videos</Typography>
                <Box>
                    {loadingYoutubeVideos ? (
                        <Box>
                            <LoadingCircle loadingText="Loading Videos..." />
                        </Box>
                    ) : !loadingYoutubeVideos && youtubeVideos.length === 0 ? (
                        <Box display="flex" alignItems="center" justifyContent="center">
                            <Typography component="span">Videos could not be found</Typography>
                        </Box>
                        ) : (
                  
                                    <Box className='styled-scroll' display="flex" alignItems="center" margin="0 auto" columnGap={"24px"} sx={{ padding: '1rem 0', overflow: 'auto', maxWidth: `${286 * 5 + 96}px` }}>
                                        {youtubeVideos.map((video, index) => (
                                            <VideoBox key={`video-${index}`} video={video} />
                                        ))}
                                    </Box>
                             
                    )}
                </Box>
            </FadeInContainer>
        </Box>
    )
}

export default HomePage