import React from 'react';
import { toast } from 'react-toastify';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { ReactComponent as Sword } from '../../icons/sword.svg'

export const createBattleStartedToast = (battleId: number) => {
    toast.dark(
        <Box display="flex" alignItems="center" sx={{ color: 'text.secondary' }}>
                <Sword style={{ height: '32px', width: '32px', color: 'var(--text-secondary)', marginRight: '.75rem' }} />
                <Typography sx={{ fontSize: '16px' }}>
                    Your Battle has started! <Link className='link' to={`/battle/${battleId}`}>Go to.</Link>
                </Typography>
        </Box>
        ,{
            position: 'bottom-right',
            progressStyle: {
                backgroundColor: "#d32f2f"
            }
        }
    )
}