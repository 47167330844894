import { Socket, io } from "socket.io-client";
import { API_URL } from "./utils";
import { battleFinished, battleStarted, playerAdded, setActiveRound } from "./redux/battle/battleSlice";
import { addBounty, bountyCompleted, setGamdomBountyHealth } from "./redux/bounty/bountySlice";
import { addBattle, battlePlayerJoined, removeBattle } from "./redux/battle/battlesSlice";
import { selectProfile } from "./redux/profile/profileSlice";
import { SlotBet, SlotBetTeam } from "./shared/slotbet.interface";
import { slotbetBetPlaced, slotbetUpdated } from "./redux/slotbet/slotbetSlice";

const socket: Socket = io(API_URL, {
    withCredentials: true
});     

export const connectSocket = () => {
    
    socket.on("connect", () => {
        clientPing();
    });

};

export const clientPing = () => {

    if(!localStorage.getItem('client')) {
       
    }

    const client = localStorage.getItem('client');

    if(socket) socket.emit("client", client);
}

export const joinBattleRoom = (battleId: string) => {

    if(socket && battleId) socket.emit("battle", battleId);

}

export const initializeSocketListeners = (dispatch: any, profile: any) => {

    if(!socket) return;

    socket.on("battle", (battleId: string | null, event: string | null, data: any | null) => {
        if(!battleId || !event || !data) return;
        if(event === "playerJoined"){
            dispatch(playerAdded({ battleId: battleId, player: data }))
        } else if (event === "newRound") {
            dispatch(setActiveRound({ battleId: battleId, round: data }))
        } else if (event === "battleStarted") {
            dispatch(battleStarted({ battleId: battleId, userId: profile?._id, data: data }))
        } else if (event === "battleFinished") {
            dispatch(battleFinished({ battleId: battleId, data: data }))
        }
    })

    socket.on("battles", (battleId: string | null, event: string | null, data: any | null) => {
        if(!battleId || !event) return;

        if(event === "battleFinished") {
            dispatch(removeBattle({ battleId: battleId }))
        }

        if(!data) return;

        if(event === "playerJoined"){
            dispatch(battlePlayerJoined({ battleId: battleId, player: data }))
        }

        if(event === "battleCreated") {
            dispatch(addBattle(data))
        }


    })

    socket.on('slotbetUpdated', (data: SlotBet) => {
        dispatch(slotbetUpdated(data))
    })

    socket.on('slotbetStatusUpdated', (data: { _id: string, status: number}) => {
        dispatch(slotbetUpdated(data))
    })

    socket.on('slotbetBetPlaced', (data: SlotBetTeam) => {
        dispatch(slotbetBetPlaced(data))
    })

    socket.on('newBounty', (data: any) => {
        dispatch(addBounty(data))
    })

    socket.on('bountyCompleted', (data: any) => {
        dispatch(bountyCompleted(data))
    })

    socket.on('gamdomBountyHealth', (data: any) => {
        dispatch(setGamdomBountyHealth(data))
    })
}

export { socket }