import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import LoadingCircle from '../../components/LoadingCircle'
import { fetchAPI } from '../../utils'
import { styled } from '@mui/material'
import { BattleStatus, IBattle } from '../../shared/battle.interface'
import { Link } from 'react-router-dom'
import CreateBattleModal from '../../components/Battle/CreateBattleModal'
import { selectProfile } from '../../redux/profile/profileSlice'
import { useDispatch, useSelector } from 'react-redux'
import { selectBattles, setBattles } from '../../redux/battle/battlesSlice'
import { ReactComponent as Swords } from '../../icons/sword.svg'

const BattleRowContainer = styled(Box)(( { theme }) => ({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    columnGap: '1rem',
    position: 'relative',
    padding: '.5rem 1rem',
    borderRadius: '12px',
    background: 'var(--primary-bg-soft)',
    color: 'var(--text-primary)',

    '@media (max-width: 800px)': {
        
        display: 'grid',
        rowGap: '1rem',

        '.rounds': {
            display: 'none !important'
        },

        '.status': {
            gridRow: 1,
            textAlign: 'start !important',
            display: 'block !important',
            textTransform: 'capitalize'
        },

        '.cost': {
            gridRow: 3,
            alignItems: 'start',
            width: '100% !important'
        },

        '.players': {
            gridRow: 1,
            flexDirection: 'row',
            justifyContent: 'end',
            alignItems: 'center',
            columnGap: '1rem',
            width: '100% !important'
        },

        '.box-container': {
            gridRow: 2,
            width: '100%',
            gridColumn: "span 2",

        },

        '.view': {
            gridRow: 3,
            width: '100%',
            textAlign: 'end !important'
        }

    },

    '&::after': {
        content: '""',
        position: 'absolute',
        top: '0',
        left: '0',
        height: '100%',
        width: '100%',
        borderRadius: '12px',
        background: 'linear-gradient(135deg, rgba(23, 20, 119, .05) 33%, rgba(85, 30, 119, .2) 100%)',
        pointerEvents: 'none'
    },

    '.status': {
        textAlign: 'center',
        fontSize: '18px',
        minWidth: '80px',
        display: 'none'
    },

    '.rounds': {
        display: 'block',
        fontSize: '24px',
        minWidth: '80px',
        textAlign: 'center'
    },

    '.cost': {
        width: '15%',
        minWidth: '120px',
        textAlign: 'center',
        fontSize: '24px',
    },

    '.players': {
        width: '15%',
        minWidth: '120px',
    },

    '.box-container': {
        backgroundColor: 'rgba(0,0,0, .2)',
        padding: '.25rem 1rem',
        borderRadius: '.5rem'
    },

    '.box': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',

        'img': {
            height: '80px',
            width: '80px'
        },

        'p': {
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            maxWidth: '80px'
        }
    },

    '& > a': {
        width: '15%',
        textAlign: 'center',
    }
}))

const CreateBattleButton = styled(Button)(( { theme }) => ({
    backgroundColor: 'var(--primary-bg-light)',
    padding: '.75rem 1.25rem !important',
    borderRadius: '12px',
    position: 'relative',

    '&:hover': {
        backgroundColor: 'var(--primary-bg-highlight)',
    },

    '&:active': {
        backgroundColor: 'var(--primary-bg-dark)',
    }
}))

const BattleRow = ({ battle }: { battle: IBattle}) => {
    return (
        <BattleRowContainer>
            <Box display="flex" className="rounds">
                <Typography sx={{ fontSize: '24px' }}>{battle.cases.length}</Typography>
                <Typography sx={{ fontSize: '14px', color: 'var(--text-secondary)' }}>Rounds</Typography>
            </Box>
            
            <Typography className="status">{BattleStatus[battle.status.toString()].toLowerCase()}</Typography>
            <Box display="flex" className='box-container styled-scroll' alignItems="center" columnGap="1.5rem" sx={{ overflowY: 'auto', width: '100%', maxWidth: '100%' }}>
                {battle.cases.map((battleCase: any, index: number) => (
                    <Box className='box' key={`battle-case-${index}`}>
                        <img src={battleCase.image} alt="" />
                        <Typography>{battleCase.name}</Typography>
                    </Box>
                ))}
            </Box>
            <Box className="players" rowGap=".5rem" display="flex" flexDirection="column" alignItems="center">
                <span style={{ fontSize: '18px' }}>{battle.players.length}<span style={{ fontSize: '16px' }}>/</span>{battle.slots}</span>
                <Box display="flex" columnGap=".25rem">
                    {[...Array(battle.slots)].map((i: number, index: number) => (
                        <React.Fragment key={`battle-player-${index}`}>
                            {battle.players[index] ? (
                                <img src={battle.players[index]?.user.avatar} alt="" style={{ height: '28px', width: '28px', borderRadius: '50%', border: '2px solid transparent', borderColor: battle.players[index]?.isWinner ? "var(--success)" : "var(--primary-bg-highlight)" }} />
                            ) : (
                                <img src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" alt="" style={{ height: '28px', width: '28px', borderRadius: '50%', border: '2px solid var(--primary-bg-highlight)' }} />
                            )}

                        </React.Fragment>

                    ))}
                </Box>
            </Box>
            <Box className="cost" display="flex" flexDirection="column">
                <Typography>{Intl.NumberFormat('en-US').format(battle.cost || 0)}</Typography>
                {battle.isGiveaway && (
                    <Typography sx={{ fontSize: '14px', color: 'var(--text-secondary)' }}>Giveaway</Typography>
                )}

            </Box>
            <Link className="view" to={`/battle/${battle.battleId}`}>
                <Button
                    variant="contained"
                    disableRipple
                    color="secondary"
                    sx={{ textTransform: 'capitalize' }}
                >
                    View
                </Button>
            </Link>
        </BattleRowContainer>
    )
}

const BattleListPage = () => {

    const dispatch = useDispatch();

    const profile = useSelector(selectProfile);

    const [createBattleModalOpen, setCreateBattleModalOpen] = useState(false);

    const battles: Array<IBattle> = useSelector(selectBattles);

    const [isLoading, setIsLoading] = useState(true)


    useEffect(() => {
        const fetchBattleHistory = async () => {
            try {
                const response: any = await fetchAPI(`/battles`, 'GET');

                dispatch(setBattles(response));
                
                setIsLoading(false);

            } catch (e) {

            }
        }

        fetchBattleHistory();
    }, [dispatch])


    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            className="page"
        >

            <Box display="flex" flexDirection="column" alignItems="center" sx={{ marginBottom: '2rem' }}>
            <Typography variant="h3" sx={{ color: 'var(--text-primary)' }}>Battles</Typography>
                {profile && (
                    <Typography component="p" sx={{ color: 'var(--text-secondary)', marginTop: '.5rem' }}>
                        You currently have <Typography component="span" style={{ color: 'var(--primary-main)' }}>{profile.points}</Typography> Points
                    </Typography>
                )}
            </Box>


            
            {profile && (
                <Box display="flex" flexDirection="column" width="100%" alignItems="center" justifyContent="center" rowGap="1rem">
                    <CreateBattleButton
                        onClick={() => setCreateBattleModalOpen(true)}
                        disableRipple
                        startIcon={<Swords />}
                        variant="contained"
                        sx={{ textTransform: 'capitalize', marginBottom: '1rem', padding: '10px 16px' }}
                    > 
                    <Typography>
                        Create Battle
                    </Typography>
                        
                    </CreateBattleButton>

                    <Link to="/battles/history/my" style={{ alignSelf: 'flex-end' }}>
                        <Button
                            variant="text"
                            disableRipple
                            sx={{
                                fontSize: '16px',
                                textTransform: 'none',
                                color: 'var(--text-secondary)',
                                '&:hover': {
                                    color: 'var(--text-primary)',
                                    backgroundColor: 'transparent'
                                },

                                '&:active': {
                                    color: 'text.disabled'
                                },
                                marginBottom: '1rem'
                            }}
                        >
                            My Battle History
                        </Button>
                    </Link>
                </Box>

            )}

            {isLoading ? (
                <LoadingCircle loadingText="Loading your battle history..." />
            ) : (
                <>
                    {battles.length === 0 ? (
                        <Typography variant="h4" marginTop="1rem">No ongoing Battles</Typography>
                    ) : (
                        <Box display="flex" flexDirection="column" rowGap=".75rem" width="100%">
                            {battles.map((battle: IBattle, index: number) => (
                                <BattleRow battle={battle} key={`battle-${index}`} />
                            ))}
                        </Box>
                    )}
                </>
                
            )}
            <CreateBattleModal open={createBattleModalOpen} handleClose={() => setCreateBattleModalOpen(false)} />
        </Box>
    )
}

export default BattleListPage