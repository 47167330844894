import React from 'react';
import { toast } from 'react-toastify';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Item } from '../../shared/item.interface';

export const promocodeItemClaimed = (item: Item) => {
    toast.dark(
      <Box display="flex" alignItems="center" sx={{ color: "text.secondary" }}>
        <img
          src={item.image}
          alt={item.name}
          style={{ height: "32px", width: "32px", marginRight: ".75rem" }}
        />
        <Box display="flex" flexDirection="column">
        <Typography sx={{ fontSize: "18px", fontWeight: 'bold' }}>
            Promocode Claimed!
          </Typography>
          <Typography sx={{ fontSize: "16px" }}>
            <b>{item.name}</b> added to your Inventory!
          </Typography>
        </Box>
      </Box>,
      {
        position: "bottom-right",
        progressStyle: {
          backgroundColor: "var(--success)",
        },
      }
    );
}

export const promocodePointsClaimed = (points: number) => {
    toast.dark(
      <Box display="flex" alignItems="center" sx={{ color: "text.secondary" }}>
        <Box display="flex" flexDirection="column">
          <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
            Promocode Claimed!
          </Typography>
          <Typography sx={{ fontSize: "16px" }}>
            <b>{points}</b> points added to your account!
          </Typography>
        </Box>
      </Box>,
      {
        position: "bottom-right",
        progressStyle: {
          backgroundColor: "var(--success)",
        },
      }
    );
}