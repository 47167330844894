import React, { useEffect, useState } from 'react'
import { BonusHunt, BonusHuntSlotGuess } from './types'
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, TextField, Typography } from '@mui/material'
import { Search } from '@mui/icons-material'
import SlotGuessDialog from './SlotGuess'
import DisableBettingOnSlot from './DisableBettingOnSlot'
import EnableBettingOnSlot from './EnableBettingOnSlot'

enum BonusSortOption {
    ID = "id",
    NAME = "name",
    BET = "bet",
    MULTIPLIER = "multiplier",
    PAYOUT = "payout"
  }

const BonusesTable = ({ onSlotDisabled, onSlotEnabled, isAdmin, isGuessingSlot, slotGuess, isLoading, bonuses, bonusHuntId, bonusCount, onSlotGuessPlaced }: { onSlotEnabled?: (name: string) => void, onSlotDisabled?: (name: string) => void, isAdmin?: boolean, bonusHuntId?: number, bonusCount?: number, onSlotGuessPlaced?: (guess: BonusHuntSlotGuess) => void, isGuessingSlot?: boolean, slotGuess?: BonusHuntSlotGuess, isLoading: boolean, bonuses?: BonusHunt["bonuses"] }) => {


    const [filteredBonuses, setFilteredBonuses] = useState<BonusHunt["bonuses"]>([])
    const [bonusName, setBonusName] = useState("")
  
    const [sortBy, setSortBy] = useState<{ key: BonusSortOption, order: "asc" | "desc" }>({
      key: BonusSortOption.ID,
      order: "desc"
    })
  
    useEffect(() => {
      if(bonuses)
        setFilteredBonuses(bonuses)
    }, [bonuses])
  
    useEffect(() => {
      if(bonuses) {
        const reg = new RegExp(bonusName, "i")
        setFilteredBonuses(bonuses.filter((bonus) => bonus.name.match(reg)))
      }
        
    }, [bonuses, bonusName])
  
    async function onSortChange(sortOption: BonusSortOption) {
      if(sortBy.key === sortOption) {
        setSortBy(prev => ({
          key: prev.key,
          order: prev.order === "asc" ? "desc" : "asc"
        })) 
      } else {
        setSortBy({
          key: sortOption,
          order: "desc"
        })
      }
    }
  
  
    useEffect(() => {
      filteredBonuses.sort((a, b) => {
        switch(sortBy.key) {
          case BonusSortOption.ID:
            return sortBy.order === "asc" ? a.id - b.id : b.id - a.id
          case BonusSortOption.NAME:
            return sortBy.order === "asc" ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name)
          case BonusSortOption.BET:
            return sortBy.order === "asc" ? a.betSize - b.betSize : b.betSize - a.betSize
          case BonusSortOption.MULTIPLIER:
            return sortBy.order === "asc" ? (a.multiplier || 0) - (b.multiplier || 0) : (b.multiplier || 0) - (a.multiplier || 0)
          case BonusSortOption.PAYOUT:
            return sortBy.order === "asc" ? (a.payout || 0) - (b.payout || 0) : (b.payout || 0) - (a.payout || 0)
          default:
            return sortBy.order === "asc" ? a.id - b.id : b.id - a.id
        }
      })
    }, [filteredBonuses, sortBy])
  
    return (
      <Box display="flex" flexDirection="column" gap="2rem">
  
          <TextField 
            InputProps={{
              sx: {
                backgroundColor: 'var(--primary-bg-soft)',
                borderColor: 'var(--primary-border)'
              },
              startAdornment: <Search sx={{ mr: '1rem' }} />
            }} 
            placeholder="Search for a Slot..."
            value={bonusName}
            
            onChange={(e) => setBonusName(e.target.value)}
          />
      
          <TableContainer sx={{ width: '100%', minHeight: '600px', maxHeight: '600px', overflow: 'auto', backgroundColor: 'var(--primary-bg-soft)', borderRadius: '.5rem', border: '2px solid var(--primary-border)' }}>
            <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell 
                        align="left"
                        sx={{ fontWeight: 'bold', textTransform: 'uppercase', width: '48px', borderColor: 'var(--primary-border)', backgroundColor: 'var(--primary-bg-dark)' }}
                        sortDirection={sortBy.key === BonusSortOption.ID ? sortBy.order : false}
                      >
                        <TableSortLabel 
                          active={sortBy.key === BonusSortOption.ID}
                          direction={sortBy.key === BonusSortOption.ID ? sortBy.order : "desc"}
                          onClick={() => onSortChange(BonusSortOption.ID)}
                        >
                          #
                        </TableSortLabel>
                      </TableCell>
                    <TableCell 
                      sx={{ fontWeight: 'bold', textTransform: 'uppercase', borderColor: 'var(--primary-border)', backgroundColor: 'var(--primary-bg-dark)' }}
                      sortDirection={sortBy.key === BonusSortOption.NAME ? sortBy.order : false}
                    >
                      <TableSortLabel 
                          active={sortBy.key === BonusSortOption.NAME}
                          direction={sortBy.key === BonusSortOption.NAME ? sortBy.order : "desc"}
                          onClick={() => onSortChange(BonusSortOption.NAME)}
                        >
                          Slot
                      </TableSortLabel>
                    </TableCell>
                    <TableCell 
                      sx={{ fontWeight: 'bold', textTransform: 'uppercase', borderColor: 'var(--primary-border)', backgroundColor: 'var(--primary-bg-dark)' }}
                      sortDirection={sortBy.key === BonusSortOption.BET ? sortBy.order : false}
                    >
                        <TableSortLabel 
                          active={sortBy.key === BonusSortOption.BET}
                          direction={sortBy.key === BonusSortOption.BET ? sortBy.order : "desc"}
                          onClick={() => onSortChange(BonusSortOption.BET)}
                        >
                          Bet
                      </TableSortLabel>
                    </TableCell>
                    <TableCell 
                      sx={{ fontWeight: 'bold', textTransform: 'uppercase', width: '96px', borderColor: 'var(--primary-border)', backgroundColor: 'var(--primary-bg-dark)' }}
                      sortDirection={sortBy.key === BonusSortOption.MULTIPLIER ? sortBy.order : false}
                    >
                        <TableSortLabel 
                          active={sortBy.key === BonusSortOption.MULTIPLIER}
                          direction={sortBy.key === BonusSortOption.MULTIPLIER ? sortBy.order : "desc"}
                          onClick={() => onSortChange(BonusSortOption.MULTIPLIER)}
                        >
                          Multiplier
                      </TableSortLabel>
                    </TableCell>
                    <TableCell 
                      align="right"
                      sx={{ fontWeight: 'bold', textTransform: 'uppercase', borderColor: 'var(--primary-border)', backgroundColor: 'var(--primary-bg-dark)' }}
                      sortDirection={sortBy.key === BonusSortOption.PAYOUT ? sortBy.order : false}
                    >
                      <TableSortLabel 
                          active={sortBy.key === BonusSortOption.PAYOUT}
                          direction={sortBy.key === BonusSortOption.PAYOUT ? sortBy.order : "desc"}
                          onClick={() => onSortChange(BonusSortOption.PAYOUT)}                     
                        >
                          Win
                      </TableSortLabel>
                    </TableCell>
                    {(isAdmin || isGuessingSlot) && (
                      <TableCell                      
                        align="right"
                        sx={{ fontWeight: 'bold', textTransform: 'uppercase', borderColor: 'var(--primary-border)', backgroundColor: 'var(--primary-bg-dark)' }}
                      >

                      </TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                {isLoading ? (
                <></>
              ) : !bonuses ? (
                <TableRow>
                  <TableCell sx={{ borderColor: 'var(--primary-border)', textAlign: 'center', fontStyle: 'italic' }} colSpan={5}>Bonus Hunt not found.</TableCell>
                </TableRow>
              ) : filteredBonuses.length === 0 ? (
                <TableRow>
                  <TableCell sx={{ borderColor: 'var(--primary-border)', textAlign: 'center', fontStyle: 'italic' }} colSpan={5}>No bonuses found.</TableCell>
                </TableRow>
              
              ) : filteredBonuses.map((bonus) => (
                  <TableRow 
                    key={`bonus-${bonus.id}`} 
                    sx={{ 
                      '&:nth-of-type(even)': {
                        "td": {
                          backgroundColor: 'var(--primary-bg-dark)'
                        }
                        
                      },
                    }}
                  >
                    <TableCell sx={{ borderColor: 'var(--primary-border)' }}>{bonus.id}</TableCell>
                    <TableCell sx={{ borderColor: 'var(--primary-border)'}}>
                      <Box display="flex" flexDirection="column">
                        <Typography>{bonus.name} </Typography>
                        {slotGuess && slotGuess.slotName === bonus.name && (
                          <Typography component="span" sx={{fontWeight: 'bold', fontSize: '.9em' }}><Typography component="span" sx={{ fontSize: '1em', color: 'var(--text-disabled)' }}>Your Bet:</Typography> {Intl.NumberFormat("en-US").format(slotGuess.bet)} {(slotGuess.payout || 0) >= 0 && `(Payout: ${Intl.NumberFormat("en-us").format(slotGuess.payout || 0)})`}</Typography>
                        )}
                      </Box>
  
                    </TableCell>
                    <TableCell sx={{ borderColor: 'var(--primary-border)' }}>${Intl.NumberFormat("en-US").format(bonus.betSize)}</TableCell>
                    <TableCell sx={{ borderColor: 'var(--primary-border)' }}>{bonus.multiplier ? `${Intl.NumberFormat("en-US").format(bonus.multiplier)}x` : "0x"}</TableCell>
                    <TableCell sx={{ borderColor: 'var(--primary-border)', textAlign: 'right' }}>{bonus.payout ? `$${Intl.NumberFormat("en-US").format(bonus.payout)}` : "N/A"}</TableCell>
                    {!bonus.isBettingDisabled && bonusCount && bonusHuntId && isGuessingSlot && onSlotGuessPlaced && (
                      <TableCell sx={{ width: '160px', maxWidth: '160px', borderColor: 'var(--primary-border)', textAlign: 'right' }}>
                          <SlotGuessDialog bonusHuntId={bonusHuntId} name={bonus.name} bonusCount={bonusCount} onSlotGuessPlaced={onSlotGuessPlaced} />
                      </TableCell>
                    )}
                    {isAdmin && bonusHuntId && (
                        <TableCell sx={{ width: '160px', maxWidth: '160px', borderColor: 'var(--primary-border)', textAlign: 'right' }}>
                          {!bonus.isBettingDisabled ? (
                            <>
                              {onSlotDisabled && (
                                 <DisableBettingOnSlot onSlotDisabled={onSlotDisabled} huntId={bonusHuntId} slotName={bonus.name} />
                              )}
                            </>
                           
                          ) : (
                            <>
                              {onSlotEnabled && (
                                <EnableBettingOnSlot onSlotEnabled={onSlotEnabled} huntId={bonusHuntId} slotName={bonus.name} />
                              )
                              }
                            </>
                            
                          )}
                        </TableCell>
                    )}
                  </TableRow>
                ))}
                </TableBody>
            </Table>
          </TableContainer>
  
      </Box>
    )
  }

export default BonusesTable