import React from 'react'
import Modal from '../../Modal'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { fetchAPI } from '../../../utils';
import { createSuccessToast } from '../../Toasts/SuccessToasts';
import { ReactComponent as Kick } from '../../../icons/sites/kick.svg';
import { useDispatch } from 'react-redux';
import { unlinkKick } from '../../../redux/profile/profileSlice';

type UnlinkKickModalProps = {
    open: boolean
    handleClose: () => void
}

const UnlinkKickModal = ({ open, handleClose }: UnlinkKickModalProps) => {

    const dispatch = useDispatch()

    async function unlink() {

        try {
            const data: any = await fetchAPI('/user/kick/unlink', 'POST')

            if (data) {
                createSuccessToast('Kick account unlinked successfully');
                dispatch(unlinkKick())
                handleClose()
            }

        } catch (e) {

        }
        
    }
 
  return (
    <React.Fragment>
          <Modal
              open={open}
              handleClose={handleClose}
              width={"500px"}
          >
              <Box display="flex" flexDirection="column" alignItems="flex-start" rowGap="2rem">
                  <Box display="flex" alignItems="center" justifyContent="center">
                      <Kick style={{ height: '32px', width: '32px' }} />
                      <Typography variant="h5" alignSelf="flex-start" marginLeft=".5rem">Unlink Kick</Typography>
                  </Box>
                    <Typography>Are you sure you want to unlink your Kick account?</Typography>
                    <Box display="flex" width="100%">
                        <Button variant="contained" color="success" sx={{ alignSelf: 'flex-start' }} onClick={() => unlink()}>Confirm</Button>
                        <Button variant="contained" color="error" sx={{ marginLeft: 'auto' }} onClick={() => handleClose()}>Close</Button>
                    </Box>

              </Box>
          </Modal>
    </React.Fragment>
  )
}

export default UnlinkKickModal