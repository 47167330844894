import React, { useState } from 'react'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import Box from '@mui/material/Box'
import { styled } from '@mui/material'
import { getTimeAgo } from '../../utils'
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

const Container = styled(Box)(( { theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'var(--primary-bg-soft)',
    color: 'rgba(240, 240, 240, 1)',
    border: '1px solid var(--primary-border)',
    borderRadius: '.75rem',
    paddingBottom: '.75rem',
    position: 'relative',
    boxShadow: '0 0 6px rgba(16, 16, 24, .75)',
    transition: 'transform .3s ease-in-out',

    '&:hover': {
        transform: 'scale(1.02)',
    },

    'img': {
        width: '280px',
        height: '157px',
        borderRadius: '.75rem .75rem 0 0',
        zIndex: '1',
        boxShadow: 'inset 0 0 6px rgba(16, 16, 24, .75)',
    },

    '.overlay': {
        width: '280px',
        height: '157px',
        zIndex: '3',
        position: 'absolute',
        top: '0',
        backgroundColor: 'rgba(0, 0, 0, .85)',
        borderRadius: '.75rem .75rem 0 0'
    },

    '.title': {
        textOverflow: 'ellipsis',
        whiteSpace: 'break-word',
        padding: '0 .25rem',
        overflow: 'hidden',
        maxHeight: '2.8em',
        minHeight: '2.8em',
        lineHeight: '1.4em',
        maxWidth: '280px',
        color: 'rgb(255, 255, 255, .75)',
    },

    '.date': {
        position: 'absolute',
        top: '123px',
        left: '5px',
        backgroundColor: 'rgba(20, 20, 36, .9)',
        borderRadius: '.33rem',
        fontSize: '14px',
        padding: '.25rem',
        zIndex: '2'
    },

})) as typeof Box

const creators: {
    [key: string]: {
        image: string;
        name: string;
    }
} = {
    'UCZTRgf0h-OAGXR7uP2iKc6g': {
        image: 'https://static-cdn.jtvnw.net/jtv_user_pictures/e2182bd6-6cac-46ef-8e6e-73042fdf6c30-profile_image-300x300.png',
        name: 'ProdigyDDK'
    },
    'UCUDM_NArV9eSkFoNQ1FrmNg': {
        image: 'https://yt3.googleusercontent.com/V_N0-5FC6p37oE6fyGPOA8OrhLdkqD0_1kfQoE_dEuERX6yhIsUQ61cqTUow0nDiZ0ooIEePeRM=s88-c-k-c0x00ffffff-no-rj',
        name: 'KranzzOfficial'
    }
}

type VideoProps = {
    video: {
        snippet: {
            channelId: string;
            publishedAt: string;
            title: string;
            thumbnails: {
                medium: {
                    url: string;
                }
            },
            resourceId: {
                videoId: string;
            }
        }
    }
}

const VideoBox = ({ video }: VideoProps) => {

    const [hoveringVideo, setHoveringVideo] = useState(false);

   return (
      <Container onMouseEnter={() => setHoveringVideo(true)} onMouseLeave={() => setHoveringVideo(false)} component="a" href={`https://www.youtube.com/watch?v=${video.snippet.resourceId.videoId}`} target="_blank">
           {hoveringVideo && (
               <Box className='overlay' display="flex" alignItems="center" justifyContent="center" style={{ opacity: 1, animation: 'opacityFadeIn 500ms' }}>
                   <PlayArrowIcon sx={{ height: '32px', width: '32px', marginRight: '.25rem'}} />
                   <Typography fontSize="18px">
                       Play
                   </Typography>
                </Box>
            )}
           <img src={video.snippet.thumbnails.medium.url} alt="" />
           <Typography className='date'>{getTimeAgo(new Date(video.snippet.publishedAt))}</Typography>
           
           <Tooltip title={video.snippet.title}>
               <Box
                   display="flex"
                   flexDirection="column"
                   justifyContent="center"
                   sx={{ padding: '1rem .75rem 0 .75rem' }}
               >
                   <Box
                       display="flex"
                       alignItems="center"
                       sx={{ marginBottom: '.75rem' }}
                   >
                       <img src={creators[video.snippet.channelId].image} alt="" style={{ width: '32px', height: '32px', borderRadius: '50%' }} />
                       <Typography sx={{ marginLeft: '.75rem', fontSize: '18px', color: 'rgb(255, 255, 255, .9)' }}>{creators[video.snippet.channelId].name}</Typography>
                   </Box>
                   <Typography className='title'>
                       {video.snippet.title}
                   </Typography>
               </Box>

          </Tooltip>
    </Container>
  )
}

export default VideoBox