import React, { useEffect, useState } from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { styled } from '@mui/material'

import Casino500 from '../../icons/casino500.png'
import Gamdom from '../../icons/gamdom.png'

import BlueGem from '../../icons/gems/bluegem.png'
import PurpleGem from '../../icons/gems/purplegem.png'
import RedGem from '../../icons/gems/redgem.png'
import YellowGem from '../../icons/gems/yellowgem.png'

const GiveawayContainer = styled(Box)(( { theme }) => ({
    backgroundColor: 'var(--primary-bg)',
    borderRadius: 12,
    margin: '0 .5rem',
    minWidth: 285,
    boxShadow: '0 0 3px rgba(16, 16, 24, .75)',
    border: '1px solid transparent',
    borderColor: theme.palette.primary.border + '25',
    position: 'relative'
}))

const ContainerFade = styled(Box)(( { theme }) => ({
    borderRadius: 'inherit',
    background: 'radial-gradient(at 50% 0%, rgba(90,130,250,0.35) 10%, rgba(255,255,255,0) 90%)',
    position: 'absolute',
    width: '100%',
    height: '100%'
}))

const FadeLine = styled('span')(( { theme }) => ({
    background: 'linear-gradient(90deg, rgba(0,0,0,0) 8%, rgba(100,140,255,1) 50%, rgba(0,0,0,0) 92%)',
    height: '2px',
    width: '100%',
    position: 'absolute',
    top: '0',
}))


const UserBox = styled(Box)(( { theme }) => ({
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.secondary,

    'img': {
        height: '20px',
        width: '20px',
        borderRadius: '25%',
        marginRight: '.4rem',
        boxShadow: '0 0 .2rem rgb(16, 24, 32)'
    }
}))

const ContentContainer = styled(Box)(( { theme }) => ({

    zIndex: 1
}))

const TimeAgoText = styled(Typography)(( { theme }) => ({
    color: theme.palette.text.secondary,
}))


const getGiveawayRarityColor = (amount) => {
    if(amount < 25){
        return 'rgb(82, 137, 217)'
    } else if (amount >= 25 && amount < 100){
        return 'rgb(136, 81, 237)'
    } else if (amount >= 100 && amount < 200) {
        return 'rgb(235,75,75)'
    } else {
        return 'rgb(202,171,5)'
    }
}

const getGiveawayBackgroundRarityColor = (amount) => {
    if(amount < 25){
        return 'rgba(82, 137, 217, 0.7)'
    } else if (amount >= 25 && amount < 100){
        return 'rgba(146, 91, 245, 0.5)'
    } else if (amount >= 100 && amount < 200) {
        return 'rgba(235,75,75, 0.5)'
    } else {
        return 'rgba(202,171,5, 0.5)'
    }
}

const getGem = (amount) => {
    if(amount < 25){
        return <img src={BlueGem} alt="" style={{ height: '52px', width: '52px', filter: "drop-shadow(0 0 4px rgba(0, 0, 0, 1))" }} />
    } else if (amount >= 25 && amount < 100){
        return <img src={PurpleGem} alt="" style={{ height: '52px', width: '52px', filter: "drop-shadow(0 0 4px rgba(0, 0, 0, 1))" }} />
    } else if (amount >= 100 && amount < 200) {
        return <img src={RedGem} alt="" style={{ height: '52px', width: '52px', filter: "drop-shadow(0 0 4px rgba(0, 0, 0, 1))" }} />
    } else {
        return <img src={YellowGem} alt="" style={{ height: '52px', width: '52px', filter: "drop-shadow(0 0 4px rgba(0, 0, 0, 1))" }} />
    }
}





const CommonGiveawayBox = ({ amount, user, date, type, casino }) => {
    const [countdownString, setCountdownString] = useState("");

    const getTimeAgo = (time) => {

        var days = Math.floor(time / (1000 * 60 * 60 * 24));
        var hours = Math.floor((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));

        let countString = ``

        if(days > 0) countString += `${days}d`
            else if(hours > 0) countString += `${hours}h`
            else if (minutes > 0) countString += `${minutes}m`
        
        setCountdownString(countString);
    }

    useEffect(() => {

        if(!date){
            return;
        }

        let now = new Date();
        let distance = now - new Date(date).getTime();
        getTimeAgo(distance);

        const interval = setInterval(() => {
            let now = new Date();
            let distance = now - new Date(date).getTime();
            getTimeAgo(distance);
        }, 1000 * 60)

        return () => {
            clearInterval(interval);
        }
        

    }, [date])


    return (
        <GiveawayContainer
            display="flex"
            flexDirection="column"
            position="relative"
        >            
            <ContainerFade sx={{ background: `radial-gradient(at 50% 0%, ${getGiveawayBackgroundRarityColor(amount)} -5%, rgba(255,255,255,0) 90%)`}} />
            <FadeLine sx={{ background: `linear-gradient(90deg, rgba(0,0,0,0) 8%, ${getGiveawayRarityColor(amount)} 50%, rgba(0,0,0,0) 92%)`}} />
            <ContentContainer
                display="flex"
                alignItems="center"
                sx={{
                    padding: '16px 12px',
                }}
            >
                {getGem(amount)}
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"

                    sx={{
                        marginLeft: 2
                    }}
                >
                    <Typography
                        sx={{ fontSize: '12px', color: 'text.primary' }}
                    >
                        Stake Tip
                    </Typography>
                    <Typography
                        fontWeight="bold"
                    >
                        ${amount}
                    </Typography>
                </Box>
            </ContentContainer>
            <Box
                display="flex"      
                sx={{
                    marginTop: 'auto',
                    padding: '8px 16px'
                }}    
            >
                {user ? (
                    <UserBox>
                        <img src={user.avatar} alt="" />
                        <Typography sx={{
                            textOverflow: 'ellipsis',
                            maxWidth: '150px',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap'
                        }}>
                            {user.display_name}
                        </Typography>
                    </UserBox>
                ) : null}


                <Typography
                    sx={{
                        marginLeft: 'auto',
                        color: 'text.secondary'
                    }}
                >
                    {countdownString ? countdownString + ' ago' : 'Just now'}
                </Typography>
            </Box>
            
        </GiveawayContainer>
    )
}

export default CommonGiveawayBox
