export type Bonus = {
    id: number;
    name: string
    note?: string
    providerId: number
    providerName: string
    betSize: number
    multiplier?: number
    payout?: number
    addedAt: string
    isBettingDisabled?: boolean
}

export enum BonusHuntGuessState {
    NOT_STARTED = "not_started",
    OPEN = "open",
    CLOSED = "closed",
    COMPLETED = "completed",
    CANCELED = "canceled"
}   

export enum GuessState {
    CREATED = "created",
    COMPLETED = "completed",
    CANCELED = "canceled"
}   

export type BonusHuntGuess = {
    _id: string
    guess: number
    bet: number
    payout?: number
    payoutMultiplier?: number
    guessType?: BonusHuntGuessType
    state: GuessState
    user: {
        display_name: string
        avatar: string
    }
    createdAt: string
}

export type BonusHuntSlotGuess = {
    _id: string
    bet: number
    slotName: string
    payout?: number
    payoutMultiplier?: number
    state: GuessState
    user: {
        display_name: string
        avatar: string
    }
    createdAt: string
}


export type BonusHunt = {
    huntId: number;
    bhtHuntId: string;
    name: string;
    streamer?: {
        display_name: string;
        image: string;
        kickUrl: string;
    },
    createdAt: Date;
    updatedAt: Date;
    totalBonuses: number;
    openedBonuses: number;
    over100xWins: number;
    startCost: number;
    totalPayout?: number;
    highestPayout?: number;
    highestMulti?: number;
    requiredAverage: number;
    runningAverage?: number;
    averagePayout?: number;
    bonuses: Bonus[];
    isCompleted: boolean;
    profit: number;
    guessState: BonusHuntGuessState;
}

export enum BonusHuntSortOption {
    ID = "id",
    PROFIT = "profit",
    BONUSES = "bonuses",
    START_COST = "startCost",
    HIGHEST_X = "highestX",
    BIGGEST_WIN = "biggestWin",
    DATE = "date"
}

export enum BonusHuntGuessSortOption {
    GUESS = "guess",
    BET = "bet",
    PAYOUT = "payout",
    DATE = "date"
}

export enum BonusHuntSlotGuessSortOption {
    BET = "bet",
    PAYOUT = "payout",
    DATE = "date"
}

export enum BonusHuntGuessType {
    TEN_PERCENT = "10",
    FIVE_PERCENT = "5",
    ONE_PERCENT = "1",
    EXACT = "exact"
}
