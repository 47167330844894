import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { API_URL } from '../../utils';
import { formatDate } from '../../utils';
import TimeIcon from '@mui/icons-material/AccessTime';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { styled } from '@mui/material'
import { ECommonGiveawayStatus, ICommonGiveaway } from '../../shared/giveaway.interface';

const GiveawayContainer = styled(Box)(( { theme }) => ({
    display: 'flex',
    alignItems: 'center',
    color: 'rgb(255, 255, 255, .8)',

    '.date': {
        color: theme.palette.text.disabled,
        fontSize: '14px'
    },
    '&:nth-of-type(even)': {
        backgroundColor: 'var(--primary-bg-soft)'
    },

    '&:nth-of-type(odd)': {
        backgroundColor: 'var(--primary-bg-soft)'
    },
    'tr td:first-of-type': {
        paddingLeft: '24px'
    },

    'tr td:last-of-type': {
        paddingRight: '24px'
    },
}))

const StatusSuccessTextBox = styled(Box)(( { theme }) => ({
    color: theme.palette.success.light,
    fill: theme.palette.success.light,
    display: 'flex',
    alignItems: 'center',

    'svg': {
        marginRight: '.25rem',
        fill: 'inherit'
    }
}))

const StatusErrorTextBox = styled(Box)(( { theme }) => ({
    color: theme.palette.error.main,
    display: 'flex',
    alignItems: 'center',

    'svg': {
        marginRight: '.25rem',
        fill: theme.palette.error.main
    }
}))

type GiveawayTabProps = {
    index: number;
    value: number;
}



const CommonGiveawayTab = (props: GiveawayTabProps) => {
    const { value, index, ...other } = props;

    const [loadingGiveaways, setLoadingGiveaways] = useState(true);
    const [giveaways, setGiveaways] = useState<Array<ICommonGiveaway>>([]);


    useEffect(() => {
        if (value === index) {
            setLoadingGiveaways(true);
            fetch(`${API_URL}/user/casinogiveaways`, {
                method: 'GET',
                credentials: 'include'
            }).then(res => {
                return res.json();
            }).then(data => {
                if (data && !data.error) {
                    setGiveaways(data);
                    setLoadingGiveaways(false);
                } else if (data.error) {
    
                }
            })
        } else {
            setGiveaways([]);
        }
    }, [value, index])

    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`giveaway-tab-${index}`}
            {...other}
            sx={{ maxHeight: '500px', overflowY: 'auto'}}
        >
            {value === index && (
                <Box>
                    {loadingGiveaways ? (
                        <Box>
                            <Typography sx={{ padding: '1rem' }}>
                                Loading your Giveaways wins...
                            </Typography>
                        </Box>
                    ) : !giveaways || giveaways.length === 0 ? (
                        <Box>
                            <Typography sx={{ padding: '1rem' }}>
                                You have not won any Common Giveaways yet :(
                            </Typography>
                        </Box>
                    ) : giveaways && giveaways.length > 0 && (
                                <>
                                    {giveaways.map((giveaway, index) => (
                                        <GiveawayContainer
                                            key={`common-giveaway-${index}`}
                                            sx={{
                                                padding: '12px 24px'
                                            }}>
                                            <Box
                                                display="flex"
                                                flexDirection="column"
                                                sx={{
                                                    marginRight: 'auto'
                                                }}
                                            >
                                        <Typography style={{ fontSize: '18px'}}>
                                            ${giveaway.amount}
                                        </Typography>
                                        <Typography fontSize="14px" className='date'>
                                            {formatDate(giveaway.date, "time")}
                                        </Typography>
                                    </Box>
                                    {giveaway.status === ECommonGiveawayStatus.RECEIVED ? (
                                        <StatusSuccessTextBox>
                                            <CheckIcon />
                                            <Typography>
                                                Received
                                            </Typography>
                                        </StatusSuccessTextBox>
                                    ) : giveaway.status === ECommonGiveawayStatus.PENDING ? (
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <TimeIcon />
                                            <Typography sx={{ marginLeft: '.25rem' }}>

                                                Processing
                                            </Typography>
                                        </Box>
                                    ) : giveaway.status === ECommonGiveawayStatus.USERNAME_UNAVAILABLE || giveaway.status === ECommonGiveawayStatus.CANCELED ? (
                                        <StatusErrorTextBox
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <CloseIcon />
                                            <Typography sx={{ marginLeft: '.25rem' }}>
                                                Canceled
                                            </Typography>
                                        </StatusErrorTextBox>
                                    ) : null}
                                </GiveawayContainer>
                            ))}
                        </>
                    )}
                </Box>
            )}
        </Box>
    )
}


const VIPGiveawayTab = (props: GiveawayTabProps) => {
    const { value, index, ...other } = props;

    const [loadingGiveaways, setLoadingGiveaways] = useState(true);
    const [giveaways, setGiveaways] = useState<Array<ICommonGiveaway>>([]);


    useEffect(() => {
        if (value === index) {
            setLoadingGiveaways(true);
            fetch(`${API_URL}/user/vipgiveaways`, {
                method: 'GET',
                credentials: 'include'
            }).then(res => {
                return res.json();
            }).then(data => {
                if (data && !data.error) {
                    setGiveaways(data);
                    setLoadingGiveaways(false);
                } else if (data.error) {
    
                }
            })
        } else {
            setGiveaways([]);
        }
    }, [value, index])

    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`giveaway-tab-${index}`}
            {...other}
            sx={{ maxHeight: '500px', overflowY: 'auto'}}
        >
            {value === index && (
                <Box>
                    {loadingGiveaways ? (
                        <Box>
                            <Typography sx={{ padding: '1rem' }}>
                                Loading your Giveaways wins...
                            </Typography>
                        </Box>
                    ) : !giveaways || giveaways.length === 0 ? (
                        <Box>
                            <Typography sx={{ padding: '1rem' }}>
                                You have not won any Super Giveaways yet :(
                            </Typography>
                        </Box>
                    ) : giveaways && giveaways.length > 0 && (
                        <>
                            {giveaways.map((giveaway, index) => (
                                <GiveawayContainer
                                    display="flex"
                                    alignItems="center"
                                    key={`vip-giveaway-${index}`}
                                    sx={{
                                        padding: '12px 24px'
                                    }}>
                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                        sx={{
                                            marginRight: 'auto'
                                        }}
                                    >
                                        <Typography>
                                            ${giveaway.amount}
                                        </Typography>
                                        <Typography fontSize="12px" className="date">
                                            {formatDate(giveaway.timestamp || new Date(0), "time")}
                                        </Typography>
                                    </Box>
                                    {giveaway.status === ECommonGiveawayStatus.RECEIVED ? (
                                        <StatusSuccessTextBox>
                                            <CheckIcon />
                                            <Typography>
                                                Received
                                            </Typography>
                                        </StatusSuccessTextBox>
                                    ) : giveaway.status === ECommonGiveawayStatus.PENDING ? (
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <TimeIcon />
                                            <Typography sx={{ marginLeft: '.25rem' }}>

                                                Processing
                                            </Typography>
                                        </Box>
                                    ) : giveaway.status === ECommonGiveawayStatus.USERNAME_UNAVAILABLE || giveaway.status === ECommonGiveawayStatus.CANCELED ? (
                                        <StatusErrorTextBox
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <CloseIcon />
                                            <Typography sx={{ marginLeft: '.25rem' }}>
                                                Canceled
                                            </Typography>
                                        </StatusErrorTextBox>
                                    ) : null}
                                </GiveawayContainer>
                            ))}
                        </>
                    )}
                </Box>
            )}
        </Box>
    )
}

VIPGiveawayTab.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
}


const GiveawayTab = () => {

    const [value, setValue] = useState(0);

    const changeTab = (event: React.SyntheticEvent<Element, Event>, newValue: any) => {
        setValue(newValue);
    }


  return (
    <React.Fragment>
    <Box>
        <Tabs value={value} onChange={changeTab} sx={{ padding: '1rem', borderBottom: '1px solid transparent', borderColor: 'primary.border' }} >
            <Tab label="Common" sx={{textTransform: 'capitalize'}}/>
            <Tab label="Super" sx={{textTransform: 'capitalize'}} />
        </Tabs>
        <CommonGiveawayTab value={value} index={0} />
        <VIPGiveawayTab value={value} index={1}  />
    </Box>
    </React.Fragment>
  )
}

export default GiveawayTab