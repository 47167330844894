import { toast } from 'react-toastify';

type ToastType = 'live_announcement' | 'success' | 'error' | 'info' | 'warning' | 'default' | 'dark';

export const createToast = (message: string, type: ToastType) => {
if (type === 'success'){
        toast.success(message, {
                className: 'success-toast',
                position: 'top-right',
                pauseOnHover: true,
                pauseOnFocusLoss: true,   
                hideProgressBar: true,   
            }
        )        
    } else if (type === 'error'){
        toast.error(message, {
                className: 'error-toast',
                position: 'top-right',
                pauseOnHover: true,
                pauseOnFocusLoss: true,   
                hideProgressBar: true,   
            }
        )        
    }
}