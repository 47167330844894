import Box from '@mui/material/Box'
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import React from 'react'
import { Permission, User } from '../../shared/user.interface';
import { ReactComponent as CoinIcon } from '../../icons/assets/coin.svg'
import { Link } from 'react-router-dom';
import StarOutlinedIcon from '@mui/icons-material/StarRateOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import { API_URL, hasPermission } from '../../utils';

const ProfileMenu = ({ profile }: { profile: User }) => {

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);
    const id = open ? 'profile-menu-popover' : undefined;

  return (
    <>
        <Button 
            disableRipple 
            sx={{ display: 'flex', alignItems: 'center', gap: '.75rem', textTransform: 'none'}}
            onClick={handleClick}
        >
            <Box display="flex" flexDirection="column" alignItems="flex-end">
                <Typography sx={{ ml: '1rem', fontSize: '14px', fontWeight: 'bold' }}>{profile.display_name}</Typography>
                <Box display="flex" alignItems="center" gap=".25rem">
                    <CoinIcon style={{ height: '10px', width: '10px'}} />
                    <Typography sx={{ fontSize: '12px', fontWeight: 'bold' }}>{new Intl.NumberFormat("en-US").format(profile.points)}</Typography>
                </Box>
        
            </Box>
                    
            <Box component="img" src={profile.avatar} alt="Avatar" sx={{ height: '42px', width: '42px', borderRadius: '100%', border: '2px solid var(--primary-border)' }} />
        </Button>
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <Box display="flex" flexDirection="column" sx={{ pb: '.5rem' }}>
                <Box component={Link} to="/profile" onClick={handleClose} sx={{ transition: 'color .2s ease-in-out', color: 'var(--text-primary)', '&:hover': { color: 'var(--primary-main)' } }}>
                    <Box display="flex" gap=".5rem" sx={{ p: '1rem', borderBottom: '1px solid var(--primary-border)' }}>
                        <Box component="img" src={profile.avatar} alt="Avatar" sx={{ height: '32px', width: '32px', borderRadius: '100%', border: '2px solid var(--primary-border)' }} />
                        <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>{profile.display_name}</Typography>
                    </Box>
                </Box>
                <List sx={{ p: '0'}}>
                    <ListItemButton disableRipple component={Link} to="/claimpoints" onClick={handleClose} sx={{ transition: 'color .2s ease-in-out', gap: '.5rem', color: 'var(--text-primary)', '&:hover': { color: 'var(--primary-main)' } }}>
                        <StarOutlinedIcon sx={{ fill: 'gold' }} />
                        <Typography>Claim Points</Typography>
                    </ListItemButton>
                    <ListItemButton disableRipple component={Link} to="/promocode" onClick={handleClose} sx={{ transition: 'color .2s ease-in-out', gap: '.5rem', color: 'var(--text-primary)', '&:hover': { color: 'var(--primary-main)' } }}>
                        <StarOutlinedIcon />
                        <Typography>Promo Code</Typography>
                    </ListItemButton>
                    {hasPermission(profile, Permission.VIEW_ADMIN_PAGE) && (
                        <ListItemButton disableRipple component={Link} to="/admin" onClick={handleClose} sx={{ transition: 'color .2s ease-in-out', gap: '.5rem', color: 'var(--text-primary)', '&:hover': { color: 'var(--primary-main)' } }}>
                            <StarOutlinedIcon />
                            <Typography>Admin</Typography>
                        </ListItemButton>
                    )}
                    {hasPermission(profile, Permission.CREATE_SLOT_BET) && (
                        <ListItemButton disableRipple component={Link} to="/admin/slot-battles" onClick={handleClose} sx={{ transition: 'color .2s ease-in-out', gap: '.5rem', color: 'var(--text-primary)', '&:hover': { color: 'var(--primary-main)' } }}>
                            <StarOutlinedIcon />
                            <Typography>Slot Battles</Typography>
                        </ListItemButton>
                    )}
                    {hasPermission(profile, Permission.MANAGE_REDEMPTIONS) && (
                        <ListItemButton disableRipple component={Link} to="/admin/redemptions" onClick={handleClose} sx={{ transition: 'color .2s ease-in-out', gap: '.5rem', color: 'var(--text-primary)', '&:hover': { color: 'var(--primary-main)' } }}>
                            <StarOutlinedIcon />
                            <Typography>Redemptions</Typography>
                        </ListItemButton>
                    )}
                    {hasPermission(profile, "admin_stake_giveaways_view") && (
                        <ListItemButton disableRipple component={Link} to="/admin/giveaways/casino" onClick={handleClose} sx={{ transition: 'color .2s ease-in-out', gap: '.5rem', color: 'var(--text-primary)', '&:hover': { color: 'var(--primary-main)' } }}>
                            <StarOutlinedIcon />
                            <Typography>Admin - Casino Giveaways</Typography>
                        </ListItemButton>
                    )}
                    <ListItemButton disableRipple component="a" href={`${API_URL}/auth/twitch/logout`} onClick={handleClose} sx={{ transition: 'color .2s ease-in-out', gap: '.5rem', color: 'var(--text-primary)', '&:hover': { color: 'var(--primary-main)' } }}>
                        <LogoutIcon />
                        <Typography>Sign Out</Typography>
                    </ListItemButton>
                </List>
            </Box>
        </Popover>
    </>
  )
}

export default ProfileMenu