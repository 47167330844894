import React, { useEffect, useState } from 'react'
import useSWR from 'swr'
import { fetcher } from '../../utils'
import { Raffle, RafflePrizeType, RaffleState } from './types'
import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'
import Typography from '@mui/material/Typography'
import RaffleBetModal from './RaffleBetModal'
import { Link } from 'react-router-dom'
import { socket } from '../../socket'
import { ReactComponent as Coin } from '../../icons/assets/coin.svg'
import { Button } from '@mui/material'
import TicketIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import UserIcon from '@mui/icons-material/AccountCircleOutlined';
import { useSelector } from 'react-redux'
import { selectProfile } from '../../redux/profile/profileSlice'
import { EmojiEvents, Schedule } from '@mui/icons-material'

type RaffleWithBets = Raffle & {
    totalBet: number
    userBet: number
}

const RaffleCardSkeleton = () => {
    return (
        <Box display="flex" flexDirection="column" alignItems="center" padding="1rem" sx={{ backgroundColor: 'var(--primary-bg-soft)', borderRadius: '8px', border: '1px solid var(--primary-border)' }}>
            <Skeleton variant="rectangular" sx={{ height: '128px', width: '128px' }} />
            <Box display="flex" flexDirection="column" gap=".5rem" width="100%" sx={{ mt: '2rem', mb: '2rem' }}>
                <Skeleton variant="text" width="66%" sx={{ mx: 'auto' }} />
                <Skeleton variant="text" width="66%" />
                <Skeleton variant="text" width="66%" />
            </Box>
            <Skeleton variant="rectangular" sx={{ height: '36px', width: '100%' }} />
        </Box>
    )
}

const RaffleCard = ({ raffle }: { raffle: RaffleWithBets }) => {

    const profile = useSelector(selectProfile)

    return (
  
        <Box display="flex" flexDirection="column" alignItems="center" gap="1rem" sx={{ padding: '1rem', backgroundColor: 'var(--primary-bg-soft)', borderRadius: '8px', border: '1px solid var(--primary-border)' }}>
                <Box display="flex" flexDirection="column" alignItems="center" gap=".5rem" color="inherit" component={Link} mb="auto" to={`/raffle/${raffle._id}`}>
                    
                    {raffle.name && (
                        <Typography fontWeight="bold" sx={{ 
                            maxWidth: '240px' , 
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis"
                         }}>{raffle.name}</Typography>
                    )}

                    {raffle.prizeType === RafflePrizeType.ITEM && raffle.item ? (
                        <>
                            <Box component="img" src={raffle.item.image} alt={raffle.item.name} sx={{ height: '128px', width: 'auto' }} />
                            <Typography fontWeight="bold" sx={{ 
                                maxWidth: '240px' , 
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis"
                             }}>{raffle.item.name}</Typography>
                        </>
                        
                    ) : RafflePrizeType.CASH && (
                        <>
                            <Box display="flex" alignItems="center" justifyContent="center" sx={{ height: '128px' }}>
                                <Coin style={{ height: '80px', width: 'auto' }} />
                            </Box>
                            
                            <Typography fontWeight="bold">${raffle.cashPrize} Crypto</Typography>
                        </>
                    )}
                </Box>

            {raffle.state === RaffleState.ROLLING && (
                <Box display="flex" width="100%" flexDirection="column" alignItems="center" gap=".25rem" 
                    sx={{ p: '1rem', backgroundColor: 'var(--primary-bg-soft)', border: '1px solid var(--primary-border)', borderRadius: '.5rem' }}>                
                    <Box display="flex" alignItems="center" gap=".25rem" sx={{ color: 'var(--text-disabled)' }}>
                        <Schedule sx={{ height: '16px', width: '16px'}} />
                        <Typography sx={{ fontSize: '12px', letterSpacing: '-.5px', fontWeight: 'bold' }}>State</Typography>
                    </Box>

                    <Box display="flex" gap=".5rem" width="100%" alignItems="center" justifyContent="center">
                        <Typography sx={{ fontStyle: 'italic' }}>Rolling</Typography>
                    </Box>                               
                </Box>
            )}

            {raffle.state === RaffleState.FINISHED && raffle.result?.winner && (
                <Box display="flex" width="100%" flexDirection="column" alignItems="center" gap=".25rem" 
                    sx={{ p: '1rem', backgroundColor: 'var(--primary-bg-soft)', border: '1px solid var(--primary-border)', borderRadius: '.5rem' }}>                
                    <Box display="flex" alignItems="center" gap=".25rem" sx={{ color: 'var(--text-disabled)' }}>
                        <EmojiEvents sx={{ height: '16px', width: '16px'}} />
                        <Typography sx={{ fontSize: '12px', letterSpacing: '-.5px', fontWeight: 'bold' }}>Winner</Typography>
                    </Box>

                    <Box display="flex" gap=".5rem" width="100%" alignItems="center" justifyContent="center">
                        <Box component="img" alt={raffle.result.winner.display_name} src={raffle.result.winner.avatar} sx={{ height: '20px', width: '20px', borderRadius: '100%' }} />
                        <Typography sx={{ fontWeight: 'bold', letterSpacing: '.5px' }}>{raffle.result.winner.display_name}</Typography>
                    </Box>                               
                </Box>

            )}


            <Box display="flex" gap="1rem" width="100%">
                <Box display="flex" width="100%" flexDirection="column" alignItems="start" gap=".25rem" 
                    sx={{ p: '1rem', backgroundColor: 'var(--primary-bg-soft)', border: '1px solid var(--primary-border)', borderRadius: '.5rem' }}>
                    
                    <Box display="flex" alignItems="center" gap=".25rem" sx={{ color: 'var(--text-disabled)' }}>
                        <TicketIcon sx={{ height: '16px', width: '16px'}} />
                        <Typography sx={{ fontSize: '12px', letterSpacing: '-.5px', fontWeight: 'bold' }}>Entries</Typography>
                    </Box>
                    <Typography sx={{ fontWeight: 'bold', letterSpacing: '.5px' }}>{raffle.totalBet}</Typography>
                </Box>
                {profile && (
                    <Box display="flex" width="100%" flexDirection="column" alignItems="start" gap=".25rem"
                        sx={{ p: '1rem', border: '1px solid var(--primary-border)', borderRadius: '.5rem' }}>
                        <Box display="flex" alignItems="center" gap=".25rem" sx={{ color: 'var(--text-disabled)' }}>
                            <UserIcon sx={{ height: '16px', width: '16px'}} />
                            <Typography sx={{ fontSize: '12px',  letterSpacing: '-.5px', fontWeight: 'bold'}}>
                                Your Entries
                            </Typography>
                        </Box>
                        <Typography sx={{ fontWeight: 'bold', letterSpacing: '.5px' }}>{raffle.userBet}</Typography>                                       
                    </Box>
                )}
 
            </Box>

            {raffle.streamer && (
                <Box display="flex" width="100%" flexDirection="column" alignItems="start" gap=".25rem" 
                    sx={{ p: '1rem', backgroundColor: 'var(--primary-bg-soft)', border: '1px solid var(--primary-border)', borderRadius: '.5rem' }}>
                    
                    <Box display="flex" alignItems="center" gap=".25rem" sx={{ color: 'var(--text-disabled)' }}>
                        <TicketIcon sx={{ height: '16px', width: '16px'}} />
                        <Typography sx={{ fontSize: '12px', letterSpacing: '-.5px', fontWeight: 'bold' }}>Streamer</Typography>
                    </Box>
                    <Typography component="a" href={raffle.streamer.kickUrl} target="_blank" rel="noreferrer" sx={{ letterSpacing: '.-5px', textDecoration: 'underline', color: 'var(--text-primary)' }}>
                        <Box display="flex" gap=".5rem" alignItems="center">
                            <Box component="img" alt={raffle.streamer.display_name} src={raffle.streamer.image} sx={{ height: '20px', width: '20px', borderRadius: '100%' }} />
                            <Typography sx={{ fontWeight: 'bold', letterSpacing: '.5px' }}>{raffle.streamer.display_name}</Typography>
                        </Box>
                    </Typography>
                    
                </Box>
            )}    

        
            
                <Box display="flex" gap="1rem" alignItems="center" width="100%">
                    <Link to={`/raffle/${raffle._id}`} style={{ width: '100%' }}>
                        <Button variant="contained" color="secondary" fullWidth>
                            View
                        </Button>
                    </Link>

                    {raffle.state === RaffleState.OPEN_FOR_BETS && (
                        <RaffleBetModal raffleId={raffle._id} maxBetPerUser={raffle.maxBetPerUser} />
                    )}
                    
                </Box>
            
        </Box>
        
    )
}

enum RaffleEvent {
    RAFFLE_CREATED = "raffleCreated"
}

const RafflesPage = () => {

    const [raffleHistoryPage, setRaffleHistoryPage] = useState(1)

    const { data, isLoading, mutate } = useSWR<RaffleWithBets[]>('/raffles', fetcher)
    const raffleHistory = useSWR<{ count: number, countPerPage: number, data: RaffleWithBets[] }>(`/raffles/history?page=${raffleHistoryPage}`, fetcher)
   
    useEffect(() => {

        function raffleEvents(event: RaffleEvent, raffleId: string, data: any) {
            switch(event) {
                case RaffleEvent.RAFFLE_CREATED:
                    mutate((prev) => {
                        if(!prev) return prev
                        return [...prev, data]
                    }, {
                        revalidate: false
                    })
                    break
            }
        }

        socket.on('raffle', raffleEvents)

        return function cleanup() {
            socket.off('raffle', raffleEvents) 
          }
    }, [])

  return (
    <Box component="main" display="flex" flexDirection="column" className='page' sx={{ gap: '3rem' }}>
        <Typography variant="h3" sx={{ my: '3rem', textAlign: 'center' }}>Raffles</Typography>
        <Box display="flex" flexDirection="column" gap="2rem">
            <Typography variant="h5">
                Active Raffles
            </Typography>
            <Box display="grid" gap="1rem" sx={{ gridTemplateColumns: { xs: 'repeat(1, minmax(0, 1fr))', sm: 'repeat(2, minmax(0, 1fr))', md: 'repeat(3, minmax(0, 1fr))', lg: 'repeat(4, minmax(0, 1fr))', xl: 'repeat(5, minmax(0, 1fr))' }}}>
                {isLoading ? (
                    <>
                        {new Array(10).fill(0).map((_, i) => (
                            <RaffleCardSkeleton key={`raffle-skeleton-${i}`} />
                        ))}
                    </>
                ) : !data ? (
                    <Typography>There was an error fetching active Raffles, please refresh the page.</Typography>
                ) : data.length === 0 ? (
                    <Typography sx={{ fontStyle: 'italic', color: 'var(--text-secondary)' }}>
                        No active Raffles at the moment.
                    </Typography>
                ) : (
                    data.map((raffle) => (
                        <RaffleCard key={`raffle-${raffle._id}`} raffle={raffle} />
                    ))
                )}
            </Box>
        </Box>
        <Box display="flex" flexDirection="column" gap="2rem">
            <Typography variant="h5">
                Previous Raffles
            </Typography>
            <Box display="flex" flexDirection="column" gap="1rem">
                <Box display="grid" gap="1rem" sx={{ gridTemplateColumns: { xs: 'repeat(1, minmax(0, 1fr))', sm: 'repeat(2, minmax(0, 1fr))', md: 'repeat(3, minmax(0, 1fr))', lg: 'repeat(4, minmax(0, 1fr))', xl: 'repeat(5, minmax(0, 1fr))' }}}>
                    {raffleHistory.isLoading ? (
                        <>
                            {new Array(5).fill(0).map((_, i) => (
                                <RaffleCardSkeleton key={`raffle-skeleton-${i}`} />
                            ))}
                        </>
                    ) : !raffleHistory.data ? (
                        <Typography>There was an error fetching previous Raffles, please refresh the page.</Typography>
                    ) : raffleHistory.data.data.length === 0 ? (
                        <Typography sx={{ fontStyle: 'italic', color: 'var(--text-secondary)' }}>
                            No previous Raffles at the moment.
                        </Typography>
                    ) : (
                        raffleHistory.data.data.map((raffle) => (
                            <RaffleCard key={`raffle-${raffle._id}`} raffle={raffle} />
                        ))
                    )}
                </Box>
                {raffleHistory.data && (
                    <Box display="flex" alignItems="center" justifyContent="flex-end" gap="1rem" marginTop="1rem">
                        <Typography sx={{ color: 'var(--text-secondary)' }}>
                            Showing 
                            {" "}
                            <Typography component="span" sx={{ color: 'var(--text-primary)', fontWeight: 'bold' }}>{(raffleHistoryPage - 1) * raffleHistory.data.countPerPage + raffleHistory.data.data.length}</Typography>
                            {" "}
                            of
                            {" "}
                            <Typography component="span" sx={{ color: 'var(--text-primary)', fontWeight: 'bold' }}>{raffleHistory.data.count}</Typography> 
                        </Typography>
                        <Button disabled={raffleHistoryPage === 1} onClick={() => setRaffleHistoryPage(prevState => Math.max(0, --prevState))} variant="contained" color="soft" sx={{ border: '1px solid var(--primary-border)' }}>
                            Previous
                        </Button>   
                        <Button onClick={() => setRaffleHistoryPage(prevState => ++prevState)} disabled={raffleHistory.data.count <= ((raffleHistoryPage - 1) * raffleHistory.data.countPerPage) + raffleHistory.data.data.length} variant="contained" color="soft" sx={{ border: '1px solid var(--primary-border)' }}>
                            Next
                        </Button>   
                    </Box>
                )}
            </Box>

        </Box>

    </Box>
  )
}

export default RafflesPage