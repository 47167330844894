import React, { useState } from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ReactComponent as HypedropSmall } from '../../icons/sites/hypedrop_small.svg';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Link } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import ClaimPointsTermsModal from './ClaimPointsTermsModal';
import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';


const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '500px',
    maxWidth: '95%',
    bgcolor: 'var(--primary-bg)',
    border: '2px solid var(--primary-border)',
    boxShadow: 24,
    padding: '1rem',
    borderRadius: '8px'
  }

const HypedropPointClaimModal = ({ isOpen, handleClose }: { isOpen: boolean, handleClose: () => void }) => {

    const [readTerms, setReadTerms] = useState<boolean>(localStorage.getItem('agreedToHypedropTerms') === null || localStorage.getItem('agreedToHypedropTerms') !== "true" ? false : true || false)

    const toggleTermsRead = (value: Boolean) => {
        if(value) {
            localStorage.setItem('agreedToHypedropTerms', 'true')
            setReadTerms(true)
        } else {
            localStorage.removeItem('agreedToHypedropTerms')
            setReadTerms(false)
        }
    }

  return (
    <Modal
        open={isOpen}
        onClose={handleClose}
    >
            <Box display="flex" flexDirection="column" rowGap=".25rem" sx={style}>
                <IconButton onClick={handleClose} sx={{ position: 'absolute', top: '8px', right: '8px' }}>
                    <Close />
                </IconButton>
              <Box display="flex" alignItems="center" marginBottom="2rem">
                  <Typography component="span" sx={{ marginRight: '.25rem', width: '38px', height: '16px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <HypedropSmall style={{ width: '15px', height: '6px', transform: 'scale(2)' }} />
                  </Typography>
                  <Typography variant="h6">
                      Claim Points Info
                  </Typography>
              </Box>


              <Typography>You will receive Points for every deposit under code DDK</Typography>
              <Typography>For every <Typography component="span" sx={{ color: 'var(--secondary-light)' }}>$1</Typography> you deposit
                  you will receive <Typography component="span" sx={{ color: 'var(--secondary-light)' }}>10 Points</Typography></Typography>
                <Typography marginTop="2rem">To be able to claim points you need to have your Hypedrop connected/verified, you can do this under your <Link className="link" to="/profile">Profile</Link> </Typography>
              <Typography marginTop="2rem" sx={{ color: 'var(--text-secondary)' }}>Your claimable Points get updated once a day at 6AM UTC</Typography>

                <Box display="flex" flexDirection="column" sx={{ marginTop: '2rem' }} >
                <Typography component="span" fontSize="14px" sx={{ color: 'var(--text-secondary)' }}>To be able to claim Points, confirm the following</Typography>
                <FormControlLabel control={<Checkbox checked={readTerms} color="success" onChange={(event) => toggleTermsRead(event.target.checked)} />}
                  label={<Typography fontSize="14px">I've read both Hypedrop's ToS & our &thinsp;
                        <ClaimPointsTermsModal buttonStyle={{ fontSize: "14px", verticalAlign: 'baseline', color: 'var(--secondary-light)', '&:hover': { color: 'var(--secondary-main)' }, '&:active': { color: 'var(--secondary-main)' } }} />  
                        {" "} Terms</Typography>} />
                  {readTerms && <Typography sx={{ color: 'success.light', textAlign: 'center' }}>You can now claim points</Typography>}
                </Box>
              

              
          </Box>
              
          </Modal>      
  )
}

export default HypedropPointClaimModal