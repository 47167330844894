import React, { useEffect } from 'react'
import useSWR from 'swr'
import { fetcher } from '../../utils'
import { ContentCreator } from '../../shared/user.interface'
import { useDispatch } from 'react-redux'
import { setContentCreators } from '../../redux/contentCreators/contentCreatorsSlice'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { Skeleton, styled } from '@mui/material'
import NotificationsIcon from '@mui/icons-material/Notifications';
import { ReactComponent as Kick } from '../../icons/sites/kick.svg';

const Container = styled(Box)(( { theme }) => ({
    position: 'relative',
    transition: 'transform 250ms ease-in-out',

    '&:hover': {
        transform: 'scale(1.02)'
    },

    '::before': {
        content: '""',
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        borderRadius: '12px',
        background: 'linear-gradient(180deg, rgba(0,0,0,0) 30%, rgba(180, 33, 59, .07) 100%)',
    },

    '&.live::before': {
        background: 'linear-gradient(180deg, rgba(0,0,0,0) 30%, rgba(33, 180, 33, .07) 100%)',
    },

    '&.bones::before': {
        background: 'linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(180, 33, 59, .07) 100%)',
    },

    '&.bones.live::before': {
        background: 'linear-gradient(90deg, rgba(0,0,0,0) 30%, rgba(33, 180, 33, .07) 100%)',
    }
}))

const StreamerSkeleton = () => {
    return (
        <Box display="flex" flexDirection="column" alignItems="center" position="relative" gap="1rem" sx={{ borderRadius: '.5rem', backgroundColor: 'var(--primary-bg-soft)', p: '1rem' }} >
            <Skeleton variant="circular" height="64px" width="64px" sx={{ position: 'absolute', top: '-32px', left: '50%', transform: 'translateX(-50%)' }} />
            <Skeleton variant="rectangular" height="24px" width="75%" sx={{ mt: '36px', borderRadius: '.5rem' }} />
            <Skeleton variant="rectangular" height="16px" width="50%" sx={{ borderRadius: '.5rem'}} />
            <Skeleton variant="rectangular" height="32px" width="100%" sx={{ borderRadius: '.5rem'}} />
        </Box>
    )
}

const LiveButton = styled(Button)(( { theme }) => ({
    background: "linear-gradient(170deg, rgba(40,95,40,1) 0%, rgba(86,172,86, .8) 33%, rgba(86,172,86, .8) 66%,  rgba(40,95,40,1) 100%)",
    gap: '.5rem',
    padding: '10px 20px',
    boxShadow: '0 0 8px rgb(16, 16, 24)',
    justifyContent: 'flex-start',
    borderRadius: '.5rem',
    color: 'white',
    '&:hover': {
        backgroundColor: 'rgb(32,132,32)',
        color: 'white',
    }
}))

const OfflineButton = styled(Button)(( { theme }) => ({
    background: 'linear-gradient(180deg, rgba(150, 33, 50, .8) 0%, rgba(180, 33, 59, 1) 100%)',
    gap: '.5rem',
    padding: '10px 20px',
    boxShadow: '0 0 8px rgb(16, 16, 24)',
    justifyContent: 'flex-start',
    borderRadius: '.5rem',
    color: 'var(--primary-text)',
    '&:hover': {
        backgroundColor: 'var(--primary-light)',
        color: 'white',
    }
}))

const StreamerBox = ({ streamer }: { streamer: ContentCreator }) => {
    return (
        <Container className={streamer.live ? "live" : ""} display="flex" flexDirection="column" alignItems="center" position="relative" gap=".5rem" sx={{ width: '100%', borderRadius: '.5rem', backgroundColor: 'var(--primary-bg-soft)', p: '1rem' }} >
            <Box component="img" src={streamer.image} alt={streamer.display_name} height="64px" width="64px" 
                sx={{ boxShadow: '0 0 1rem rgba(0,0,0, .5)', border: '2px solid var(--primary-border)', borderRadius: '100%', position: 'absolute', top: '-32px', left: '50%', transform: 'translateX(-50%)' }} 
            />
            <Typography sx={{ fontSize: '20px', mt: '36px', letterSpacing: '-.5px' }}>{streamer.display_name}</Typography>
            {streamer.live ? (
                <Typography sx={{ color: 'rgb(86,172,86)', fontSize: '14px',  textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', width: '100%', textAlign: 'center' }}>
                    {streamer.live ? streamer.category : "Offline"}
                </Typography>
            ) : (
                <Typography sx={{ color: 'var(--primary-main)', fontSize: '14px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', width: '100%', textAlign: 'center' }}>
                    Offline
                </Typography>
            )}

            {streamer.live ? (
                <Box component={"a"} href={streamer.kickUrl} width="100%" sx={{ color: 'inherit', transition: 'color .3s' }}>
                    <LiveButton variant="contained" startIcon={<Kick />} sx={{ mt: '1rem', textTransform: 'capitalize', borderRadius: '.5rem', width: '100%' }}>
                        <Typography>LIVE</Typography>             
                    </LiveButton>
                </Box>
            ) : (
                <Box component={"a"} href={streamer.kickUrl} width="100%" sx={{ color: 'inherit', transition: 'color .3s' }}>
                    <OfflineButton variant="contained" startIcon={<NotificationsIcon />} sx={{ mt: '1rem', textTransform: 'capitalize', width: '100%' }}>
                        <Typography>Follow</Typography>                
                    </OfflineButton>
                </Box>
            )}


     </Container>
    )
}

const StreamerSection = () => {

    const dispatch = useDispatch()
    const { data, isLoading } = useSWR<ContentCreator[]>(`/contentcreators`, fetcher)

    useEffect(() => {
        if(data) {
            dispatch(setContentCreators(data))
        }
    }, [data, dispatch])


  return (
    <Box display="flex" width="100%" flexDirection="column">
        <Box display="grid" width="100%" sx={{ columnGap: '1rem', rowGap: '56px', gridTemplateColumns: { xs: 'repeat(1, minmax(0, 1fr))', sm: 'repeat(2, minmax(0, 1fr))', md: 'repeat(3, minmax(0, 1fr))', lg: 'repeat(5, minmax(0, 1fr))', xl: 'repeat(5, minmax(0, 1fr))' }}}>
            {isLoading ? (
                <>
                    {new Array(6).fill(0).map((_, i) => (
                        <StreamerSkeleton key={"streamer-skeleton-" + i} />               
                    ))}
                </>
            ) : !data ? (
                <></>
            ) : (
                <>
                    {data.map((streamer, index) => (
                        <StreamerBox key={`streamer-${index}`} streamer={streamer} />
                    ))}
                </>
            )}
        </Box>
    </Box>
  )
}

export default StreamerSection