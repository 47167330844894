import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material'
import { formatDate } from '../../utils';

import CheckIcon from '@mui/icons-material/Check';
import TimeIcon from '@mui/icons-material/AccessTime';
import CloseIcon from '@mui/icons-material/Close';
import { ERaffleStatus } from '../../shared/giveaway.interface';

const RaffleContainer = styled(Box)(( { theme }) => ({
    display: 'flex',
    alignItems: 'center',
    color: 'rgb(255, 255, 255, .8)',
    padding: '12px 24px',

    '.date': {
        color: theme.palette.text.disabled,
        fontSize: '14px'
    },

    '&:nth-of-type(even)': {
        backgroundColor: 'var(--primary-bg-soft)'
    },

    '&:nth-of-type(odd)': {
        backgroundColor: 'var(--primary-bg-soft)'
    },
    'tr td:first-of-type': {
        paddingLeft: '24px'
    },

    'tr td:last-of-type': {
        paddingRight: '24px'
    },
}))

const StatusSuccessTextBox = styled(Box)(( { theme }) => ({
    color: theme.palette.success.light,
    display: 'flex',
    alignItems: 'center',

    'svg': {
        marginRight: '.25rem',
        fill: theme.palette.success.light
    }
}))

const StatusErrorTextBox = styled(Box)(( { theme }) => ({
    color: theme.palette.error.main,
    display: 'flex',
    alignItems: 'center',

    'svg': {
        marginRight: '.25rem',
        fill: theme.palette.error.main
    }
}))

type RaffleProps = {
    id: string;
    amount: number,
    status: ERaffleStatus,
    date: string
}

export const RaffleRow = ({id, amount, status, date}: RaffleProps) => {
    return (
        <RaffleContainer>
            <Box
                display="flex"
                flexDirection="column"
                sx={{
                    marginRight: 'auto'
                }}
            >
                <Typography fontSize="18px">
                    ${amount}
                </Typography>
                <Typography className="date">
                    {formatDate(date, "time")}
                </Typography>
            </Box>
            {status === ERaffleStatus.PAID ? (
                <StatusSuccessTextBox>
                    <CheckIcon />
                    <Typography>
                        Received
                    </Typography>
                </StatusSuccessTextBox>
            ) : status === ERaffleStatus.ROLLED ? (
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <TimeIcon/>
                        <Typography sx={{marginLeft: '.25rem'}}>

                            Processing
                        </Typography>
                    </Box>
            ) : status === ERaffleStatus.CANCELED && (
                <StatusErrorTextBox>
                    <CloseIcon />
                    <Typography>
                        Canceled
                    </Typography>
                </StatusErrorTextBox>
            )}

        </RaffleContainer>
    )
}

export default RaffleRow
