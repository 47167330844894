import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import { fetchAPI, hasPermission, randomNumberInBetween } from '../../utils';
import { Typography, styled } from '@mui/material'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import AddPlayerPopover from '../../components/Battle/AddPlayerPopover';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useDispatch, useSelector } from 'react-redux'
import { joinBattleRoom } from '../../socket';
import { selectBattle, setBattle } from '../../redux/battle/battleSlice';
import BattleWinnerModal from '../../components/Battle/BattleWinnerModal';
import { BattleDrop, BattleItem, BattlePlayer, GameRound } from '../../shared/battle.interface';
import { Permission, Profile, PublicProfile, User } from '../../shared/user.interface';
import { IBattle } from '../../shared/battle.interface';
import { selectProfile } from '../../redux/profile/profileSlice';
import { createSuccessToast } from '../../components/Toasts/SuccessToasts';
import { ReactComponent as Gift } from '../../icons/gift.svg'
import LoadingCircle from '../../components/LoadingCircle';

const BattleContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '12px',
    color: 'var(--text-secondary)',

    '@media (max-width: 600px)': {
        '.creator': {
            visibility: 'hidden'
        },
    },

    '.avatar': {
        height: '32px',
        width: '32px',
        marginRight: '.75rem',
        borderRadius: '50%',
        boxShadow: '0 0 0.3rem rgba(0, 0, 0, .2)'
    },

    '.battle-container': {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',    
        

        '.battle-inner': {
            background: 'linear-gradient(rgba(23, 20, 119, .12) 0%, rgba(85, 30, 119, .15) 100%)',
            boxShadow: 'inset 0 0 1rem rgba(20, 16, 24, .1)',
            borderRadius: '12px',
            border: '2px solid rgba(255, 255, 255, .03)'
        }
    },

    '.player-container': {
        height: '80px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',   
        color: theme.palette.text.primary,
        backgroundColor: 'var(--primary-bg-soft)',
        borderRadius: '8px',
        zIndex: '2',
        boxShadow: 'inset 0 0 2rem rgb(16, 8, 24, .2)',
        margin: '.5rem 0 .5rem 0',
        transition: 'background-color .2s ease-in-out, box-shadow .5s ease-in-out',

        '.profile': {

            '@media (max-width: 600px)': {
                '.name': {
                      maxWidth: "90%",
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden'
                },

                '.avatar': {
                    display: 'none'
                }
            }
        }
    },


    '.player-container.winner': {
        backgroundColor: 'rgba(71,240,100, .4)',
        boxShadow: 'inset 0 0 2rem rgb(16, 8, 24, .5)'
    },

    '.case-image': {
        height: '32px',
        width: '32px',
        opacity: '.5',
        filter: 'drop-shadow(0 0 0.3rem black)'
    },

    '.case-image.active': {
        opacity: 1,
        height: '48px',
        width: '48px'
    }
}))

const ItemDropContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    background: 'linear-gradient(270deg, rgb(32, 26, 40) 0%, var(--primary-bg-light) 100%)',
    borderLeft: '3px solid transparent',
    padding: '1rem',
    borderRadius: '8px',
    width: '95%',
    //filter: 'drop-shadow(0 0 0.3rem rgba(20, 16, 24, .66))',

    '.value': {
        fontSize: '14px',
        color: 'var(--text-disabled)'
    },

    '@media (max-width: 600px)': {
        '.name': {
            maxWidth: "90%",
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden'
        },

        'img': {
            height: '24px',
            width: '24px',
            marginRight: '.5rem',
            filter: 'drop-shadow(0 0 0.3rem rgba(0, 0, 0, .66))'
        },
    },

    '@media (min-width: 601px)': {
        '.name': {
            maxWidth: "90%",
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden'
        },

        'img': {
            height: '48px',
            width: '48px',
            marginRight: '1rem',
            filter: 'drop-shadow(0 0 0.3rem rgba(0, 0, 0, .66))'
        },
    },
}))

const BattleSection = styled(Box)(({ theme }) => ({
    display: 'flex',
    width: '100%',
    columnGap: '.5rem'
}))

const ItemRoulette = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    height: '100%',

    '.box-container': {
        transition: "transform 4s ease-in-out, opacity .5s ease-in-out",
        width: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        rowGap: '1rem',
    },

    '.item': {
        opacity: '.1',
        transition: 'opacity 150ms ease-in-out'
    },

    'img': {
        height: '100px',
        width: '100px',
    },

    'focused': {
        opacity: '1'
    }
}))


const AddPlayerButton = styled(Button)(({ theme }) => ({
    textTransform: 'capitalize',
}))

const getRarityBorder = (rarity: string) => {
    rarity = rarity.toLowerCase();
    switch(rarity) {
        case "common": 
            return 'rgba(240, 240, 240, 0.33)'
        case "uncommon":
            return 'rgba(74, 134, 226, 0.33)'
        case "rare":
            return 'rgba(131, 64, 179, 0.9)'
        case "epic":
            return 'rgba(131, 64, 179, 0.9)'
        case "legendary":
            return 'rgba(255, 71, 82, 0.33)'
        default:
            return 'rgba(240, 240, 240, 0.33)'
    }
}


const PlayerContainer = ({ player }: { player: PublicProfile }) => {

    return (
        <Box className="profile" display="flex" alignItems="center">
            <img src={player.avatar} alt="" className='avatar' />
            <Typography className="name">{player.display_name}</Typography>
        </Box>

    )
}

const ItemDrop = ({ drop }: { drop: BattleDrop }) => {
    return(
        <ItemDropContainer sx={{ borderColor: getRarityBorder((drop.item as BattleItem).rarity) }}>
            <img src={(drop.item as BattleItem).image} alt="" />
            <Box display="flex" flexDirection="column" width="100%">
                <Typography className="name">{(drop.item as BattleItem).name}</Typography>
                <Typography className="value">${(drop.item as BattleItem).value}</Typography>
            </Box>
        </ItemDropContainer>
    )
}

const winningItemIndex = 1;

const getCenterElement = () => {
    const columnContainer = document.getElementsByClassName("column")[0];
    const boxContainers = document.getElementsByClassName("box-container");

    if(!columnContainer || boxContainers.length === 0) return;

    const offsetY = columnContainer.clientWidth / 2;
    const offsetX = boxContainers[0].clientHeight / 2;
    

    const interval = setInterval(() => {
        for(let c = 0; c < boxContainers.length; c++) {
            if(!boxContainers[c]) return;
            const translateValue = window.getComputedStyle(boxContainers[c]).getPropertyValue('transform').split(',')[5] ? parseFloat(window.getComputedStyle(boxContainers[c]).getPropertyValue('transform').split(',')[5]) : false;
            if(!translateValue) return;

            const activeIndex = translateValue < 0 ? 
                Math.floor((offsetX - Math.abs(translateValue + itemGlowOffset)) / 123) + Math.floor(offsetY / 123) :
                Math.floor((offsetX / 123) + Math.floor(Math.abs(translateValue + itemGlowOffset) / 123) + Math.floor(offsetY / 123))
            const childElements = Array.from(boxContainers[c].children) as Array<HTMLElement>;

            const reverseIndex = childElements.length - activeIndex;

            // band aid fix for comparing index, it will currently go up to the last item, need to fix this
            if(childElements[reverseIndex] && reverseIndex >= winningItemIndex) {
                childElements[reverseIndex].style.opacity = "1";
                if(childElements[reverseIndex + 1]) {
                    childElements[reverseIndex + 1].style.opacity = ".1";
                }
    
                if(reverseIndex === winningItemIndex) {
                    setTimeout(() => {
                        childElements[reverseIndex].classList.add('item-won');
                    }, 600)
                }
            }
        }

    }, 10)

    setTimeout(() => {
        clearInterval(interval);
    }, 10 * 1000 + 500);
}

const itemHeight = 123;
const itemGlowOffset = itemHeight * .2;

const startItemRoulette = async (battle: IBattle, pool: Array<string>) => {

    if(!battle.activeRound) return;

    const col = document.querySelectorAll('.column');

    for(let c = 0; c < col.length; c++) {
        const column = col[c];

        while(column.firstChild) {
            if(column.lastChild) 
                column.removeChild(column.lastChild)
        }

        const boxContainer = document.createElement('div');
        boxContainer.classList.add('box-container');
        boxContainer.style.opacity = "0";

        for(let i = 0; i < 31; i++) {
            const box = document.createElement('div');
            const itemImage = document.createElement('img');

            box.classList.add('item');

            itemImage.style.height = "100px"
            itemImage.style.width = "100px"

            if(i === 1) {
                if(battle.activeRound.drops[c]) {
                    itemImage.src = battle.activeRound.drops[c].item.image;
                }
            } else {
               itemImage.src = pool[Math.floor(Math.random() * pool.length)]
            }

            box.appendChild(itemImage)
            boxContainer.appendChild(box);
        }
        
        boxContainer.style.transitionTimingFunction = "cubic-bezier(.5, .1, .1, 1)";
        boxContainer.style.transform = `translateY(-${itemHeight * 14}px)`;        
        column.appendChild(boxContainer);

        getCenterElement();

        const randomOffset = randomNumberInBetween(((itemHeight * .66) * -1), ((itemHeight * .66)))

        boxContainer.setAttribute("data-offset", randomOffset.toString());

        //await new Promise((resolve) => setTimeout(resolve, 5 * 100));
        setTimeout(() => {
            boxContainer.style.opacity = "1";
            boxContainer.style.transform = `translateY(${itemHeight * 14 + randomOffset}px)`;	
        }, 50)

        setTimeout(() => {
            const translateValue = window.getComputedStyle(boxContainer).getPropertyValue('transform').split(',')[5] ? parseFloat(window.getComputedStyle(boxContainer).getPropertyValue('transform').split(',')[5]) : false;
            boxContainer.animate([
                { transform: `translateY(${translateValue + 'px'})`  },
                { transform: `translateY(${(123 * 14) + 'px'})` }
            ], { duration: 400, easing: 'ease', fill: 'both' })
        }, 4000)
        
    }
}

const BattlePage = () => {

    const profile = useSelector(selectProfile);

    const { id } = useParams();

    const dispatch = useDispatch();

    const battle: IBattle | null = useSelector(selectBattle)
    const [loadingBattle, setLoadingBattle] = useState(true);

    const [addPlayerPopoverAnchor, setAddPlayerPopoverAnchor] = useState<Element | null>(null);
    const [gameRounds, setGameRounds] = useState<Array<GameRound>>([]);

    useEffect(() => {

        dispatch(setBattle(null));

        const getBattle = async () => {
            try {
                const data: any = await fetchAPI(`/battle/${id}`, 'GET', null, null, true)

                if(data) {
                    
                    dispatch(setBattle(data))
                    setGameRounds(data.gameRounds)

                    const pool: Array<string> = [];
                    data.cases[0].content.forEach((caseContent: any) => {
                        pool.push(caseContent.item.image);
                    })
                           
                    joinBattleRoom(data.battleId);
                }

                setLoadingBattle(false);

            } catch (e) {
               
            }
        }

        getBattle();

        return () => {
            dispatch(setBattle(null));
        }

    }, [id, dispatch])

    const openAddPlayerPopOver = (e: React.MouseEvent<HTMLButtonElement>) => {
        setAddPlayerPopoverAnchor(e.currentTarget);
    }

    useEffect(() => {
        if(battle && battle.activeRound) {
            const pool: Array<string> = [];
            battle.activeRound.case.content.forEach((caseContent: any) => {
                pool.push(caseContent.item.image);
            })

            startItemRoulette(battle, pool);

            const gameroundTimer = setTimeout(() => {

                setGameRounds(prevState => [battle.activeRound as GameRound, ...prevState])

                if(battle.gameRounds.length === 0) {
                    //setGameRounds(prevState => [battle.activeRound as GameRound, ...prevState])
                } else if (battle.activeRound && battle.gameRounds[0].order !== battle.activeRound.order) {
                    //setGameRounds(prevState => [battle.activeRound as GameRound, ...prevState])
                }

            }, 4500)

            return () => {
                clearTimeout(gameroundTimer)
            }
        }
    }, [battle, battle?.activeRound])

    const isUserInBattle = ( user: User | null, players: Array<BattlePlayer | null>) => {
        if(!user || !players) return false;
        
        return players.some((player: BattlePlayer | null) => player?.user._id === user._id)
    }

    const joinBattle = async () => {
       
        if(!battle) return;

        try { 
            const response: any = await fetchAPI(`/battle/${battle.battleId}/join`, 'POST');

            if(response.success) {
                createSuccessToast(response.message);
            }
        } catch(e) {}
        

    }

    const addBotToBattle = async () => {
        if(!battle) return;
        try { 
            const response: any = await fetchAPI(`/battle/${battle.battleId}/addbot`, 'POST');

            if(response.success) {
                createSuccessToast(response.message);
            }
        } catch(e) {}
    }

  return (
    <Box className="page">
          <Link to="/battles">
              <Button
                  variant="text"
                  disableRipple
                  sx={{
                      fontSize: '16px',
                      marginBottom: '2rem',
                      textTransform: 'none',
                      color: 'var(--text-secondary)',
                      '&:hover': {
                          color: 'var(--text-primary)',
                          backgroundColor: 'transparent'
                      },

                      '&:active': {
                          color: 'text.disabled'
                      }
                  }}
                  startIcon={<ChevronLeftIcon />}
              >
                  Back to Battles
              </Button>
          </Link>
        {loadingBattle ? (
            <LoadingCircle loadingText='Loading Battle' />
        ) : battle ? (
            <BattleContainer>
                <Box display="flex" alignItems="flex-end" justifyContent="space-evenly" sx={{ 
                        padding: '1rem 0',
                        marginBottom: '1rem'

                    }}>
                    <Box width="25%" maxWidth="200px" display="flex" alignItems="center" className='creator'>
                        <Typography sx={{ marginRight: '1rem' }}>Created By</Typography>
                        <img src={battle.creator.avatar} alt="" className='avatar' />
                        <Typography>{battle.creator.display_name}</Typography>
                          {battle.isGiveaway && (
                              <Typography display="flex" alignItems="center" justifyContent="center" sx={{ 'svg': { fill: 'var(--text-secondary)', marginLeft: '1rem', width: '16px', height: '16px' } }}><Gift /></Typography>
                          )}
                    </Box>
                      <Box width="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="center" sx={{ 
                            overflow: 'hidden',                            
                        }}>
                          <Box display="flex" alignItems="center" justifyContent="flex-start" gap="12px" sx={{
                            transform: `translateX(${battle.currentRound * 43 * -1 + 200 - 36}px)`,
                            transition: 'transform 1s ease-in-out',
                            width: '400px'
                          }}>
                              {battle.cases.map((box: any, index: number) => (
                                  <img src={box.image} alt="" className={`case-image ${index === battle.currentRound ? 'active' : ''}`} key={`case-${index}`} />
                              ))}
                          </Box>
                          <Typography marginTop=".33rem" sx={{ fontSize: '18px', color: 'text.secondary' }}>{battle.cases[battle.currentRound].name}</Typography>
                      </Box>

                    <Box width="25%" maxWidth="200px" display="flex" justifyContent="flex-end">
                        <Typography>Round {battle.currentRound + 1} of {battle.totalRounds}</Typography>
                    </Box>
                </Box>
                  <BattleSection>
                      {[...Array(battle.slots)].map((i, index) => (
                        <React.Fragment>
                          <Box className="battle-container" key={index}>
                              <Box height="350px" className='battle-inner'>
                                {battle.status === 1 && battle.activeRound ? (
                                      <ItemRoulette className="column">
        
                                      </ItemRoulette>
                                ) : battle.status === 2 ? (
                                    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100%">
                                        {battle.players[index] && battle.players[index]?.isWinner ? (
                                                  <React.Fragment>
                                                      <Typography variant="h4" sx={{
                                                          textShadow: '0 0 1rem rgba(14,214,113,1)',
                                                          background: 'linear-gradient(140deg, rgb(14,214,90) 0%, rgba(160,240,140,0.75) 50%, rgb(14,214,90) 100%)',
                                                          backgroundClip: 'text',
                                                          WebkitTextFillColor: 'transparent'
                                                      }}>Winner</Typography>
                                                      <Typography variant="h4" sx={{ 
                                                          textShadow: '0 0 1rem rgba(14,214,113,1)',
                                                          background: 'linear-gradient(140deg, rgb(14,214,90) 0%, rgba(160,230,160,0.75) 50%, rgb(14,214,90) 100%)',
                                                          backgroundClip: 'text',
                                                          WebkitTextFillColor: 'transparent',
                                                          opacity: '.9'
                                                       }}>
                                                          ${battle.players[index]?.value || "0"}
                                                      </Typography>
                                                  </React.Fragment>
                                              ) : (
                                                      <React.Fragment>
                                                          <Typography variant="h4" sx={{
      
                                                          }}>😢</Typography>
                                                          <Typography variant="h4" sx={{
                                                              textShadow: '0 0 1rem rgba(190,51,62, 1)',
                                                              background: 'linear-gradient(140deg, rgba(170,51,62, 1) 0%, rgba(240,120,120, 1) 50%, rgba(190,51,62, 1) 100%)',
                                                              backgroundClip: 'text',
                                                              WebkitTextFillColor: 'transparent', 
                                                              opacity: '.9' 
                                                        }}>
                                                          ${battle.players[index]?.value || "0"}
                                                      </Typography>
                                                  </React.Fragment>
                                              )}
                                    </Box>
                                ) : battle.status === 0 && (
                                    <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                                        {battle.players[index] ? (
                                            <Typography variant="h5" sx={{ color: 'text.secondary'}}>Ready</Typography>
                                        ) : (
                                            <Typography variant="h5" sx={{ color: 'text.secondary'}}>Waiting...</Typography>
                                        )}
                                        
                                    </Box>
                                )}

                              </Box>
                              <Box 
                                className={`player-container ${battle.status === 2 ? battle.players[index] && battle.players[index]?.isWinner ? 'winner' : '' : ''}`}
                                >
                                      {battle.players[index] ? (
                                          <PlayerContainer player={(battle.players[index]?.user) as PublicProfile} />
                                      ) : battle.isGiveaway && hasPermission(profile, Permission.CREATE_BATTLE) ? (
                                              <AddPlayerButton
                                                  color="primary"
                                                  variant="contained"
                                                  onClick={(e) => openAddPlayerPopOver(e)}
                                              >
                                              Add Player
                                          </AddPlayerButton>
                                      ) : !battle.isGiveaway && !isUserInBattle(profile, battle.players) ? (
                                          <AddPlayerButton
                                              variant="contained"
                                              color="primary"
                                              onClick={joinBattle}
                                          >
                                              Join
                                          </AddPlayerButton>
                                      ) : !battle.isGiveaway && profile?._id === battle.creator._id && (
                                          <AddPlayerButton
                                              variant="contained"
                                              color="primary"
                                              onClick={addBotToBattle}
                                          >
                                              Add Bot
                                          </AddPlayerButton>
                                      )}
 
                              </Box>
                              <Box 
                                display="flex" 
                                flexDirection="column" 
                                alignItems="center" 
                                padding="2rem 0" 
                                rowGap="0" 
                                height={`${(battle.currentRound + 1) * 104 + 32}px`}
                                sx={{ 
                                    background: 'var(--primary-bg-soft)',
                                    boxShadow: 'inset 0 0 2rem rgb(16, 8, 24, .2)',
                                    borderRadius: '12px',
                                    transition: 'height 1s ease-in-out'
                                }}>
                                    {gameRounds.map((round, rIndex) => (
                                        <React.Fragment key={`drop-${rIndex - battle.totalRounds}`}>
                                            {round.drops[index] && (
                                                <Box width="100%" display="flex" justifyContent="center"
                                                    sx={{
                                                        borderTop: '1px solid var(--primary-border)',
                                                        padding: '.75rem 0',
                                                        '&:first-child': {
                                                            border: 'none',
                                                            paddingTop: '0'
                                                        },
                                                    }}>
                                                    <ItemDrop drop={round.drops[index]} />
                                                </Box>
                                            )}
                                        </React.Fragment>
    
                                        
                                    ))}
                              </Box>
                              
                          </Box>


                          </React.Fragment>
                      ))}
                  </BattleSection>

            </BattleContainer>
        ) : (
            <Box>
                <Typography variant="h4">Battle not found</Typography>
            </Box>
        )}
        <AddPlayerPopover anchorEl={addPlayerPopoverAnchor} setAnchorEl={setAddPlayerPopoverAnchor} battleId={battle?.battleId} />
        {battle && (
            <BattleWinnerModal battleId={battle.battleId} />
        )}
        
    </Box>
  )
}

export default BattlePage