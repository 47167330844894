import { createTheme } from '@mui/material/styles'

export const defaultTheme = createTheme({
    typography: {
      fontFamily: `"Noto Sans", "Inter", "Poppins", "Montserrat", "Helvetica", "Arial", sans-serif`,
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            color: "var(--text-primary)",
            backgroundColor: "var(--primary-bg)"
          },
          '.container': {
            padding: '1rem 2rem'
          },
        }
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            backgroundColor: 'var(--primary-bg-dark)'
          }
        }
      },
      MuiOutlinedInput:  {
        styleOverrides: {
          root: {
            backgroundColor: 'var(--primary-bg-light)',
            borderRadius: 8,
            boxShadow: 'inset 0 0 4px rgba(16, 16, 24, .25)',
            color: 'var(--text-secondary)',
            borderColor: 'transparent',
            transition: '.3s ease-in-out',

            ':hover': {
              borderColor: 'var(--primary-main)',
            },
            '&.Mui-focused': {
              borderColor: 'var(--primary-main)',
  
            }
          },
          notchedOutline: {
            borderColor: 'inherit !important',
            borderWidth: '2px !important'
          },
        }
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            position: 'absolute',
            bottom: '-20px'
          }
        }
      },
      MuiPopover: {
        styleOverrides: {
            root: {
              zIndex: 2000,
              
            },
            paper: {
              borderRadius: '.5rem'
            }
        }
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            'svg': {
              transition: 'fill .3s ease-in-out',
              fill: 'var(--text-primary)',

              '&:hover': {
                fill: 'var(--primary-main)'
              }
            }
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            color: 'rgb(230, 230, 240)',
            transition: 'background-color .3s ease-in-out'
          },
          outlined: {
            transition: 'border-color .3s ease-in-out',
          },          
          text: {
            backgroundColor: 'transparent',
            transition: 'color .3s ease-in-out, fill .3s ease-in-out',
            fontSize: '16px',
            textTransform: 'capitalize',
            padding: '0',
            minWidth: 'auto',
            color: 'var(--text-primary)',
            '&:hover': {
              backgroundColor: 'transparent',
              color: 'var(--primary-main)',
            },

            '&:active': {
              color: 'var(--primary-dark)'
            }
          },
        }
      },
      MuiChip: {
        styleOverrides: {
          root: {
            backgroundColor: 'rgba(72, 64, 80)'
          }
        }
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: 'var(--primary-bg)',
            padding: '.75rem',
            fontSize: '14px',
            border: '1px solid var(--primary-border)',

            'svg': {
              height: '12px',
              width: '12px'
            }
          }
        }
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {

            transition: 'background-color .3s ease-in-out',
            backgroundColor: 'var(--primary-bg)',

            '&:hover': {
              backgroundColor: 'var(--primary-bg-soft)'
            },
            '&.Mui-selected': {
              backgroundColor: 'var(--primary-bg-soft)'
            },
            '&.Mui-selected:hover': {
              backgroundColor: 'var(--primary-bg)'
            }
          }
        }
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundColor: 'var(--primary-bg-soft)',
            color: 'var(--text-primary)'
          }
        }
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: 'var(--text-secondary) !important'
          }
        }
      },
      MuiList: {
        styleOverrides: {
          root: {
            paddingBottom: '0',
            paddingTop: '0'
          }
        }
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            backgroundColor: 'var(--primary-bg-soft)',
            borderRadius: 8,
            border: '1px solid var(--primary-border)',
            boxShadow: '0 0 4px rgba(16, 16, 16, .4)',
            color: 'var(--text-primary)',
          },
          root: {
            ':hover': {
              borderColor: 'var(--primary-main) !important',
            },
            ':focus': {
              borderColor: 'var(--primary-main) !important',
            },
            ':active': {
              border: '1px solid var(--primary-main) !important',
            },
          },
          icon: {
            color: 'rgb(230, 230, 240)'
          },
          iconOpen: {
            color: 'var(--primary-main)'
          }
        }
      }
    },
    palette: {
      primary: {
        main: '#bd314b',
        light: '#d13653',
        dark: '#9e293f',
        background: '#1c1925',
        background_light: '#2e2839',
        panel: 'rgb(44, 40, 54)',
        border: '#433c5099',
      },
      secondary: {
        main: '#534d99',
        light: '#606bbf',
        dark: '#3f397f'
      },
      soft: {
        main: '#261f30',
        light: '#3c324a',
        dark: '#17121f',
      },
      text: {
        primary: 'rgb(223, 218, 228)',
        secondary: 'rgba(195, 183, 203, 1)',
        disabled: 'rgb(139, 128, 151)',
      },
      succes: {
        main: '#2e802d'
      },
      error: {
        main: '#a52525'
      },
      background: {
        paper: 'rgba(54, 54, 60)'
      },
      neutral: {
        main: '#2e2839',
        light: '#3f397f',
        dark: '#1c1925',
      },
      action: {
        disabledBackground: 'rgba(0,0,0, .4)',
        disabled: 'rgba(255, 255, 255, .8)'
      },
      rare: {
        main: '#caab05',
        contrastText: '#fff'
      },
      covert: {
        main: '#eb4b4b',
        contrastText: '#fff'
      },
      classified: {
        main: '#d32ce6',
        contrastText: '#fff'
      },
      restricted: {
        main: '#8747ff',
        contrastText: '#fff'
      },
      milspec: {
        main: '#1155dd',
        contrastText: '#fff'
      },
    },
  })