import { Box, Button, Modal, Typography } from '@mui/material'
import React from 'react'
import { EItemType, Item, ItemRarity } from '../../shared/item.interface'
import { getRarityColor } from './CaseTopUnboxings'
import { apiFetch, isAPIImage } from '../../utils'
import { createErrorToast } from '../../components/Toasts/ErrorToasts'
import { createSuccessToast } from '../../components/Toasts/SuccessToasts'
import { Link } from 'react-router-dom'
import { Profile } from '../../shared/user.interface'

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '360px',
    maxWidth: '95%',
    bgcolor: 'var(--primary-bg)',
    border: '2px solid var(--primary-border)',
    boxShadow: 24,
    padding: '1rem',
    borderRadius: '8px'
  }


const CaseWonDialog = ({ isOpen, handleClose, item, inventoryItemId, user }: { isOpen: boolean, handleClose: () => void, inventoryItemId?: string, item?: Item, user?: Profile}) => {

    if(!item) return <></>

    async function redeemItem() {
        console.log(inventoryItemId)
        try {
            const data = await apiFetch('/item/redeem', "POST", {  
                body: JSON.stringify({
                    useritemid: inventoryItemId
                })
            })

            if(data) {
                createSuccessToast("Item redeemed")
                handleClose()
            }
        } catch (e) {
            if(e instanceof Error) {
                createErrorToast(e.message)
            }
            
        }
    }

  return (
    <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="item-won-modal-title"
        aria-describedby="item-won-modal-description"
    >
        <Box
            sx={style}
            display="flex"
            flexDirection="column"
            gap="4rem"
        >
            {user ? (
                <Box display="flex" gap=".5rem">
                    <Box component="img" src={user.avatar} alt={user.display_name} sx={{ height: '32px', width: '32px', borderRadius: '50%' }} />
                    <Typography variant="h6" sx={{ fontWeight: 600 }}>{user.display_name} won!</Typography>
                </Box>
            ) : (
                <Typography variant="h6" sx={{ fontWeight: 600, flexGrow: 1 }}>You won!</Typography>
            )}
            
                <Box display="flex" flexDirection="column" alignItems="center" gap="1rem">
                    <Box component="img" src={isAPIImage(item.image)} alt={item.name} sx={{ height: '100px', width: '100px', filter: `drop-shadow(0 0 .5rem ${getRarityColor(item.rarity.toLowerCase() as ItemRarity)})` }} />
                    <Typography variant="h6" sx={{ 
                            fontWeight: 600, 
                            flexGrow: 1 ,
                            textOverflow: 'ellipsis',
                            maxWidth: '80%',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap'
                        }}>
                            {item.name}
                    </Typography>

                    {item.description && (
                        <Typography sx={{ textAlign: 'center', width: '100%', fontSize: '.8em', color: 'var(--text-secondary)', p: '1rem', borderTop: '1px solid var(--primary-border)', borderBottom: '1px solid var(--primary-border)' }}>{item.description}</Typography>
                    )}
                </Box>
                <Box display="flex" flexDirection="column" gap="1rem">
                    {!user && (
                        <Box display="flex" alignItems="center" gap="1rem">
                            <Button variant="contained" color="success" disabled={!item.redeemable || item.type === EItemType.NOTHING} fullWidth onClick={() => redeemItem()}>
                                Redeem
                            </Button> 
                            <Button component={Link} to="/profile" variant="contained" fullWidth>
                                Inventory
                            </Button>
                        </Box>
                    )}

                    <Button variant="contained" color="soft" onClick={handleClose}>
                        Close
                    </Button>
                </Box>

        </Box>
    </Modal>
  )
}

export default CaseWonDialog