import React, { useEffect, useState } from 'react'
import { Badge, Box, Button, Skeleton, Tooltip, Typography } from '@mui/material'
import useSWR from 'swr'
import { Link, useParams } from 'react-router-dom'
import { apiFetch, fetcher, getClientSeed, hasPermission, isAPIImage } from '../../utils'
import { CaseTopUnboxing, CaseWithItems, TopUnboxing } from './types'
import { ReactComponent as CoinIcon } from '../../icons/assets/coin.svg'
import { ArrowBack, Backpack } from '@mui/icons-material'
import CaseTopUnboxings from './CaseTopUnboxings'
import CaseItems from './CaseItems'
import { createErrorToast } from '../../components/Toasts/ErrorToasts'
import { Item } from '../../shared/item.interface'
import CaseUnboxing from './CaseUnboxing'
import CaseWonDialog from './CaseWonDialog'
import { useSelector } from 'react-redux'
import { selectProfile } from '../../redux/profile/profileSlice'
import { Permission, Profile } from '../../shared/user.interface'
import GiveawayCaseModal from '../../components/Admin/Cases/GiveawayCaseModal'



type CaseWithTopUnboxings = CaseWithItems & { topUnboxings: CaseTopUnboxing[], boxesInInventory?: number }

const CasePage = () => {

    const { id } = useParams()

    const profile = useSelector(selectProfile)

    const { data, isLoading, mutate } = useSWR<CaseWithTopUnboxings>(`/case/${id}`, fetcher, { revalidateOnFocus: false, revalidateIfStale: false })
    const topUnboxing = useSWR<TopUnboxing[]>(`/case/${id}/top`, fetcher, { revalidateOnFocus: false })

    const [isOpening, setIsOpening] = useState(false)
    const [unboxingResult, setUnboxingResult] = useState<{ item: Item, inventoryItemId: string } | undefined>(undefined)
    const [isOpenButtonDisabled, setIsOpenButtonDisabled] = useState(false)
    const [showItemWonModal, setShowItemWonModal] = useState(false)
    const [receivingUser, setReceivingUser] = useState<Profile | undefined>(undefined)

    function onCaseOpened(unboxingResult: { item: Item, inventoryItemId: string }, receivingUser?: Profile) {
      setUnboxingResult(unboxingResult)
      if(receivingUser) setReceivingUser(receivingUser)
    }

    async function openCase() {
      try {
        setIsOpenButtonDisabled(true)
        const  data = await apiFetch(`/case/open/${id}`, "POST", {
          body: JSON.stringify({
            clientSeed: getClientSeed()
          })
        })

          if(data) {
            onCaseOpened(data.data)
          }
      } catch (e) {
        if(e instanceof Error) {
          createErrorToast(e.message)
          setIsOpenButtonDisabled(false)
        }
      }
    }
    
    async function openCaseFromInventory() {
      try {
        setIsOpenButtonDisabled(true)
        const  data = await apiFetch(`/case/open/${id}/inventory`, "POST", {
          body: JSON.stringify({
            clientSeed: getClientSeed()
          })
        })

          if(data) {
            onCaseOpened(data.data) 

            mutate((prev) => {
              if(!prev) return prev

              return {
                ...prev,
                boxesInInventory: prev.boxesInInventory ? prev.boxesInInventory - 1 : 0
              }
            }, {
              revalidate: false
            })

          }
      } catch (e) {
        if(e instanceof Error) {
          createErrorToast(e.message)
          setIsOpenButtonDisabled(false)
        }
      }
    }

    async function cleanupOpening() {
      setIsOpening(false)
      setIsOpenButtonDisabled(false)
    }


    useEffect(() => {
      if(unboxingResult) {
        setIsOpening(true)
      }
    }, [unboxingResult])

  return (
    <Box
        display="flex"
        flexDirection="column"
        gap="3rem"
        className="page"
        overflow="hidden"
    >
      <Box display="flex">
        <Button disableRipple component={Link} to="/cases" startIcon={<ArrowBack />}>         
          <Typography component="span" sx={{ fontSize: '14px' }}>Cases</Typography>
        </Button>
      </Box>
      {isLoading ? (
        <Box display="flex" flexDirection="column" alignItems="center" gap="2rem">
          <Skeleton variant="rectangular" width="164px" height="140px" />
          <Skeleton variant="text" width="120px" height="48px" />
          <Box display="flex" alignItems="center" gap=".25rem">
            <Skeleton variant="text" width="128px" height="64px" />
            <Skeleton variant="text" width="64px" height="64px" />
          </Box>  
        </Box>
      ) : !data ? (
        <Typography>Something went wrong, please refresh the page!</Typography>
      ) : (
        <Box display="flex" flexDirection="column" alignItems="center" gap="3rem">

            <Box display="flex" flexDirection="column" width="100%">

              <Box display="flex" alignItems="center" justifyContent="center" gap="1rem" sx={{ flexDirection: isOpening ? "row" : "column"}}>
                <Box 
                  component="img" 
                  src={isAPIImage(data.image)}
                  alt={data.name} 
                  sx={{ height: isOpening ? '80x' : '164px', width: isOpening ? '80px' : '164px', filter:  'drop-shadow(0 0 .5rem rgba(0,0,0, .5))' }}               
                />
                  <Box display="flex" flexDirection="column" alignItems="start" gap=".25rem">
                    <Typography component="span" sx={{ letterSpacing: '-.3px', fontSize: isOpening ? '18px' : '24px' }}>{data.name}</Typography>
                    <Box alignItems="center" gap=".25rem" sx={{ display: isOpening ? 'flex' : 'none' }}>
                      <Typography component="span"   sx={{ color: 'var(--text-secondary)', fontSize: '14px' }}>
                          {Intl.NumberFormat("en-US").format(data.price)}            
                      </Typography>
                      <CoinIcon style={{ height: '14px', width: '14px' }} />
                    </Box>
                  </Box>               
                </Box>

                {isOpening && (
                  <CaseUnboxing caseItems={data.content} unboxing={unboxingResult} cleanupOpening={cleanupOpening} showItemWonModal={() => setShowItemWonModal(true)}  />
                )}
                

              </Box>

              

              <Box display="flex" gap="1rem">
                <Button variant="contained" sx={{ borderRadius: '.5rem', gap: '.5rem' }} disabled={isOpenButtonDisabled} onClick={openCase}>
                  <Typography component="span" sx={{ fontSize: '14px' }}>Open</Typography>
                  <Box display="flex" alignItems="center" gap=".25rem" sx={{ p: '6px 10px',  fontSize: '14px' }}>                
                    <Typography> {Intl.NumberFormat("en-US").format(data.price)} </Typography>
                    <CoinIcon style={{ height: '14px', width: '14px' }} />
                  </Box>
                </Button>
                {profile && (
                  <Tooltip title="Open a usable Case from your Inventory">
                    <Badge badgeContent={data.boxesInInventory} color="primary" sx={{ borderRadius: '50%' }}>
                      <Button disabled={isOpenButtonDisabled || !data.boxesInInventory || data.boxesInInventory === 0} onClick={openCaseFromInventory} variant="contained" color="secondary">
                        <Backpack />
                      </Button>
                    </Badge>
                  </Tooltip>
                )}

        
              </Box>

              <Typography sx={{ color: 'var(--text-secondary)', fontStyle: 'italic' }}>
                Case has been opened {data.opened_amount} times.
              </Typography>

              {hasPermission(profile, Permission.GIVEAWAY_CASE) && (
                <GiveawayCaseModal slug={id} onCaseOpened={onCaseOpened} />
              )}


            <CaseTopUnboxings isLoading={isLoading} topUnboxings={topUnboxing.data} />

        </Box>
        )}

        <CaseItems isLoading={isLoading} items={data?.content} />

        <CaseWonDialog isOpen={showItemWonModal} handleClose={() => setShowItemWonModal(false)} item={unboxingResult?.item} inventoryItemId={unboxingResult?.inventoryItemId} user={receivingUser} />
    </Box>
  )
}

export default CasePage