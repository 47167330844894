import React from 'react'
import CookieConsent  from 'react-cookie-consent'

const CookieConsentBanner = () => {
  return (
    <CookieConsent
        style={{ backgroundColor: 'white', color: 'black'}}
        buttonText="Accept"
        buttonStyle={{ backgroundColor: '#486bc7', fontWeight: 'bold', color: 'white', borderRadius: '.2rem', padding: '.5rem .75rem', borderRadius: '.3rem' }}
    >
        This website uses essential cookies to ensure proper operation. 
    </CookieConsent>
  )
}

export default CookieConsentBanner