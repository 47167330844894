import { createSlice } from "@reduxjs/toolkit";
import { User } from "../../shared/user.interface";
import { UserItem } from "../../shared/item.interface";

const initialState: {
    profile: User | null;
    inventory: UserItem[]
} = {
    profile: null,
    inventory: []
}

export const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        setProfile: (state, action) => {
            state.profile = action.payload
        },
        setUserPoints: (state, action) => {
            if(!state.profile) return
            state.profile.points = action.payload
        },
        increaseUserPoints: (state, action) => {
            if(!state.profile) return
            state.profile.points += action.payload
        },
        setTradelink: (state, action) => {
            if(!state.profile) return
            state.profile.trade_url = action.payload
        },
        setClashID: (state, action) => {
            if(!state.profile) return
            state.profile.clash_id = action.payload
        },
        verifyClashID: (state, action) => {
            if(!state.profile) return
            state.profile.clash_id = action.payload
            state.profile.clashVerified = true
        },
        verifyRoobetID: (state, action) => {
            if(!state.profile) return
            state.profile.roobetVerified = true
            state.profile.roobetUsername = action.payload.roobetUsername
            state.profile.roobetId = action.payload.roobetId
        },
        setRoobetID: (state, action) => {
            if(!state.profile) return
            state.profile.roobetId = action.payload
        },
        unlinkRoobetID: (state, action) => {
            if(!state.profile) return
            state.profile.roobetVerified = false
            state.profile.roobetUsername = undefined
        },
        unlinkKick: (state) => {
            if(!state.profile) return
            state.profile.kick_id = undefined
            state.profile.kick_username = undefined
        },
        verifyGamdomID: (state, action) => {
            if(!state.profile) return
            state.profile.gamdom_id = action.payload
            state.profile.gamdomVerified = true
        },
        dispatchSetRoobetUsername: (state, action) => {
            if(!state.profile) return
            state.profile.roobetUsername = action.payload
        },
        dispatchSetEthAddress: (state, action) => {
            if(!state.profile) return
            state.profile.eth_address = action.payload
        },
        setUserInventory: (state, action) => {
            state.inventory = action.payload
        },
        updateItemInInventory: (state, action) => {
            state.inventory = state.inventory.map((item) => (
                item._id === action.payload._id ? {...item, ...action.payload} : item
            ))
        },
        setKickID: (state, action) => {
            if(!state.profile) return
            state.profile.kick_id = action.payload.kick_id
            state.profile.kick_username = action.payload.kick_username
        },
        setHypedropID: (state, action) => {
            if(!state.profile) return
            state.profile.hypedrop_id = action.payload.hypedrop_id
        },
        setPackdrawID: (state, action) => {
            if(!state.profile) return
            state.profile.packdrawId = action.payload.packdrawId
        },
        setStakeUsername: (state, action) => {
            if(!state.profile) return
            state.profile.stakeUsername = action.payload.stakeUsername
        },
    }
})

export const { setStakeUsername, setProfile, setKickID, setTradelink, setRoobetID, setClashID, verifyClashID, setHypedropID, setPackdrawID, dispatchSetRoobetUsername, dispatchSetEthAddress, setUserPoints, increaseUserPoints, setUserInventory, updateItemInInventory, verifyGamdomID, unlinkKick, verifyRoobetID, unlinkRoobetID } = profileSlice.actions

export const selectProfile = (state: any) => state.profile.profile as User | null

export const selectInventory = (state: { profile: { inventory: UserItem[] }}) => state.profile.inventory

export default profileSlice.reducer