import { CSGOItem } from "./steam.interface";
import { IUser, Profile, PublicProfile } from "./user.interface";

export enum ECSGOGiveawayStatus {
    BOT_SENDING = 0,
    BOT_CONFIRMING = 1,
    TRADE_READY = 2,
    TRADE_COMPLETED = 3,
    TRADE_CANCELED = 4,
    TRADE_DECLINED = 7,
    INVENTORY_PRIVATE = 10,
    CANCELED = 11
}

export enum ECSGOGiveawayStatusDescription {
    BOT_SENDING = 'Bot Sending',
    BOT_CONFIRMING = 'Bot Confirming',
    TRADE_READY = 'Trade Ready',
    TRADE_COMPLETED = 'Trade Completed',
    TRADE_CANCELED = 'Trade Canceled',
    INVENTORY_PRIVATE = 'Inventory Private',
    CANCELED = 'Canceled'
}

export enum ECommonGiveawayStatus {
    PENDING = 0,
    RECEIVED = 1,
    CANCELED = 2,
    USERNAME_UNAVAILABLE = 3
}

export enum ECommonGiveawayStatusDescription {
    PENDING = 'Pending',
    RECEIVED = 'Received',
    CANCELED = 'Canceled',
    USERNAME_UNAVAILABLE = 'Username Unavilable'
}

export interface ICommonGiveaway {
    _id: string;
    amount: number;
    status: number;
    user?: any;
    rarity: string;
    date: Date;
    timestamp?: number;
    sgwid: number;
}

export interface CSGOGiveaway {
    _id: string;
    item: CSGOItem
    status: number;
    user?: Profile | string;
    tradeofferid: string;
    date: Date;
    csgwid: number;
}


export interface Raffle {
    _id: string;
    raffle_id: number;
    creation_date: Date;
    roll_date: Date;
    prize: number;
    entries: Array<{
        user: PublicProfile;
        points: number;
    }>;
    max_points_per_entry: number;
    status: number;
    total_points: number;
    total_users: number;
    won_entry: {
        user: IUser;
        points: number;
        winning_ticket: number;
    };
    daily: boolean;
    roulette?: string[]
}

export enum ERaffleStatus {
    ACTIVE = 0,
    ROLLED = 1,
    CANCELED = 2,
    PAID = 3
}
