import React from 'react'
import Modal from '../Modal'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { styled } from '@mui/material'

const commonGiveawayOptions = [
    { 
        name: '$1 Common Giveaway',
        odds: 50,
        amount: 1,
        rarity: 'common'
    },
    { 
        name: '$2 Common Giveaway',
        odds: 10,
        amount: 2,
        rarity: 'common'
    },
    { 
        name: '$3 Common Giveaway',
        odds: 10,
        amount: 3,
        rarity: 'common'
    },
    { 
        name: '$5 Common Giveaway',
        odds: 10,
        amount: 5,
        rarity: 'common'
    },
    { 
        name: '$25 Common Giveaway',
        odds: 5,
        amount: 25,
        rarity: 'rare'
    },
    { 
        name: '$50 Common Giveaway',
        odds: 1,
        amount: 50,
        rarity: 'rare'
    },
    { 
        name: '$100 Common Giveaway',
        odds: 1,
        amount: 100,
        rarity: 'epic'
    },
    { 
        name: '$150 Common Giveaway',
        odds: 0.5,
        amount: 150,
        rarity: 'epic'
    },
    { 
        name: '$200 Common Giveaway',
        odds: 0.5,
        amount: 200,
        rarity: 'legendary'
    },
    { 
        name: '$250 Common Giveaway',
        odds: 0.45,
        amount: 250,
        rarity: 'legendary'
    },
    { 
        name: '$1000 Common Giveaway',
        odds: 0.05,
        amount: 1000,
        rarity: 'legendary'
    },
]


const OddsTable = styled('table')(( { theme }) => ({

    width: '100%',
    borderSpacing: '0 6px',
    color: 'var(--text-secondary)',

    'tr > th': {
        color: theme.palette.primary.main,
    },

    '& > th,td': {
        padding: '1rem .5rem',
        textAlign: 'center'
    },


    'tr td': {
        backgroundColor: 'var(--primary-bg-soft)',
        color: 'var(--text-secondary)',
    }


}))

const CommonGiveawayOddsModal = ({open, handleClose}) => {
  return (
      <React.Fragment>
          <Modal
              open={open}
              handleClose={handleClose}
              title={<span>Common Giveaway Odds</span>}
              width="800px"
              closeButton={true}
          >
            <OddsTable>
                <thead>
                    <tr>
                        <th>Prize</th>
                        <th>Odds</th>
                    </tr>
                </thead>
                <tbody>
                    {commonGiveawayOptions.map((option, index) => (
                        <tr key={`odds-vip-ga-${index}`}>
                            <td>${option.amount}</td>
                            <td>{option.odds}%</td>                    
                        </tr>
                    ))}
                    
                </tbody>
            </OddsTable>
          </Modal>
      </React.Fragment>
  )
}

export default CommonGiveawayOddsModal