import React from 'react'
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';

const UnlinkDiscordDialog = ({ open, handleClose, handleAction }) => {

    const confirmAction = () => {
        handleAction();
        handleClose();
    }

  return (
    <Dialog
        open={open}
        handleClose={handleClose}
    >
        <DialogTitle>
            Unlink Discord Account
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                Are you sure you want to unlink your Discord account?
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button variant="contained" color="success" onClick={confirmAction} sx={{ marginRight: 'auto' }}>
                Confirm
            </Button>
            <Button variant="contained" color="error" onClick={handleClose}>
                Cancel
            </Button>
        </DialogActions>
    </Dialog>
  )
}

export default UnlinkDiscordDialog