import React from 'react'

import { Route, Routes } from 'react-router-dom'

import VIPGiveawaysPage from './VIPGiveawaysPage';
import CommonGiveawaysPage from './CommonGiveawaysPage';


const GiveawayIndexPage = () => {

  return (
    <div>
    <Routes>
        <Route exact path="/vip" element={<VIPGiveawaysPage />} />
        <Route exact path="/" element={<CommonGiveawaysPage /> } />
    </Routes>
    
</div>
  )
}

export default GiveawayIndexPage