import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

const NotFoundPage = () => {
  return (
    <Box display="flex" alignItems="center" justifyContent="center" sx={{ padding: '6rem' }}>
        <Typography variant="h3">Page not found</Typography>
    </Box>
  )
}

export default NotFoundPage