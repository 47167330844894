import { Close } from '@mui/icons-material';
import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material'
import React from 'react'

const BonusHuntGuessPayoutDialog = () => {

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };
  
  return (
    <>
        <Typography onClick={handleClickOpen} component="button" sx={{ cursor: 'pointer', transition: 'color .2s', textTransform: 'unset', textDecoration: 'underline', p: '0', '&:hover': { color: 'var(--primary-main)' } }}>
            here
        </Typography>
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="finish-hunt-guessing-dialog-title"
            aria-describedby="finish-hunt-guessing-dialog-description"
            PaperProps={{
                sx: { border: '1px solid var(--primary-border)', borderRadius: '.5rem', position: 'relative' }
            }}
        >
            <IconButton sx={{ position: 'absolute', top: '0', right: '0'}} onClick={handleClose}>
                <Close />
            </IconButton>
            <DialogTitle>
                Bonus Hunt Guessing
            </DialogTitle>
            <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>

                <Box display="flex" flexDirection="column" gap=".5rem">
                    <Typography sx={{ fontWeight: 'bold' }}>Payouts</Typography>
                    <Typography component="ul">
                        <Typography component="li"><b>10%</b> within the end balance you receive <b>2x</b> your bet.</Typography>
                        <Typography component="li"><b>5%</b> within the end balance you receive <b>10x</b> your bet.</Typography>
                        <Typography component="li"><b>1%</b> within the end balance you receive <b>100x</b> your bet.</Typography>
                        <Typography component="li" sx={{ fontWeight: 'bold' }}>Guessing the exact balance (without decimals) pays <b>1,000x</b> your bet.</Typography>
                    </Typography>                    
                </Box>
                <Box display="flex" flexDirection="column" gap=".5rem">
                    <Typography>You can place one guess on each option per bonus hunt.</Typography>
                    <Typography>Max bet is <b>5,000 Points</b>.</Typography>
                </Box>

            </DialogContent>
        </Dialog>
    </>
  )
}

export default BonusHuntGuessPayoutDialog