import React from 'react'
import { Box, Skeleton, Typography } from '@mui/material'
import { CaseItem } from './types'
import { isAPIImage } from '../../utils'
import { getRarityColor } from './CaseTopUnboxings'
import { ItemRarity } from '../../shared/item.interface'


const CaseItemSkeleton = () => {
    return (
        <Box display="flex" flexDirection="column" position="relative" alignItems="center" gap="1rem" sx={{ 
            padding: '1rem', 
            backgroundColor: 'var(--primary-bg-soft)', 
            borderRadius: '.5rem',        
        }}>
            <Skeleton variant="circular" width="100px" height="100px" sx={{ position: 'absolute', top: '-50px', left: '50%', transform: 'translateX(-50%)' }} />
            <Skeleton variant="text" width="66%" height="24px" sx={{ mt: '3rem' }} />
            <Skeleton variant="text" width="33%" height="24px" />

            <Box display="flex" alignItems="center" justifyContent="center" width="100%" gap=".5rem" sx={{ pt: '1rem', borderTop: '1px solid var(--primary-border)' }}>
                <Skeleton variant="text" width="50%" height="24px" />
            </Box>

        </Box>  
    )
}

const CaseItemBox = ({ item }: { item: CaseItem }) => {
    return (
        <Box display="flex" flexDirection="column" position="relative" gap="1rem" sx={{ 
            padding: '1rem', 
            backgroundColor: 'var(--primary-bg-soft)', 
            borderRadius: '.5rem',
        }}>
        <Box component="img" src={isAPIImage(item.item.image)} alt={item.item.name} 
            sx={{ position: 'absolute', top: '-64px', height: '128px', width: '128px', left: '50%', transform: 'translateX(-50%)', 
            filter: `drop-shadow(0 0 .5rem ${getRarityColor(item.item.rarity.toLowerCase() as ItemRarity)})` }} 
        />

        <Box display="flex" flexDirection="column" alignItems="center" gap=".25rem" sx={{ mt: '3rem' }}>
            <Typography sx={{
                fontWeight: 'bold',
                maxWidth: '90%',        
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
            }}>
            {item.item.name}
            </Typography>
            <Typography sx={{ color: 'var(--primary-main)', textTransform: 'capitalize', fontSize: '14px' }}>{item.item.type}</Typography>
        </Box>



        <Box display="flex" alignItems="center" justifyContent="center" gap=".5rem" sx={{ pt: '1rem', borderTop: '1px solid var(--primary-border)' }}>
            <Typography sx={{ fontSize: '14px', color: 'var(--text-secondary)' }}>{item.odds}% Chance</Typography>
        </Box>

    </Box>
    )
}

const CaseItems = ({ isLoading, items }: { isLoading: boolean, items?: CaseItem[] }) => {
  return (
    <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        width="100%"
        gap="6rem"
    >
        <Typography variant="h4" sx={{ color: 'var(--text-secondary)' }}>Case Items</Typography>
        {isLoading ? (
            <>
                <Box display="grid" width="100%" sx={{ rowGap: '6rem', columnGap: '2rem', gridTemplateColumns: { xs: 'repeat(1, minmax(0, 1fr))', sm: 'repeat(2, minmax(0, 1fr))', lg: 'repeat(4, minmax(0, 1fr))', xl: 'repeat(5, minmax(0, 1fr))' }}}>
                    {new Array(16).fill(0).map((_, i) => (
                        <CaseItemSkeleton key={`case-item-skeleton-${i}`} />
                    ))}
                </Box>
            </>
        ) : !items || items.length === 0 ? (
            <Typography>Something went wrong, please refresh</Typography>
        ) : (
            <Box display="grid" width="100%" sx={{ rowGap: '6rem', columnGap: '2rem', gridTemplateColumns: { xs: 'repeat(1, minmax(0, 1fr))', sm: 'repeat(2, minmax(0, 1fr))', lg: 'repeat(4, minmax(0, 1fr))', xl: 'repeat(5, minmax(0, 1fr))' }}}>
                {items.map((item, i) => (
                    <CaseItemBox key={`case-item-${i}`} item={item} />
                ))}
            </Box>
        )}
    </Box>
  )
}

export default CaseItems