import { Box, Button, IconButton, SwipeableDrawer, Typography, styled } from '@mui/material'
import React from 'react'
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import Logo from '../../icons/prodigylogo.png';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import { selectProfile } from '../../redux/profile/profileSlice';
import { Route } from './Nav';

import { ReactComponent as BoxIcon } from '../../icons/box.svg'
import { ReactComponent as Football } from '../../icons/assets/football.svg';
import TrophyIcon from '@mui/icons-material/EmojiEvents';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { ReactComponent as TargetIcon } from '../../icons/assets/circular_target.svg'
import { ReactComponent as TargetXIcon } from '../../icons/assets/target_x.svg'
import StarIcon from '@mui/icons-material/Star';
import { API_URL } from '../../utils';

const StyledDrawer = styled(SwipeableDrawer)(() => ({
    width: '100%',
    height: '100%',
    overflow: 'hidden',

    '.MuiBackdrop-root': {
        backgroundColor: 'rgba(0, 0, 0, .9)',
    },

    '.MuiPaper-root': {
        backgroundColor: 'var(--primary-bg)',
        height: '100%'
    }
}))

const NavButton = styled(Button)(( { theme }) => ({
    textTransform: 'initial',
    color: 'var(--text-primary)',
    fontWeight: 'bold',
    gap: '1rem',
    alignItems: 'center',
  
    svg: {
      height: '32px',
      width: '32px',
      filter: 'drop-shadow(0 0 6px rgba(255, 0, 0, .5))',
      fill: 'var(--primary-main)',
      opacity: .8
    },

    'p': {
        fontSize: '20px'
    }
  }))

const NavSheet = () => {

    const profile = useSelector(selectProfile)
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
      setOpen(true);
    };
  
    const handleDrawerClose = () => {
      setOpen(false);
    };
  

  return (
    <>
        <IconButton color="inherit" 
            sx={{ 
                '&:hover': { 'svg': { fill: 'var(--text-secondary)' }},
                backgroundColor: 'var(--primary-bg-soft)', 
                border: '1px solid var(--primary-border)',
                borderRadius: '.5rem', 
                color: 'var(--primary-main)' 
            }}
            onClick={handleDrawerOpen}
        >
            <MenuIcon />
        </IconButton>
        <StyledDrawer
            anchor={"bottom"}
            open={open}
            onClose={handleDrawerClose}
            onOpen={handleDrawerOpen}
        >
            <Box display="flex" flexDirection="column" height="100%" paddingBottom="2rem">
                <Box display="flex" justifyContent="space-between" sx={{ backgroundColor: 'var(--primary-bg)', boxShadow: '0 0 1rem rgba(0, 0, 0, .25)', px: '1rem', py: '20px', borderBottom: '1px solid var(--primary-border)' }}>
                    <Box component={Link} to={"/"} sx={{ color: 'inherit' }}>
                        <Button disableRipple sx={{ gap: '1rem', fontSize: '20px' }} onClick={handleDrawerClose}>
                            <Box component="img" src={Logo} alt="Logo" sx={{ height: '36px', width: '36px' }} />
                            <Typography sx={{ fontSize: '20px' }}>ProdigyDDK</Typography>
                        </Button>
                    </Box>
                    <IconButton color="inherit" 
                        sx={{ 
                            '&:hover': { 'svg': { fill: 'var(--text-secondary)' }},
                            backgroundColor: 'var(--primary-bg-soft)', 
                            border: '1px solid var(--primary-border)',
                            borderRadius: '.5rem', 
                            color: 'var(--primary-main)' 
                        }}
                        onClick={handleDrawerClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box display="flex" flexDirection="column" gap="2rem" sx={{ my: 'auto', padding: '1rem' }}>

                    <Link to={Route.FREE} style={{ color: 'inherit' }}>
                        <NavButton disableRipple onClick={handleDrawerClose} sx={{ color: 'gold' }}>
                            <StarIcon sx={{ fill: 'gold !important', filter: 'drop-shadow(0 0 .5rem rgba(255, 215, 0, .5)) !important' }} />
                            <Typography sx={{ textShadow: '0 0 .5rem rgba(255, 215, 0, .5)' }}>Free</Typography>
                        </NavButton>
                    </Link>
                    <Link to={Route.CHALLENGES} style={{ color: 'inherit' }}>
                        <NavButton disableRipple onClick={handleDrawerClose}>
                            <TargetXIcon />
                            <Typography>Challenges</Typography>
                        </NavButton>
                    </Link>
                    <Link to={Route.STORE} style={{ color: 'inherit' }}>
                        <NavButton disableRipple onClick={handleDrawerClose}>
                            <ShoppingCartIcon />
                            <Typography>Store</Typography>
                        </NavButton>
                    </Link>
                    <Link to={Route.BONUS_HUNTS} style={{ color: 'inherit' }}>
                        <NavButton disableRipple onClick={handleDrawerClose}>
                            <TargetIcon />
                            <Typography>Bonus Hunts</Typography>
                        </NavButton>
                    </Link>
                    <Link to={Route.CASES} style={{ color: 'inherit' }}>
                        <NavButton disableRipple onClick={handleDrawerClose}>
                            <BoxIcon />
                            <Typography>Cases</Typography>
                        </NavButton>
                    </Link>
                    <Link to={Route.LEADERBOARD} style={{ color: 'inherit' }}>
                        <NavButton disableRipple onClick={handleDrawerClose}>
                            <TrophyIcon />
                            <Typography>Leaderboard</Typography>
                        </NavButton>
                    </Link>
                    {profile && (
                        <Link to={Route.PROFILE} style={{ color: 'inherit' }}>
                            <NavButton disableRipple onClick={handleDrawerClose}>
                                <Box component="img" src={profile.avatar} alt="Avatar" sx={{ height: '32px', width: '32px', borderRadius: '100%', border: '2px solid var(--primary-border)' }} />
                                <Typography>{profile.display_name}</Typography>
                            </NavButton>
                        </Link>
                    )}

                </Box>
                {!profile ? (
                    <Button disableRipple component="a" href={`${API_URL}/auth/twitch/user`} sx={{ fontWeight: 'bold' }}>
                        Sign In
                    </Button> 
                ) : (
                    <Button disableRipple component="a" href={`${API_URL}/auth/twitch/logout`} sx={{ fontWeight: 'bold' }}>
                        Sign Out
                    </Button>
                )}
     
            </Box>
        </StyledDrawer>
    </>
  )
}

export default NavSheet