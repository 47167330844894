import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { selectVipGiveawayInfo, setVipGiveawayInfo, setVipGiveawayRecentWins, setVipGiveawayTimeUntilNext } from '../../redux/giveaway/vipGiveawaySlice';
import { styled } from '@mui/material'

import { API_URL } from '../../utils'
import VIPGiveawayBox from '../../components/Giveaway/VIPGiveawayBox';
import VIPGiveawayOddsModal from '../../components/Giveaway/VIPGiveawayOddsModal';
import VIPGiveawayHistoryModal from '../../components/Giveaway/VIPGiveawayHistoryModal';

const InfoValueText = styled(Typography)(({ theme }) => ({
    fontSize: '40px',
    fontWeight: 600,
}))

const InfoDescriptionText = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.secondary,
}))

const GiveawaysNotActiveText = styled(Typography)(({ theme }) => ({
    fontSize: '20px'
}))

const LinkText = styled('a')(({ theme }) => ({
    color: theme.palette.text.secondary,
    display: 'flex',
    alignItems: 'center',
    'svg': {
        marginRight: '.5rem'
    }
}))

const updateCountdownTime = (countdownEnd, setCountdownString) => {

    let distance = countdownEnd - Date.now();

    let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    let seconds = Math.floor((distance % (1000 * 60)) / 1000);

    let countString = ``

    if (minutes > 0) countString += `${minutes}m `
    if (seconds > 0) countString += `${seconds}s`;

    if (countString === ``) countString = 'Rolling soon';

    setCountdownString(countString)
}

const fetchVIPGiveawayInfo = (dispatch) => {
    fetch(`${API_URL}/giveaway/vip/info`)
        .then((response) => {
            if (!response.ok) return false
            else return response.json()
        }).then((data) => {
            if (data && !data.error) {
                dispatch(setVipGiveawayInfo(data))
                dispatch(setVipGiveawayTimeUntilNext(data.time_until_next))
            }
        }).catch((err) => {

        });
}

const fetchVIPRecentWins = (dispatch) => {
    fetch(`${API_URL}/giveaway/vip/recentwinners`)
        .then((response) => {
            if (!response.ok) return false
            else return response.json()
        }).then((data) => {
            if (data && !data.error) dispatch(setVipGiveawayRecentWins(data))
        }).catch((err) => {

        });
}

const VIPGiveawaysPage = () => {

    const dispatch = useDispatch();

    const vipGiveawayInfo = useSelector(selectVipGiveawayInfo)

    const [vipGiveawayHistoryModalOpen, setVipGiveawayHistoryModalOpen] = useState(false);
    const [vipGiveawayOddsModalOpen, setVipGiveawayOddsModalOpen] = useState(false);

    const [countdownString, setCountdownString] = useState(0);

    useEffect(() => {
        fetchVIPGiveawayInfo(dispatch)
        fetchVIPRecentWins(dispatch)
    }, [dispatch])

    useEffect(() => {

        if (vipGiveawayInfo.time_until_next <= 0) {
            return false;
        }
    
        let countdownEnd = Date.now() + vipGiveawayInfo.time_until_next;
    
        const interval = setInterval(() => {
            updateCountdownTime(countdownEnd, setCountdownString)
        }, 1000)

        return () => {
            clearInterval(interval)
        }

    }, [vipGiveawayInfo.time_until_next])


    return (
        <Box>
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" sx={{ marginTop: '2rem', marginBottom: '2rem' }}>
                <Typography variant="h4" fontWeight="bold">Win up to $2,000</Typography>
                <Typography variant="h6" sx={{ margin: '.5rem 0' }}>Every 30 Minutes</Typography>
                <Typography sx={{ color: 'primary.light', cursor: 'pointer' }} onClick={() => setVipGiveawayOddsModalOpen(true)}>View Odds</Typography>
            </Box>

            <Box
                display="flex"
                flexDirection="column"
                sx={{
                    padding: '2rem 1rem 2rem 1rem',
                    backgroundColor: 'primary.background',
                    boxShadow: 'inset 0 0 12px rgba(20, 16, 24, .5)',
                }}
            >
                {vipGiveawayInfo.active ? (
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <InfoValueText>
                                {countdownString ? countdownString : 'Getting Time Left...'}
                            </InfoValueText>
                            <InfoDescriptionText>
                                Until next Giveaway
                            </InfoDescriptionText>
                        </Box>

                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                            sx={{
                                marginTop: 2
                            }}
                        >
                            <InfoValueText>
                                {vipGiveawayInfo.eligible_viewers.length}
                            </InfoValueText>
                            <InfoDescriptionText>
                                Eligible Viewers
                            </InfoDescriptionText>
                        </Box>
                    </Box>
                ) : (
                    <Box display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        textAlign="center"
                    >
                        <GiveawaysNotActiveText>
                            Super Giveaways are currently not active
                        </GiveawaysNotActiveText>
                        <InfoDescriptionText>
                            Check back when Prodigy is live
                        </InfoDescriptionText>
                        <LinkText href="https://www.twitch.tv/prodigyddk">

                            <Typography variant="span">
                                https://www.twitch.tv/prodigyddk
                            </Typography>
                        </LinkText>
                    </Box>
                )}

                {vipGiveawayInfo.recent_wins && vipGiveawayInfo.recent_wins.length > 0 ? (
                    <Box
                        display="flex"
                        flexDirection="column"
                    >
                        <Box
                            display="flex"
                            alignItems="center"
                            sx={{
                                marginTop: 4,
                                scrollbarColor: '#49547a #404761',
                                scrollbarWidth: 'thin',

                                overflowX: 'scroll',
                                paddingBottom: 2,
                            }}
                        >
                            {vipGiveawayInfo.recent_wins.map((giveaway, index) => (
                                <VIPGiveawayBox key={`vip-recent-wins-${index}`} id={`casino500-${index}`} amount={giveaway.amount} user={giveaway.user} date={giveaway.timestamp} />
                            ))}
                        </Box>
                        <Typography
                            sx={{ cursor: 'pointer', color: 'primary.light', margin: '0 auto', marginTop: '1rem' }}
                            onClick={() => setVipGiveawayHistoryModalOpen(true)}
                        >
                            View All
                        </Typography>
                    </Box>
                ) : (<Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                        marginTop: '1.5rem',
                        color: 'rgba(255, 255, 255, .8)',
                        fontSize: 18
                    }}
                >
                    No Giveaways have been rolled yet
                </Box>
                )}
            </Box>
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" sx={{ paddingTop: '1rem', color: 'rgba(255, 255, 255, .75)' }}>
                <Typography variant="span" sx={{ margin: '.5rem', fontWeight: 'bold', fontSize: '18px' }}>Super Giveaway Requirements</Typography>
                <Typography>Every 30 Minutes a random Winner is drawn</Typography>
                <ul>
                    <li>Follow <Typography to="/profile" component="a" sx={{ color: 'primary.light' }} href="https://www.twitch.tv/prodigyddk" target="href">ProdigyDDK</Typography>
                    </li>
                    <li>Be active in Twitch Chat</li>
                    <li>Set your Gamdom ID under <Typography to="/profile" component={Link} sx={{ color: 'primary.light' }}>Profile</Typography></li>
                </ul>
            </Box>
            <VIPGiveawayHistoryModal open={vipGiveawayHistoryModalOpen} handleClose={() => setVipGiveawayHistoryModalOpen(false)} />
            <VIPGiveawayOddsModal open={vipGiveawayOddsModalOpen} handleClose={() => setVipGiveawayOddsModalOpen(false)} />
        </Box>
    )
}

export default VIPGiveawaysPage