import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    kw_giveaway: {
        eligible_entries: [],
        winner_messages: [],
    }
}

export const kwGiveawaySlice = createSlice({
    name: 'kw_giveaway',
    initialState,
    reducers: {
        setEligibleEntries: (state, action) => {
            state.kw_giveaway.eligible_entries = action.payload
        },
        addWinnerMessage: (state, action) => {
            state.kw_giveaway.winner_messages = [...state.kw_giveaway.winner_messages, action.payload]
        },
        clearWinnerMessages: (state, action) => {
            state.kw_giveaway.winner_messages = []
        }
            
    }
})


export const { setEligibleEntries, addWinnerMessage, clearWinnerMessages } = kwGiveawaySlice.actions

export const selectEligibleEntries = state => state.kw_giveaway.kw_giveaway.eligible_entries
export const selectWinnerMessages = state => state.kw_giveaway.kw_giveaway.winner_messages

export default kwGiveawaySlice.reducer