import React, { useEffect, useRef, useState } from 'react'
import { Box } from '@mui/material'
import { Raffle } from './types'
import { ArrowDropDown } from '@mui/icons-material'

const CAROUSEL_ELEMENT_WIDTH = 80

const RANDOM_ANIMATIONS = [
  {
      transform: -1269,
      animationDuration: 7000,
      animationTimingFunction: 'cubic-bezier(.2,.33,.33,.98)'
  },  
  {
      transform: -1212,
      animationDuration: 7000,
      animationTimingFunction: 'cubic-bezier(.2,.33,.33,.98)'
  },
]

function getHighlightedUser(carouselEl: HTMLElement | null) {
  if (!carouselEl) return;
  const offsetX = carouselEl.clientWidth / 2;
  const value = parseFloat(
    window.getComputedStyle(carouselEl).getPropertyValue("transform").split(",")[4]
  )
  if (!value) return;
  const transform = value * -1 + offsetX;
  const activeIndex = Math.floor(transform / CAROUSEL_ELEMENT_WIDTH);

  return activeIndex;
}

const CarouselUser = ({ user, isHighlighted }: { user: { display_name: string, avatar: string }, isHighlighted: boolean }) => {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box component="img" src={user.avatar} alt={user.display_name} sx={{ border: '1px solid var(--primary-border)', height: '64px', width: '64px', borderRadius: '100%', transition: 'transform 200ms ease-in-out', 
        ...isHighlighted ? { transform: 'scale(1.05)', opacity: 1 } : { opacity: .85 } }} />
      </Box>
    )
}

const RaffleWinner = ({ raffle }: { raffle: Raffle }) => {

  let carouselRef = useRef(null)
  const [userCarousel, setUserCarousel] = useState<{ display_name: string, avatar: string }[]>([])
  const [highlightedUser, setHighlightedUser] = useState<number>(0)
  const [isRollTransitionFinished, setIsRollTransitionFinished] = useState(false)
  const [isTransitionFinished, setIsTransitionFinished] = useState(false)
  const [isTransitioning, setIsTransitioning] = useState(false)
  const [transitionFinishTime, setTransitionFinishTime] = useState<number | undefined>(undefined)
  const [transition, setTransition] = useState<{ 
    transform: number, 
    animationDuration: number,
    animationTimingFunction: string } 
    | undefined
>(undefined)

  useEffect(() => {
    if(userCarousel.length === 0) return 
    
      setIsTransitioning(true)

      const timeout = setTimeout(() => {

        setIsRollTransitionFinished(true)

        setTimeout(() => {
          setIsTransitionFinished(true)
        }, 1000)

      }, Math.max((transitionFinishTime || 1000) - Date.now(), 1000) + 150)

    const highlightedInterval = setInterval(() => {
      setHighlightedUser(prevState => getHighlightedUser(carouselRef.current) || prevState)
    }, 24)

    return () => {
      clearTimeout(timeout)
      clearInterval(highlightedInterval)
    }
  }, [userCarousel, transitionFinishTime])

  useEffect(() => {
    if(raffle.carousel) {
      setUserCarousel(raffle.carousel)
      setTransition(RANDOM_ANIMATIONS[Math.floor(Math.random() * RANDOM_ANIMATIONS.length)])
    }
      
  }, [raffle.carousel])

  useEffect(() => {
    if(raffle.finishAt)
      setTransitionFinishTime(new Date(raffle.finishAt).getTime())
  },  [raffle.finishAt])

  useEffect(() => {

    if(!isTransitionFinished || !raffle.result?.winner) return

    //setIsTransitioning(false)

    //setUserCarousel([])

  }, [isTransitionFinished, raffle.result?.winner])

  return (
    <Box 
      display="flex" 
      height="auto" 
      width="100%"
      position="relative"
      sx={{
        transition: 'transform 1s, opacity 1s, max-height .5s, width .5s, height .5s',
        ...isTransitioning ? {
          visibility: 'visible',
          height: '96px',
          width: '100%',
          opacity: 1,
          transform: `translateY(0)`,
        } : {
          visibility: 'hidden',
          width: '0px',
          height: '0px',
          maxHeight: '0px',
          transform: `translateY(4px)`,
        }
      }}
    >
      {userCarousel.length > 0 && (
        <ArrowDropDown sx={{ height: '32px', width: '32px', position: 'absolute', top: '-20px', left: '50%', transform: 'translateX(-50%)'}} />
      )}
      
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        width="100%"
        height="96px"
        position="absolute"
        overflow="hidden"
      >
        <Box
          ref={carouselRef}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{
            top: '50%',
            gap: '1rem',
            ...!isTransitionFinished && isTransitioning && transitionFinishTime  ? {
              opacity: 1
            } : {
              opacity: 0
            },
            ...isRollTransitionFinished ? {
              transition: `transform 300ms ease-in-out, opacity 2s ease`,
              transform: `translate(-1240px, -50%)`
            } : userCarousel.length > 0 ? {
              transition: `transform ${Math.max((transitionFinishTime || 1000) - Date.now(), 1000)}ms ${transition?.animationTimingFunction || "ease"}, opacity 2s ease`,
              transform: `translate(${transition?.transform || -1240}px, -50%)`
            } : {
              transition: 'transform 0s ease, opacity 2s ease',
              transform: `translate(1240px, -50%)`,
              visibility: 'invisible'
            }
          }}
        >
          {userCarousel.map((user, index) => (
            <CarouselUser key={`raffle-${raffle._id}-user-${index}`} user={user} isHighlighted={index === highlightedUser} />
          ))}
        </Box>
      </Box>
    </Box>
  )
}

export default RaffleWinner   