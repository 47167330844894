import React, { useState, useEffect, useRef } from 'react'
import Modal from '../Modal'

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import { fetchAPI, hasPermission } from '../../utils';
import { MenuItem, Select, styled } from '@mui/material'
import Badge from '@mui/material/Badge';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import OutlinedInput from '@mui/material/OutlinedInput';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { useNavigate } from 'react-router-dom';
import { Fade } from '@mui/material';
import { createErrorToast } from '../Toasts/ErrorToasts';
import { useSelector } from 'react-redux';
import { selectProfile } from '../../redux/profile/profileSlice';
import { Permission } from '../../shared/user.interface';
import Switch from '@mui/material/Switch';
import { useMediaQuery } from 'react-responsive';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

const BoxContainer = styled(Box)(( { theme }) => ({
  backgroundColor: 'var(--primary-bg-soft)',
  padding: "1rem",
  borderRadius: "12px",
  width: "auto",
  height: "190px",
  position: 'relative',
  boxShadow: '0 0 10px rgba(0, 0, 0, .2)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  'img': {
    height: '128px',
    width: '128px',
    top: 0,
    position: 'absolute',
    transform: 'translateY(-50%)',
    filter: 'drop-shadow(0 0 0.3rem rgb(0, 0, 0))'
  },
  
  '.name': {
    color: theme.palette.text.secondary
  }
}))

const SelectedBoxSmallContainer = styled(Box)(( { theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  'img': {
    height: '42px',
    width: '42px',
  },

  '.MuiBadge-badge': {
    backgroundColor: 'transparent',
    color: 'var(--text-secondary)',
    fontWeight: 'bold'
  }
}))



type CreateBattleModalProps = {
    open: boolean;
    handleClose: () => void;
}

type CaseProps = {
  box: {
    _id: string;
    unique_id: string;
    name: string;
    image: string;
    price: number;
    battle_disabled: boolean;
    battle_hidden: boolean;
    disabled: boolean;
    hidden: boolean;
  },
  addCase: (box: ISelectedCase) => void;
}

type SelectedCaseProps = {
  box: ISelectedCase;
  index: number;
  updateSelectedCase: (index: number, amount: number) => void;
}

interface ISelectedCase {
  _id: string;
  unique_id: string;
  name: string;
  image: string;
  price: number;
  amount: number
}

const CaseBox = ({ box, addCase }: CaseProps) => {

  const [buttonTimeout, setButtonTimeout] = useState(false);

  useEffect(() => {

    const interval = setTimeout(() => {
      setButtonTimeout(false)
    }, 500);

    return () => {
      clearTimeout(interval)
    }

  }, [buttonTimeout])

  const handleAdd = () => {
    if(box.battle_disabled) return createErrorToast( 'This case is disabled');
    if(box.battle_hidden) return createErrorToast('This case is hidden');
    addCase({ ...box, amount: 1 })
    setButtonTimeout(true);
  }

  return (
    <BoxContainer>
      <img src={box.image} alt="" />
      <Box display="flex" flexDirection="column" sx={{ 
        position: 'absolute',
        top: '50%',
        left: '50%',
        width: '100%',
        textAlign: 'center',
        transform: 'translate(-50%, -70%)',
      }}>
        <Typography sx={{ fontSize: '18px'}}>{box.name}</Typography>
        <Typography sx={{ fontSize: '14px', color: 'var(--text-secondary)' }}>{Intl.NumberFormat('en-US').format(box.price)} Points</Typography>
      </Box>



      {buttonTimeout ? (
        <Fade in={buttonTimeout} key={`added-${box._id}`} timeout={500}>
          <Box
            marginTop="auto"
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="100%"
          >
            <CheckIcon sx={{ color: 'success.main', marginRight: '.5rem', height: '24px', width: '24px' }} />
            <Typography sx={{ color: 'text.primary', fontSize: '20px' }}>Added</Typography>
          </Box>
        </Fade>
      ) : (
        <Fade key={`add-${box._id}`} in={!buttonTimeout} timeout={{
          appear: 500,
          enter: 500,
          exit: 500
        }}>
          <Button variant="contained" color="primary" sx={{ marginTop: 'auto', transition: 'background-color .3s ease-in-out, 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important' }} onClick={handleAdd}>
            Add
          </Button>
        </Fade>
      )}


    </BoxContainer>
  )
}

const SelectedCaseBox = ({ box, index, updateSelectedCase }: SelectedCaseProps) => {

  const updateCaseAmount = (index: number, value: number) => {
    if(value > 50) return updateSelectedCase(index, 50);
    updateSelectedCase(index, value)
  }

  return (
    <BoxContainer>
      <IconButton
        sx={{
          color: 'error.light',
          position: 'absolute',
          top: '2px',
          right: '2px'
        }}
        onClick={() => updateCaseAmount(index, 0)}
      >
        <CloseIcon />
      </IconButton>
      <img src={box.image} alt="" />
      <Box display="flex" flexDirection="column" sx={{ 
        position: 'absolute',
        top: '50%',
        left: '50%',
        width: '100%',
        textAlign: 'center',
        transform: 'translate(-50%, -70%)'
      }}>
        <Typography sx={{ fontSize: '18px'}}>{box.name}</Typography>      
        <Typography sx={{ fontSize: '14px', color: 'var(--text-secondary)' }}>{Intl.NumberFormat('en-US').format(box.price * box.amount)} Points</Typography> 
      </Box>
      <Box display="flex" alignItems="center" justifyContent="space-evenly" marginTop="auto" width="100%">
        <IconButton 
          sx={{ border: '2px solid var(--primary-border)', borderRadius: '50%', padding: '4px', color: 'text.primary', '&:hover': { backgroundColor: 'rgb(64, 60, 80)'} }}
          onClick={() => updateCaseAmount(index, box.amount - 1) }
        >
          <RemoveIcon />
        </IconButton>
        <OutlinedInput
          type="number"
          name="amount"
          value={box.amount}
          onChange={(e) => updateCaseAmount(index, parseInt(e.target.value))}
          sx={{ 
            width: '50px',
            margin: '0 .5rem',
            textAlign: 'center',

            'input': {
              textAlign: 'center',
              padding: '.33rem'
            }
          }}
          
        />
        <IconButton

          sx={{ border: '2px solid var(--primary-border)', borderRadius: '50%', padding: '4px', color: 'text.primary', '&:hover': { backgroundColor: 'rgb(64, 60, 80)'} }}
          onClick={() => updateCaseAmount(index, box.amount + 1) }
        >
          <AddIcon />
        </IconButton>
      </Box>

    </BoxContainer>
  )
}

const CreateBattleModal = ({ open, handleClose }: CreateBattleModalProps) => {

  const navigate = useNavigate();

  const isSmallDevice = useMediaQuery({ query: '(max-width: 800px)' });

  const profile = useSelector(selectProfile);

  const [cases, setCases] = useState([]);
  const [playerAmount, setPlayerAmount] = useState(2);
  const [isGiveaway, setIsGiveaway] = useState(false);

  const [selectedCases, setSelectedCases] = useState<Array<ISelectedCase>>([])
  const [battleCost, setBattleCost] = useState(0);

  const [showScrollButton, setShowScrollButton] = useState(false);

  useEffect(() => {

    const getCases = async () => {
      try {
        const data: any = await fetchAPI('/battle/cases', 'GET', null, null, true)

        setCases(data);

      } catch (e) {}
    }

    if(open) {
      getCases();
    }
  }, [open])

  const addCase = (box: ISelectedCase) => {
    
    if(selectedCases.length > 0 && selectedCases.slice(-1)[0]._id === box._id) {
      let tempCases = [...selectedCases]
      let _box = { ...tempCases.slice(-1)[0] };
      _box.amount = _box.amount + 1;
      tempCases.splice(-1, 1, _box);
      setSelectedCases(tempCases)

    } else setSelectedCases([...selectedCases, box])
    
  }

  const updateSelectedCase = (index: number, amount: number) => {
    let tempCases = [...selectedCases]
    let _box = { ...tempCases[index] }
    if(amount === 0) {
      tempCases.splice(index, 1)
    } else {
      _box.amount = amount
      tempCases[index] = _box;
    }
    
    setSelectedCases(tempCases);
  }

  const createBattle = async () => {

    if(selectedCases.length < 1) {
      alert('You need to add at least 1 case')
    }

    const cases: any = [];

    selectedCases.forEach((box, index) => {
      cases.push({_id: box._id, amount: box.amount, order: index})
    })

    try {
      const data: any = await fetchAPI('/battle/create', 'POST', {
        cases: cases,
        players: playerAmount,
        isGiveaway: isGiveaway
      })

      if(data.success) {
        handleClose();
        navigate(`/battle/${data.data.battleId}`)
      }
      
    } catch (e) {}
  }

  useEffect(() => {
    selectedCases.length > 0 && setBattleCost(selectedCases.reduce((acc, box) => acc + (box.price * box.amount), 0))
  }, [selectedCases])

  function scrollToTop() {

    const el = document.getElementById('create-battle-modal');
    
    if(el) {
      el.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }

  function handleScroll(event: React.UIEvent<HTMLDivElement, UIEvent>) {
    const el = event.currentTarget;
    if(el.scrollTop > 200) {
      setShowScrollButton(true)
    } else {
      setShowScrollButton(false)
    }
  }

  return (
    <Modal
      id="create-battle-modal"
      open={open}
      handleClose={handleClose}
      closeButton={true}
      width="1200px"
      padding={false}
      onScroll={(e) => handleScroll(e)}
    >
      <Box
        display="flex"
        flexDirection="column"
      >
        
        <Box
          display="flex"
          flexDirection="column"
          padding="1rem"
        >
          <Typography variant="h4" marginBottom="1rem">Create Battle</Typography>
          <Box display="flex" alignItems="center" height="64px">

            <Box display="flex" gap="1rem" alignItems="center" justifyContent="flex-end" sx={{ marginLeft: 'auto' }}>
              {hasPermission(profile, Permission.CREATE_BATTLE) && (
                <Tooltip title="Allows you to add users to the battle">
                  <FormControlLabel control={<Switch checked={isGiveaway} onChange={(e) => setIsGiveaway(e.target.checked)} />} label="Giveaway" />
                </Tooltip>

              )}
              <Select value={playerAmount} onChange={(e) => setPlayerAmount(e.target.value as number)} sx={{ width: '120px' }}>
                <MenuItem value={2}>1v1</MenuItem>
                <MenuItem value={3}>1v1v1</MenuItem>
                <MenuItem value={4}>1v1v1v1</MenuItem>
              </Select>

            </Box>
          </Box>

          <Box>
            {selectedCases.length > 0 ? (
              <Box
                display="grid"
                gridTemplateColumns="repeat(auto-fill,minmax(240px, 240px))"
                justifyContent="center"
                rowGap="7rem"
                columnGap="1rem"
                marginTop="7rem"
              >
                {selectedCases.map((box: any, index: number) => <SelectedCaseBox key={index} box={box} index={index} updateSelectedCase={updateSelectedCase} />)}
              </Box>
            ) : (
              <Typography variant="h4" marginTop="4rem" textAlign="center" sx={{ color: 'text.secondary' }}>Select Cases to create a Battle</Typography>
            )}

          </Box>
          <Typography variant="h3" sx={{ marginTop: '4rem', color: 'text.secondary' }}>Cases</Typography>
          <Box
            display="grid"
            gridTemplateColumns="repeat(auto-fill,minmax(240px, 240px))"
            justifyContent="center"
            rowGap="6.5rem"
            columnGap="1rem"
            marginTop="6.5rem"
          >
            {cases.map((box: any, index: number) => <CaseBox key={index} box={box} addCase={addCase} />)}
          </Box>
        </Box> 
        {showScrollButton && (
          <IconButton onClick={() => scrollToTop()} 
            disableRipple
            sx={{ bottom: '72px', left: '.5rem', width: '48px', fill: 'var(--text-primary) !important', height: "48px", position: 'sticky', backgroundColor: 'var(--secondary-main)',
              ':hover': { backgroundColor: 'var(--secondary-dark)' }, 'svg': { fill: 'var(--text-primary) !important'}
            }}>
            <ArrowUpwardIcon />
          </IconButton>
        )}

        <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ position: 'sticky', height: '64px', borderRadius: '.5rem', bottom: '0', padding: '.5rem 1rem',
          background: 'var(--primary-bg-light)', width: '100%', columnGap: '.5rem'
        }}>
          <Typography sx={{ color: 'var(--text-secondary)', width: '25%', maxWidth: '150px' }}>{Intl.NumberFormat('en-US').format(battleCost)} Points</Typography>
          <Box className="styled-scroll" display="flex" alignItems="center" justifyContent="center" columnGap="1.25rem" sx={{ width: '100%', maxWidth : '100%', height: '64px', overflowX: 'auto' }}>
            {selectedCases.map((box, index) => (
              <SelectedBoxSmallContainer key={`battle-case-small-${index}`}>
                <Badge badgeContent={box.amount} color="secondary">
                  <img src={box.image} alt="" />
                </Badge>
              </SelectedBoxSmallContainer>
            ))}
          </Box>
          <Box display="flex" alignItems="center" justifyContent="center" sx={{ width: '30%', maxWidth: '120px'}}>
            <Button variant="contained" color="secondary" disableRipple onClick={() => createBattle()} >Create</Button>
          </Box>
          
        </Box>
      </Box>



    </Modal>
  )
}

export default CreateBattleModal