import React from 'react'
import Modal from '../Modal'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { styled } from '@mui/material'

const vipGiveawayOptions = [
    { 
        odds: 10,
        amount: 5,
        rarity: 'common'
    },
    { 
        odds: 59,
        amount: 10,
        rarity: 'common'
    },
    { 
        odds: 5,
        amount: 20,
        rarity: 'rare'
    },
    { 
        odds: 5,
        amount: 50,
        rarity: 'rare'
    },
    { 
        odds: 9,
        amount: 69,
        rarity: 'epic'
    },
    { 
        odds: 5,
        amount: 100,
        rarity: 'epic'
    },
    { 
        odds: 5,
        amount: 250,
        rarity: 'legendary'
    },
    { 
        odds: 1,
        amount: 500,
        rarity: 'legendary'
    },
    { 
        odds: 0.1,
        amount: 1000,
        rarity: 'legendary'
    },
    {
        odds: 0.99,
        amount: 0,
        rarity: 'legendary',
        min_amount: 1,
        max_amount: 2000,
        type: 'mystery'
    }
]


const OddsTable = styled('table')(( { theme }) => ({
    width: '100%',
    borderCollapse: 'collapse',
    borderSpacing: 0,

    '& > th,td': {
        width: '50%',
        padding: '.75rem',
        textAlign: 'center',
    },

    'tr > th': {
        paddingBottom: '1rem'
    },

    'tr > td': {
        border: '1px solid transparent',
        borderColor: 'rgba(200, 200, 200, .5)'
    }

}))

const VIPGiveawayOddsModal = ({open, handleClose}) => {
  return (
      <React.Fragment>
          <Modal
              open={open}
              handleClose={handleClose}
              title={<span>Super Giveaway Odds</span>}
              width="800px"
              actions={
                  <Box display="flex">
                      <Button variant='contained' sx={{ marginLeft: 'auto' }} onClick={handleClose}>Close</Button>
                  </Box>}
          >
            <OddsTable>
                <thead>
                    <tr>
                        <th>Prize</th>
                        <th>Odds</th>
                    </tr>
                </thead>
                <tbody>
                    {vipGiveawayOptions.map((option, index) => (
                        <tr key={`odds-vip-ga-${index}`}>
                            <td>{option.type === "mystery" ? `Mystery ($${option.min_amount} - $${option.max_amount})` : '$' + option.amount}</td>
                            <td>{option.odds}%</td>                    
                        </tr>
                    ))}
                    
                </tbody>
            </OddsTable>
          </Modal>
      </React.Fragment>
  )
}

export default VIPGiveawayOddsModal