import React from 'react'
import IconButton from '@mui/material/IconButton'
import InfoIcon from '@mui/icons-material/Info';
import Popover from '@mui/material/Popover';
import { Box } from '@mui/material';

const ItemDescriptionPopover = ({ description }: { description: string }) => {

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);
    const id = open ? 'item-description-popover' : undefined;

  return (
    <>
        <IconButton aria-describedby={id} onClick={handleClick}>
            <InfoIcon />
        </IconButton>
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'center',
                horizontal: 'right',
            }}
        >
            <Box sx={{ p: '1rem', maxWidth: '300px', borderRadius: '.5rem', border: '1px solid var(--primary-border)' }}>{description}</Box>
        </Popover>
    </>
  )
}

export default ItemDescriptionPopover