import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { styled } from '@mui/material'

import { useSelector, useDispatch } from 'react-redux'
import { selectProfile, setProfile } from '../../redux/profile/profileSlice'
import { OutlinedInput, Accordion, AccordionSummary, AccordionDetails} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CheckIcon from '@mui/icons-material/Check'

import { API_URL, formatDate } from '../../utils'
import StarIcon from '@mui/icons-material/Star'
import RaffleRow from '../../components/Profile/RaffleRow'

import { createToast } from '../../components/Toasts/Toasts'

import CSGOGiveawayRow from '../../components/Profile/CSGOGiveawayRow'
import InventoryTab from '../../components/Profile/InventoryTab'
import { ReactComponent as Kick } from '../../icons/sites/kick.svg'
import { ReactComponent as Discord } from '../../icons/discord.svg'
import GiveawayTab from '../../components/Profile/GiveawayTab'
import UnlinkDiscordDialog from '../../components/Profile/UnlinkDiscordDialog'
import { unlinkDiscordAccountToast } from '../../components/Toasts/LinkAccountToasts'
import SettingsIcon from '@mui/icons-material/Settings'
import { ReactComponent as Inventory } from '../../icons/assets/inventory.svg'
import { ReactComponent as RaffleIcon } from '../../icons/ticket.svg'
import { ReactComponent as CSGOIcon } from '../../icons/csgo.svg'
import MoneyIcon from '@mui/icons-material/AttachMoney'
import LinkIcon from '@mui/icons-material/Link'
import { useMediaQuery } from 'react-responsive'
import GamdomHelp from '../../components/Profile/GamdomHelp'
import UnlinkKickModal from '../../components/Profile/Connections/UnlinkKickModal'
import ConnectKickModal from '../../components/Profile/Connections/ConnectKickModal'
import { CSGOGiveaway } from '../../shared/giveaway.interface'
import { Link } from 'react-router-dom'
import ProfileSettings from './ProfileSettings'

const ProfileNameText = styled(Typography)(({ theme }) => ({
    fontSize: '24px',
    marginTop: 8
}))

const JoinedDateText = styled(Typography)(({ theme }) => ({
    color: 'rgba(255, 255, 255, .6)'
}))

const ProfileContainer = styled(Box)(({ theme }) => ({
    padding: 32,
    backgroundColor: 'var(--primary-bg-soft)',
    color: theme.palette.text.secondary,
    width: '100%',
    maxWidth: '1200px',
    borderRadius: "1rem",
    boxShadow: '0 0 6px rgba(16, 16, 24, .1)',

    '.avatar': {
        height: '64px',
        width: '64px',
        borderRadius: '50%'
    },
}))

const RaffleContainer = styled(Box)(({ theme }) => ({

    maxHeight: '400px',
    overflowY: 'auto',
    borderRadius: '0 0 1rem 1rem',

    '.raffle-row:nth-of-type(even)': {
        backgroundColor: 'var(--primary-bg)'
        
    },
    '.raffle-row:nth-of-type(odd)': {
        backgroundColor: 'var(--primary-bg-light)'
    }
}))

const CSGOGiveawayContainer = styled(Box)(({ theme }) => ({

    maxHeight: '400px',
    overflowY: 'auto',
    borderRadius: '0 0 1rem 1rem',

    '.csgo-ga-row:nth-of-type(even)': {
        backgroundColor: 'var(--primary-bg)',
        
    },
    '.csgo-ga-row:nth-of-type(odd)': {
        backgroundColor: 'var(--primary-bg-light)',
    }
}))

const StyledAccordion = styled(Accordion)(({ theme }) => ({

    boxShadow: 'none',
    color: theme.palette.text.secondary,
    backgroundColor: 'transparent',
    width: '100%',
    maxWidth: '1200px',

    svg: {
        fill: theme.palette.text.secondary,
        height: '24px',
        width: '24px',
    },

    '.MuiAccordionSummary-root.Mui-expanded': {
        borderRadius: '1rem 1rem 0 0'
    },

    '.MuiAccordionSummary-root': {
        borderRadius: '1rem',
        boxShadow: '0 0 6px rgba(16, 16, 24, .1)',
        padding: '.5rem 1rem',
        backgroundColor: 'var(--primary-bg-soft)',
    },

    '.MuiAccordion-region .MuiAccordionDetails-root': {
        borderRadius: '0 0 1rem 1rem',
        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
    },

    '.MuiAccordionDetails-root': {
        backgroundColor: 'var(--primary-bg-dark)',
        padding: '0'
    }

}))

const ConnectButton = styled(Button)(({ theme }) => ({

    backgroundColor: 'var(--primary-bg)',
    padding: '.75rem 1rem',
    borderRadius: '.5rem',
    width: '170px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    border: '2px solid transparent',
    borderColor: 'var(--primary-border)',
    textTransform: 'capitalize',
    color: 'var(--text-primary)',
    transition: 'background-color .3s ease-in-out, color .3s ease-in-out',

    '&:hover': {
        backgroundColor: 'var(--primary-bg-soft)',
        color: 'var(--text-primary)'
    },

    'svg': {
        width: '24px',
        height: '24px',
        marginRight: '.5rem'
    },
    

}))

const UnlinkButton = styled(Button)(({ theme }) => ({

    color: 'var(--text-secondary)',

    transition: 'background-color .3s ease-in-out, color .3s ease-in-out',

    '&:hover': {
        backgroundColor: 'var(--primary-bg-soft)',
        color: 'var(--primary-main)'
    },


    'svg': {
        width: '24px',
        height: '24px',
        marginRight: '.5rem'
    },
}))

const DisabledInput = styled(OutlinedInput)(({ theme }) => ({
    'input': {
        WebkitTextFillColor: 'var(--text-secondary) !important',

    }
}))

const ProfilePage = () => {

    let dispatch = useDispatch()

    const isSmallDevice = useMediaQuery({ query: '(max-width: 925px)' })

    const profile = useSelector(selectProfile)

    const [userRaffles, setUserRaffles] = useState<any[]>([])
    const [userCSGOGiveaways, setUserCSGOGiveaways] = useState<CSGOGiveaway[]>([])

    const [unlinkDiscordDialogOpen, setUnlinkDiscordDialogOpen] = useState(false)

    const [unlinkKickModalOpen, setUnlinkKickModalOpen] = useState(false)
    const [connectKickModalOpen, setConnectKickModalOpen] = useState(false)

    const [gamdomHelpModalOpen, setGamdomHelpModalOpen] = useState(false)

    useEffect(() => {

        fetch(`${API_URL}/user/raffles`, {
            method: 'GET',
            credentials: 'include',
        })
            .then((response) => {
                if (!response.ok) return false
                else return response.json()
            }).then((data) => {
                if (data && !data.error) setUserRaffles(data)
            }).catch((err) => {

            })

            fetch(`${API_URL}/user/csgogiveaways`, {
                method: 'GET',
                credentials: 'include',
            })
                .then((response) => {
                    if (!response.ok) return false
                    else return response.json()
                }).then((data) => {
                    if (data && !data.error) setUserCSGOGiveaways(data)
                }).catch((err) => {
    
                })
    }, [])
    

    const unlinkDiscord = () => {
        fetch(`${API_URL}/user/discord/unlink`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
        }).then(res => {
            return res.json()
        }).then(data => {
            if (data && !data.error) {
                dispatch(setProfile({...profile, discord_id: undefined}))
                unlinkDiscordAccountToast()
            } else {
                createToast('Could not unlink your Discord account', 'error')
            }
        })
    }

    return (
      <div className="page">
        {profile ? (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <img
                src={profile.avatar}
                alt=""
                style={{
                  height: "64px",
                  width: "64px",
                  borderRadius: "50%",
                  border: "3px solid var(--primary-main)",
                }}
              />
              <ProfileNameText>{profile.display_name}</ProfileNameText>
              <JoinedDateText>
                Joined {formatDate(profile.first_joined)}
              </JoinedDateText>
            </Box>

            <ProfileContainer display="flex" marginTop="2rem">
              <Box
                display="flex"
                flexDirection="column"
                sx={{
                  width: "100%",
                }}
              >
                <Box display="flex" alignItems="center" marginBottom="2rem">
                  <SettingsIcon
                    sx={{ height: "32px", width: "32px", marginRight: ".5rem" }}
                  />
                  <Typography variant="h5">Settings</Typography>
                </Box>
                <Box display="flex" alignItems="center" flexWrap="wrap" gap="1rem" marginBottom="2rem">
                  <Link
                    to="/claimpoints"
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      startIcon={<StarIcon />}
                    >
                      Claim Points
                    </Button>
                  </Link>
                  <Link
                    to="/promocode"
                  >
                    <Button
                      variant="contained"
                      color="success"
                      startIcon={<StarIcon />}
                    >
                      Claim Promo
                    </Button>
                  </Link>
                </Box>

                <ProfileSettings />
              </Box>
            </ProfileContainer>
            <ProfileContainer
              display="flex"
              flexDirection="column"
              marginTop="2rem"
            >
              <Box display="flex" alignItems="center" marginBottom="1.5rem">
                <LinkIcon
                  sx={{ height: "32px", width: "32px", marginRight: ".5rem" }}
                />
                <Typography variant="h6">Connect Accounts</Typography>
              </Box>

              <Box
                display="flex"
                flexDirection={isSmallDevice ? "column" : "row"}
                columnGap={isSmallDevice ? "0" : "2rem"}
                rowGap={isSmallDevice ? "2rem" : "0"}
                alignItems="flex-start"
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  sx={isSmallDevice ? { marginBottom: "3rem" } : {}}
                >
                  <ConnectButton
                    variant="contained"
                    disableRipple
                    startIcon={<Discord />}
                    href={`${API_URL}/auth/discord`}
                    disabled={profile.discord_id ? true : false}
                    endIcon={
                      profile.discord_id ? (
                        <CheckIcon sx={{ color: "text.secondary" }} />
                      ) : null
                    }
                  >
                    Discord
                  </ConnectButton>

                  <Typography
                    sx={{
                      marginTop: ".5rem",
                      fontSize: "14px",
                      color: "text.secondary",
                    }}
                  >
                    {profile.discord_id || "Not Connected"}
                  </Typography>
                  {profile.discord_id && (
                    <UnlinkButton
                      disableRipple
                      variant="text"
                      sx={{ marginTop: "1rem", width: "168px" }}
                      onClick={() => setUnlinkDiscordDialogOpen(true)}
                    >
                      Unlink
                    </UnlinkButton>
                  )}
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  sx={isSmallDevice ? { marginBottom: "3rem" } : {}}
                >
                  <ConnectButton
                    variant="contained"
                    disableRipple
                    startIcon={<Kick />}
                    onClick={() => setConnectKickModalOpen(true)}
                    disabled={profile.kick_id ? true : false}
                    endIcon={
                      profile.kick_id ? (
                        <CheckIcon sx={{ color: "text.secondary" }} />
                      ) : null
                    }
                  >
                    Kick
                  </ConnectButton>

                  <Typography
                    sx={{
                      marginTop: ".5rem",
                      fontSize: "14px",
                      color: "text.secondary",
                    }}
                  >
                    {profile.kick_id
                      ? `${profile.kick_username}`
                      : "Not Connected"}
                  </Typography>
                </Box>
              </Box>
            </ProfileContainer>

            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              marginTop="2rem"
              width="100%"
              rowGap="1.5rem"
            >
              <StyledAccordion id="inventory">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="csgo-accord-content"
                  id="csgo-accord-header"
                >
                  <Box display="flex" alignItems="center">
                    <Inventory style={{ marginRight: ".5rem" }} />
                    <Typography>Inventory</Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <InventoryTab />
                </AccordionDetails>
              </StyledAccordion>
              <StyledAccordion id="casino500_giveaways">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="stake-accord-content"
                  id="stake-accord-header"
                >
                  <Box display="flex" alignItems="center">
                    <MoneyIcon style={{ marginRight: ".5rem" }} />
                    <Typography>Casino Giveaways</Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <GiveawayTab />
                </AccordionDetails>
              </StyledAccordion>
              <StyledAccordion id="csgo_giveaways">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="stake-accord-content"
                  id="stake-accord-header"
                >
                  <Box display="flex" alignItems="center">
                    <CSGOIcon style={{ marginRight: ".5rem" }} />
                    <Typography>CSGO Giveaways</Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  {userCSGOGiveaways && userCSGOGiveaways.length > 0 ? (
                    <CSGOGiveawayContainer
                      display="flex"
                      flexDirection="column"
                    >
                      {userCSGOGiveaways.map((giveaway, index) => (
                        <CSGOGiveawayRow
                          key={`stake-ga-row-${index}`}
                          date={giveaway.date}
                          status={giveaway.status}
                          _id={giveaway._id}
                          item={giveaway.item}
                          csgwid={giveaway.csgwid}
                          tradeofferid={giveaway.tradeofferid}
                        />
                      ))}
                    </CSGOGiveawayContainer>
                  ) : (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      sx={{
                        padding: "1rem",
                        fontSize: "18px",
                        fontWeight: "bold",
                      }}
                    >
                      <Typography>
                        You have not won any Giveaways yet
                      </Typography>
                    </Box>
                  )}
                </AccordionDetails>
              </StyledAccordion>
              <StyledAccordion id="raffles">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="csgo-accord-content"
                  id="csgo-accord-header"
                >
                  <Box display="flex" alignItems="center">
                    <RaffleIcon style={{ marginRight: ".5rem" }} />
                    <Typography>Raffles</Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  {userRaffles && userRaffles.length > 0 ? (
                    <RaffleContainer display="flex" flexDirection="column">
                      {userRaffles.map((raffle, index) => (
                        <RaffleRow
                          key={`raffle-row-${index}`}
                          date={raffle.roll_date}
                          status={raffle.status}
                          id={raffle._id}
                          amount={raffle.prize}
                        />
                      ))}
                    </RaffleContainer>
                  ) : (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      sx={{
                        padding: "1rem",
                        fontSize: "18px",
                        fontWeight: "bold",
                      }}
                    >
                      <Typography>You have not won any raffles yet</Typography>
                    </Box>
                  )}
                </AccordionDetails>
              </StyledAccordion>
            </Box>
          </Box>
        ) : null}
        <ConnectKickModal
          open={connectKickModalOpen}
          handleClose={() => setConnectKickModalOpen(false)}
        />
        <UnlinkKickModal
          open={unlinkKickModalOpen}
          handleClose={() => setUnlinkKickModalOpen(false)}
        />
        <UnlinkDiscordDialog
          open={unlinkDiscordDialogOpen}
          handleClose={() => setUnlinkDiscordDialogOpen(false)}
          handleAction={unlinkDiscord}
        />
        <GamdomHelp
          open={gamdomHelpModalOpen}
          handleClose={() => setGamdomHelpModalOpen(false)}
        />
      </div>
    );
}

export default ProfilePage
