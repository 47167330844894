import { BottomNavigation, BottomNavigationAction, Box, styled } from '@mui/material';
import React from 'react'
import HomeIcon from '@mui/icons-material/Home';
import { ReactComponent as GiftIcon } from '../../icons/gift.svg'
import { ReactComponent as VSIcon } from '../../icons/assets/vs.svg'
import { Link, useLocation } from 'react-router-dom';
import { Route } from './Nav';
import { useSelector } from 'react-redux';
import { selectProfile } from '../../redux/profile/profileSlice';
import AccountCircleSharpIcon from '@mui/icons-material/AccountCircleSharp';

const NavButton = styled(BottomNavigationAction)(( { theme }) => ({
    width: '100%',
    padding: '0',

    'svg': {
        fill: 'var(--text-primary)',
        height: '24px',
        width: '24px'
    },

    'span': {
        opacity: 1
    },

    '&.active': {
        color: 'var(--primary-main)',
        'svg': {
            fill: 'var(--primary-main)'
        }
    }
}))

const BottomNavbar = () => {

    const location = useLocation()
    const profile = useSelector(selectProfile)
    const [value, setValue] = React.useState(0)

    function isActive(path: Route) {
        return location.pathname.startsWith(path)
    }

  return (
    <Box position="fixed" bottom="0" zIndex={20} sx={{ width: "100%", display: { xs: 'flex', md: 'none' } }}>
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        sx={{ width: '100%', height: '64px', backgroundColor: 'var(--primary-bg)', alignItems: 'center', borderTop: '1px solid var(--primary-border)' }}
      >
        
        <Link to="/" style={{ width: '100%' }}>
            <NavButton className={location.pathname === "/" ? "active" : ""} disableRipple label="Home" icon={<HomeIcon />}/>
        </Link>
        <Link to={Route.SLOT_BATTLES} style={{ width: '100%' }}>
            <NavButton className={isActive(Route.SLOT_BATTLES) ? "active" : ""} disableRipple label="Slot Battles" icon={<VSIcon />}/>
        </Link>
        <Link to={Route.GIVEAWAYS} style={{ width: '100%' }}>
            <NavButton className={isActive(Route.GIVEAWAYS) ? "active" : ""} disableRipple label="Giveaways" icon={<GiftIcon />}/>
        </Link>
        {profile && (
            <Link to={Route.PROFILE} style={{ width: '100%' }}>
                <NavButton className={isActive(Route.PROFILE) ? "active" : ""} disableRipple label="Profile" icon={<AccountCircleSharpIcon />}/>
            </Link> 
        )}

      </BottomNavigation>
    </Box>
  )
}

export default BottomNavbar