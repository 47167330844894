import React, { useEffect } from 'react'
import { useSelector } from "react-redux";
import { selectProfile } from "../../redux/profile/profileSlice";
import { RaffleBet } from "./types";
import { useState } from "react";
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { ReactComponent as RaffleTicketIcon }  from '../../icons/ticket.svg'
import { Skeleton, Tab, Tabs, Tooltip } from '@mui/material';
import { ReactComponent as Coin } from '../../icons/assets/coin.svg'
import { Star } from '@mui/icons-material';
import useSWR from 'swr';
import { fetcher } from '../../utils';
import { socket } from '../../socket';
import TicketIcon from '@mui/icons-material/ConfirmationNumberOutlined';

interface RaffleTabProps {
    children?: React.ReactNode;
    index: number;
    value: number;
    raffleId: string
    page: number
    setPage: React.Dispatch<React.SetStateAction<number>>
    isLoading: boolean,
    tab: "all" | "user",
    data?: {
        data: RaffleBet[]
        count: number
        countPerPage: number
    }
}

const RaffleBetSkeleton = () => {
    return (
        <Box display="flex" flexDirection="column" position="relative" alignItems="center" gap="1rem" 
            sx={{ 
                backgroundColor: 'var(--primary-bg-soft)', 
                padding: '1rem', 
                border: '1px solid var(--primary-border)', 
                borderRadius: '8px' 
            }}
        >
            <Skeleton variant="circular" width={64} height={64} />
            <Box display="flex" flexDirection="column" alignItems="center" width="100%" gap=".5rem">
                <Skeleton variant="text" width="100%" />
                <Box display="flex" alignItems="center" gap=".5rem">
                    <Skeleton variant="circular" width={12} height={12} />
                    <Skeleton variant="text" width={50} />
                </Box>
                <Skeleton variant="text" width="100%" />
            </Box>
        </Box>
    )
}

const RaffleBetCard = ({ bet }: { bet: RaffleBet }) => {
    return (
        <Box display="flex" flexDirection="column" position="relative" alignItems="center" gap="1rem" sx={{ backgroundColor: 'var(--primary-bg-soft)', padding: '1rem', border: '1px solid var(--primary-border)', borderRadius: '8px' }}>
            {bet.isWinner && (
                <Star sx={{ position: 'absolute', top: '8px', right: '8px', color: 'gold'}} />
            )}
            {bet.isTicket && (
                <Tooltip title="Ticket Entry">
                    <TicketIcon sx={{ position: 'absolute', top: '8px', left: '8px' }}  />
                </Tooltip>
                
            )}
            <Box component="img" src={bet.user.avatar} alt={bet.user.display_name} sx={{ border: '1px solid var(--primary-border)', height: '64px', width: '64px', borderRadius: '100%' }} />
            <Box display="flex" flexDirection="column" alignItems="center" width="100%" gap=".5rem">
                <Typography fontWeight="bold" sx={{
                    textOverflow: 'ellipsis',
                    width: '100%',
                    textAlign: 'center',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap'
                }}>
                    {bet.user.display_name}
                </Typography>
                <Box display="flex" alignItems="center" gap=".5rem">
                    <Coin style={{ height: '12px', width: '12px' }} />
                    <Typography component="b" sx={{ color: 'var(--text-primary)', fontWeight: '600' }}>{Intl.NumberFormat("en-US").format(bet.bet)}</Typography>
                </Box>
                <Typography sx={{ color: 'var(--text-disabled)', fontSize: '14px', letterSpacing: '-.5px' }}>{Intl.NumberFormat("en-US").format(bet.minTicket)} - {Intl.NumberFormat("en-US").format(bet.maxTicket)}</Typography>
            </Box>

        </Box>
    )
}


const AllBetsTab = (props: RaffleTabProps) => {

    const { children, value, index, raffleId, isLoading, page, setPage, data, tab, ...other } = props

    return (
        <div
        role="tabpanel"
        hidden={value !== index}
        id={`slot-battle-tabpanel-${index}`}
        aria-labelledby={`slot-battle-label-${index}`}
        {...other}
      >
          {value === index && (
            <Box display="flex" flexDirection="column" gap="1rem" py="1rem">
                {isLoading ? (
                    <Box display="grid" gap="1rem" width="100%" sx={{ gridTemplateColumns: { xs: 'repeat(2, minmax(0, 1fr))', sm: 'repeat(4, minmax(0, 1fr))', md: 'repeat(6, minmax(0, 1fr))', lg: 'repeat(7, minmax(0, 1fr))', xl: 'repeat(10, minmax(0, 1fr))' }}}>
                        {new Array(20).fill(0).map((_, index) => (
                            <RaffleBetSkeleton key={`raffle-bet-skeleton-${index}`} />
                        ))}
                    </Box>
                ) : !data ? (
                    <Typography>Something went wrong, please refresh the page</Typography>
                ) : data.data.length === 0 ? (
                    <Box display="flex" gap="1rem" flexDirection="column" alignItems="center" justifyContent="center">
                        <RaffleTicketIcon style={{ height: '56px', width: '56px', fill: 'var(--text-disabled)' }} />
                        <Typography sx={{ fontSize: '24px', fontWeight: 'bold', color: 'var(--text-secondary)' }}>{tab === "all" ? "Be the first one to bet" : "You do not have any bets yet"}</Typography>
                    </Box>
                ) : (
                    <Box display="flex" flexDirection="column" gap="1rem"> 
                        <Box display="grid" gap="1rem" width="100%" sx={{ gridTemplateColumns: { xs: 'repeat(2, minmax(0, 1fr))', sm: 'repeat(4, minmax(0, 1fr))', md: 'repeat(6, minmax(0, 1fr))', lg: 'repeat(7, minmax(0, 1fr))', xl: 'repeat(10, minmax(0, 1fr))' }}}>
                            {data.data.map((bet) => (
                                <RaffleBetCard key={`raffle-bet-${bet._id}`} bet={bet} />
                            ))}
                        </Box>
                        <Box display="flex" alignItems="center" justifyContent="flex-end" gap="1rem" marginTop="1rem">
                            <Typography sx={{ color: 'var(--text-secondary)' }}>
                                Showing 
                                {" "}
                                <Typography component="span" sx={{ color: 'var(--text-primary)', fontWeight: 'bold' }}>{(page - 1) * data.countPerPage + data.data.length}</Typography>
                                {" "}
                                of
                                {" "}
                                <Typography component="span" sx={{ color: 'var(--text-primary)', fontWeight: 'bold' }}>{data.count}</Typography> 
                            </Typography>
                            <Button disabled={page === 1} onClick={() => setPage(prevState => Math.max(0, --prevState))} variant="contained" color="soft" sx={{ border: '1px solid var(--primary-border)' }}>
                                Previous
                            </Button>   
                            <Button onClick={() => setPage(prevState => ++prevState)} disabled={data.count <= ((page - 1) * data.countPerPage) + data.data.length} variant="contained" color="soft" sx={{ border: '1px solid var(--primary-border)' }}>
                                Next
                            </Button>   
                        </Box>
                    </Box>
    
                )}
            </Box>
        )}
      </div>
    );
}

export enum RaffleEvent {
    BET_CREATED = "raffleBetCreated",
    RAFFLE_ROLLED = "raffleRolled",
    RAFFLE_FINISHED = "raffleFinished",
    WAITING_FOR_EOS_BLOCK = "waitingForEosBlock"
}


const RaffleBets = ({ id, updateTotalBet }: { id: string, updateTotalBet: (bet: number) => void }) => {

    const profile = useSelector(selectProfile)

    const [value, setValue] = useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
    }

    const [page, setPage] = useState(1)
    const { data, isLoading, mutate } = useSWR<{ count: number, countPerPage: number, data: RaffleBet[]}>(value === 0 ? `/raffle/${id}/bets?page=${page}` : `/raffle/${id}/user-bets?page=1`, fetcher)

    useEffect(() => {
        setPage(1)
    }, [value])

    useEffect(() => {

        function raffleEvents(event:  RaffleEvent, raffleId: string, data: any) {
            if(raffleId === id) {
                switch(event) {
                    case RaffleEvent.BET_CREATED:
                        mutate((prev) => {
                            if(!prev) return prev
                            if(prev.data.length >= prev.countPerPage) {
                                return { ...prev, count: prev.count + 1 }
                            } else {
                                return { ...prev, count: prev.count + 1, data: [...prev.data, data] }
                            }
                        }, {
                            revalidate: false
                        })
                        updateTotalBet(data.bet)
                        break

                }
            }
        }

        socket.on('raffle', raffleEvents)
    
        return function cleanup() {
          socket.off('raffle', raffleEvents) 
        }
      }, [])

    return (
        <Box className="page" display="flex" flexDirection="column">
            <Typography variant="h4" textAlign="center" marginBottom="2rem">Raffle Bets</Typography>
            <Tabs 
                value={value} 
                onChange={handleChange} 
                aria-label="slot battle tabs"
                sx={{ py: '1rem', mb: '1rem', borderBottom: '1px solid var(--primary-border)', '.MuiTabs-flexContainer': { gap: '1rem' } }}
                TabIndicatorProps={{
                    style: { display: "none" }
                }}
            >
                <Tab disableRipple label="All" id="raffle-all-bets" 

                    sx={{ 
                        backgroundColor: 'var(--primary-bg)',
                        borderRadius: '.5rem',
                        border: '1px solid var(--primary-border)',
                        transition: 'background-color .2s ease-in-out',
                        color: 'var(--text-primary) !important',
                        '&.Mui-selected': {
                            backgroundColor: 'var(--primary-bg-light)'
                        },
                        '&:hover': {
                            backgroundColor: 'var(--primary-bg-soft)'
                        }
                    }} 
                />
                {profile && (
                    <Tab disableRipple label="Your Bets" id="raffle-user-bets"
                        sx={{ 
                            backgroundColor: 'var(--primary-bg)',
                            borderRadius: '.5rem',
                            border: '1px solid var(--primary-border)',
                            transition: 'background-color .2s ease-in-out',
                            color: 'var(--text-primary) !important',
                            '&.Mui-selected': {
                                backgroundColor: 'var(--primary-bg-light)'
                            },
                            '&:hover': {
                                backgroundColor: 'var(--primary-bg-soft)'
                            }
                        }} 
                    />    
                )}
            </Tabs>
            <AllBetsTab value={value} index={0} raffleId={id} page={page} setPage={setPage} isLoading={isLoading} data={data} tab="all" />
            <AllBetsTab value={value} index={1} raffleId={id} page={page} setPage={setPage} isLoading={isLoading} data={data} tab="user" />
            {profile && (
                <></>
            )}

        </Box>
    )
}

export default RaffleBets