import React, { useState, useEffect } from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Avatar from '@mui/material/Avatar'

import { styled } from '@mui/material'

const GiveawayContainer = styled(Box)(( { theme }) => ({
    backgroundColor: theme.palette.primary.background,
    borderRadius: 12,
    margin: '0 .5rem',
    minWidth: 285,
    boxShadow: '0 0 3px rgba(16, 16, 24, .75)',
    border: '1px solid rgba(0, 0, 0, .1)',
    position: 'relative',
}))

const ContainerFade = styled(Box)(( { theme }) => ({
    borderRadius: 'inherit',
    position: 'absolute',
    width: '100%',
    height: '100%'
}))

const FadeLine = styled('span')(( { theme }) => ({
    height: '2px',
    width: '100%',
    position: 'absolute',
    top: '0',
}))

const ContentContainer = styled(Box)(( { theme }) => ({
    zIndex: 1,
    
}))

const UserBox = styled(Box)(( { theme }) => ({
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.secondary,

    'img': {
        height: '20px',
        width: '20px',
        borderRadius: '25%',
        marginRight: '.4rem',
        boxShadow: '0 0 .2rem rgb(16, 24, 32)'
    }
}))

const ItemImage = styled(Avatar)(( { theme }) => ({
    height: 'auto',
    width: '85px',
    filter: 'drop-shadow(0 0 0.5rem rgba(0,0,0, .5))',
    zIndex: 1
}))

const exteriorAbbreviations = {
    'Battle-Scarred': 'BS',
    'Well-Worn': 'WW',
    'Field-Tested': 'FT',
    'Minimal Wear': 'MW',
    'Factory New': 'FN'
}

const CSGOGiveawayBox = ({ item, user, date}) => {

    const [countdownString, setCountdownString] = useState("");

    const getTimeAgo = (time) => {

        var days = Math.floor(time / (1000 * 60 * 60 * 24));
        var hours = Math.floor((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));

        let countString = ``

        if(days > 0) countString += `${days}d`
            else if(hours > 0) countString += `${hours}h`
            else if (minutes > 0) countString += `${minutes}m`
        
        setCountdownString(countString);
    }

    useEffect(() => {

        if(!date){
            return;
        }

        let now = new Date();
        let distance = now - new Date(date).getTime();
        getTimeAgo(distance);

        const interval = setInterval(() => {
            let now = new Date();
            let distance = now - new Date(date).getTime();
            getTimeAgo(distance);
        }, 1000 * 60)
        
        return () => {
            clearInterval(interval)
        }

    }, [date])

    return (
        <>
            {item && user && date ? (
                <GiveawayContainer
                    display="flex"
                    flexDirection="column"
                >
                    <ContainerFade
                        sx={{
                            background: `radial-gradient(at 50% 0%, #${item.tags.rarity_color}5A 10%, rgba(255,255,255,0) 90%)`
                        }}
                    />
                    <FadeLine
                        sx={{
                            background: `linear-gradient(90deg, rgba(0,0,0,0) 8%, #${item.tags.rarity_color}FF 50%, rgba(0,0,0,0) 92%)`,
                        }}
                    />
                    <ContentContainer
                        display="flex"
                        alignItems="center"
                        sx={{
                            padding: '16px 12px',
                        }}
                    >
                        <ItemImage
                            src={item.icon_url + '/96fx96f'}
                            alt="item-img"
                            variant="square"

                        />
                        <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            sx={{
                                marginLeft: 2
                            }}
                        >
                            <Typography sx={{ fontSize: '12px', color: 'text.secondary' }}>
                                {item.item_name} ({exteriorAbbreviations[item.tags.exterior]})
                            </Typography>
                            <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                {item.paint_name}
                            </Typography>
                        </Box>
                    </ContentContainer>
                    <Box
                        display="flex"
                        sx={{
                            marginTop: 'auto',
                            padding: '8px 16px'
                        }}
                    >
                        <UserBox>
                            <img src={user.avatar} alt="" />
                            <Typography variant="span" style={{
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    maxWidth: '150px',
                            }}>
                                {user.display_name}
                            </Typography>
                        </UserBox>

                        <Typography
                            variant="span"
                            sx={{
                                marginLeft: 'auto',
                                color: 'text.secondary'
                            }}
                        >
                            {countdownString ? countdownString + ' ago' : 'Just now'}
                        </Typography>
                    </Box>

                </GiveawayContainer>
            ) : null}

        </>
    )
}

export default CSGOGiveawayBox
