import { configureStore } from '@reduxjs/toolkit'
import profileReducer from './profile/profileSlice';
import contentCreatorsSlice from './contentCreators/contentCreatorsSlice';
import rewardsSlice from './rewards/rewardsSlice';
import kwGiveawaySlice from './admin/kwGiveawaySlice';
import raffleSlice from './raffle/raffleSlice';
import messageSlice from './message/messageSlice';
import rollbitGiveawaySlice from './giveaway/rollbitGiveawaySlice';
import csgoGiveawaySlice from './giveaway/csgoGiveawaySlice';
import vipGiveawaySlice from './giveaway/vipGiveawaySlice';
import storeSlice from './store/storeSlice';
import battleSlice from './battle/battleSlice';
import bountySlice from './bounty/bountySlice';
import battlesSlice from './battle/battlesSlice';
import slotbetsSlice from './slotbet/slotbetSlice';

export const store = configureStore({
    reducer: {
        profile: profileReducer,
        content_creators: contentCreatorsSlice,
        rewards: rewardsSlice,
        kw_giveaway: kwGiveawaySlice,
        raffles: raffleSlice,
        messages: messageSlice,
        rollbit_giveaway: rollbitGiveawaySlice,
        csgo_giveaway: csgoGiveawaySlice,
        vip_giveaway: vipGiveawaySlice,
        store: storeSlice,
        battle: battleSlice,
        battles: battlesSlice,
        bounties: bountySlice,
        slotbets: slotbetsSlice
    },
})