import React, { ReactNode, useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { apiFetch, fetcher } from '../../utils';
import { createSuccessToast } from '../../components/Toasts/SuccessToasts';
import { Button, IconButton, Tooltip } from '@mui/material';
import HypedropPointClaimModal from './HypedropPointClaimModal';
import { ReactComponent as CoinIcon } from '../../icons/assets/coin.svg'
import useSWR from 'swr';
import { ReactComponent as Hypedrop } from '../../icons/sites/hypedrop.svg'
import { createErrorToast } from '../../components/Toasts/ErrorToasts';
import { Warning } from '@mui/icons-material';

interface PointsInfo {
    availablePoints: number;
    claimedPoints: number;
    lastClaimAt: string;
}


const ClaimPointsContainer = ({ info, image, openInfoModal, claim, color, alert }: { alert?: string, color: string, info?: { availablePoints: number, claimedPoints: number, lastClaimAt: string }, image: ReactNode, openInfoModal: () => void, claim: () => void}) => {
    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap=".5rem"
            position="relative"
            width="240px"
            sx={{ 
                p: '1rem', 
                border: '1px solid var(--primary-border)', 
                borderRadius: '.5rem',
                backgroundColor: 'var(--primary-bg-soft)'
            }}
        >

            {alert && (
                <Tooltip title={alert} placement="top" arrow>
                    <IconButton disableRipple sx={{ position: 'absolute', top: '0', right: '0' }}>
                        <Warning />
                    </IconButton>
                </Tooltip>
            
            )}

            {image}

            
            <Box display="flex" flexDirection="column" alignItems="center" gap=".5rem" sx={{ padding: '.5rem', borderRadius: '.5rem', mt: '2rem', width: '100%', border: `2px solid ${color}` }}>
                <Box display="flex" alignItems="center" gap=".25rem">
                    <Typography sx={{ fontWeight: 'bold' }}>{Intl.NumberFormat("en-US").format(info?.availablePoints || 0)}</Typography>
                    <CoinIcon style={{ height: '16px', width: '16px' }} />
                </Box>
            </Box>

            <Button disableRipple onClick={openInfoModal} sx={{ fontSize: '14px', my: '.5rem', fontWeight: 'bold' }}>More Info</Button>


            <Box display="flex" alignItems="center" gap=".25rem" sx={{ fontSize: '14px', color: 'var(--text-secondary)' }}>
                    <Typography sx={{ color: 'var(--text-primary)', fontSize: '14px' }}>{Intl.NumberFormat("en-US").format(info?.claimedPoints || 0)}</Typography>
                    <CoinIcon style={{ height: '14px', width: '14px' }} />
                    claimed in total.
            </Box>
            <Button variant="contained" disabled={!info || info.availablePoints <= 0} fullWidth onClick={claim} sx={{ backgroundColor: color + 'c3', '&:hover': { backgroundColor: color + '91' } }}>
                Claim
            </Button>  

        </Box>
    )
}

const ClaimPointsPage = () => {


    const [hypedropPointClaimModalOpen, setHypedropPointClaimModalOpen] = useState(false)

    const hypedrop = useSWR<PointsInfo | undefined>(`/user/points/hypedrop`, fetcher, { refreshInterval: 0, revalidateOnFocus: false, revalidateIfStale: false })


    async function claimHypedropPoints() {

        if(localStorage.getItem('agreedToHypedropTerms') !== "true") {
            return setHypedropPointClaimModalOpen(true)
        } 

        try {
            const data = await apiFetch(`/user/points/hypedrop/claim`, "POST")

            if(data && data.message) {
                createSuccessToast(data.message)
                hypedrop.mutate((prev) => {
                    if(!prev || !prev) return prev
                    return { ...prev, hypedrop: { ...prev, availablePoints: 0, claimedPoints: (prev?.claimedPoints || 0) + (prev?.availablePoints || 0) }}
                })
            }


        } catch (e) {
            if(e instanceof Error) {
                createErrorToast(e.message || "Something went wrong")
            }
        }
    }


  return (
      <Box
          display="flex"
          flexDirection="column"
          className='page'
          maxWidth="1000px"
      >
          <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              sx={{
                  marginBottom: '4rem'
              }}
          >
              <Box display="flex" flexDirection="column" alignItems="center" rowGap=".5rem" sx={{ marginBottom: '3rem' }}>
                  <Typography variant="h3">
                      Claim Points
                  </Typography>
                  <Typography sx={{ color: 'var(--text-secondary)' }}>
                    Earn Points for wagering / depositing on the following sites.
                  </Typography>
              </Box>

              {hypedrop.isLoading ? (
                <></>
              ) : (
                <Box
                    display="flex"
                    gap="1rem"
                    width="100%"
                >
   
                    <ClaimPointsContainer image={<Box sx={{ maxHeight: '24px' }}><Hypedrop style={{ width: "auto", minHeight: "36px", maxHeight: "36px" }} /></Box>} color="#3d789d" info={hypedrop.data} claim={claimHypedropPoints}  openInfoModal={() => setHypedropPointClaimModalOpen(true)} />

                    
                </Box>
              )}


          </Box>
          <HypedropPointClaimModal isOpen={hypedropPointClaimModalOpen} handleClose={() => setHypedropPointClaimModalOpen(false)} />
      </Box>
  )
}

export default ClaimPointsPage