import React, { useEffect, useReducer, useRef, useState } from 'react'
import useSWR from 'swr'
import { BonusHunt, BonusHuntGuess, BonusHuntGuessState, BonusHuntGuessType, BonusHuntSlotGuess } from './types'
import { apiFetch, fetcher, formatDate } from '../../utils'
import { Link, useParams } from 'react-router-dom'
import { Box, Button, ButtonGroup, Collapse, FormControl, FormLabel, IconButton, LinearProgress, OutlinedInput, Skeleton, SxProps, Theme, Typography } from '@mui/material'
import { createErrorToast } from '../../components/Toasts/ErrorToasts'
import { createSuccessToast } from '../../components/Toasts/SuccessToasts'
import { ReactComponent as CoinIcon } from '../../icons/assets/coin.svg'
import { ReactComponent as Target } from '../../icons/assets/target.svg'
import { socket } from '../../socket'
import { LoadingButton } from '@mui/lab'
import { ArrowBack, Close, StackedLineChart } from '@mui/icons-material'
import BonusHuntGuessPayoutDialog from './BonusHuntGuessPayoutDialog'
import SlotGuessDialog from './SlotGuess'
import BonusesTable from './BonusesTable'
import { useSelector } from 'react-redux'
import { selectProfile } from '../../redux/profile/profileSlice'

const StatComponent = ({ label, value, sx }: { label: string, value: string | number | JSX.Element, sx?: SxProps<Theme> }) => (
  <Box display="flex" flexDirection="column" gap=".25rem" width="100%" 
    sx={{ 
        p: '1rem', 
        bgcolor: 'var(--primary-bg-soft)', 
        borderRadius: '.5rem', 
        border: '2px solid var(--primary-border)',
        boxShadow: '0 0 8px rgba(0,0,0,.1)',
        ...sx 
    }}
  >
    <Typography 
      sx={{ 
        fontWeight: 'bold',         
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden', 
      }}
    >
          {value}
    </Typography>
    <Typography sx={{ color: 'var(--text-primary)', opacity: '.5', fontSize: '.75em', fontWeight: 'bold', textTransform: 'uppercase' }}>{label}</Typography>

  </Box>
)


const GuessTheBalanceBetForm = ({ huntId, guessState }: { huntId: number, guessState: BonusHuntGuessState }) => {

    const [betAmount, setBetAmount] = useState(0)
    const [guessType, setGuessType] = useState<BonusHuntGuessType>(BonusHuntGuessType.TEN_PERCENT)
    const [guess, setGuess] = useState(0)
    const [isLoading, setIsLoading] = useState(false)

    const { data, mutate } = useSWR<BonusHuntGuess[]>(`/hunt/${huntId}/guess`, fetcher)

    async function placeBet() {

      setIsLoading(true)

      try {
        const data = await apiFetch('/hunt/guess', "POST", {
          body: JSON.stringify({
            bet: betAmount,
            guess,
            huntId,
            type: guessType
          })
        })

        if(data) {

          if(data._id)
            mutate((prev) => {
              if(!prev) return prev

              return [...prev, data]
            }, {
              revalidate: false
            }
          )

          createSuccessToast("Bet placed!")
        }
      } catch (e) { 
          createErrorToast((e as Error).message || "Something went wrong!")
      }

      setIsLoading(false)
    }

    function getGuess(guessType: BonusHuntGuessType) {
      return data?.find((guess) => guess.guessType === guessType)
    }

    function getGuessTypeText(guessType: BonusHuntGuessType) {
      switch(guessType) {
        case BonusHuntGuessType.TEN_PERCENT:
          return "Your guess needs to be within 10% of the hunt end balance."
        case BonusHuntGuessType.FIVE_PERCENT:
          return "Your guess needs to be within 5% of the hunt end balance."
        case BonusHuntGuessType.ONE_PERCENT:
          return "Your guess needs to be within 1% of the hunt end balance."
        case BonusHuntGuessType.EXACT:
          return "Your guess needs to be the same as the hunt end balance."
      }
    }

    return (
      <Box display="flex" flexDirection="column" sx={{ gap: '1rem' }}>

        <Box display="flex" flexDirection="column" gap=".25rem">            
          <ButtonGroup>
            <Button 
              onClick={() => setGuessType(BonusHuntGuessType.TEN_PERCENT)}
              sx={{ transition: 'background-color .2s', backgroundColor: guessType === BonusHuntGuessType.TEN_PERCENT ? "var(--primary-main)" : "", '&:hover': { backgroundColor: "var(--primary-dark)" }}}
            >
              <Box display="flex" flexDirection="column">
                <Typography>10%</Typography>
                <Typography component="span" sx={{ fontSize: '.6em', textTransform: 'uppercase' }}>{getGuess(BonusHuntGuessType.TEN_PERCENT) ? 
                <Typography component="span" sx={{ fontSize: '1em', textTransform: 'uppercase' }}>
                  {getGuess(BonusHuntGuessType.TEN_PERCENT)!.bet} <CoinIcon />
                </Typography> 
                  : "No Bet"}
                </Typography>
              </Box>
              
            </Button>
            <Button
              onClick={() => setGuessType(BonusHuntGuessType.FIVE_PERCENT)}
              sx={{ transition: 'background-color .2s', backgroundColor: guessType === BonusHuntGuessType.FIVE_PERCENT ? "var(--primary-main)" : "", '&:hover': { backgroundColor: "var(--primary-dark)" }}}
            >
              <Box display="flex" flexDirection="column">
                <Typography>5%</Typography>
                <Typography component="span" sx={{ fontSize: '.6em', textTransform: 'uppercase' }}>{getGuess(BonusHuntGuessType.FIVE_PERCENT) ? 
                <Typography component="span" sx={{ fontSize: '1em', textTransform: 'uppercase' }}>
                  {getGuess(BonusHuntGuessType.FIVE_PERCENT)!.bet} <CoinIcon />
                </Typography> 
                  : "No Bet"}
                </Typography>
              </Box>
            </Button>
            <Button
              onClick={() => setGuessType(BonusHuntGuessType.ONE_PERCENT)}
              sx={{ transition: 'background-color .2s', backgroundColor: guessType === BonusHuntGuessType.ONE_PERCENT ? "var(--primary-main)" : "", '&:hover': { backgroundColor: "var(--primary-dark)" }}}
            >
              <Box display="flex" flexDirection="column">
                <Typography>1%</Typography>
                <Typography component="span" sx={{ fontSize: '.6em', textTransform: 'uppercase' }}>{getGuess(BonusHuntGuessType.ONE_PERCENT) ? 
                <Typography component="span" sx={{ fontSize: '1em', textTransform: 'uppercase' }}>
                  {getGuess(BonusHuntGuessType.ONE_PERCENT)!.bet} <CoinIcon />
                </Typography> 
                  : "No Bet"}
                </Typography>
              </Box>
            </Button>
            <Button
              onClick={() => setGuessType(BonusHuntGuessType.EXACT)}
              sx={{ transition: 'background-color .2s', backgroundColor: guessType === BonusHuntGuessType.EXACT ? "var(--primary-main)" : "", '&:hover': { backgroundColor: "var(--primary-dark)" }}}
            >
              <Box display="flex" flexDirection="column">
                <Typography>EXACT</Typography>
                <Typography component="span" sx={{ fontSize: '.6em', textTransform: 'uppercase' }}>{getGuess(BonusHuntGuessType.EXACT) ? 
                <Typography component="span" sx={{ fontSize: '1em', textTransform: 'uppercase' }}>
                  {getGuess(BonusHuntGuessType.EXACT)!.bet} <CoinIcon />
                </Typography> 
                  : "No Bet"}
                </Typography>
              </Box>
            </Button>
          </ButtonGroup>
          <Typography sx={{ fontSize: '.8em', color: 'var(--text-secondary)' }}>{getGuessTypeText(guessType)}</Typography>
        </Box>

        {getGuess(guessType) ? (
            <Box display="flex" flexDirection="column" sx={{ mt: '1rem', mb: '1rem', gap: '.25rem' }}>
                <Box display="grid"                   
                  sx={{ 
                    gap: '2rem',
                    width: '100%',
                    gridTemplateColumns: { 
                      xs: `repeat(2, minmax(0, 1fr))`, 
                      sm: `repeat(3, minmax(0, 1fr))`,
                    }
                  }}>
                  <StatComponent label="Bet" value={<Typography><CoinIcon /> {Intl.NumberFormat("en-US").format(getGuess(guessType)!.bet)}</Typography>} />
                  <StatComponent label="My Guess" value={<Typography>$ {Intl.NumberFormat("en-US").format(getGuess(guessType)!.guess)}</Typography>} />
                  <StatComponent label="Payout" value={<Typography><CoinIcon /> {Intl.NumberFormat("en-US").format(getGuess(guessType)!.payout || 0)}</Typography>} />
                </Box>

            </Box>
        ) : !getGuess(guessType) && data?.length === 1 && (
          <Box display="flex" flexDirection="column" sx={{ mt: '1rem', mb: '1rem', gap: '.25rem' }}>
          <Box display="grid"                   
            sx={{ 
              gap: '2rem',
              width: '100%',
              gridTemplateColumns: { 
                xs: `repeat(2, minmax(0, 1fr))`, 
                sm: `repeat(3, minmax(0, 1fr))`,
              }
            }}>
            <StatComponent label="Bet" value={<Typography><CoinIcon /> {Intl.NumberFormat("en-US").format(data[0].bet)}</Typography>} />
            <StatComponent label="My Guess" value={<Typography>$ {Intl.NumberFormat("en-US").format(data[0].guess)}</Typography>} />
            <StatComponent label="Payout" value={<Typography><CoinIcon /> {Intl.NumberFormat("en-US").format(data[0].payout || 0)}</Typography>} />
          </Box>

      </Box>
        )} 
            
        {!getGuess(guessType) && guessState === BonusHuntGuessState.OPEN ? (
          <Box display="flex" flexDirection="column" gap="1rem" sx={{ width: '100%', mb: '1rem', mt: '1rem' }}>
            <Box display="grid" gap="1rem" sx={{ gridTemplateColumns: { xs: 'repeat(1, minmax(0, 1fr))', md: 'repeat(2, minmax(0, 1fr))' }, width: '100%' }}>
              <FormControl sx={{ width: '100%' }}>
                <FormLabel sx={{ mb: '4px',color: 'var(--text-primary)', textTransform: 'capitalize', fontSize: '.8em' }}>Point Bet</FormLabel>
                <OutlinedInput 
                  value={betAmount}
                  onChange={(e) => setBetAmount(parseInt(e.target.value) || 0)}
                  startAdornment={<CoinIcon style={{ height: 'auto', width: '16px', marginRight: '1rem'}} />}
                  sx={{ height: '48px', width: '100%' }}
                  placeholder='Your Points...'
                />     
              </FormControl>
              <FormControl sx={{ width: '100%' }}>
                <FormLabel sx={{ mb: '4px', color: 'var(--text-primary)', textTransform: 'capitalize', fontSize: '.8em' }}>Bonus Hunt Guess</FormLabel>
                <OutlinedInput 
                  value={guess}
                  onChange={(e) => setGuess(parseInt(e.target.value) || 0)}
                  startAdornment={<Typography sx={{ width: '16px', marginRight: '1rem'}}>$</Typography>}
                  sx={{ height: '48px' }}
                  placeholder="End Balance..."
                />       
              </FormControl>

            </Box>
          <LoadingButton disabled={betAmount === 0 || guess === 0} loading={isLoading} variant="contained" sx={{ alignSelf: 'flex-start'}} type="submit" onClick={placeBet}>
              Place Guess
            </LoadingButton>
          </Box>
        ) : (!getGuess(guessType) && data?.length === 0) && (guessState === BonusHuntGuessState.COMPLETED || guessState === BonusHuntGuessState.CLOSED)  ? (
            <Typography sx={{ fontStyle: 'italic', mb: '2rem', mt: '1rem' }}>Guess the balance is closed for this bonus hunt.</Typography>
        ) : guessState === BonusHuntGuessState.NOT_STARTED && (
          <Typography sx={{ fontWeight: 'bold',  mb: '2rem', mt: '1rem' }}>Guess the Balance has not started yet.</Typography>   
        )}
    
      </Box>

    )
}


export const BonusHuntStats = ({ data, isLoading }: { data?: BonusHunt, isLoading: boolean }) => {
  return (
    <>
      {isLoading ? (
        <Box display="grid" gap="2rem" 
          sx={{ 
            gridTemplateColumns: { 
              xs: `repeat(2, minmax(0, 1fr))`, 
              sm: `repeat(3, minmax(0, 1fr))`,
              md: `repeat(4, minmax(0, 1fr))`,
              lg: `repeat(5, minmax(0, 1fr))`
            }
          }}
        >
          <StatComponent label="Streamer" value={<Skeleton width="96px" height="24px" />} />
          <StatComponent label="Start" value={<Skeleton width="48px" height="24px" />} />
          <StatComponent label="Date" value={<Skeleton width="96px" height="24px" />} />
          <StatComponent label="Total Bonuses" value={<Skeleton width="32px" height="24px" />} />
          <StatComponent label="Opened Bonuses" value={<Skeleton width="32px" height="24px" />} />
          <StatComponent label="Req. Avg. X" value={<Skeleton width="32px" height="24px" />} />
          <StatComponent label="Run. Avg. X" value={<Skeleton width="32px" height="24px" />} />
          <StatComponent label="Highest Win" value={<Skeleton width="96px" height="24px" />} />
          <StatComponent label="Highest Multi" value={<Skeleton width="96px" height="24px" />} />
          <StatComponent label="Profit" value={<Skeleton width="96px" height="24px" />} />
        </Box>   
        )  : !data ? (
          <Typography sx={{ fontStyle: 'italic'}}>Bonus Hunt not found</Typography>
        ) : (
          <Box display="grid" gap="2rem" 
            sx={{ 
              gridTemplateColumns: { 
                xs: `repeat(2, minmax(0, 1fr))`, 
                sm: `repeat(3, minmax(0, 1fr))`,
                md: `repeat(4, minmax(0, 1fr))`,
                lg: `repeat(5, minmax(0, 1fr))`
              }
            }}
          >
            {data.streamer && (
              <StatComponent label="Streamer" value={
                
                <Box display="flex" alignItems="center">
                  <Box component="img" src={data.streamer.image} sx={{ width: '20px', height: '20px', borderRadius: '50%', mr: '.5rem' }} />

                  <Typography 
                    component="a"
                    href={data.streamer.kickUrl}
                    sx={{ 
                      fontWeight: 'bold',         
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',  
                      color: 'var(--text-primary)',
                      textDecoration: 'underline',
                      '&:hover': {
                        color: 'var(--primary-main)'
                      }
                    }}>
                    {data.streamer.display_name}
                  </Typography>
                </Box>
              }/>
            )}

          <StatComponent label="Start" value={data?.startCost ? `$${Intl.NumberFormat("en-US", { maximumFractionDigits: 0 }).format(data.startCost)}` : "N/A"} />
          <StatComponent label="Date" sx={{ gridColumn: { xs: "1 / span 2", sm: "unset" }  }} value={<Typography sx={{ fontWeight: 'bold' }}>{formatDate(data.createdAt, "time")}</Typography>} />
          <StatComponent label="Total Bonuses" value={data?.totalBonuses || "N/A"} />
          <StatComponent label="Opened Bonuses" value={data?.openedBonuses || 0} />
          <StatComponent label="Req. Avg. x" value={data?.requiredAverage ? `${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(data.requiredAverage)} x` : "N/A"} />
          <StatComponent label="Run. Avg. X" value={data?.runningAverage ? `${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(data.runningAverage)} x` : "N/A"} />
          <StatComponent label="Highest Win" value={data?.highestPayout ? `$ ${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(data.highestPayout)}` : "N/A"} />
          <StatComponent label="Highest Multi" value={data?.highestMulti ? `${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(data.highestMulti)} x` : "N/A"} />
          <StatComponent label="Profit" sx={{ gridColumn: { xs: "1 / span 2", sm: "unset" }, borderColor: data.profit > 0 ? "var(--success)" : "var(--error)" }} value={data?.profit ? `$ ${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(data.profit)}` : "N/A"} />
        </Box>
      )}
    </>
  )
}

export const SlotGuessing = ({ setGuessingMode, hasGuess }: { hasGuess: boolean, setGuessingMode: () => void }) => {
  return (
    <Box display="flex" justifyContent="space-between" sx={{ borderRadius: '.5rem', padding: '1rem', backgroundColor: 'var(--primary-bg-dark)', border: '1px solid var(--primary-border)' }}>
      <Box display="flex" flexDirection="column">
        <Typography sx={{ fontWeight: 'bold' }}>Guess the highest multiplier win!</Typography>
        <Typography sx={{ fontSize: '.8em', color: 'var(--text-secondary)' }}>Place a bet on which slot will think have the highest multiplier!</Typography>
      </Box>
      
      <Button disabled={hasGuess} variant="contained" onClick={setGuessingMode}>
        Choose Slot
      </Button>
    </Box>
  )
}


enum BonusHuntEvent {
  GUESSING_OPENED = "guessingOpened",
  GUESSING_CLOSED = "guessingClosed"
}


const BonusHuntPage = () => {

    const profile = useSelector(selectProfile)

    const { id } = useParams()

    const { data, isLoading, mutate } = useSWR<BonusHunt>(`/hunt/${id}`, fetcher)
    const slotGuess = useSWR<BonusHuntSlotGuess>(`/hunt/${id}/slot-guess`, fetcher)

    const [isGuessing, setIsGuessing] = useState(false)
    const [isGuessingSlot, setIsGuessingSlot] = useState(false)

    const bonusTableRef = useRef(null)
    
    function setSlotGuessingMode() {
      setIsGuessingSlot(true)
      if(bonusTableRef.current) {
        (bonusTableRef.current as HTMLElement).scrollIntoView({ behavior: 'smooth' })
      }
      
    }

    function onSlotGuessPlaced(guess: BonusHuntSlotGuess) {
      setIsGuessingSlot(false)
      slotGuess.mutate((prev) => {
        if(!prev) return guess
  
        return { ...prev, ...guess }
      }, {
        revalidate: false
      })
    }
  

    useEffect(() => {

      function bonusHuntEvents(event: BonusHuntEvent, huntId: number) {
          switch(event) {
            case  BonusHuntEvent.GUESSING_OPENED:
                mutate((prev) => {
  
                  if(!prev || prev.huntId !== huntId) return prev
                  
                  return { ...prev, guessState: BonusHuntGuessState.OPEN }
                }, {
                  revalidate: false
                })
          }
      } 
  
      socket.on("bonusHunt", bonusHuntEvents)
  
      return () => {
          socket.off("bonusHunt", bonusHuntEvents)
      }
  
    }, [])

  return (
    <Box
        display="flex"
        gap="2rem"
        width="100%"
        position="relative"
    >

          {isGuessingSlot && (
            <Box position="fixed" top="7rem" sx={{ zIndex: '10', backgroundColor: 'var(--primary-bg-soft)', width: '90%', borderRadius: '.5rem', left: '50%', transform: 'translateX(-50%)', boxShadow: '0 0 1rem rgba(255,0,0, .4)', border: '2px solid var(--primary-main)', padding: '1rem' }}>
              <Box display="flex" alignItems="center" justifyContent="center" sx={{ position: 'relative', width: '100%' }}>
                <Typography sx={{ fontWeight: 'bold', textAlign: 'center' }}>Choose a Slot to place a bet on!</Typography>
                <IconButton sx={{ position: 'absolute', right: '1rem' }} onClick={() => setIsGuessingSlot(false)}>
                  <Close />
                </IconButton>
              </Box>

            </Box>
          )}



        <Box display="flex" flexDirection="column" className="page" maxWidth="1600px" flexGrow={1} sx={{ px: { xs: '8px', md: '1rem' }}}>
          <Button
            startIcon={<ArrowBack />}
            disableRipple
            component={Link}
            to="/hunts"
            sx={{ alignSelf: 'flex-start', mb: '2rem' }}
          >
            <Typography>Bonus Hunts</Typography>
          </Button>
            <Box display="flex" flexDirection="column" sx={{ width: '100%' }}>
              <Box display="flex">
                <Box display="flex" alignItems="center" gap=".5rem" onClick={() => setIsGuessing(false)} 
                sx={{ 
                    cursor: 'pointer', 
                    backgroundColor: !isGuessing ? 'var(--primary-bg-dark)' : 'var(--primary-bg-soft)', 
                    p: '.75rem', 
                    borderRadius: '.5rem 0 0 0', 
                    border: '1px solid', 
                    borderColor: !isGuessing ? 'var(--primary-main)' : 'var(--primary-border)',
                  }}
                >
                  <StackedLineChart sx={{ height: '20px', width: '20px' }} />
                  <Typography sx={{ fontWeight: 'bold' }}>Stats</Typography>
                </Box>
                <Box display="flex" alignItems="center" gap=".5rem" 
                  onClick={() => setIsGuessing(prevState => !prevState)}
                  sx={{ 
                    cursor: 'pointer', 
                    backgroundColor: isGuessing ? 'var(--primary-bg-dark)' : 'var(--primary-bg-soft)',
                    p: '.75rem', 
                    borderRadius: '0 .5rem 0 0', 
                    border: '1px solid', 
                    borderColor: isGuessing ? 'var(--primary-main)' : 'var(--primary-border)',
                    '&:hover': {
                      '.close': {
                        transform: 'rotate(-5deg)'
                      }
                    }
                  }}>
                    <Target />
                  <Typography sx={{ fontWeight: 'bold' }}>
                    Guess The Balance
                  </Typography>
                  {isGuessing && (
                      <Close className='close' sx={{ transition: 'transform .2s ease-in-out'}} />
                  )}
                </Box>
              </Box>
              <Box display="flex" flexDirection="column" sx={{ backgroundColor: 'var(--primary-bg-dark)', p: '1rem',  borderRadius: '0 .5rem .5rem .5rem', border: '1px solid var(--primary-border)' }}>
                <Typography sx={{ fontWeight: "bold", mb: '1rem' }}>Bonus Hunt #{data?.huntId || "NA"}</Typography>
 
                <BonusHuntStats data={data} isLoading={isLoading} />


                
                <Box display="flex" flexDirection="column" sx={{ width: '100%', mt: '2rem' }}>
                  {data && data.isCompleted ? (
                    <Typography sx={{ fontWeight: 'bold', alignSelf: 'flex-end', fontSize: '.9em', textTransform: 'capitalize' }}>Bonus Hunt Finished!</Typography>
                  ) : data && (
                    <Typography sx={{ fontWeight: 'bold', alignSelf: 'flex-end', fontSize: '.9em' }}>{data.totalBonuses - data.openedBonuses} Bonuses remaining.</Typography>                 
                  )}
                  <LinearProgress variant="determinate" sx={{ height: '8px', borderRadius: '.5rem' }} value={(data?.openedBonuses || 0) / (data?.totalBonuses || 0) * 100} />
                </Box>
                
                {data && (
                  <Collapse in={isGuessing}>
                    <Box display="flex" flexDirection="column" sx={{ pt: '1rem', borderTop: '1px solid var(--primary-border)', mt: '2rem' }}>
                      <Typography sx={{ fontWeight: 'bold' }}>Guess The Balance</Typography>
                      <Typography sx={{ fontSize: '.8em', color: 'var(--text-secondary)', mb: '2rem' }}>Win points by placing your prediction of the end balance!</Typography>

                      <GuessTheBalanceBetForm huntId={data.huntId} guessState={data.guessState} />

                      <Typography component="span" sx={{ fontSize: '.9em', color: 'var(--text-secondary)' }}>
                          You can read more about payouts <BonusHuntGuessPayoutDialog />.
                      </Typography>
                      
                    </Box>
                  </Collapse>
                )}

              </Box>
            </Box>

            {profile && data && data.guessState === BonusHuntGuessState.OPEN && (
              <Box sx={{ width: '100%', mt: '2rem' }}>
                <SlotGuessing hasGuess={slotGuess.data ? true : false} setGuessingMode={setSlotGuessingMode} />
              </Box>
            )}
            
            <Box ref={bonusTableRef} sx={{ mt: '2rem', width: '100%' }}>
              <BonusesTable isLoading={isLoading} bonuses={data?.bonuses} isGuessingSlot={isGuessingSlot} slotGuess={slotGuess.data} bonusCount={data?.bonuses.length} bonusHuntId={data?.huntId} onSlotGuessPlaced={onSlotGuessPlaced}  />
            </Box>
        </Box>
    </Box>
  )
}

export default BonusHuntPage