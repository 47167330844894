import { IUser, Profile, User } from "./user.interface";

export enum EUserItemStatus {
    NOT_REDEEMABLE = 0,
    REDEEMABLE = 1,
    REDEEMED = 2,
    OUT_OF_STOCK = 3,
    RECEIVED = 4,
    INVALID = 5,
    CANCELED = 6,
    USABLE = 10,
    USED = 11
}

export enum EBoughtItemNoteKeys {
    SLOT = "slot",
    ETH_ADDRESS = "eth_address",
}

export enum EItemType {
    BONUS_BUY = "bonus_buy",
    POINTS = "points",
    CRYPTO = "crypto", 
    CSGO = "csgo",
    IRL = "irl",
    GIFT_CARD = "giftcard",
    CASE =  "case",
    CLASH = "clash",
    RAFFLE_TICKET = "raffle_ticket",
    BONUS_HUNT_TICKET = "bonus_hunt_ticket",
    NOTHING = "nothing",
    PACKDRAW = "packdraw",
}

export enum EItemTypeDescription {
    "bonus_hunt_ticket" = "Ticket",
    "Bonus Hunt Ticket" = "Ticket",
    "packdraw" = "Packdraw",
    "bonus_buy" = "Bonus Buy",
    "Bonus Buy" = "Bonus Buy",
    "crypto" = "Crypto",
    "Crypto" = "Crypto",
    "csgo" = "CSGO",
    "CSGO" = "CSGO",
    "irl" = "IRL",
    "IRL" = "IRL",
    "Points" = "Points",
    "points" = "Points",
    "giftcard" = "Giftcard",
    "Giftcard" = "Giftcard",
    "case" = "Case",
    "Case" = "Case",
    "clash" = "Clash",
    "Clash" = "Clash",
    "raffle_ticket" = "Raffle Ticket",
    "Raffle Ticket" = "Raffle Ticket",
    "nothing" = "Nothing",
    "Nothing" = "Nothing"
}


export enum EUserItemStatusDescription {
    NOT_REDEEMABLE = 'Not Redeemable',
    REDEEMABLE = 'Redeemable',
    REDEEMED = 'Redeemed',
    NOT_IN_STOCK = 'Out of Stock',
    RECEIVED = 'Received',
    USABLE = 'Usable',
    USED = 'Used',
    CANCELED = 'Canceled'
}

export enum EUserItemOrigin {
    STORE = 'store',
    CASE = 'case',
    GIFT = 'item_gift',
    CASE_GIVEAWAY = 'giveaway_case',
}

export enum ItemRarity {
    COMMON = "common",
    UNCOMMON = "uncommon",
    RARE = "rare",
    EPIC = "epic",
    LEGENDARY = "legendary"
}

export interface Item {
    _id: string;
    itemid: number;
    name: string;
    image: string;
    description: string;
    rarity: ItemRarity;
    value: number;
    redeemable: boolean;
    type: EItemType;
    point_value?: number;
    raffleId?: string
}

export interface UserItem {
    user_item_id: number;
    user: User;
    _id: string;
    item: Item;
    status: EUserItemStatus;
    acquire_date: string;
    redemption_date?: string;
    received_date?: string;
    origin: string;
    origin_id: string;
    origin_image: string;
    origin_name: string;    
    source_user?: Profile;
}

