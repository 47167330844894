import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import CheckIcon from '@mui/icons-material/Check';

import { styled } from '@mui/material'
import Button from '@mui/material/Button'
import { formatDate } from '../../utils';
import TimeIcon from '@mui/icons-material/AccessTime';
import CloseIcon from '@mui/icons-material/Close';
import { API_URL } from '../../utils';
import { createToast } from '../Toasts/Toasts';
import { ECSGOGiveawayStatus } from '../../shared/giveaway.interface';
import { CSGOItem } from '../../shared/steam.interface';


const GiveawayContainer = styled(Box)(( { theme }) => ({
    display: 'flex',
    alignItems: 'center',
    color: 'rgb(255, 255, 255, .8)',
    padding: '12px 24px',

    '.date': {
        color: theme.palette.text.disabled,
        fontSize: '14px'
    },

    '&:nth-of-type(even)': {
        backgroundColor: 'var(--primary-bg-soft)'
    },

    '&:nth-of-type(odd)': {
        backgroundColor: 'var(--primary-bg-soft)'
    },
    'tr td:first-of-type': {
        paddingLeft: '24px'
    },

    'tr td:last-of-type': {
        paddingRight: '24px'
    },
}))

const StatusBox = styled(Box)(( { theme }) => ({
    display: 'flex',
    alignItems: 'center',

    'svg': {
        marginRight: '.25rem',
    }
}))


const StatusSuccessTextBox = styled(Box)(( { theme }) => ({
    color: theme.palette.success.light,
    fill: theme.palette.success.light,
    display: 'flex',
    alignItems: 'center',

    'svg': {
        marginRight: '.25rem',
        fill: theme.palette.success.light
    }
}))

const StatusErrorTextBox = styled(Box)(( { theme }) => ({
    color: theme.palette.error.main,
    display: 'flex',
    alignItems: 'center',
    'svg': {
        marginRight: '.25rem',
        fill: theme.palette.error.main
    }
}))


const resendTradeoffer = (giveawayid: string) => {
    fetch(`${API_URL}/user/csgo/resend`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
            giveawayid: giveawayid
        })
    })
        .then((response) => {
            return response.json()
        }).then((data) => {
            if(data && !data.error){
                createToast('Trade resent', 'success')
            } else if (data.error){
                createToast('Error resending trade', 'error')
            }
        }).catch((err) => {

        });
}

type CSGOGiveawayProps = {
    _id: string;
    item: CSGOItem;
    status: ECSGOGiveawayStatus,
    tradeofferid: string;
    date: Date;
    csgwid: number;
}

const CSGOGiveawayRow = ({ item, date, status, _id }: CSGOGiveawayProps) => {
    return (
        <GiveawayContainer>
            <img
                src={item.icon_url}
                alt=""
                style={{ height: '64px', width: 'auto', marginRight: '1rem' }}
            />
            <Box display="flex"
                flexDirection="column"
                sx={{
                    marginRight: 'auto'
                }}>
                <Typography>
                    {item.market_hash_name}
                </Typography>
                <Typography className="date">
                    {formatDate(date, "time")}
                </Typography>
            </Box>
            {status === ECSGOGiveawayStatus.TRADE_COMPLETED ? (
                <StatusSuccessTextBox>
                    <CheckIcon />
                    <Typography>
                        Trade Completed
                    </Typography>
                </StatusSuccessTextBox>
            ) : status === ECSGOGiveawayStatus.TRADE_CANCELED ? (
                <StatusErrorTextBox>
                    <CloseIcon />
                    <Typography>Trade Canceled</Typography>
                    <Button variant="contained" sx={{ marginLeft: '1rem' }} onClick={() => resendTradeoffer(_id)}>
                        Resend Trade
                    </Button>
                </StatusErrorTextBox>
            ) : status === ECSGOGiveawayStatus.TRADE_DECLINED ? (
                <StatusErrorTextBox>
                    <CloseIcon />
                    <Typography>Trade Declined</Typography>
                    <Button variant="contained" sx={{ marginLeft: '1rem' }} onClick={() => resendTradeoffer(_id)}>
                        Resend Trade
                    </Button>
                </StatusErrorTextBox>
            ) : status === ECSGOGiveawayStatus.INVENTORY_PRIVATE ? (
                <StatusErrorTextBox>
                    <CloseIcon />
                    <Typography>Inventory Private</Typography>
                </StatusErrorTextBox>
            ) : status === ECSGOGiveawayStatus.TRADE_READY ? (
                <StatusBox>
                    <CheckIcon />
                    <Typography>Trade Ready</Typography>
                </StatusBox>
            ) : status === ECSGOGiveawayStatus.CANCELED ? (
                <StatusErrorTextBox>
                    <CloseIcon />
                    <Typography>Canceled</Typography>
                </StatusErrorTextBox>
            ) : (
                <StatusBox>
                    <TimeIcon />
                    <Typography>Processing</Typography>
                </StatusBox>
            )}
        </GiveawayContainer>
    )
}

export default CSGOGiveawayRow
