import React, { useEffect, useState } from 'react'
import Modal from '../Modal'
import Pagination from '@mui/material/Pagination';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Button, styled } from '@mui/material'
import { API_URL, formatDate } from '../../utils';
import LoadingCircle from '../LoadingCircle';

const GiveawayTable = styled('table')(({ theme }) => ({
    width: '100%',
    borderSpacing: '0 6px',
    color: 'var(--text-secondary)',

    'tr > th': {
        color: theme.palette.primary.main,
    },

    '& > th,td': {
        padding: '1rem .5rem',
        textAlign: 'center'
    },


    'tr td': {
        backgroundColor: 'var(--primary-bg-soft)',
    }

}))

const WinnerContainer = styled(Box)(({ theme }) => ({

    display: 'flex',
    alignItems: 'center',

    'img': {
        width: '32px',
        height: '32px',
        borderRadius: '50%',
        marginRight: '1rem'
    }

}))

const CommonGiveawayHistoryModal = ({ open, handleClose }) => {

    const [activePage, setActivePage] = useState(1);
    const [maxPage, setMaxPage] = useState(10);

    const [giveaways, setGiveaways] = useState(null);
    const [loadingGiveaways, setLoadingGiveaways] = useState(true);

    const changePage = (event, value) => {
        setActivePage(value);
    }

    useEffect(() => {
        if (open) {
            fetch(`${API_URL}/giveaway/casino/page/${activePage}`)
                .then((response) => {
                    if (!response.ok) return false
                    else return response.json()
                }).then((data) => {
                    if (data && !data.error) {
                        setGiveaways(data.data);
                        setMaxPage(data.pages);
                        setLoadingGiveaways(false);
                    }
                }).catch((err) => {

                });
        }

    }, [activePage, open])



    return (
        <React.Fragment>
            <Modal
                open={open}
                handleClose={handleClose}
                title={<span>Giveaway History</span>}
                width="900px"
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    sx={{ height: '100%' }}
                >
                    <Box
                        display="flex"
                        flexDirection="column"
                        sx={{ width: '100%'}}
                    >
                        {loadingGiveaways ? (
                            <Box>
                                <LoadingCircle loadingText="Loading giveaway history..." />
                            </Box>
                        ) : giveaways && giveaways.length > 0 ? (
                            <GiveawayTable>
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Date</th>
                                        <th>Amount</th>
                                        <th>Winner</th>
                                    </tr>
                                </thead>
                                <tbody >
                                    {giveaways.map((giveaway, index) => (
                                        <tr key={`giveaway-history-row-${giveaway._id}`}>
                                            <td>{giveaway.sgwid}</td>
                                            <td>{formatDate(giveaway.date, "time")}</td>
                                            <td>${giveaway.amount}</td>
                                            <td>
                                                <WinnerContainer>
                                                    <img src={giveaway.user.avatar} alt="" />
                                                    <Typography variant="span">{giveaway.user.display_name}</Typography>
                                                </WinnerContainer>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </GiveawayTable>
                        ) : null}
                    </Box>
                    <Pagination
                        count={maxPage}
                        page={activePage}
                        onChange={changePage}
                        color="primary"
                        sx={{ marginTop: '1rem' }}
                    />
                </Box>

            </Modal>
        </React.Fragment>
    )
}

export default CommonGiveawayHistoryModal