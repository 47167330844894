import React from 'react'
import useSWR from 'swr'
import { StoreItem } from '../../shared/store.interface'
import { fetcher } from '../../utils'
import { Box, Skeleton, Typography } from '@mui/material'
import { ReactComponent as Coin } from '../../icons/assets/coin.svg'
import BuyItemModal from './BuyItemModal'
import ItemDescriptionPopover from './ItemDescriptionPopover'
import { useSelector } from 'react-redux'
import { selectProfile } from '../../redux/profile/profileSlice'
import { ReactComponent as CoinIcon } from '../../icons/assets/coin.svg'

const StoreItemSkeleton = () => {
    return (
        <Box 
            display="flex" 
            flexDirection="column" 
            alignItems="center" 
            justifyContent="center" 
            gap="1rem" 
            sx={{ 
                p: '1rem', 
                borderRadius: '.5rem',
                backgroundColor: 'var(--primary-bg-soft)',
                border: '1px solid var(--primary-border)'
            }}>
            <Skeleton variant="circular" sx={{ height: '100px', width: '100px' }} />
            <Skeleton variant="text" width="66%" sx={{ my: '1rem' }} />
            <Box display="flex" alignItems="center" gap=".5rem" width="100%">
                <Skeleton variant="text" width="40%" />
                &#8226;
                <Skeleton variant="text" width="25%" />
            </Box>
            <Skeleton variant="rectangular" width="100%" sx={{ height: '32px', borderRadius: '.5rem' }} />
        </Box>
    )
}

const StoreItemCard = ({ storeItem }: { storeItem: StoreItem}) => {
    return (
        <Box 
            display="flex" 
            flexDirection="column" 
            alignItems="center" 
            justifyContent="center" 
            gap=".5rem" 
            position="relative"
            sx={{ 
                p: '1rem', 
                borderRadius: '.5rem',
                backgroundColor: 'var(--primary-bg-soft)',
                border: '1px solid var(--primary-border)'
            }}>
            <Box display="flex" alignItems="center" justifyContent="center" sx={{ width: '100%', pb: '1.5rem', borderBottom: '1px solid var(--primary-border)' }}>
                <Box component="img" src={storeItem.item.image} alt={storeItem.item.name} 
                    sx={{ height: '100px', width: '100px', filter: 'drop-shadow(.25rem .25rem .5rem rgba(0, 0, 0, .5))' }} />
            </Box>
            <Box component="span" sx={{ position: 'absolute', top: '4px', right: '4px' }}>
                <ItemDescriptionPopover description={storeItem.item.description} />
            </Box>

            <Typography sx={{ my: '1.5rem', fontWeight: 'bold' }}>{storeItem.item.name}</Typography>
            <Box display="flex" alignItems="center" gap=".5rem" width="100%" sx={{ color: 'var(--text-secondary)', mt: 'auto' }}>
                <Box display="flex" alignItems="center" gap=".25rem">
                    <Coin style={{ height: '16px', width: '16px' }} />
                    <Typography component="b" sx={{ fontWeight: 'bold' }}>{Intl.NumberFormat("en-US").format(storeItem.price)}</Typography>
                </Box>
                &#8226;
                <Typography sx={{ color: 'var(--text-disabled)' }}>{Intl.NumberFormat("en-US").format(storeItem.stock)} Left</Typography>
            </Box>
            <BuyItemModal storeItemId={storeItem._id} price={storeItem.price} item={storeItem.item} />

        </Box>
    )
}

const StorePage = () => {

    const profile = useSelector(selectProfile)
    const { data, isLoading } = useSWR<StoreItem[]>('/store', fetcher)

  return (
    <Box className="page" display="flex" flexDirection="column" gap="4rem" sx={{ mt: '2rem', maxWidth: '1600px' }}>
        <Box display="flex" flexDirection="column" gap=".5rem">
            <Box display="flex" flexDirection="column" gap=".25rem" alignItems="center">
                <Typography variant="h3">Store</Typography>
                {profile && (
                    <Typography sx={{ color: 'var(--text-secondary)' }}>You have  <CoinIcon style={{ height: '14px', width: '14px' }} /> <Typography component="span" sx={{ color: 'var(--text-primary)', fontWeight: 'bold' }}>{Intl.NumberFormat("en-US").format(profile.points)} Points</Typography></Typography>                
                )}
            </Box>
        </Box>
        
        <Box display="grid" gap="1rem"
            sx={{ 
                    gridTemplateColumns: { 
                        xs: 'repeat(1, minmax(0, 1fr))', 
                        sm: 'repeat(2, minmax(0, 1fr))', 
                        md: 'repeat(3, minmax(0, 1fr))', 
                        lg: 'repeat(4, minmax(0, 1fr))', 
                        xl: 'repeat(5, minmax(0, 1fr))' 
                    }
                }} 
        >
            {isLoading ? (
                <>
                    {new Array(10).fill(0).map((_, i) => (
                        <StoreItemSkeleton key={`store-item-skeleton-${i}`} />                
                    ))}
                </>
            ) : !data ? (
                <>
                    <Typography width="100%" sx={{ gridColumn: 1/-1 }}>Something went wrong, please refresh the page</Typography>
                </>
            ) : (
                <>
                    {data.map((storeItem: StoreItem) => (
                        <StoreItemCard key={`store-item-${storeItem._id}`} storeItem={storeItem} />                  
                    ))}
                </>
            )}
        </Box>
    </Box>
  )
}

export default StorePage