import React, { useEffect, useState } from 'react'
import Modal from '../../Modal'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { fetchAPI, saveToClipboard } from '../../../utils';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { ReactComponent as Kick } from '../../../icons/sites/kick.svg';
import { socket } from '../../../socket';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from 'react-redux';
import { setKickID } from '../../../redux/profile/profileSlice';

type ConnectKickModalProps = {
    open: boolean
    handleClose: () => void
}

const ConnectKickModal = ({ open, handleClose }: ConnectKickModalProps) => {

    const dispatch = useDispatch();

    const [verificationCode, setVerificationCode] = useState<string | undefined>(undefined);
    const [loadingVerificationCode, setLoadingVerificationCode] = useState<boolean>(true);

    const [verified, setVerified] = useState(false);
    const [verificationExpired, setVerificationExpired] = useState(false);
    

    async function requestVerification() {
            
            try {
                const data: any = await fetchAPI('/user/kick/verify', 'GET')
    
                if (data.success) {
                    setVerificationCode(data.data.verificationCode);
                    setVerificationExpired(false)
                    setLoadingVerificationCode(false)
                }
    
            } catch (e) {
    
            }           
    }

    useEffect(() => {

        if(open) {

            socket.on('kickVerified', (data) => {
                if(data.verified) {
                    setVerified(true)
                    dispatch(setKickID({ kick_id: data.kickId, kick_username: data.kickUsername } ))
                }              
            })

            socket.on('kickVerificationExpired', (data) => {
                if(data.expired) {
                    setVerificationExpired(true);
                }
                
            })


            if(verificationCode === undefined) {
                setLoadingVerificationCode(true)
                requestVerification();
            }
        }

        return () => {
            socket.off('kickVerified')
            socket.off('kickVerificationExpired')
        }

    }, [open, dispatch, verificationCode, verificationExpired])

  return (
    <React.Fragment>
          <Modal
              open={open}
              handleClose={handleClose}
              width={"600px"}
          >
              <Box display="flex" flexDirection="column" alignItems="flex-start" rowGap="2rem">
                  <Box display="flex" alignItems="center" justifyContent="center">
                      <Kick style={{ height: '32px', width: '32px' }} />
                      <Typography variant="h5" alignSelf="flex-start" marginLeft=".5rem">Connect Kick account</Typography>
                  </Box>

                  {verified ? (
                    <Box display="flex" alignItems="center">
                        <CheckIcon sx={{ color: 'var(--success)', marginRight: '.5rem' }}/>
                        <Typography>You have been verified!</Typography>
                    </Box>
                  ) : verificationExpired ? (
                          <Box display="flex" flexDirection="column" width="100%" alignItems="center" justifyContent="center" rowGap="1rem">
                              <Box display="flex" alignItems="center">
                                  <CloseIcon sx={{ color: 'var(--error)', marginRight: '.5rem' }} />
                                  <Typography>Your verification code expired!</Typography>
                              </Box>

                              <Button variant="text" className="link" sx={{ textTransform: 'none' }} disableRipple onClick={() => requestVerification()}>Request new Code</Button>
                          </Box>
                  ) : (
                      <Box display="flex" width="100%" flexDirection="column" rowGap="1rem">

                          <Typography>Your Verification Code:</Typography>

                          {loadingVerificationCode ? (
                              <Typography>Generating Verification Code...</Typography>
                          ) : (
                              <Box sx={{
                                  padding: '1rem',
                                  backgroundColor: 'var(--primary-bg-light)',
                                  color: '#53fc19',
                                  borderRadius: '.5rem',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  margin: '0 auto',
                                  width: '300px',
                                  marginBottom: '1rem',
                                  cursor: 'pointer',
                                  position: 'relative',
                                  '&:hover': {
                                      'svg': {
                                          fill: '#53fc19'
                                      }
                                  }
                              }}
                                  onClick={verificationCode ? () => saveToClipboard(`!verify ${verificationCode}`) : () => null}
                              >
                                  <Typography>{verificationCode}</Typography>
                                  <ContentCopyIcon sx={{ position: 'absolute', right: '.5rem', fill: 'var(--text-secondary)' }} />

                              </Box>
                          )}

                          <ol>
                              <li>
                                  Go to our verification <a href="https://kick.com/ddkbot/chatroom" rel="noreferrer" target="_blank" style={{ color: '#53fc19' }}>Kick Channel</a>
                              </li>
                              <li style={{ marginTop: '.75rem' }}><Typography>Type the following command: &nbsp;
                                  <Typography
                                      onClick={verificationCode ? () => saveToClipboard(`!verify ${verificationCode}`) : () => null}
                                      component="span"
                                      sx={{
                                          color: '#53fc19',
                                          padding: '.5rem',
                                          backgroundColor: 'var(--primary-bg-light)',
                                          borderRadius: '.5rem',
                                          cursor: 'pointer'
                                      }}>
                                      {loadingVerificationCode ? "Generating..." : `!verify ${verificationCode}`}
                                  </Typography></Typography>
                              </li>
                          </ol>

                          <Typography sx={{ color: 'var(--text-secondary)' }}>Your verification code becomes invalid after 5 minutes</Typography>
                      </Box>
                  )}



                  <Box display="flex" width="100%">
                      <Button variant="contained" color="error" sx={{ marginLeft: 'auto' }} onClick={() => handleClose()}>Close</Button>
                  </Box>

              </Box>
          </Modal>
    </React.Fragment>
  )
}

export default ConnectKickModal