import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    items: [],
}

export const storeSlice = createSlice({
    name: 'store',
    initialState,
    reducers: {
        setStoreItems: (state, action) => {
            state.items = [...state.items, ...action.payload] //action.payload.sort((a, b) => (b.priority || 0) - (a.priority || 0))
        },
        addStoreItem: (state, action) => {
            state.items = [action.payload, ...state.items]
        },
        updateStoreItem: (state, action) => {
            state.items = state.items.map((item) => (
                item._id === action.payload._id ? {...item, ...action.payload} : item
            ))
        }        
    }
})

export const { setStoreItems, addStoreItem, updateStoreItem } = storeSlice.actions

export const selectStoreItems = state => state.store.items;

export default storeSlice.reducer