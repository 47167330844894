import React, { useState, useEffect } from 'react'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import Modal from '../Modal'
import { ReactComponent as Discord } from '../../icons/discord.svg';
import ProdigyLogo from '../../icons/prodigylogo.png';
import { ReactComponent as Gamdom } from '../../icons/sites/gamdom_tower.svg';
import { styled } from '@mui/material'
import { API_URL } from '../../utils'
import CheckIcon from '@mui/icons-material/Check';
import { useSelector } from 'react-redux';
import { selectProfile } from '../../redux/profile/profileSlice';
import VerifyModal from './VerifyModal'

const DiscordButton = styled(Button)(( { theme }) => ({
    backgroundColor: '#7289DA',
    padding: '.75rem',
    display: 'flex',
    alignItems: 'center',
    width: '140px',
    borderRadius: '6px',
    boxShadow: '0 0 1rem rgba(16, 12, 24, .75)',

    '&:hover': {
        backgroundColor: '#5F73BC'
    },

    'svg': {
        width: '24px',
        height: '24px'
    }
}))

const ProdigyButton = styled(Button)(( { theme }) => ({
    backgroundColor: "rgb(52, 46, 60)",
    padding: '.75rem',
    display: 'flex',
    alignItems: 'center',
    width: '140px',
    borderRadius: '6px',
    boxShadow: '0 0 8px rgba(16, 12, 24, .5)',

    '&:hover': {
        backgroundColor: 'rgb(38, 34, 46)'
    },

    'img': {
        width: '24px',
        height: '24px'
    }
}))

const GamdomButton = styled(Button)(( { theme }) => ({
    backgroundColor: 'rgb(47, 61, 69)',
    padding: '.75rem',
    display: 'flex',
    alignItems: 'center',
    width: '140px',
    borderRadius: '6px',
    boxShadow: '0 0 8px rgba(16, 12, 24, .5)',

    '&:hover': {
        backgroundColor: 'rgb(38, 49, 56)'
    },

    'svg': {
        width: '24px',
        height: '24px'
    }
}))

const VerifyButton = styled(Button)(( { theme }) => ({
    backgroundColor: "#2e7d32",
    padding: '.75rem',
    display: 'flex',
    alignItems: 'center',
    width: '140px',
    borderRadius: '6px',
    boxShadow: '0 0 8px rgba(16, 12, 24, .5)',

    '&:hover': {
        backgroundColor: '#1b5e20'
    },

    'svg': {
        width: '24px',
        height: '24px'
    }
}))

const CustomList = styled("ul")(( { theme }) => ({

    listStyle: 'none',

    'li::before': {
        content: '"⭐"',
        fontWeight: "bold",
        display: "inline-block",
        marginLeft: "-1rem",
        marginRight: ".5rem"
    },

    'li': {
        margin: '.5rem 0'
    }
}))

const GamdomInfoModal = ({ open, handleClose }) => {

    const profile = useSelector(selectProfile)
    const [verifyModalOpen, setVerifyModalOpen] = useState(false)
    const [isVerified, setIsVerified] = useState(false);

    useEffect(() => {
        if(open) {
            const fetchIsVerified = async () => {
                const res = await fetch(`${API_URL}/user/gamdom/verified`, {
                    credentials: 'include'
                })
                const data = await res.json()
                setIsVerified(data.verified)
            }
            fetchIsVerified()
        }

    }, [open])


    return (
        <React.Fragment>
            <Modal
                open={open}
                handleClose={handleClose}
                width="900px"
                closeButton={true}
            >
                <Box display="flex" flexDirection="column">
                    <Typography variant="h4">Gamdom Rewards</Typography>
                    <Typography variant="h6" sx={{ marginTop: '1rem', color: 'text.secondary' }}>
                        💸 Giveaways, Tips and Free Spins
                    </Typography>

                    <CustomList sx={{ marginTop: '3rem', color: 'text.secondary' }}>
                        <li>Sign up on Gamdom under <Typography variant="span" sx={{ color: 'rgba(14,214,113,1)' }}>code DDK</Typography></li>
                        <li>Connect your Discord account to your ProdigyDDK account</li>
                        <li>Connect your Discord account to your Gamdom account</li>
                        <li>Join our <a href="https://discord.gg/EaMVs4Php4" target="_blank" rel="noreferrer" style={{ color: 'rgba(14,214,113,1)' }}>Discord Server</a></li>
                        <li>Verify to receive your Discord Role</li>
                    </CustomList>
                    {!isVerified ? (
                        <Box display="flex" justifyContent="space-evenly" sx={{ marginTop: '3rem' }}>
                            {profile && !profile.discord_id ? (
                                <ProdigyButton startIcon={<img src={ProdigyLogo} alt="" />} href={`${API_URL}/auth/discord`} target="_blank" rel="noreferrer">
                                    Connect
                                </ProdigyButton>
                            ) : (
                                <ProdigyButton startIcon={<CheckIcon />} disabled={true} target="_blank" rel="noreferrer">
                                    Connected
                                </ProdigyButton>
                            )}

                            <Tooltip title="On Gamdom, at the bottom you will see a Connect Discord Account Button">
                                <GamdomButton startIcon={<Gamdom />} href="https://gamdom.com/profile" target="_blank" rel="noreferrer">
                                    Connect
                                </GamdomButton>
                            </Tooltip>
                            <DiscordButton startIcon={<Discord />} href="https://discord.gg/EaMVs4Php4" target="_blank" rel="noreferrer">
                                Join
                            </DiscordButton>
                            <VerifyButton startIcon={<CheckIcon />} onClick={() => setVerifyModalOpen(true)}>
                                Verify
                            </VerifyButton>
                        </Box>
                    ) : (
                        <Box display="flex" alignItems="center" sx={{ margin: '3rem auto 0 auto', color: 'success.light' }}>
                            <CheckIcon sx={{ marginRight: '1rem', height: '32px', width: '32px' }} />
                            <Typography variant="h4">You're already verified</Typography>
                        </Box>
                    )}

                </Box>

            </Modal>
            <VerifyModal open={verifyModalOpen} handleClose={() => setVerifyModalOpen(false)} setVerified={setIsVerified} />
        </React.Fragment>
    )
}

export default GamdomInfoModal