import React from 'react';
import { toast } from 'react-toastify';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export const createCopiedToClipboardToast = (copyText = "") => {
    toast.dark(
        <Box display="flex" alignItems="center" sx={{ color: 'text.secondary' }}>
            <ContentCopyIcon style={{ height: '32px', width: '32px' }} />
            <Box display="flex" flexDirection="column" sx={{ marginLeft: '1rem' }}>
                <Typography sx={{ fontSize: '20px', fontWeight: 'bold' }}>
                    Copied to Clipboard
                </Typography>
                <Typography>
                    {copyText ? copyText : "Content has been copied to your clipboard" }
                </Typography>
            </Box>

        </Box>
        ,{
            position: 'bottom-right',
            progressStyle: {
                backgroundColor: "#4caf50"
            }
        }
    )
}