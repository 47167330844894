import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import useSWR from 'swr';
import { fetcher } from '../../../utils';
import { ReactComponent as RainGG } from '../../../icons/sites/raingg.svg'
import Skeleton from '@mui/material/Skeleton';
import { LeaderboardSite, LeaderboardWager } from '../../../shared/leaderboard.interface';
import PreviousLeaderboard from '../Previous';

const COLOR = "#f6af16";

const textGradient = "linear-gradient(90deg, #f6af16 0%, #f6af16 100%)"

export const LeaderboardSkeleton = () => {
  return (
    <Box component="tr">
      <Box component="td" sx={{ textAlign: 'start', pl: '24px !important' }}>
        <Skeleton variant="rounded" sx={{ width: '32px', height: '24px' }} />
      </Box>
      <Box component="td" sx={{ textAlign: 'start' }}>
        <Skeleton variant="rounded" sx={{ width: '50%', minWidth: '128px', height: '24px' }} />
      </Box>
      <Box component="td" sx={{ justifyContent: 'flex-end' }}>
        <Skeleton variant="rounded" sx={{ mx: 'auto', width: '80px', height: '24px' }} />
      </Box>
      <Box component="td" sx={{ width: '120px', pr: '24px !important' }}>
        <Skeleton variant="rounded" sx={{ ml: 'auto', width: '64px', height: '24px' }} />
      </Box>
    </Box>
  )
}

const LeaderboardRow = ({ wager, placement, isSelf }: { wager: LeaderboardWager, placement: number, isSelf?: boolean }) => {
  return (
    <Box component="tr" className={`${placement === 1 ? "first" : placement === 2 ? "second" : placement === 3 ? "third" : ""}`}
      sx={{
        'td': { 
          borderTop: isSelf ? `2px solid ${COLOR}` : "",
          borderBottom: isSelf ? `2px solid ${COLOR}` : "",
        },
        'td:last-of-type': {
          borderRight: isSelf ? `2px solid ${COLOR}` : "" 
        },
        'td:first-of-type': {
          borderLeft: isSelf ? `2px solid ${COLOR}` : "" 
        }
      }}
    >
      <Box component="td" sx={{ textAlign: 'start', pl: '24px !important' }}>
        <Typography className={`placement`}>{wager.placement}</Typography>
      </Box>
      <Box component="td" sx={{ textAlign: 'start' }}>
        <Typography>{isSelf ? "Me" : wager.name}</Typography>
      </Box>
      <Box component="td" sx={{ justifyContent: 'flex-end' }}>
        <Typography>{Intl.NumberFormat("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(wager.wagered)} Coins</Typography>
      </Box>
      <Box component="td" sx={{ width: '120px', pr: '24px !important', textAlign: 'end' }}>
        <Typography>{Intl.NumberFormat("en-US").format(wager.prize)} Coins</Typography>
      </Box>
    </Box>

  )
}

const GradientText = ({ disable = false, children, sx }: { disable?: boolean, children: React.ReactNode, sx?: any }) => {
    return (
      <Typography
        component="span"
        sx={{
          color: COLOR,
          fontWeight: "600",
          background: disable ? "var(--text-disabled)" : textGradient,
          backgroundClip: "text",
          WebkitTextFillColor: "transparent",
          textShadow: disable ?  "var(--text-disabled)" : "0 0 .5rem #f6af16",
          ...sx
        }}
      >
        {children}
      </Typography>
    );
}
function getTimeLeft(countdown?: number): undefined | { days: number, minutes: number, hours: number} {

  if(!countdown)
    return undefined

  if(countdown <= 0) return {
      days: 0,
      hours: 0,
      minutes: 0
  }

  const days = Math.floor(countdown / (1000 * 60 * 60 * 24))
  const hours = Math.floor((countdown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
  const minutes = Math.floor((countdown % (1000 * 60 * 60)) / (1000 * 60))

  return {
      days,
      hours, 
      minutes
  }
}


const Timer = ({ timeLeft }: { timeLeft?: { days: number, hours: number, minutes: number } }) => {

  return (
    <GradientText sx={{ fontSize: '2.5em', display: 'flex', alignItems: 'center' }}>
      {!timeLeft || (timeLeft.minutes <= 0 && timeLeft.days <= 0 && timeLeft.hours <= 0) ? (
        "New Leaderboard will start soon."
      ) : (
        <Typography component="ul" sx={{ p: '0', fontSize: '1.2em' }}>
          <li style={{ display: 'inline-block' }}>{timeLeft.days} Days</li>
          <li style={{ display: 'inline-block', margin: '0 .5rem' }}>&bull;</li>
          <li style={{ display: 'inline-block' }}>{timeLeft.hours} Hrs</li>
          <li style={{ display: 'inline-block', margin: '0 .5rem' }}>&bull;</li>
          <li style={{ display: 'inline-block' }}>{timeLeft.minutes} Mins</li>          
        </Typography>
      )}
    </GradientText>
  );
}  


const RainGGLeaderboard = () => {

  const data = useSWR<{ startAt: string, endAt: string, wagers: LeaderboardWager[] }>('/leaderboard/rain', fetcher)

  const [countdown, setCountdown] = useState<number | undefined>(undefined)

  useEffect(() => {
      if (data.data?.endAt) {
        setCountdown(new Date(data.data.endAt).getTime() - Date.now());

          const interval = setInterval(() => {
            if(data.data?.endAt)
              setCountdown(new Date(data.data.endAt).getTime() - Date.now())
          }, 10000);

          return () => {
              clearInterval(interval)
          }
      }
  }, [data.data?.endAt])

  return (
    <Box
        className="page"
        display="flex"
        flexDirection="column"
        alignItems="center"
        textAlign="center"
        gap="4rem"
    >
       <Box
          sx={{
            marginTop: "2rem",
            width: "auto",
            height: "100px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
            <RainGG style={{ height: '64px', width: 'auto' }} />
        </Box>
        <Box display="flex" flexDirection="column" rowGap=".25rem">
          <GradientText
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography sx={{ fontSize: "3.5em", letterSpacing: "-1px", textTransform: 'uppercase'}}>
              {Intl.NumberFormat('en-US').format(10000)} Coins
            </Typography>
          </GradientText>
          <Typography sx={{ fontSize: "2.5em", letterSpacing: "-1px" }}>
              Monthly
            </Typography>
          <GradientText sx={{ fontSize: "2.5em", letterSpacing: "1px", textTransform: 'uppercase' }}>
            Leaderboard
          </GradientText>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          rowGap="1rem"
        >
          <Box display="flex" flexDirection="column">
              <Typography sx={{ fontSize: "1.5em", color: "var(--text-primary)" }}>
                <span>
                  Wager under code{" "}
                  <Typography
                    component={Link}
                    to="/casino"
                    sx={{
                      color: COLOR,
                      letterSpacing: "-1px",
                      fontWeight: "600",
                      fontSize: "1.25em",
                      "&:hover": { color: COLOR + "a1" },
                    }}
                  >
                    DDK
                  </Typography>
                </span>
              </Typography>
          </Box>

        </Box>
        {!countdown ? (
          <></>
        ) : countdown <= 0 ? (
          <GradientText sx={{ fontSize: '2.5em', display: 'flex', alignItems: 'center' }}>
            Leaderboard is finished.
          </GradientText>
        ) : countdown !== undefined && (
          <Box display="flex" flexDirection="column" gap=".25rem">
            <Timer timeLeft={getTimeLeft(countdown)} />
          </Box>
        )}

        


        <Box sx={{ width: { xs: '100%', lg: '900px' }}}>
            {countdown && countdown < 0 && (
              <Typography sx={{ my: '1rem', fontWeight: 'bold' }}>Finishing up the Leaderboard, please check back later.</Typography>
            )}

            <Box display="flex" sx={{ justifyContent: 'flex-end' }}>
              <PreviousLeaderboard site={LeaderboardSite.RAIN} currency="coins" />
            </Box>

            <Box sx={{ overflow: 'auto' }}> 
            <table className='leaderboard-table'>
                  <thead>
                      <tr>
                          <Box component="th" sx={{ color: COLOR, width: '120px', textAlign: 'start !important', pl: '24px !important'}}>#</Box>
                          <Box component="th" sx={{ color: COLOR, textAlign: 'start !important' }}>User</Box>
                          <Box component="th" sx={{ color: COLOR }}>Wagered</Box>
                          <Box component="th" sx={{ color: COLOR, width: '120px', textAlign: 'end !important', pr: '24px !important' }}>Prize</Box>
                      </tr>
                  </thead>
                  <tbody>
                      {data.isLoading ? (
                        <>
                          {new Array(10).fill(0).map((_, i) => (
                            <LeaderboardSkeleton key={`leaderboard-skeleton-${i}`} />
                          ))}
                        </>
                      ) : !data.data ? (
                        <>
                          <tr>
                            <td colSpan={4}>Something went wrong, please refresh the page</td>
                          </tr>
                        </>
                      ) : (
                        <>
                          {data.data?.wagers.map((wager, i) => (
                            <LeaderboardRow key={`leaderboard-row-${i}`} wager={wager} placement={wager.placement} isSelf={false} />
                          ))}
                        </>
                      )}

                  </tbody>
              </table>
            </Box>
        </Box>

    </Box>
  )
}

export default RainGGLeaderboard