 import React, { useState, useEffect, ChangeEvent } from 'react'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { fetchAPI, formatDate, isAPIImage } from '../../utils';
import { API_URL } from '../../utils';
import { styled } from '@mui/material'
import Button from '@mui/material/Button'
import Pagination from '@mui/material/Pagination'

import CheckIcon from '@mui/icons-material/Check';
import TimeIcon from '@mui/icons-material/AccessTime';
import CloseIcon from '@mui/icons-material/Close';
import { EItemType, EUserItemStatus, EUserItemStatusDescription, UserItem } from '../../shared/item.interface';
import { createSuccessToast } from '../Toasts/SuccessToasts';
import ItemRedemptionModal from './ItemRedemptionModal';
import { useDispatch, useSelector } from 'react-redux';
import { selectInventory, setUserInventory, updateItemInInventory } from '../../redux/profile/profileSlice';
import LoadingCircle from '../LoadingCircle';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

const UserInventoryTable = styled('table')(({ theme }) => ({
    width: '100%',
    borderCollapse: 'collapse',
    color: 'var(--text-secondary)',

    '& > thead > tr > th': {
        padding: '1.25rem 0',
        fontWeight: '500',
        color: 'var(--primary-main)'
    },

    '& > th,td': {
        padding: '1rem .5rem',
        textAlign: 'center',
        margin: '0'
    },

    'tbody tr': {
        backgroundColor: 'var(--primary-bg-soft)'
    },

    '.origin-type': {
        textTransform: 'capitalize'
    },

    '.date': {
        color: theme.palette.text.secondary
    },

    'tr td:first-of-type': {
        paddingLeft: '24px'
    },

    'tr td:last-of-type': {
        paddingRight: '24px'
    },

}))

const ItemContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',

    'img': {
        height: '48px',
        width: '48px',
        marginRight: '1rem'
    },

}))

const StatusTextBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    'svg': {
        marginRight: '.5rem',
    }
}))

const StatusSuccessTextBox = styled(Box)(({ theme }) => ({
    color: theme.palette.success.light,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    'svg': {
        marginRight: '.5rem',
        fill: theme.palette.success.light
    }
}))

const StatusErrorTextBox = styled(Box)(({ theme }) => ({
    color: theme.palette.error.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    'svg': {
        marginRight: '.25rem',
        fill: theme.palette.error.main
    }
}))


type RedeemableInventoryTableProps = {
    value: number,
    index: number,
    onRedeemItemClick: (item: UserItem) => void
}

const AllInventoryTable = ({ value, index, onRedeemItemClick}: RedeemableInventoryTableProps) => {

    const [page, setPage] = useState(1);
    const [maxPage, setMaxPage] = useState(1);

    const [loadingInventory, setLoadingInventory] = useState(true);
    const inventory = useSelector(selectInventory);
    const dispatch = useDispatch();

    const [showNothing, setShowNothing] = useState(false);

    const changePage = (event: ChangeEvent<unknown>, value: number) => {
        if(!isFinite(value) || value === null) return;
        setPage(value);
    }

    useEffect(() => {
        if (value === index) {
            setLoadingInventory(true);
            dispatch(setUserInventory([]));
            fetch(`${API_URL}/user/inventory?page=${page}&showNothing=${showNothing}`, {
                method: 'GET',
                credentials: 'include'
            }).then(res => {
                return res.json()
            }).then(data => {
                if (data && !data.error) {
                    dispatch(setUserInventory(data.data))
                    setMaxPage(data.pages)
                    setLoadingInventory(false)
                } else if (data.error) {
    
                }
            })
        } 
    }, [value, page, showNothing, index, dispatch])

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`inventory-tab-${index}`}
        >
            {value === index && (
                <Box>
                    {loadingInventory ? (
                        <LoadingCircle loadingText='Loading Inventory' />
                    ) : inventory && inventory.length > 0 ? (
                        <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                        >
                                <Box
                                    width="100%"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="flex-end"
                                    sx={{
                                        padding: '0.5rem 0 0rem 1rem'
                                    }}
                                >
                                    <FormControlLabel control={<Checkbox color="primary" checked={showNothing} onChange={(e) => setShowNothing(e.target.checked)} />}
                                        label="Show Nothing"
                                        labelPlacement="end"
                                    />
                                </Box>
                            <UserInventoryTable>
                                <thead>
                                    <tr>
                                        <th>Item</th>
                                        <th>Date</th>
                                        <th>Origin</th>
                                        <th>Status</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {inventory.map((item, index) => (
                                        <tr key={`all-inventory-${index}`}>
                                            <td>
                                                <ItemContainer>
                                                    <img src={isAPIImage(item.item.image)} alt="" />
                                                    <Typography>{item.item.name}</Typography>
                                                </ItemContainer></td>
                                            <td className='date'>{formatDate(item.acquire_date, "time")}</td>
                                            <td>
                                                {item.origin === 'case' ? (
                                                    <ItemContainer alignItems="center" justifyContent="center">
                                                        <img src={isAPIImage(item.origin_image)} alt="" />
                                                        <Typography>{item.origin_name}</Typography>
                                                    </ItemContainer>
                                                ) : item.origin === 'giveaway_case' ? (
                                                    <ItemContainer alignItems="center" justifyContent="center">
                                                        <img src={isAPIImage(item.origin_image)} alt="" />
                                                        <Typography>{item.origin_name} (Giveaway)</Typography>
                                                    </ItemContainer>
                                                ) : item.origin === "item_gift" ? (
                                                    <Typography>Item Gift</Typography>                       
                                                ) : (
                                                    <Typography className="origin-type">
                                                        {item.origin}
                                                     </Typography>
                                                )}

                                            </td>
                                            <td>
                                                {item.status === EUserItemStatus.REDEEMED ? (
                                                    <StatusTextBox>
                                                        <TimeIcon />
                                                        <Typography>
                                                            Processing
                                                        </Typography>
                                                    </StatusTextBox>
                                                ) : item.status === EUserItemStatus.RECEIVED ? (
                                                    <StatusSuccessTextBox>
                                                        <CheckIcon />
                                                        <Typography>
                                                            Received
                                                        </Typography>
                                                    </StatusSuccessTextBox>
                                                ) : item.status === EUserItemStatus.CANCELED ? (
                                                    <StatusErrorTextBox>
                                                        <CloseIcon />
                                                        <Typography>
                                                            Canceled
                                                        </Typography>
                                                    </StatusErrorTextBox>
                                                ) : (
                                                    <StatusTextBox>
                                                        <Typography>
                                                        {item.item.type !== EItemType.NOTHING ? (
                                                          <>{EUserItemStatusDescription[EUserItemStatus[item.status] as keyof typeof EUserItemStatusDescription ]}</>
                                                        ) : (
                                                            "Not Redeemable"
                                                        )}         
                                                        </Typography>
                                                    </StatusTextBox>
                                                )}
                                            </td>
                                            {item.item.type !== EItemType.NOTHING && item.status === EUserItemStatus.REDEEMABLE && item.item.redeemable ? (
                                                <td>
                                                    <Button variant="contained" onClick={() => onRedeemItemClick(item)}>
                                                        Redeem
                                                    </Button>
                                                </td>
                                            ) : <td></td>}
                                        </tr>
                                    ))}

                                </tbody>

                            </UserInventoryTable>
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                sx={{
                                    backgroundColor: 'var(--primary-bg-dark)',
                                    width: '100%',
                                    padding: '1rem',
                                    borderTop: '1px solid transparent',
                                    borderColor: 'var(--primary-border)'
                                }}
                            >
                                <Pagination
                                    count={maxPage}
                                    page={page}
                                    onChange={changePage}
                                    color="primary"
                                />
                            </Box>

                        </Box>
                    ) : (
                        <Box sx={{ padding: '1rem' }}>
                            <Typography>Your Inventory is empty :(</Typography>
                        </Box>
                    )}
                </Box>
            )}
        </div>
    )
}

const RedeemableInventoryTable = ({ value, index, onRedeemItemClick}: RedeemableInventoryTableProps) => {

    const [page, setPage] = useState(1);
    const [maxPage, setMaxPage] = useState(1);

    const [loadingInventory, setLoadingInventory] = useState(true)
    const inventory = useSelector(selectInventory);
    const dispatch = useDispatch();

    const changePage = (event: ChangeEvent<unknown>, value: number) => {
        if(!isFinite(value) || value === null) return;
        setPage(value);
    }

    useEffect(() => {
        if (value === index) {
            setLoadingInventory(true);
            dispatch(setUserInventory([]));
            fetch(`${API_URL}/user/inventory?page=${page}&status=redeemable`, {
                method: 'GET',
                credentials: 'include'
            }).then(res => {
                return res.json();
            }).then(data => {
                if (data && !data.error) {
                    dispatch(setUserInventory(data.data));
                    setMaxPage(data.pages);
                    setLoadingInventory(false);
                } else if (data.error) {
    
                }
            })
        }
    }, [value, page, index, dispatch])


    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`inventory-tab-${index}`}
        >
            {value === index && (
                <Box>
                    {loadingInventory ? (
                        <LoadingCircle loadingText='Loading Inventory' />
                    ) : inventory && inventory.length > 0 ? (
                        <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <UserInventoryTable>
                                <thead>
                                    <tr>
                                        <th>Item</th>
                                        <th>Date</th>
                                        <th>Origin</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {inventory.map((item, index) => (
                                        <tr key={`redeemable-inventory-${index}`}>
                                            <td>
                                                <ItemContainer>
                                                    <img src={isAPIImage(item.item.image)} alt="" />
                                                    <Typography>{item.item.name}</Typography>
                                                </ItemContainer>
                                            </td>
                                            <td className='date'>{formatDate(item.acquire_date, "time")}</td>
                                            <td>
                                                {item.origin === 'case' ? (
                                                    <ItemContainer alignItems="center" justifyContent="center">
                                                        <img src={isAPIImage(item.origin_image)} alt="" />
                                                        <Typography>{item.origin_name}</Typography>
                                                    </ItemContainer>
                                                ) : item.origin === 'giveaway_case' ? (
                                                    <ItemContainer alignItems="center" justifyContent="center">
                                                        <img src={isAPIImage(item.origin_image)} alt="" />
                                                        <Typography>{item.origin_name} (Giveaway)</Typography>
                                                    </ItemContainer>
                                                ) : item.origin === "item_gift" ? (
                                                    <Typography className="origin-type">
                                                        Gift
                                                    </Typography>
                                                ) : (
                                                    <Typography className="origin-type">
                                                        {item.origin}
                                                    </Typography>
                                                )}

                                            </td>
                                            {item.item.type !== EItemType.NOTHING && item.status === EUserItemStatus.REDEEMABLE && item.item.redeemable ? (
                                                <td>
                                                    <Button variant="contained" onClick={() => onRedeemItemClick(item)}>
                                                        Redeem
                                                    </Button>
                                                </td>
                                            ) : <td></td>}
                                        </tr>
                                    ))}

                                </tbody>

                            </UserInventoryTable>
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                sx={{
                                    backgroundColor: 'var(--primary-bg-dark)',
                                    width: '100%',
                                    padding: '1rem',
                                    borderTop: '2px solid transparent',
                                    borderColor: 'rgba(72, 64, 80)'
                                }}
                            >
                                <Pagination
                                    count={maxPage}
                                    page={page}
                                    onChange={changePage}
                                    color="primary"
                                />
                            </Box>
                        </Box>
                    ) : (                        
                    <Box sx={{ padding: '1rem' }}>
                        <Typography>You don't have any redeemable Items</Typography>
                    </Box>
                    )}
                </Box>
            )}
        </div>
    );
}

const ReceivedInventoryTable = (props: any) => {
    const { children, value, index, ...other } = props;

    const [page, setPage] = useState(1);
    const [maxPage, setMaxPage] = useState(1);
    const [loadingInventory, setLoadingInventory] = useState(true);

    const inventory = useSelector(selectInventory);
    const dispatch = useDispatch();


    const changePage = (event: ChangeEvent<unknown>, value: number) => {
        if(!isFinite(value) || value === null) return;
        setPage(value);
    }

    useEffect(() => {
        if (value === index) {
            setLoadingInventory(true);
            dispatch(setUserInventory([]));
            fetch(`${API_URL}/user/inventory?page=${page}&status=received`, {
                method: 'GET',
                credentials: 'include'
            }).then(res => {
                return res.json();
            }).then(data => {
                if (data && !data.error) {
                    dispatch(setUserInventory(data.data))
                    setMaxPage(data.pages);
                    setLoadingInventory(false);
                } else if (data.error) {
    
                }
            })
        }
    }, [value, page, index, dispatch])


    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`inventory-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {loadingInventory ? (
                        <LoadingCircle loadingText='Loading Inventory' />
                    ) : inventory && inventory.length > 0 ? (
                        <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <UserInventoryTable>
                                <thead>
                                    <tr>
                                        <th>Item</th>
                                        <th>Date</th>
                                        <th>Origin</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {inventory.map((item, index) => (
                                        <tr key={`received-inventory-${index}`}>
                                            <td>
                                                <ItemContainer>
                                                    <img src={isAPIImage(item.item.image)} alt="" />
                                                    <Typography>{item.item.name}</Typography>
                                                </ItemContainer></td>
                                            <td className='date'>{formatDate(item.acquire_date, "time")}</td>
                                            <td>
                                                {item.origin === 'case' ? (
                                                    <ItemContainer alignItems="center" justifyContent="center">
                                                        <img src={isAPIImage(item.origin_image)} alt="" />
                                                        <Typography>{item.origin_name}</Typography>
                                                    </ItemContainer>
                                                ) : item.origin === 'giveaway_case' ? (
                                                    <ItemContainer alignItems="center" justifyContent="center">
                                                        <img src={isAPIImage(item.origin_image)} alt="" />
                                                        <Typography>{item.origin_name} (Giveaway)</Typography>
                                                    </ItemContainer>
                                                ) : item.origin === "item_gift" ? (
                                                    <Typography>Item Gift</Typography>                       
                                                ) : (
                                                    <Typography className="origin-type">
                                                        {item.origin}
                                                    </Typography>
                                                )}

                                            </td>
                                            <td>Received</td>
                                        </tr>
                                    ))}

                                </tbody>

                            </UserInventoryTable>
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                sx={{
                                    backgroundColor: 'var(--primary-bg-dark)',
                                    width: '100%',
                                    padding: '1rem',
                                    borderTop: '2px solid transparent',
                                    borderColor: 'rgba(72, 64, 80)'
                                }}
                            >
                                <Pagination
                                    count={maxPage}
                                    page={page}
                                    onChange={changePage}
                                    color="primary"
                                />
                            </Box>
                        </Box>
                    ) : (
                            <Box sx={{ padding: '1rem' }}>
                                <Typography>Items you have received will shop up here</Typography>
                            </Box>
                    )}
                </Box>
            )}
        </div>
    );
}

const RedeemedInventoryTable = (props: any) => {
    const { children, value, index, ...other } = props;

    const [page, setPage] = useState(1);
    const [maxPage, setMaxPage] = useState(1);

    const [loadingInventory, setLoadingInventory] = useState(true);
    const inventory = useSelector(selectInventory);
    const dispatch = useDispatch();

    const changePage = (event: ChangeEvent<unknown>, value: number) => {
        if(!isFinite(value) || value === null) return;
        setPage(value);
    }

    useEffect(() => {
        if (value === index) {
            setLoadingInventory(true)
            dispatch(setUserInventory([]));
            fetch(`${API_URL}/user/inventory?page=${page}&status=redeemed`, {
                method: 'GET',
                credentials: 'include'
            }).then(res => {
                return res.json();
            }).then(data => {
                if (data && !data.error) {
                    dispatch(setUserInventory(data.data))
                    setMaxPage(data.pages);
                    setLoadingInventory(false);
                    
                } else if (data.error) {

                }
            })
        }
    }, [value, page, index, dispatch])


    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`inventory-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {loadingInventory ? (
                        <LoadingCircle loadingText='Loading Inventory' />
                    ) : inventory && inventory.length > 0 ? (
                        <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <UserInventoryTable>
                                <thead>
                                    <tr>
                                        <th>Item</th>
                                        <th>Date</th>
                                        <th>Origin</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {inventory.map((item, index) => (
                                        <tr key={`redeemed-inventory-${index}`}>
                                            <td>
                                                <ItemContainer>
                                                    <img src={isAPIImage(item.item.image)} alt="" />
                                                    <Typography>{item.item.name}</Typography>
                                                </ItemContainer></td>
                                            <td className='date'>{formatDate(item.acquire_date, "time")}</td>

                                            <td>
                                                {item.origin === 'case' ? (
                                                    <ItemContainer alignItems="center" justifyContent="center">
                                                        <img src={isAPIImage(item.origin_image)} alt="" />
                                                        <Typography >{item.origin_name}</Typography>
                                                    </ItemContainer>
                                                ) : item.origin === 'giveaway_case' ? (
                                                    <ItemContainer alignItems="center" justifyContent="center">
                                                        <img src={isAPIImage(item.origin_image)} alt="" />
                                                        <Typography>{item.origin_name} (Giveaway)</Typography>
                                                    </ItemContainer>
                                                ): item.origin === "item_gift" ? (
                                                    <Typography>Item Gift</Typography>                       
                                                ) : (
                                                    <Typography className="origin-type">
                                                        {item.origin}
                                                    </Typography>
                                                )}

                                            </td>
                                            <td>
                                                <StatusTextBox>
                                                    <TimeIcon />
                                                    <Typography>
                                                        Processing
                                                    </Typography>
                                                </StatusTextBox>
                                            </td>
                                        </tr>
                                    ))}

                                </tbody>

                            </UserInventoryTable>
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                sx={{
                                    backgroundColor: 'var(--primary-bg-dark)',
                                    width: '100%',
                                    padding: '1rem',
                                    borderTop: '2px solid transparent',
                                    borderColor: 'rgba(72, 64, 80)'
                                }}
                            >
                                <Pagination
                                    count={maxPage}
                                    page={page}
                                    onChange={changePage}
                                    color="primary"
                                />
                            </Box>
                        </Box>
                    ) : (
                            <Box sx={{ padding: '1rem' }}>
                                <Typography>Your redeemed Items will show up here</Typography>
                            </Box>
                    )}
                </Box>
            )}
        </div>
    );
}

const CanceledInventoryTable = (props: any) => {
    const { children, value, index, ...other } = props;

    const [page, setPage] = useState(1);
    const [maxPage, setMaxPage] = useState(1);

    const [loadingInventory, setLoadingInventory] = useState(true);
    const inventory = useSelector(selectInventory);
    const dispatch = useDispatch();

    const changePage = (event: ChangeEvent<unknown>, value: number) => {
        if(!isFinite(value) || value === null) return;
        setPage(value);
    }

    useEffect(() => {
        if (value === index) {
            setLoadingInventory(true);
            dispatch(setUserInventory([]))
            fetch(`${API_URL}/user/inventory?page=${page}&status=canceled`, {
                method: 'GET',
                credentials: 'include'
            }).then(res => {
                return res.json();
            }).then(data => {
                if (data && !data.error) {
                    dispatch(setUserInventory(data.data))
                    setLoadingInventory(false);
                    setMaxPage(data.pages);
                } else if (data.error) {
    
                }
            })
        }
    }, [value, page, index, dispatch])

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`inventory-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {loadingInventory ? (
                        <LoadingCircle loadingText='Loading Inventory' />
                    ) : inventory && inventory.length > 0 ? (
                        <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <UserInventoryTable>
                                <thead>
                                    <tr>
                                        <th>Item</th>
                                        <th>Date</th>
                                        <th>Origin</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {inventory.map((item, index) => (
                                        <tr key={`canceled-inventory-${index}`}>
                                            <td>
                                                <ItemContainer>
                                                    <img src={isAPIImage(item.item.image)} alt="" />
                                                    <Typography>{item.item.name}</Typography>
                                                </ItemContainer></td>
                                            <td className='date'>{formatDate(item.acquire_date, "time")}</td>
                                            <td>
                                                {item.origin === 'case' ? (
                                                    <ItemContainer alignItems="center" justifyContent="center">
                                                        <img src={isAPIImage(item.origin_image)} alt="" />
                                                        <Typography>{item.origin_name}</Typography>
                                                    </ItemContainer>
                                                ) : item.origin === 'giveaway_case' ? (
                                                    <ItemContainer alignItems="center" justifyContent="center">
                                                        <img src={isAPIImage(item.origin_image)} alt="" />
                                                        <Typography>{item.origin_name} (Giveaway)</Typography>
                                                    </ItemContainer>
                                                ) : item.origin === "item_gift" ? (
                                                    <Typography>Item Gift</Typography>
                                                ) : (
                                                    <Typography className="origin-type">
                                                        {item.origin}
                                                    </Typography>
                                                )}

                                            </td>
                                            <td>
                                                Canceled
                                            </td>
                                        </tr>
                                    ))}

                                </tbody>

                            </UserInventoryTable>
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                sx={{
                                    backgroundColor: 'var(--primary-bg-dark)',
                                    width: '100%',
                                    padding: '1rem',
                                    borderTop: '2px solid transparent',
                                    borderColor: 'rgba(72, 64, 80)'
                                }}
                            >
                                <Pagination
                                    count={maxPage}
                                    page={page}
                                    onChange={changePage}
                                    color="primary"
                                />
                            </Box>
                        </Box>
                    ) : (
                        <Box sx={{ padding: '1rem' }}>
                            <Typography>Your canceled Items will show up here</Typography>
                        </Box>
                    )}
                </Box>
            )}
        </div>
    );
}

const InventoryTab = () => {

    const dispatch = useDispatch();

    const [ItemRedemptionModalOpen, setItemRedemptionModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState<UserItem | null>(null);

    const [value, setValue] = useState(0);

    const changeTab = (event: React.SyntheticEvent<Element, Event>, newValue: any) => {
        setValue(newValue);
    }

    const redeemItem = async (userItemId: string) => {

        try { 
            const data: any = await fetchAPI('/item/redeem', 'POST', {
                useritemid: userItemId
            })
    
            if(data.success) {
                createSuccessToast(data.success);
                dispatch(updateItemInInventory(data.data))
            }
    
            return true;
        } catch (e) {
            return false;
        }
    }
    

    
    const onRedeemItemClick = (userItem: UserItem) => {
        if (!userItem.item.redeemable) return;

        if (userItem.item.type === EItemType.BONUS_HUNT_TICKET) {
            // Open Bonus Hunt Ticket Modal
            setSelectedItem(userItem)
            setItemRedemptionModalOpen(true);
            return
        }

        redeemItem(userItem._id)
    }


    return (
        <React.Fragment>
        <Box>
            <Tabs value={value} onChange={changeTab} sx={{ padding: '1rem', borderBottom: '1px solid var(--primary-border)' }}>
                <Tab disableRipple label="All" sx={{textTransform: 'capitalize'}}/>
                <Tab disableRipple label="Redeemable" sx={{textTransform: 'capitalize'}} />
                <Tab disableRipple label="Redeemed" sx={{textTransform: 'capitalize'}} />
                <Tab disableRipple label="Received" sx={{textTransform: 'capitalize'}} />
                <Tab disableRipple label="Canceled" sx={{textTransform: 'capitalize'}} />
            </Tabs>
            <AllInventoryTable value={value} index={0} onRedeemItemClick={onRedeemItemClick} />
            <RedeemableInventoryTable value={value} index={1} onRedeemItemClick={onRedeemItemClick}  />
            <RedeemedInventoryTable value={value} index={2} />
            <ReceivedInventoryTable value={value} index={3} />
            <CanceledInventoryTable value={value} index={4} />
        </Box>
        <ItemRedemptionModal open={ItemRedemptionModalOpen} handleClose={() => setItemRedemptionModalOpen(false)} item={selectedItem}/>
        </React.Fragment>
    )
}
export default InventoryTab