import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    rollbit_giveaway: {
        active: false,
        time_until_next: 0,
        eligible_viewers: [],
        recent_wins: []
    }
}

export const rollbitGiveawaySlice = createSlice({
    name: 'rollbit_giveaway',
    initialState,
    reducers: {
        setRollbitGiveawayInfo: (state, action) => {
            state.rollbit_giveaway = {...state.rollbit_giveaway, ...action.payload}
        },
        setRollbitGiveawayEligibleViewers: (state, action) => {
            state.rollbit_giveaway.eligible_viewers = action.payload
        },
        setRollbitGiveawayActive: (state, action) => {
            state.rollbit_giveaway.active = action.payload
        },
        setRollbitGiveawayTimeUntilNext: (state, action) => {
            state.rollbit_giveaway.time_until_next = action.payload
        },
        setRollbitGiveawayRecentWins: (state, action) => {
            state.rollbit_giveaway.recent_wins = action.payload
        },
        addRollbitGiveawayRecentWin: (state, action) => {
            state.rollbit_giveaway.recent_wins = [action.payload, ...state.rollbit_giveaway.recent_wins || []]
        },
    }
})

export const { 
    setRollbitGiveawayActive,
    setRollbitGiveawayEligibleViewers, 
    setRollbitGiveawayInfo, 
    setRollbitGiveawayRecentWins, 
    setRollbitGiveawayTimeUntilNext, 
    addRollbitGiveawayRecentWin } = rollbitGiveawaySlice.actions

export const selectRollbitGiveawayInfo = state => state.rollbit_giveaway.rollbit_giveaway

export default rollbitGiveawaySlice.reducer