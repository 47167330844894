import React from 'react'

import { Route, Link, Routes, useLocation, Navigate } from 'react-router-dom'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

import CSGOGiveawayPage from './CSGOGiveawaysPage';

import { styled } from '@mui/material'

import { ReactComponent as Ticket } from '../../icons/ticket.svg';
import { useMediaQuery } from '@mui/material';
import GiveawayIndexPage from './GiveawayIndexPage';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { ReactComponent as CSGO } from '../../icons/csgo.svg'
import RafflesPage from '../Raffle/RafflesPage';

const paths = {
    csgo: '/giveaways/csgo',
    casino: '/giveaways/casino',
    raffle: '/giveaways/raffles'
}

const TabButtonContainer = styled(Box)(( { theme }) => ({
    '.active': {
        backgroundColor: 'var(--primary-bg-highlight)',
    }
}))

const GiveawayPageButton = styled(Button)<{ to?: string }>(( { theme }) => ({
    margin: '0 .5rem',
    backgroundColor: 'var(--primary-bg-soft)',
    boxShadow: '0 0 6px rgba(16, 16, 24, .75)',
    padding: '16px 20px',
    borderRadius: "1rem",
    textTransform: 'none',
    transition: 'all .3s ease-in-out',
    color: 'var(--text-primary) !important',
    
    '&:hover': {
        backgroundColor: 'var(--primary-bg-dark)'
    },

    'svg': {
        height: '32px',
        width: 'auto',
        fill: 'var(--text-primary)',

    },

    '.csgo-image': {
        height: '32px',
        width: '32px',    
        filter: 'invert(99%) sepia(7%) saturate(1%) hue-rotate(152deg) brightness(107%) contrast(100%)'
    },
}))

const GiveawayPageSmallButton = styled(Button)<{ to?: string }>(( { theme }) => ({
    margin: '0 .5rem',
    backgroundColor: 'var(--primary-bg-soft)',
    boxShadow: '0 0 6px rgba(16, 16, 24, .75)',
    padding: '14px 20px',
    borderRadius: 15,
    border: '2px solid transparent',
    borderColor: 'var(--primary-border)',
    textTransform: 'none',

    ':hover': {
        backgroundColor: `${theme.palette.primary.main}`
    },

    'svg': {
        height: '32px',
        width: 'auto',
    },

    '.casino500-image': {
        height: '32px',
        width: '72px',        
    },

    '.csgo-image': {
        height: '32px',
        width: '32px',    
        filter: 'invert(99%) sepia(7%) saturate(1%) hue-rotate(152deg) brightness(107%) contrast(100%)'
    },

    '.MuiButton-startIcon': {
        margin: '0'
    }
}))

const GiveawayPage = () => {

    const isSmallDevice = useMediaQuery('(max-width: 900px)');

    let location = useLocation();

    return (
        <Box sx={{ mt: '2rem' }}>
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                sx={{marginBottom: '2rem'}}
            >
                <TabButtonContainer
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ marginTop: '.5rem' }}
                >
                    {isSmallDevice ? (
                        <>
                            <GiveawayPageSmallButton
                                variant="contained"
                                LinkComponent={Link}
                                to={paths.csgo}
                                className={location.pathname.includes(paths.csgo) ? 'active' : ''}
                                startIcon={<CSGO />}
                            />
                    
                            <GiveawayPageSmallButton
                                variant="contained"
                                LinkComponent={Link}
                                to={paths.casino}
                                className={location.pathname.includes(paths.casino) ? 'active' : ''}
                                startIcon={<AttachMoneyIcon />}
                            />
           
                            <GiveawayPageSmallButton
                                variant="contained"
                                LinkComponent={Link}
                                to={paths.raffle}
                                className={location.pathname.includes(paths.raffle) ? 'active' : ''}
                                startIcon={<Ticket />}
                            />
                        </>
                    ) : (
                            <>
                                <GiveawayPageButton
                                    disableRipple
                                    variant="contained"
                                    LinkComponent={Link}
                                    to={paths.csgo}
                                    className={location.pathname.includes(paths.csgo) ? 'active' : ''}
                                    startIcon={<CSGO />}
                                >
                                    {isSmallDevice ? null : "CS2 Giveaways"}

                                </GiveawayPageButton>
                                <GiveawayPageButton
                                    disableRipple
                                    variant="contained"
                                    LinkComponent={Link}
                                    to={paths.casino}
                                    className={location.pathname.includes(paths.casino) ? 'active' : ''}
                                    startIcon={<AttachMoneyIcon />}
                                >
                                    {isSmallDevice ? "" : "Giveaways"}
                                </GiveawayPageButton>
                                <GiveawayPageButton
                                    disableRipple
                                    variant="contained"
                                    LinkComponent={Link}
                                    to={paths.raffle}
                                    className={location.pathname.includes(paths.raffle) ? 'active' : ''}
                                    startIcon={<Ticket />}
                                >
                                    {isSmallDevice ? "" : "Raffles"}
                                </GiveawayPageButton>
                            </>   
                    )}
                    

            </TabButtonContainer>
            </Box>
            <Routes>
                <Route path="/" element={<Navigate replace to={paths.raffle} />} />
                <Route path="/casino/*" element={<GiveawayIndexPage />} />
                <Route path="/csgo" element={<CSGOGiveawayPage />} />
                <Route path="/raffles" element={<RafflesPage />} />
            </Routes>
            
        </Box>
    )
}

export default GiveawayPage
