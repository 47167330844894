import React, { useState } from 'react'
import { Box, Button, Skeleton, SxProps, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Theme, Typography } from '@mui/material'
import useSWR from 'swr'
import { fetcher, formatDate } from '../../utils'
import { BonusHunt, BonusHuntSortOption } from './types'
import { useNavigate } from 'react-router-dom'
import SelectStreamer from './SelectStreamer'
import SelectDate from './SelectDate'

const StatComponent = ({ label, value, sx }: { label: string, value: string | number | JSX.Element, sx?: SxProps<Theme> }) => (
  <Box display="flex" flexDirection="column" gap=".25rem" width="100%" 
    sx={{ 
        p: '1rem', 
        bgcolor: 'var(--primary-bg-soft)', 
        borderRadius: '.5rem', 
        border: '2px solid var(--primary-border)',
        boxShadow: '0 0 8px rgba(0,0,0,.1)',
        ...sx 
    }}
  >
    <Typography 
      sx={{ 
        fontWeight: 'bold',         
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden', 
      }}
    >
          {value}
    </Typography>
    <Typography sx={{ color: 'var(--text-primary)', opacity: '.5', fontSize: '.75em', fontWeight: 'bold', textTransform: 'uppercase' }}>{label}</Typography>

  </Box>
)

const BonusHuntStats = ({selectedStreamerId, date }: { selectedStreamerId?: string, date?: string}) => {

  const { data, isLoading } = useSWR<{ profit: number, totalCost: number, amountOfHunts: number}>(`/hunts/stats?${selectedStreamerId ? `streamerId=${selectedStreamerId}` : ""}${date ? `&date=${date}` : ""}`, fetcher)

  return (
    <Box display="grid" gap="2rem" 
      sx={{ 
        gridTemplateColumns: { 
          xs: `repeat(2, minmax(0, 1fr))`, 
          sm: `repeat(3, minmax(0, 1fr))`,
          md: `repeat(3, minmax(0, 1fr))`,
          lg: `repeat(3, minmax(0, 1fr))`
        }
     }}>
      {isLoading ? (
        <>
         <StatComponent label="Bonus Hunts" value={<Skeleton variant="text" width="32px" />} />
         <StatComponent label="Total Cost" value={<Skeleton variant="text" width="48px"  />} />
         <StatComponent label="Profit" value={<Skeleton variant="text" width="64px"  />} />
        </>
      ) : data && (
        <>
         <StatComponent label="Bonus Hunts" value={data.amountOfHunts} />
         <StatComponent label="Total Cost" value={`$${Intl.NumberFormat("en-US").format(data.totalCost)}`} />
         <StatComponent label="Profit" value={`$${Intl.NumberFormat("en-US").format(data.profit)}`} />
        </>
      )}
   
  </Box>
  )
}

export const BonusHuntTable = ({ data, isLoading, onSortChange, sortBy, navigatePath }: { data?: { data: BonusHunt[] }, isLoading: boolean, onSortChange: (sortOption: BonusHuntSortOption) => void, sortBy: { key: BonusHuntSortOption, order: "asc" | "desc" }, navigatePath: string }) => {
  
  const navigate = useNavigate()
  
  return (
    <TableContainer sx={{ backgroundColor: 'var(--primary-bg-soft)', borderRadius: '.5rem', border: '1px solid var(--primary-border)'}}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell 
                sx={{bgcolor: 'var(--primary-bg-dark)', borderColor: 'var(--primary-border)', borderRadius: '.5rem 0 0 .5rem', fontWeight: 'bold', textTransform: 'uppercase' }}
                sortDirection={sortBy.key === BonusHuntSortOption.ID ? sortBy.order : false}
              >
              <TableSortLabel 
                active={sortBy.key === BonusHuntSortOption.ID}
                direction={sortBy.key === BonusHuntSortOption.ID ? sortBy.order : "desc"}
                onClick={() => onSortChange(BonusHuntSortOption.ID)}
              >
                ID
              </TableSortLabel>
              
            </TableCell>
            <TableCell sx={{bgcolor: 'var(--primary-bg-dark)', borderColor: 'var(--primary-border)', fontWeight: 'bold', textTransform: 'uppercase' }}>
              Streamer
            </TableCell>
            <TableCell 
              sx={{bgcolor: 'var(--primary-bg-dark)', borderColor: 'var(--primary-border)', fontWeight: 'bold', textTransform: 'uppercase' }}
              sortDirection={sortBy.key === BonusHuntSortOption.DATE ? sortBy.order : false}
            >

              <TableSortLabel 
                active={sortBy.key === BonusHuntSortOption.DATE}
                direction={sortBy.key === BonusHuntSortOption.DATE ? sortBy.order : "desc"}
                onClick={() => onSortChange(BonusHuntSortOption.DATE)}
              >
                Date
              </TableSortLabel>
            </TableCell>
            <TableCell 
              sx={{bgcolor: 'var(--primary-bg-dark)', borderColor: 'var(--primary-border)', fontWeight: 'bold', textTransform: 'uppercase' }}
              sortDirection={sortBy.key === BonusHuntSortOption.START_COST ? sortBy.order : false}
            >
              <TableSortLabel 
                active={sortBy.key === BonusHuntSortOption.START_COST}
                direction={sortBy.key === BonusHuntSortOption.START_COST ? sortBy.order : "desc"}
                onClick={() => onSortChange(BonusHuntSortOption.START_COST)}
              >
                Start Cost
              </TableSortLabel>
            </TableCell>
            <TableCell 
              sx={{bgcolor: 'var(--primary-bg-dark)', borderColor: 'var(--primary-border)', fontWeight: 'bold', textTransform: 'uppercase' }}
              sortDirection={sortBy.key === BonusHuntSortOption.BONUSES ? sortBy.order : false}
            >
              <TableSortLabel 
                active={sortBy.key === BonusHuntSortOption.BONUSES}
                direction={sortBy.key === BonusHuntSortOption.BONUSES ? sortBy.order : "desc"}
                onClick={() => onSortChange(BonusHuntSortOption.BONUSES)}
              >
                Bonuses
              </TableSortLabel>
            </TableCell>
            <TableCell 
              sx={{bgcolor: 'var(--primary-bg-dark)', borderColor: 'var(--primary-border)', fontWeight: 'bold', textTransform: 'uppercase' }}
              sortDirection={sortBy.key === BonusHuntSortOption.BIGGEST_WIN ? sortBy.order : false}
            >
              <TableSortLabel 
                active={sortBy.key === BonusHuntSortOption.BIGGEST_WIN}
                direction={sortBy.key === BonusHuntSortOption.BIGGEST_WIN ? sortBy.order : "desc"}
                onClick={() => onSortChange(BonusHuntSortOption.BIGGEST_WIN)}
              >
                Biggest Win
              </TableSortLabel>
            </TableCell>
            <TableCell 
              sx={{bgcolor: 'var(--primary-bg-dark)', borderColor: 'var(--primary-border)', fontWeight: 'bold', textTransform: 'uppercase' }}
              sortDirection={sortBy.key === BonusHuntSortOption.HIGHEST_X ? sortBy.order : false}
            >
              <TableSortLabel 
                active={sortBy.key === BonusHuntSortOption.HIGHEST_X}
                direction={sortBy.key === BonusHuntSortOption.HIGHEST_X ? sortBy.order : "desc"}
                onClick={() => onSortChange(BonusHuntSortOption.HIGHEST_X)}
              >
                Highest X
              </TableSortLabel>
            </TableCell>
            <TableCell 
              sx={{bgcolor: 'var(--primary-bg-dark)', borderRadius: '0 .5rem .5rem 0', borderColor: 'var(--primary-border)', fontWeight: 'bold', textTransform: 'uppercase', textAlign: 'end' }}
              sortDirection={sortBy.key === BonusHuntSortOption.PROFIT ? sortBy.order : false}
            >
              <TableSortLabel 
                active={sortBy.key === BonusHuntSortOption.PROFIT}
                direction={sortBy.key === BonusHuntSortOption.PROFIT ? sortBy.order : "desc"}
                onClick={() => onSortChange(BonusHuntSortOption.PROFIT)}
              >
                Profit
              </TableSortLabel>
              </TableCell>
          </TableRow>
        </TableHead>
          <TableBody>
            {isLoading ? (
              <>
                <TableRow>
                  <TableCell sx={{ borderColor: 'var(--primary-border)' }}>
                    <Skeleton variant="rectangular" width={48} height={24} />
                  </TableCell>
                  <TableCell sx={{ borderColor: 'var(--primary-border)' }}>
                    <Skeleton variant="rectangular" width={96} height={24} />
                  </TableCell>
                  <TableCell sx={{ borderColor: 'var(--primary-border)' }}>
                    <Skeleton variant="rectangular" width={96} height={24} />
                  </TableCell>
                  <TableCell sx={{ borderColor: 'var(--primary-border)' }}>
                    <Skeleton variant="rectangular" width={48} height={24} />
                  </TableCell>
                  <TableCell sx={{ borderColor: 'var(--primary-border)' }}>
                    <Skeleton variant="rectangular" width={48} height={24} />
                  </TableCell>
                  <TableCell sx={{ borderColor: 'var(--primary-border)' }}>
                    <Skeleton variant="rectangular" width={48} height={24} />
                  </TableCell>
                  <TableCell align="right" sx={{ borderColor: 'var(--primary-border)' }}>
                    <Skeleton sx={{ ml: 'auto' }} variant="rectangular" width={48} height={24} />
                  </TableCell>
                </TableRow>
              </>
            ) : !data || data.data.length === 0 ? (
              <TableRow>
                <TableCell sx={{ borderColor: 'var(--primary-border)', textAlign: 'center' , fontStyle: 'italic' }} colSpan={7}>No bonus Hunts found</TableCell>
              </TableRow>
            ) : data.data.filter((hunt) => hunt.streamer).map((bonusHunt) => (
              <TableRow 
                key={`hunt-${bonusHunt.huntId}`} 
                sx={{ 
                  cursor: 'pointer', 
                  '&:nth-of-type(even)': {
                    "td": {
                      backgroundColor: 'var(--primary-bg-dark)',

                    },
                    
                  },                     
                  transition: 'background-color .2s',
                  '&:hover': { 
                    backgroundColor: 'var(--primary-bg-light)',  
                  }
                }} 
                onClick={() => navigate(`${navigatePath}/${bonusHunt.huntId}`)}  
              >
                <TableCell sx={{ borderColor: 'var(--primary-border)' }}>{bonusHunt.huntId}</TableCell>
                <TableCell sx={{ borderColor: 'var(--primary-border)' }}>
                  <Box display="flex" alignItems="center">
                    <Box component="img" src={bonusHunt.streamer!.image} sx={{ width: '24px', height: '24px', borderRadius: '50%', mr: '.5rem' }} />
                    <Typography sx={{ fontWeight: 'bold' }}>{bonusHunt.streamer!.display_name}</Typography>
                  </Box>
                </TableCell>
                <TableCell sx={{ borderColor: 'var(--primary-border)' }}>{formatDate(bonusHunt.createdAt, "time")}</TableCell>
                <TableCell sx={{ borderColor: 'var(--primary-border)' }}>${Intl.NumberFormat("en-US").format(bonusHunt.startCost)}</TableCell>
                <TableCell sx={{ borderColor: 'var(--primary-border)' }}>{bonusHunt.totalBonuses}</TableCell>
                <TableCell sx={{ borderColor: 'var(--primary-border)' }}>{bonusHunt.highestPayout ? `$${Intl.NumberFormat("en-US").format(bonusHunt.highestPayout)}` : "N/A"}</TableCell>
                <TableCell sx={{ borderColor: 'var(--primary-border)' }}>{bonusHunt.highestMulti ? `${Intl.NumberFormat("en-US").format(bonusHunt.highestMulti)}x` : "N/A"}</TableCell>
                <TableCell sx={{ borderColor: 'var(--primary-border)', textAlign: 'end', fontWeight: 'bold', color: bonusHunt.profit > 0 ? 'var(--success)' : 'var(--error)' }}>{`$${Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(bonusHunt.profit)}`}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
  )
}

const BonusHuntsPage = () => {

  const [date, setDate] = useState<string | undefined>()
  const [selectedStreamerId, setSelectedStreamerId] = useState<string | undefined>()
  const [page, setPage] = useState(1)
 
  const [sortBy, setSortBy] = useState<{ key: BonusHuntSortOption, order: "asc" | "desc" }>({
    key: BonusHuntSortOption.ID,
    order: "desc"
  })

  const { data, isLoading } = useSWR<{ count: number, countPerPage: number, data: BonusHunt[]}>
    (`/hunts?page=${page}${sortBy ? `&sortBy=${sortBy.key}&orderBy=${sortBy.order}` : ""}${selectedStreamerId ? `&streamerId=${selectedStreamerId}` : ""}${date ? `&date=${date}` : ""}`, fetcher)


  async function onSortChange(sortOption: BonusHuntSortOption) {
    if(sortBy.key === sortOption) {
      setSortBy(prev => ({
        key: prev.key,
        order: prev.order === "asc" ? "desc" : "asc"
      })) 
    } else {
      setSortBy({
        key: sortOption,
        order: "desc"
      })
    }
  }

  return (
    <Box
        display="flex"
        gap="2rem"
        width="100%"
    >
        <Box display="flex" flexDirection="column" className="page" maxWidth="1600px" flexGrow={1} sx={{ px: { xs: '8px', md: '1rem' }}}>
          <Typography variant="h3" sx={{ textAlign: 'center' }}>Bonus Hunts</Typography>

          <Box display="flex" flexDirection="column" sx={{ mt: '3rem', mb: '2rem' }}>
            <BonusHuntStats selectedStreamerId={selectedStreamerId} date={date} />
          </Box>

          <Box display="grid" justifyContent="flex-end" gap="1rem" sx={{ ml: 'auto', my: '1rem', gridTemplateColumns: 'repeat(2, minmax(0, 1fr))' }}>
            <SelectDate setDate={setDate} />
            <SelectStreamer streamerId={selectedStreamerId} selectStreamerId={setSelectedStreamerId} />
          </Box>


          <BonusHuntTable data={data} isLoading={isLoading} onSortChange={onSortChange} sortBy={sortBy} navigatePath='/hunt' />

          {data && (
            <Box display="flex" alignItems="center" gap="1rem" justifyContent="flex-end" sx={{ mt: '1rem' }}>
              <Typography>Showing <b>{(page - 1) * data.countPerPage + data.data.length}</b> of <b>{data.count}</b></Typography>
              <Box display="flex" gap="1rem">
                  <Button disabled={page === 1} onClick={() => setPage(prevState => Math.max(0, --prevState))} variant="contained" color="soft" sx={{ border: '1px solid var(--primary-border)' }}>Previous</Button>
                  <Button onClick={() => setPage(prevState => ++prevState)} disabled={data.count <= ((page - 1) * data.countPerPage) + data.data.length} variant="contained" color="soft" sx={{ border: '1px solid var(--primary-border)' }}>Next</Button>
              </Box>
            </Box>
          )}
        


        </Box>
    </Box>
  )
}

export default BonusHuntsPage