import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Menu, styled } from '@mui/material'
import { ReactComponent as Crown } from '../../icons/crown.svg';

import { API_URL } from '../../utils'
import { Icon } from '@mui/material';
import { useMediaQuery } from 'react-responsive';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const IconWraper = styled(Icon)(() => ({
    height: '36px',
    width: '36px',
    'svg': {
        width: '100%',
        height: '100%',
        fill: 'rgb(218,165,32)'
    }
}))

const StyledIFrame = styled("iframe")(({ theme }) => ({
    border: '2px solid transparent',
    boxSizing: 'content-box'  
}))

const getPlaceColor = (place) => {
    if(place === 1) return 'rgb(218,165,32)'
    else if (place === 2) return 'rgb(192,192,192)'
    else if (place === 3) return 'rgb(205,127,50)'
}


const HighlightPage = () => {

    const isSmallDevice = useMediaQuery({ query: '(max-width: 1200px)' });
    const [selectedHighlight, setSelectedHighlight] = useState(1);

    const [lastWeekTop, setLastWeekTop] = useState({
        display_name: "",
        slot_name: "",
        win_multi: "",
        clip_url: "",
        clip_handle: ""
    })

    const [contentCreator1, setContentCreator1] = useState({
        display_name: "",
        slot_name: "",
        win_multi: "",
        clip_url: "",
        clip_handle: ""
    })

    const [contentCreator2, setContentCreator2] = useState({
        display_name: "",
        slot_name: "",
        win_multi: "",
        clip_url: "",
        clip_handle: ""
    })

    const [contentCreator3, setContentCreator3] = useState({
        display_name: "",
        slot_name: "",
        win_multi: "",
        clip_url: "",
        clip_handle: ""
    })

    const [viewer1, setViewer1] = useState({
        display_name: "",
        slot_name: "",
        win_multi: "",
        clip_url: ""
    })

    const [viewer2, setViewer2] = useState({
        display_name: "",
        slot_name: "",
        win_multi: "",
        clip_url: ""
    })

    const [viewer3, setViewer3] = useState({
        display_name: "",
        slot_name: "",
        win_multi: "",
        clip_url: ""
    })

    const [viewerAllTimeHigh, setViewerAllTimeHigh] = useState({
        display_name: "",
        slot_name: "",
        win_multi: "",
        clip_url: ""
    });

    const getHighlights = () => {
        fetch(`${API_URL}/highlights`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then(res => {
            return res.json();
        }).then(data => {
            if (data && !data.error) {
                for(let highlight of data){
                    if(highlight.place_id === 0) setContentCreator1({ ...highlight })
                    else if(highlight.place_id === 1) setContentCreator2({ ...highlight })
                    else if(highlight.place_id === 2) setContentCreator3({ ...highlight })
                    else if(highlight.place_id === 3) setViewer1({ ...highlight })
                    else if(highlight.place_id === 4) setViewer2({ ...highlight })
                    else if(highlight.place_id === 5) setViewer3({ ...highlight })
                    else if(highlight.place_id === 6) setViewerAllTimeHigh({ ...highlight })
                    else if (highlight.last_week_top) setLastWeekTop({ ... highlight })
                }
            }

        })
    }
    
    useEffect(() => {
        getHighlights();
    }, [])

    const getHighlightInfo = (index) => {
        switch (index) {
            case 1:
                return contentCreator1;
            case 2:
                return contentCreator2;
            case 3:
                return contentCreator3;
            default:
                return contentCreator1;
        }
    }

    return (
        <Box
            className="page"
            display="flex"
            flexDirection="column"
            sx={{
                width: '85%',
                margin: '0 auto'
            }}
        >
            {isSmallDevice ? (
                <React.Fragment>
                <Select
                    id="select-highlight"
                    value={selectedHighlight}
                    onChange={(event) => setSelectedHighlight(event.target.value)}
                >
                        <MenuItem value={1}>1st Place Highlight</MenuItem>
                        <MenuItem value={2}>2nd Place Highlight</MenuItem>
                        <MenuItem value={3 }>3rd Place Highlight</MenuItem>
                    </Select>
                
                <Box display="flex" alignItems="center" flexDirection="column" sx={{ width: '100%', marginBottom: '3rem', marginTop: '3rem' }}>
                    {getHighlightInfo(selectedHighlight).clip_url ? (
                        <>
                            <Box sx={{ height: '300px', width: 'auto', aspectRatio: '16/9' }}>
                                <StyledIFrame
                                    src={getHighlightInfo(selectedHighlight).clip_url}
                                    height="100%"
                                    width="100%"
                                    title="viewer1"
                                    allowFullScreen
                                    controls
                                    autoplay="false"
                                    preload="metadata"
                                    sx={{ borderColor: getPlaceColor(selectedHighlight) }}
                                >
                                </StyledIFrame>
                            </Box>

                            <Box component="a" href={getHighlightInfo(selectedHighlight).clip_url} display="flex" flexDirection="column" alignItems="center" target="_blank" rel="noreferrer">
                                <Typography variant="span" sx={{
                                    marginTop: '.75rem',
                                    color: getPlaceColor(selectedHighlight), filter: `drop-shadow(0 0 0.1rem ${getPlaceColor(selectedHighlight)})`, fontSize: '18px'
                                }}>
                                    {getHighlightInfo(selectedHighlight).display_name}
                                </Typography>
                                <Typography variant="span" sx={{ marginTop: '.25rem', color: 'rgba(255, 255, 255, .7)' }}>
                                    {getHighlightInfo(selectedHighlight).slot_name} <Typography variant="span"
                                        sx={{ color: getPlaceColor(selectedHighlight), filter: `drop-shadow(0 0 1px ${getPlaceColor(selectedHighlight)})` }}>{getHighlightInfo(selectedHighlight).win_multi}x</Typography>
                                </Typography>
                            </Box>
                        </>
                    ) : (
                        <Typography variant="span">
                            No Highlight for this week yet :(
                        </Typography>
                    )
                    }

                    </Box>
                    </React.Fragment>

            ) : (
                <Box display="flex" alignItems="center" justifyContent="center" sx={{ width: '100%', marginTop: '5rem', marginBottom: '3rem' }}>
                    <Box display="flex" alignItems="center" flexDirection="column" sx={{ width: '400px' }}>
                        {contentCreator3.clip_url ? (
                            <>
                                <Box sx={{ height: '300px', width: 'auto', aspectRatio: '16/9' }}>
                                    <StyledIFrame
                                        src={contentCreator3.clip_url}
                                        height="100%"
                                        width="100%"
                                        title="viewer1"
                                        allowFullScreen
                                        controls
                                        preload="metadata"
                                        autoplay="false"
                                        sx={{ borderColor: getPlaceColor(3) }}
                                    >
                                    </StyledIFrame>
                                </Box>

                                <Box component="a" href={contentCreator3.clip_url} display="flex" flexDirection="column" alignItems="center" target="_blank" rel="noreferrer">
                                    <Typography variant="span" sx={{
                                        marginTop: '.75rem',
                                        color: getPlaceColor(3), filter: `drop-shadow(0 0 0.1rem ${getPlaceColor(3)})`, fontSize: '18px'
                                    }}>
                                        {contentCreator3.display_name}
                                    </Typography>
                                    <Typography variant="span" sx={{ marginTop: '.25rem', color: 'rgba(255, 255, 255, .7)' }}>
                                        {contentCreator3.slot_name} <Typography variant="span"
                                            sx={{ color: getPlaceColor(3), filter: `drop-shadow(0 0 1px ${getPlaceColor(3)})` }}>{contentCreator3.win_multi}x</Typography>
                                    </Typography>
                                </Box>
                            </>
                        ) : (
                            <Typography variant="span">
                                No Highlight for this week yet :(
                            </Typography>
                        )
                        }

                    </Box>
                    <Box display="flex" alignItems="center" flexDirection="column" sx={{ marginRight: '7rem', marginLeft: '7rem', marginBottom: '2rem' }}>
                        {contentCreator1.clip_url ? (
                            <>
                                <Box sx={{ height: '300px', width: 'auto', aspectRatio: '16/9' }}>
                                    <StyledIFrame
                                        src={contentCreator1.clip_url}
                                        height="100%"
                                        width="100%"
                                        title="viewer1"
                                        allowFullScreen
                                        preload="metadata"
                                        controls
                                        autoplay="false"
                                        sx={{ borderColor: getPlaceColor(1) }}

                                    >
                                    </StyledIFrame>
                                </Box>
                                <Box component="a" href={contentCreator1.clip_url} display="flex" flexDirection="column" alignItems="center" target="_blank" rel="noreferrer">

                                    <Typography variant="span" sx={{
                                        marginTop: '.75rem',
                                        color: getPlaceColor(1), filter: `drop-shadow(0 0 0.1rem ${getPlaceColor(1)})`, fontSize: '18px'
                                    }}>
                                        {contentCreator1.display_name}
                                    </Typography>
                                    <Typography variant="span" sx={{ marginTop: '.25rem', color: 'rgba(255, 255, 255, .7)' }}>
                                        {contentCreator1.slot_name} <Typography variant="span"
                                            sx={{ color: getPlaceColor(1), filter: `drop-shadow(0 0 1px ${getPlaceColor(1)})` }}>{contentCreator1.win_multi}x</Typography>
                                    </Typography>
                                </Box>
                            </>
                        ) : (
                            <Typography variant="span" sx={{ marginBottom: '2rem' }}>
                                No Highlight for this week yet :(
                            </Typography>
                        )}

                    </Box>
                    <Box display="flex" alignItems="center" flexDirection="column" sx={{ width: '400px' }}>
                        {contentCreator2.clip_url ? (
                            <>
                                <Box sx={{ height: '300px', width: 'auto', aspectRatio: '16/9' }}>
                                    <StyledIFrame
                                        src={contentCreator2.clip_url}
                                        height="100%"
                                        width="100%"
                                        title="viewer1"
                                        allowFullScreen
                                        preload="metadata"
                                        controls
                                        autoplay="false"
                                        sx={{ borderColor: getPlaceColor(2) }}
                                    >
                                    </StyledIFrame>
                                </Box>


                                <Box component="a" href={contentCreator2.clip_url} display="flex" flexDirection="column" alignItems="center" target="_blank" rel="noreferrer">
                                    <Typography variant="span" sx={{
                                        marginTop: '.75rem',
                                        color: getPlaceColor(2), filter: `drop-shadow(0 0 0.1rem ${getPlaceColor(2)})`, fontSize: '18px'
                                    }}>
                                        {contentCreator2.display_name}
                                    </Typography>
                                    <Typography variant="span" sx={{ marginTop: '.25rem', color: 'rgba(255, 255, 255, .7)' }}>
                                        {contentCreator2.slot_name} <Typography variant="span"
                                            sx={{ color: getPlaceColor(2), filter: `drop-shadow(0 0 1px ${getPlaceColor(2)})` }} >{contentCreator2.win_multi}x</Typography>
                                    </Typography>
                                </Box>
                            </>
                        ) : (
                            <Typography variant="span">
                                No Highlight for this week yet :(
                            </Typography>
                        )}

                    </Box>

                </Box>
            )}
           
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" sx={{ marginBottom: '3rem', color: 'rgba(255, 255, 255, .75)' }}>
                <Typography variant="span" sx={{ fontSize: '18px'}}>
                    Last weeks first place belongs to <Typography variant="span" sx={{ color: getPlaceColor(1), fontWeight: 'bold'}}>{lastWeekTop.display_name}</Typography>
                </Typography>
                <Typography variant="span" sx={{ fontSize: '18px'}}>
                    <Typography variant="span" sx={{ color: getPlaceColor(1), fontWeight: 'bold' }}>{lastWeekTop.slot_name}</Typography> with a <Typography variant="span" sx={{ color: getPlaceColor(1), fontWeight: 'bold'}}>{lastWeekTop.win_multi}x</Typography> Win
                </Typography>
                <Typography component="a" href={lastWeekTop.clip_url} target="_blank" sx={{ color: 'primary.light'}}>Open Clip</Typography>
                <Typography variant="span" sx={{ marginTop: '1rem'}}>You will receive more Points for watching them this week</Typography>

            </Box>


            <Box display="flex" alignItems="center" sx={{ width: '100%', borderTop: '1px solid rgba(225, 225, 225, .3)', paddingTop: '2.5rem' }}>
                <Box
                    display="flex"
                    flexDirection="column"

                >
                    <Typography variant="span" sx={{ margin: '36px auto 1.2rem auto', fontSize: '18px', fontWeight: 'bold', color: 'rgba(255, 255 , 255, .8)' }}>
                        Top Viewer Buys
                    </Typography>
                    <Box
                        display="flex"
                        justifyContent="flex-start"
                        sx={{marginRight: '2rem'}}
                    >

                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            sx={{ margin: '0 1rem', width: '33%' }}
                        >
                            <iframe
                                src={viewer1.clip_url}
                                height="250px"
                                width="100%"
                                title="viewer1"
                                allowFullScreen
                                autoplay="false"
                            >
                            </iframe>
                            <Typography variant="span" sx={{ marginTop: '.75rem', color: 'primary.light', fontSize: '18px' }}>
                                {viewer1.display_name}
                            </Typography>
                            <Typography variant="span" sx={{ marginTop: '.25rem', color: 'rgba(255, 255, 255, .7)'  }}>
                                {viewer1.slot_name} <Typography variant="span" sx={{ color: 'primary.light'}}>{viewer1.win_multi}x</Typography> 
                            </Typography>
                        </Box>

                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            sx={{ margin: '0 1rem', width: '33%' }}
                        >
                            <iframe
                                src={viewer2.clip_url}
                                height="250px"
                                width="100%"
                                title="viewer1"
                                allowFullScreen
                                autoplay="false"
                            >
                            </iframe>
                            <Typography variant="span" sx={{ marginTop: '.75rem', color: 'primary.light', fontSize: '18px' }}>
                                {viewer2.display_name}
                            </Typography>
                            <Typography variant="span" sx={{ marginTop: '.25rem', color: 'rgba(255, 255, 255, .7)'  }}>
                                {viewer2.slot_name} <Typography variant="span" sx={{ color: 'primary.light'}}>{viewer2.win_multi}x</Typography> 
                            </Typography>
                        </Box>
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            sx={{ margin: '0 1rem', width: '33%' }}
                        >
                            <iframe
                                src={viewer3.clip_url}
                                height="250px"
                                width="100%"
                                title="viewer1"
                                allowFullScreen
                                autoplay="false"
                            >
                            </iframe>
                            <Typography variant="span" sx={{ marginTop: '.75rem', color: 'primary.light', fontSize: '18px' }}>
                                {viewer3.display_name}
                            </Typography>
                            <Typography variant="span" sx={{ marginTop: '.25rem', color: 'rgba(255, 255, 255, .7)'  }}>
                                {viewer3.slot_name} <Typography variant="span" sx={{ color: 'primary.light'}}>{viewer3.win_multi}x</Typography> 
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ marginLeft: 'auto' }}>
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="flex-end"
                        sx={{ margin: '0 1rem', width: '100%' }}
                    >
                        <IconWraper>
                            <Crown />
                        </IconWraper>
           
                        <Typography variant="span" sx={{ margin: '0 auto 1.2rem auto', fontSize: '18px', fontWeight: 'bold', color: 'rgba(255, 255 , 255, .8)'}}>
                            All Time
                        </Typography>
                        <iframe
                            src={viewerAllTimeHigh.clip_url}
                            height="250px"
                            width="100%"
                            title="viewer1"
                            allowFullScreen
                        >
                        </iframe>
                        <Typography variant="span" sx={{ marginTop: '.75rem', color: getPlaceColor(1), fontSize: '18px' }}>
                            {viewerAllTimeHigh.display_name}
                        </Typography>
                        <Typography variant="span" sx={{ marginTop: '.25rem', color: 'rgba(255, 255, 255, .7)'  }}>
                            {viewerAllTimeHigh.slot_name} <Typography variant="span" sx={{ color: getPlaceColor(1)}}>{viewerAllTimeHigh.win_multi}x</Typography> 
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default HighlightPage