import { Close } from '@mui/icons-material'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material'
import React, { useState } from 'react'
import { apiFetch } from '../../utils'
import { createSuccessToast } from '../../components/Toasts/SuccessToasts'
import { createErrorToast } from '../../components/Toasts/ErrorToasts'

const DisableBettingOnSlot = ({ onSlotDisabled, huntId, slotName }: { onSlotDisabled: (name: string) => void, huntId: number, slotName: string }) => {

    const [isOpen, setIsOpen] = useState(false)
    const handleClose = () => setIsOpen(false)
    const handleOpen = () => setIsOpen(true)

    async function removeSlotFromGuessing() {

        try {
            const data = await apiFetch(`/admin/hunt/${huntId}/slot-guesses/disable`, "POST", {
                body: JSON.stringify({
                    slotName
                })
            })

            onSlotDisabled(slotName)

            handleClose()

            createSuccessToast(data.message || "Removed Slot from guessing")    
        } catch (e) {
            createErrorToast((e as Error).message || "Something went wrong")
        }
    }
 
  return (
    <>
        <Button variant="contained" onClick={handleOpen}>
            Disable
        </Button>
        <Dialog
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="slot-disable-dialog-title"
            aria-describedby="slot-disable-description"
            PaperProps={{
                sx: { border: '1px solid var(--primary-border)', borderRadius: '.5rem', minWidth: '60%', maxWidth: '800px', position: 'absolute' }
            }}
        >
             <IconButton onClick={handleClose} sx={{ position: 'absolute', top: '4px', right: '4px'}}>
                <Close />
            </IconButton>
            <DialogTitle id="slot-disable-dialog-title">
                Remove slot from guessing
            </DialogTitle>
            <DialogContent>

                <Box>
                    Are you sure you want remove {slotName}?
                </Box>
            </DialogContent>
            <DialogActions sx={{ px: '1rem', pb: '1rem' }}>
                    <Button sx={{ mr: 'auto' }} variant="contained" onClick={() => removeSlotFromGuessing()}>
                        Confirm
                    </Button>
                    <Button variant="contained" color="secondary" onClick={handleClose}>Close</Button>
                </DialogActions>
        </Dialog>
    </>
  )
}

export default DisableBettingOnSlot