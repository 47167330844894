import React, { useState } from 'react'

import { Route, Link, Routes, useLocation, Navigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { ReactComponent as PackDrawLogo } from '../../icons/sites/packdraw.svg'
import PackdrawLeaderboardPage from './Packdraw/Leaderboard';
import Button from '@mui/material/Button'
import StakeLeaderboard from './Stake/Leaderboard'
import CasesGGLeaderboard from './Cases/Leaderboard'
import { ReactComponent as Stake } from '../../icons/sites/stake.svg'
import { ReactComponent as CasesGG } from '../../icons/sites/casesgg.svg'
import { ReactComponent as RainGG } from '../../icons/sites/raingg.svg'
import { styled } from '@mui/styles'
import ArrowBackIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIcon from '@mui/icons-material/ArrowForwardIos';
import { IconButton } from '@mui/material'
import RainGGLeaderboard from './Rain/Leaderboard'


const NavButton = styled(Button)(( { theme }) => ({
    height: '56px',
    gap: '.5rem',

    '& > *': {
        opacity: '.8',
        filter: 'grayscale(1)',
        transition: 'opacity .2s ease-in-out, filter .2s ease-in-out',
    },

    '&:hover': {
        '*': {
            opacity: '1',
            filter: 'grayscale(.2)'
        }
    },

    '&.active': {
        '& > *': {
            opacity: '1 !important',
            filter: 'grayscale(0) !important'
        }
    }
}))

const MobileBox = styled(Box)(( { theme }) => ({

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    py: '10px',

    '& > svg': {
        height: 'auto',
        width: '128px',
        maxWidth: '80%'
    },
    '& > img': {
        height: 'auto',
        width: '128px',
        maxWidth: '80%'
    }
}))

enum LeaderboardPath {
    STAKE = "/leaderboard/stake",
    PACKDRAW = "/leaderboard/packdraw",
    CASES = "/leaderboard/cases",
    RAIN = "/leaderboard/rain",
}

function getPreviousPath(path: LeaderboardPath) {
    switch (path) {
        case LeaderboardPath.STAKE:
            return null
        case LeaderboardPath.PACKDRAW:
            return LeaderboardPath.STAKE
        case LeaderboardPath.CASES:
            return LeaderboardPath.PACKDRAW
        case LeaderboardPath.RAIN:
            return LeaderboardPath.CASES
        default:
            return LeaderboardPath.STAKE
    }
}

function getNextPath(path: LeaderboardPath) {
    switch (path) {
        case LeaderboardPath.STAKE:
            return LeaderboardPath.PACKDRAW
        case LeaderboardPath.PACKDRAW:
            return LeaderboardPath.CASES
        case LeaderboardPath.CASES:
            return LeaderboardPath.RAIN
        case LeaderboardPath.RAIN:
            return null
        default:
            return LeaderboardPath.STAKE
    }
}

const LeaderboardIndexPage = () => {

    let location = useLocation();
    const [value, setValue] = useState(0)

  return (
      <Box sx={{ height: '100%' }}>
            <Box 
                position="sticky" 
                top="84px" 
                zIndex={10}
                sx={{ boxShadow: '0 0 .5rem rgba(0, 0, 0, .25)', backgroundColor: 'var(--primary-bg-soft)', p: '.25rem', borderBottom: '1px solid var(--primary-border)' }}
            >
                <Box 
                    display="flex"
                    alignItems="center"
                    sx={{ height: '56px', px: '1rem', display: { xs: 'flex', sm: 'none' } }}
                >
                    <Link to={getPreviousPath(location.pathname as LeaderboardPath) ?? LeaderboardPath.STAKE}>
                        <IconButton disabled={location.pathname.startsWith(LeaderboardPath.STAKE)}>
                            <ArrowBackIcon sx={{ fill: location.pathname.startsWith(LeaderboardPath.STAKE) ? "rgb(0,0,0) !important" : "" }} />
                        </IconButton>
                    </Link>
                    <Box display="flex" sx={{ width: '100%' }}>
                        {location.pathname.startsWith(LeaderboardPath.STAKE) && (
                            <MobileBox>
                                <Stake style={{ height: 'auto', width: '64px'}}/>
                            </MobileBox>
                        )}
                        {location.pathname.startsWith(LeaderboardPath.PACKDRAW) && (
                            <MobileBox>
                                <Box display="flex" alignItems="center" gap=".5rem">
                                    <PackDrawLogo style={{ height: '32px', width: 'auto' }} />
                                    <Typography sx={{ fontWeight: 'bold', color: 'rgb(255, 255, 255) !important' }}>PackDraw</Typography>
                                </Box>
                            </MobileBox>
                        )}
                        {location.pathname.startsWith(LeaderboardPath.CASES) && (
                            <MobileBox>
                                <CasesGG style={{ height: 'auto', width: '64px'}}/>
                            </MobileBox>
                        )}
                        {location.pathname.startsWith(LeaderboardPath.RAIN) && (
                            <MobileBox>
                                <RainGG style={{ height: 'auto', width: '64px'}}/>
                            </MobileBox>
                        )}
                    </Box>
                    <Link to={getNextPath(location.pathname as LeaderboardPath) ?? LeaderboardPath.RAIN}>
                        <IconButton disabled={location.pathname.startsWith(LeaderboardPath.RAIN)} onClick={() => setValue(prevState => ++prevState)}>
                            <ArrowForwardIcon sx={{ fill: location.pathname.startsWith(LeaderboardPath.RAIN) ? "rgb(0,0,0) !important" : "" }} />
                        </IconButton>     
                    </Link>              
                </Box>
                <Box         
                    alignItems="center" 
                    justifyContent="center" 
                    gap="2rem"
                    sx={{ height: '56px', display: { xs: 'none', sm: 'flex' }  }}
                >                   
                    <Link to={LeaderboardPath.STAKE}>
                        <NavButton disableRipple className={location.pathname.startsWith(LeaderboardPath.STAKE) ? "active" : ""} sx={{ 'svg': { height: 'auto', width: '64px' } }}>
                            <Stake />
                        </NavButton>
                    </Link>
                    <Link to={LeaderboardPath.PACKDRAW}>
                        <NavButton disableRipple className={location.pathname.startsWith(LeaderboardPath.PACKDRAW) ? "active" : ""} sx={{ 'svg': { color: 'rgb(255, 255, 255) !important', height: 'auto', width: '24px' } }}>
                            <PackDrawLogo />
                            <Typography sx={{ fontWeight: 'bold', color: 'rgb(255, 255, 255) !important' }}>PackDraw</Typography>
                        </NavButton>
                    </Link>
                    <Link to={LeaderboardPath.CASES}>
                        <NavButton disableRipple className={location.pathname.startsWith(LeaderboardPath.CASES) ? "active" : ""} sx={{ 'svg': { height: 'auto', width: '64px' } }}>
                            <CasesGG />
                        </NavButton>
                    </Link>
                    <Link to={LeaderboardPath.RAIN}>
                        <NavButton disableRipple className={location.pathname.startsWith(LeaderboardPath.RAIN) ? "active" : ""} sx={{ 'svg': { height: 'auto', width: '64px' } }}>
                            <RainGG />
                        </NavButton>
                    </Link>
                </Box>
            </Box>

          <Routes>
              <Route path="/" element={<Navigate to="/leaderboard/stake" />} />
              <Route path="/packdraw" element={<PackdrawLeaderboardPage />} />
              <Route path="/stake" element={<StakeLeaderboard />} />
              <Route path="/cases" element={<CasesGGLeaderboard />} />
              <Route path="/rain" element={<RainGGLeaderboard />} />
          </Routes>
    </Box>
  )
}

export default LeaderboardIndexPage