import React, { useState } from 'react'
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { SxProps, Theme } from '@mui/material';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '500px',
    maxWidth: '95%',
    bgcolor: 'var(--primary-bg)',
    border: '2px solid var(--primary-border)',
    boxShadow: 24,
    padding: '1rem',
    borderRadius: '8px'
  }

const ClaimPointsTermsModal = ({ buttonStyle }: { buttonStyle: SxProps<Theme>}) => {

    const [isOpen, setIsOpen] = useState(false)

    return (
        <React.Fragment>
            <Button disableRipple sx={buttonStyle} onClick={() => setIsOpen(true)}>Claiming Points</Button>
            <Modal
                open={isOpen}
                onClose={() => setIsOpen(false)}
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    rowGap=".5rem"
                    justifyContent="center"
                    sx={style}

                >
                    <Typography variant="h5" marginBottom="2rem">Claiming Points Terms</Typography>
                    <Typography component="ul" sx={{ padding: '0 1rem', 'li': { margin: '.5rem 0'}}}>
                        <li>By continueing, you agree you are of legal age and allowed to gamble on hypedrop/clash in your country of residence.</li>
                        <li>Points are not real money & are WORTHLESS in the real world</li>
                        <li>Abusing our points system will get you banned on prodigyddk.com</li>        
                        <li>We get our data for deposits/wager from our partners, in rare occasion your data might be unobtainable & we can not guarantee that you will receive your Points</li>               
                    </Typography>
                    <Box
                        display="flex"
                        alignItems="center"
                        sx={{
                            marginTop: '1.5rem',
                            width: '100%'
                        }}
                    >
                        <Button color="secondary" variant="contained" sx={{ marginLeft: 'auto' }} onClick={() => setIsOpen(false)}>
                            Acknowledged
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </React.Fragment>
    )
}

export default ClaimPointsTermsModal