import React from 'react';
import { toast } from 'react-toastify';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';

export const createSuccessToast = (message: string) => {
    toast.dark(
        <Box display="flex" alignItems="center" sx={{ color: 'text.secondary' }}>
            <CheckIcon sx={{ height: '32px', width: '32px', color: 'success.main', marginRight: '.75rem' }} />
                <Typography sx={{ fontSize: '16px' }}>
                    {message}
                </Typography>
        </Box>
        ,{
            position: 'bottom-right',
            progressStyle: {
                backgroundColor: "#4caf50"
            }
        }
    )
}