import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    vip_giveaway: {
        active: false,
        time_until_next: 0,
        eligible_viewers: [],
        recent_wins: []
    }
}

export const vipGiveawaySlice = createSlice({
    name: 'vip_giveaway',
    initialState,
    reducers: {
        setVipGiveawayInfo: (state, action) => {
            state.vip_giveaway = {...state.vip_giveaway, ...action.payload}
        },
        setVipGiveawayEligibleViewers: (state, action) => {
            state.vip_giveaway.eligible_viewers = action.payload
        },
        setVipGiveawayActive: (state, action) => {
            state.vip_giveaway.active = action.payload
        },
        setVipGiveawayTimeUntilNext: (state, action) => {
            state.vip_giveaway.time_until_next = action.payload
        },
        setVipGiveawayRecentWins: (state, action) => {
            state.vip_giveaway.recent_wins = action.payload
        },
        addVipGiveawayRecentWin: (state, action) => {
            state.vip_giveaway.recent_wins = [action.payload, ...state.vip_giveaway.recent_wins || []]
        },
    }
})

export const { 
    setVipGiveawayActive,
    setVipGiveawayEligibleViewers, 
    setVipGiveawayInfo, 
    setVipGiveawayRecentWins, 
    setVipGiveawayTimeUntilNext, 
    addVipGiveawayRecentWin } = vipGiveawaySlice.actions

export const selectVipGiveawayInfo = state => state.vip_giveaway.vip_giveaway

export default vipGiveawaySlice.reducer