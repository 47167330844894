import React, { useState } from 'react'
import { Box, Button, Modal, Typography } from '@mui/material'
import { ReactComponent as GiftIcon } from '../../../icons/gift.svg'
import UserLookupPopover from '../User/UserLookupPopover'
import { Profile } from '../../../shared/user.interface'
import LookupUser from '../User/LookupUser'
import { createErrorToast } from '../../Toasts/ErrorToasts'
import { apiFetch } from '../../../utils'
import { Item } from '../../../shared/item.interface'

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '560px',
    maxWidth: '95%',
    bgcolor: 'var(--primary-bg)',
    border: '2px solid var(--primary-border)',
    boxShadow: 24,
    padding: '1rem',
    borderRadius: '8px'
}


const GiveawayCaseModal = ({ slug, onCaseOpened }: { slug?: string, onCaseOpened: (unboxingResult: { item: Item, inventoryItemId: string }, receivingUser?: Profile) => void }) => {

    const [isOpen, setIsOpen] = useState(false)
    const handleClose = () => setIsOpen(false)
    const handleOpen = () => setIsOpen(true)

    const [selectedUser, setSelectedUser] = useState<Profile | undefined>(undefined);

    async function openCase() {

        if(!slug) return createErrorToast("Something went wrong, please refresh the page.")

        if(!selectedUser) return createErrorToast("Please select a user.")

        try {

            const data = await apiFetch(`/admin/case/giveaway/open/${slug}`, "POST", {
                body: JSON.stringify({
                    userId: selectedUser._id
                })
            })

            if(data) {
                setIsOpen(false)
                onCaseOpened(data.data, selectedUser)
                
            }
          
        } catch (e) {
          if(e instanceof Error) {
            createErrorToast(e.message)
          }
        }
      }

  return (
    <>
    <Button variant="contained" startIcon={<GiftIcon />} sx={{ p: '12px 16px', borderRadius: '.5rem' }} onClick={handleOpen}>
        Giveaway Case
    </Button>
    <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="item-won-modal-title"
        aria-describedby="item-won-modal-description"
    >
        <Box display="flex" flexDirection="column" gap="1rem" sx={style}>
            <Typography variant="h6" sx={{ fontWeight: 600, flexGrow: 1 }}>Giveaway Case</Typography>
            <LookupUser
                selectUser={(user) => setSelectedUser(user)}
            />

            {selectedUser && (
                <Box display="flex" alignItems="center" gap=".5rem" sx={{ p: '.75rem 1rem', borderRadius: '.5rem', border: '1px solid var(--primary-border)' }}>
                    <Box component="img" src={selectedUser.avatar} alt={selectedUser.display_name} sx={{ borderRadius: '100%', height: '32px', width: '32px'}} />
                    <Typography component="span" sx={{ fontWeight: 600 }}>{selectedUser.display_name}</Typography>
                </Box>
            )}


            <Box display="flex" justifyContent="space-between">
                <Button variant="contained" onClick={openCase}>Open Case</Button>
                <Button variant="contained" color="soft" onClick={handleClose}>Close</Button>
            </Box>
        </Box>
    </Modal>
    </>
  )
}

export default GiveawayCaseModal