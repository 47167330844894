import React, { useEffect } from 'react'
import Popover from '@mui/material/Popover'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { IconButton, styled } from '@mui/material'
import { API_URL, getTimeAgo } from '../../utils'

import RollbitLogo from '../../icons/rollbit.png';
import CsgoLogo from '../../icons/csgo.png';

import { useDispatch, useSelector } from 'react-redux'
import { selectMessages, setMessageRead } from '../../redux/message/messageSlice'
import { checkIfAPIImage } from '../../utils'
import { Backdrop } from '@mui/material'
import NotificationsIcon from '@mui/icons-material/Notifications';
import CloseIcon from '@mui/icons-material/Close';

const MessagePopover = styled(Popover)(( { theme }) => ({
    '& .MuiPopover-paper': {
        backgroundColor: 'var(--primary-bg)',
        color: 'var(--text-primary)'
    }
}))

const MessageBox = styled(Box)(( { theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'var(--primary-bg-light)',
    borderRadius: '.5rem',
    padding: `.75rem`,
    width: '450px',
    maxWidth: '100%',
    transition: 'background-color .2s ease-in-out',
    position: 'relative',
    
    '.date': {
        marginLeft: 'auto',
        color: 'var(--text-disabled)'
    },

    '.message-text': {
        //transition: 'color .2s ease-in-out',
    },

    '&:hover': {
        background: 'var(--primary-bg-soft)',
        '.message-text': {
            //color: 'var(--text-primary)'
        }
    },

    '*': {
        zIndex: 1
    }
}))

const getMessageImageSrcFromType = (type: string) => {
    if(type === 'csgo_giveaway'){
        return CsgoLogo
    } else if (type === 'rollbit_giveaway'){
        return RollbitLogo
    } else if (type === 'reward'){
        return RollbitLogo
    } else return ''
}

interface Message {
    _id: string,
    type: string,
    message: string,
    date: string,
    read: boolean,
    image: string
    origin: string
    subject: string
}


const NotificationMenu = () => {

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const dispatch = useDispatch();

    const messages: Message[] = useSelector(selectMessages)

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
      };
    
      const handleClose = () => {
        setAnchorEl(null);
      };
    
      const open = Boolean(anchorEl);
      const id = open ? 'notification-popover' : undefined;

    useEffect(() => {
        if(open){
            for(let message of messages){
                if(!message.read){
                    readMessage(message);
                }
            }
        }
    }, [open, messages])

    const readMessage = (message: Message) => {
        fetch(`${API_URL}/user/message/read`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify({
                message_id: message._id
            })
        }).then(res => {
            
            return res.json();
        }).then(data => {
            dispatch(setMessageRead({ _id: message._id}))
        })
    }

    return (
        <>
            <IconButton color="inherit" 
                sx={{ 
                    '&:hover': { 'svg': { fill: 'var(--text-secondary)' }},
                    backgroundColor: 'var(--primary-bg-soft)', 
                    border: '1px solid var(--primary-border)',
                    borderRadius: '.5rem', 
                    color: 'var(--primary-main)' 
                }}
                aria-describedby={id}
                onClick={handleClick}
            >
                <NotificationsIcon sx={{ fill: 'inherit' }} />
            </IconButton>
        <Backdrop
            open={open}
            sx={{ zIndex: '100', backgroundColor: 'rgba(0, 0, 0, .93)' }}
        >
            <MessagePopover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                transitionDuration={{
                    enter: 300,
                    exit: 300
                }}
            >
                <Box display="flex" alignItems="center" sx={{ 
                    padding: '.75rem', fontWeight: 'bold', color: 'var(--text-secondary)' }}>
                    <NotificationsIcon />
                    <Typography variant="h6" sx={{ marginLeft: '.75rem' }}>
                        Notifications
                    </Typography>
                    <IconButton disableRipple disableFocusRipple sx={{ marginLeft: 'auto' }} onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>

                <Box
                    display="flex"
                    flexDirection="column"
                    sx={{
                        overflowY: 'auto',
                        maxHeight: '600px',
                        padding: '1rem',
                        width: 'auto',
                        rowGap: '1rem'
                    }}
                >
                    {messages.length > 0 ? (
                        <>
                            {messages.map((message, index: number) => (
                                <React.Fragment key={`message-${index}`}>
                                    <MessageBox
                                        display="flex"
                                        flexDirection="column"
                                    >
                                        <Box display="flex" alignItems="flex-start" sx={{ marginBottom: '.5rem' }}>
                                            {message.type === 'system' || message.type === "notification" ? (
                                                <Typography sx={{ color: 'var(--text-disabled)'}}>Notification</Typography>
                                            ) : message.type === "personal_message" ? (
                                                <Box display="flex">
                                                    <Typography sx={{ color: 'var(--text-disabled)'}}>Admin{message.subject ? `: ${message.subject}` : ""}</Typography>                                              
                                                </Box>
                                            ) : (
                                                <img
                                                    src={message.image ? checkIfAPIImage(message.image) : getMessageImageSrcFromType(message.type)} alt=""
                                                    style={{
                                                        filter: message.type === 'stake_giveaway' ? 'invert(99%) sepia(7%) saturate(1%) hue-rotate(152deg) brightness(107%) contrast(100%)' : '',
                                                        width: 'auto',
                                                        height: 'auto',
                                                        maxWidth: '48px',
                                                        maxHeight: '32px',                                                        
                                                    }}
                                                />
                                            )}
   
                                            <Typography className='date'>{getTimeAgo(message.date)}</Typography>
                                        </Box>

                                        <Typography className='message-text' sx={{ color: message.read ? 'var(--text-secondary)' : 'inherit' }}>
                                            {message.message}
                                        </Typography>
                                    </MessageBox>
                                </React.Fragment>
                            ))}
                        </>
                    ) : (
                        <Box
                            display="flex"
                            justifyContent="center"
                            sx={{
                                padding: '1rem'
                            }}
                        >
                            <span>
                                You do not have any messages
                            </span>
                        </Box>
                    )}

                </Box>
            </MessagePopover>
        </Backdrop>
        </>
    );
};

export default NotificationMenu;
