import React, { useEffect } from 'react'

import styled from '@mui/material/styles/styled'
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const ModalWrapper = styled('div')(({ theme }) => ({
    backgroundColor: 'rgba(0, 0, 0, .9)',
    position: 'fixed',
    zIndex: 1300,
    right: 0,
    bottom: 0,
    top: 0,
    left: 0,
    margin: '0 !important'
}))

const ModalContainer = styled(Box)(({ theme }) => ({
    backgroundColor: 'var(--primary-bg)',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: '0, 0, 1rem rgb(20, 16, 24)',
    padding: "1rem",
    borderRadius: "1rem",
    minHeight: "150px",
    maxHeight: '95%',
    width: '100%',
    minWidth: '350px',
    maxWidth: '90%',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    opacity: 1,
    transition: 'opacity 250ms ease-in-out'
})) as typeof Box

const ModalTitle = styled(Box)(({ theme }) => ({
    fontSize: '32px',
    fontWeight: 600,
    paddingBottom: '1rem',
    marginBottom: '1rem',
    borderBottom: '1px solid rgba(255, 255, 255, .1)',
    color: 'var(--text-primary)'
}))

const ModalContent = styled(Box)(({ theme }) => ({
    margin: 'auto 0',
    color: 'var(--text-primary)'
}))

const ModalActions = styled(Box)(({ theme }) => ({
    paddingTop: '1rem',
    marginTop: '1rem',
    borderTop: '1px solid rgba(255, 255, 255, .1)'
}))

type ModalProps = {
    handleClose: () => void;
    id?: string;
    open: boolean;
    children: any;
    title?: any;
    actions?: any;
    width?: string;
    closeButton?: boolean;
    zIndex?: number;
    padding?: boolean;
    style?: React.CSSProperties;
    contentStyle?: React.CSSProperties;
    ref?: React.Ref<unknown>;
    onScroll?: React.UIEventHandler<HTMLDivElement>;
}

const Modal = ({ id, handleClose, open, children, onScroll, title, actions, ref, width, closeButton, zIndex = 1300, padding = true, style, contentStyle}: ModalProps) => {

    useEffect(() => {
        document.body.style.overflow = 'auto';
        
        if(open) {
          document.body.style.overflow = 'hidden';
        } else {
          
        }
      }, [open])


    return (
        <React.Fragment>
            {open && (
                <ModalWrapper
                    onClick={handleClose}
                    sx={{ zIndex: zIndex }}
                >
                    <ModalContainer
                        ref={ref}
                        onClick={(e) => e.stopPropagation()}
                        style={style}
                        id={id || "modal-container"}
                        sx={{ width: width ? width : null, padding: padding ? '' : '0' }}
                        onScroll={onScroll}
                        className="styled-scroll"
                    >
                        {closeButton && (
                            <IconButton onClick={handleClose} sx={{ position: 'absolute', top: 5, right: 5, color: 'text.primary' }}>
                                <CloseIcon />
                            </IconButton>
                        )}
                        {title && (
                            <ModalTitle>{title}</ModalTitle>
                        )}

                        <ModalContent sx={{ padding: padding ? '' : '0', ...contentStyle }}>{children}</ModalContent>
                        {actions && (
                            <ModalActions>{actions}</ModalActions>
                        )}

                    </ModalContainer>

                </ModalWrapper>
            )}

        </React.Fragment>
    )
}

export default Modal