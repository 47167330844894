import React, { useEffect, useState } from 'react'
import Modal from '../Modal'
import Pagination from '@mui/material/Pagination';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Button, styled } from '@mui/material'
import { API_URL, formatDate } from '../../utils';

const GiveawayTable = styled('table')(({ theme }) => ({
    width: '100%',

    borderSpacing: '0 6px',
    
    color: 'rgb(255, 255, 255, .8)',


    '& > th,td': {
        padding: '1rem .5rem',
        textAlign: 'center'
    },

    'th, td': {
        padding: '1.25rem',

    },

    
    'tr > td': {
        borderTop: '2px solid transparent',
        borderBottom: '2px solid transparent',
        borderColor: theme.palette.primary.border,     
    },

    'td:first-of-type': {
        borderLeft: '2px solid transparent',
        borderRadius: '.5rem 0 0 .5rem',
    },

    'td:nth-last-of-type(1)': {
        borderRight: '2px solid transparent',
        borderRadius: '0 .5rem .5rem 0',
    },

   
    'tr:nth-of-type(even)': {
        'td': {
            backgroundColor:  theme.palette.primary.panel,          
            borderColor: theme.palette.primary.border,
        },
    },

    'tr:nth-of-type(odd)': {
        'td': {
            backgroundColor: '#262230',
            borderColor: theme.palette.primary.border,

        }
    },


}))

const WinnerContainer = styled(Box)(({ theme }) => ({

    display: 'flex',
    alignItems: 'center',

    'img': {
        width: '32px',
        height: '32px',
        borderRadius: '50%',
        marginRight: '1rem'
    }

}))
const VIPGiveawayHistoryModal = ({ open, handleClose }) => {

    const [activePage, setActivePage] = useState(1);
    const [maxPage, setMaxPage] = useState(10);

    const [giveaways, setGiveaways] = useState(null);

    const changePage = (event, value) => {
        setActivePage(value);
    }

    useEffect(() => {
        if (open) {
            getGiveaways(); 
        }
        
    }, [activePage, open])

    const getGiveaways = () => {
        fetch(`${API_URL}/giveaway/vip/page/${activePage}`)
        .then((response) => {
            if (!response.ok) return false
            else return response.json()
        }).then((data) => {
            if (data && !data.error){
                setGiveaways(data.data);
                setMaxPage(data.pages);
            }
        }).catch((err) => {
    
        });
    }

    return (
        <React.Fragment>
            <Modal
                open={open}
                handleClose={handleClose}
                title={<span>Previous Super 500 Casino Giveaways</span>}
                width="900px"
                actions={
                    <Box display="flex">
                        <Button variant='contained' sx={{ marginLeft: 'auto' }} onClick={handleClose}>Close</Button>
                    </Box>}
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    sx={{ height: '100%' }}
                >
                    <Box
                        display="flex"
                        flexDirection="column"
                        sx={{ width: '100%'}}
                    >
                        {giveaways && giveaways.length > 0 ? (
                            <GiveawayTable>
                                <thead>
                                    <tr>
                                        <td>ID</td>
                                        <td>Date</td>
                                        <td>Amount</td>
                                        <td>Winner</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {giveaways.map((giveaway, index) => (
                                        <tr key={`giveaway-vip-history-row-${giveaway._id}`}>
                                            <td>{giveaway.vip_gw_id}</td>
                                            <td>{formatDate(giveaway.timestamp, "time")}</td>
                                            <td>${giveaway.amount}</td>
                                            <td>
                                                <WinnerContainer>
                                                    <img src={giveaway.user.avatar} alt="" />
                                                    <Typography variant="span">{giveaway.user.display_name}</Typography>
                                                </WinnerContainer>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </GiveawayTable>
                        ) : null}
                    </Box>
                    <Pagination
                        count={maxPage}
                        page={activePage}
                        onChange={changePage}
                        color="primary"
                        sx={{ marginTop: '.5rem' }}
                    />
                </Box>

            </Modal>
        </React.Fragment>
    )
}

export default VIPGiveawayHistoryModal