import { createSlice } from "@reduxjs/toolkit";
import { BotInventory } from "../../shared/steam.interface";


const initialState: {
    active: boolean,
    time_until_next: number,
    eligible_viewers: string[],
    recent_wins: any[],
    bot_inventory: BotInventory
} = {
    active: false,
    time_until_next: 0,
    eligible_viewers: [],
    recent_wins: [],
    bot_inventory: {
        golds: [],
        coverts: [],
        classifieds: [],
        restricteds: [],
        milspecs: [],
        amountOfGolds: 0,
        amountOfCoverts: 0,
        amountOfClassifieds: 0,
        amountOfRestricteds: 0,
        amountOfMilspecs: 0,
    }
}

export const csgoGiveawaySlice = createSlice({
    name: 'csgo_giveaway',
    initialState,
    reducers: {
        setCsgoGiveawayInfo: (state, action) => {
            if(action.payload.active) state.active = action.payload.active
            if(action.payload.eligible_viewers) state.eligible_viewers = action.payload.eligible_viewers
            if(action.payload.time_until_next) state.time_until_next = action.payload.time_until_next
        },
        setCsgoGiveawayEligibleViewers: (state, action) => {
            state.eligible_viewers = action.payload
        },
        setCsgoGiveawayActive: (state, action) => {
            state.active = action.payload
        },
        setCsgoGiveawayTimeUntilNext: (state, action) => {
            state.time_until_next = action.payload
        },
        setCsgoGiveawayRecentWins: (state, action) => {
            state.recent_wins = action.payload
        },
        addCsgoGiveawayRecentWin: (state, action) => {
            state.recent_wins = [action.payload, ...state.recent_wins || []]
        },
        setCsgoGiveawayBotInventory: (state, action) => {
            state.bot_inventory = action.payload
        },
    }
})

export const { setCsgoGiveawayInfo, setCsgoGiveawayEligibleViewers, setCsgoGiveawayTimeUntilNext, setCsgoGiveawayActive, 
    setCsgoGiveawayBotInventory, setCsgoGiveawayRecentWins, addCsgoGiveawayRecentWin } = csgoGiveawaySlice.actions

export const selectCsgoGiveawayInfo = (state: any) => state.csgo_giveaway
export const selectCsgoBotInventory = (state: any) => state.csgo_giveaway.bot_inventory

export default csgoGiveawaySlice.reducer