import { createSlice } from "@reduxjs/toolkit";
import { SlotBet } from "../../shared/slotbet.interface";

const initialState: {
    slotbets: Array<SlotBet>
} = {
    slotbets: []
}

export const slotbetsSlice = createSlice({
    name: 'slotbets',
    initialState,
    reducers: {
        setSlotbets: (state, action) => {
            state.slotbets = action.payload
        },
        addSlotbet:  (state, action) => {
            state.slotbets = [action.payload, ...state.slotbets]
        },
        slotbetUpdated:  (state, action) => {
            const index = state.slotbets.findIndex((slotbet) => slotbet._id === action.payload._id);
            if(index !== -1) {
                state.slotbets[index] = { ...state.slotbets[index], ...action.payload }
            } else {
                state.slotbets = [action.payload, ...state.slotbets]
            }
        },
        slotbetBetPlaced: (state, action) => {
            const index = state.slotbets.findIndex((slotbet) => slotbet._id === action.payload.slotBetId)
            if(index !== -1) {
                state.slotbets[index] = { ...state.slotbets[index], teamOne: { ...state.slotbets[index].teamOne, ...action.payload.teamOne }, teamTwo: { ...state.slotbets[index].teamTwo, ...action.payload.teamTwo }}
            }
        },
    }
})


export const { setSlotbets, addSlotbet, slotbetBetPlaced, slotbetUpdated } = slotbetsSlice.actions

export const selectSlotBets = (state: any) => state.slotbets.slotbets

export default slotbetsSlice.reducer