import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    messages: []
}

export const messageSlice = createSlice({
    name: 'messages',
    initialState,
    reducers: {
        setMessages: (state, action) => {
            state.messages = action.payload
        },
        addMessage: (state, action) => {
            state.messages = [action.payload, ...state.messages]
        },
        updateMessage: (state, action) => {
            state.messages = state.messages.map((item) => (
                item._id === action.payload._id ? { ...item, ...action.payload } : item
            ))
        },
        setMessageRead: (state, action) => {
            state.messages = state.messages.map((item) => (
                item._id === action.payload._id ? { ...item, read: true } : item
            ))
        },
    }
})

export const { setMessages, addMessage, updateMessage, setMessageRead } = messageSlice.actions

export const selectMessages = state => state.messages.messages

export const selectUnReadMessages = state => state.messages.messages.filter(message => message.read === false)

export default messageSlice.reducer