import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
    selectRollbitGiveawayInfo,
    setRollbitGiveawayInfo,
    setRollbitGiveawayRecentWins, setRollbitGiveawayTimeUntilNext
} from '../../redux/giveaway/rollbitGiveawaySlice';

import { styled } from '@mui/material'

import RollbitGiveawayBox from '../../components/Giveaway/CommonGiveawayBox'

import { API_URL } from '../../utils'
import InfoIcon from '@mui/icons-material/Info';
import { ReactComponent as Stake } from '../../icons/sites/stake.svg'
import { checkCasinoEligibilityToast } from '../../components/Toasts/GiveawayEligibilityToast'
import CommonGiveawayHistoryModal from '../../components/Giveaway/CommonGiveawayHistoryModal'
import CommonGiveawayOddsModal from '../../components/Giveaway/CommonGiveawayOddsModal'

const InfoValueText = styled(Typography)(({ theme }) => ({
    fontSize: '40px',
    fontWeight: 600,
}))

const InfoDescriptionText = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.secondary,
}))

const GiveawaysNotActiveText = styled(Typography)(({ theme }) => ({
    fontSize: '20px'
}))

const LinkText = styled('a')(({ theme }) => ({
    color: theme.palette.text.secondary,
    display: 'flex',
    alignItems: 'center',
    'svg': {
        marginRight: '.5rem'
    }
}))

const fetchRollbitGiveawayInfo = (dispatch) => {
    fetch(`${API_URL}/giveaway/casino/info`, {
        method: 'GET',
        credentials: 'include'
    })
        .then((response) => {
            if (!response.ok) return false
            else return response.json()
        }).then((data) => {
            if (data && !data.error) {
                dispatch(setRollbitGiveawayInfo(data))
                dispatch(setRollbitGiveawayTimeUntilNext(data.time_until_next))
            }
        }).catch((err) => {

        });
}

const fetchRollbitRecentWins = (dispatch) => {
    fetch(`${API_URL}/giveaway/casino/recentwinners`, {
        method: 'GET',
        credentials: 'include'
    })
        .then((response) => {
            if (!response.ok) return false
            else return response.json()
        }).then((data) => {
            if (data && !data.error) dispatch(setRollbitGiveawayRecentWins(data))
        }).catch((err) => {

        });
}

const updateCountdownTime = (countdownEnd, setCountdownString) => {

    let distance = countdownEnd - Date.now();

    let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    let seconds = Math.floor((distance % (1000 * 60)) / 1000);

    let countString = ``

    if (minutes > 0) countString += `${minutes}m `
    if (seconds > 0) countString += `${seconds}s`;

    if(countString === ``) countString = 'Rolling soon';

    setCountdownString(countString)
}

export const CommonGiveawaysPage = () => {
    const dispatch = useDispatch();

    const rollbitGiveawayInfo = useSelector(selectRollbitGiveawayInfo)

    const [rollbitHistoryModalOpen, setRollbitHistoryModalOpen] = useState(false);

    const [rollbitGiveawayOddsModalOpen, setRollbitGiveawayOddsModalOpen] = useState(false);

    const [countdownString, setCountdownString] = useState(0);

    useEffect(() => {
        fetchRollbitGiveawayInfo(dispatch)
        fetchRollbitRecentWins(dispatch)
    }, [dispatch])

    useEffect(() => {

        if (rollbitGiveawayInfo.time_until_next <= 0) {
            return false;
        }
    
        let countdownEnd = Date.now() + rollbitGiveawayInfo.time_until_next;
    
        const interval = setInterval(() => {
            updateCountdownTime(countdownEnd, setCountdownString)
        }, 1000)

        return () => {
            clearInterval(interval)
        }

    }, [rollbitGiveawayInfo.time_until_next, setCountdownString])


    const checkGiveawayEligiblity = () => {
        checkCasinoEligibilityToast();
    }

    return (
        <Box>
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" sx={{ marginTop: '4rem', marginBottom: '3rem', color: 'var(--text-primary)' }}>
                <Stake style={{ height: '64px', width: 'auto' }} />
                <Typography variant="h4" fontWeight="bold" sx={{ marginTop: '2rem' }}>Win up to $1,000</Typography>
                <Typography variant="h6" sx={{ margin: '.5rem 0' }}>Every 20 Minutes</Typography>
                <Typography sx={{ color: 'primary.light', cursor: 'pointer' }} onClick={() => setRollbitGiveawayOddsModalOpen(true)}>View Odds</Typography>
            </Box>
            <Box
                display="flex"
                flexDirection="column"
                sx={{
                    padding: '2rem 1rem 2rem 1rem',
                    backgroundColor: 'var(--primary-bg-soft)',
                    boxShadow: 'inset 0 0 12px rgba(20, 16, 24, .5)',
                }}
            >
                {rollbitGiveawayInfo.active ? (
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <InfoValueText>
                                {countdownString ? countdownString : 'Getting Time Left...'}
                            </InfoValueText>
                            <InfoDescriptionText>
                                Until next Giveaway
                            </InfoDescriptionText>

                        </Box>

                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                            sx={{
                                marginTop: 2
                            }}
                        >
                            <InfoValueText>
                                {rollbitGiveawayInfo.eligible_viewers.length}
                            </InfoValueText>
                            <InfoDescriptionText>
                                Eligible Viewers
                            </InfoDescriptionText>
                        </Box>
                        <Typography component="a" sx={{ cursor: 'pointer', color: 'primary.main', marginTop: '.5rem'}} onClick={() => checkGiveawayEligiblity()}>
                             Check Eligiblity
                        </Typography>
                    </Box>
                ) : (
                    <Box display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        textAlign="center"
                        sx={{ color: 'var(--text-secondary)'}}
                
                    >
                            <Typography variant="h4" marginBottom=".75rem">
                                Giveaways not active
                            </Typography>
                            <Typography marginBottom=".25rem">
                                Check back when Prodigy is live
                            </Typography>
                            <a href="https://kick.com/prodigyddk" style={{ color: 'var(--primary-main)' }}>

                                <Typography variant="span">
                                    https://kick.com/prodigyddk
                                </Typography>
                            </a>
                    </Box>
                )}

                {rollbitGiveawayInfo.recent_wins && rollbitGiveawayInfo.recent_wins.length > 0 ? (
                    <Box
                        display="flex"
                        flexDirection="column"
                    >
                        <Box
                            display="flex"
                            alignItems="center"
                            sx={{
                                marginTop: 4,
                                scrollbarColor: '#49547a #404761',
                                scrollbarWidth: 'thin',
   
                                overflowX: 'scroll',
                                paddingBottom: 2,
                            }}
                        >
                            {rollbitGiveawayInfo.recent_wins.map((giveaway, index) => (
                                <RollbitGiveawayBox key={`rollbit-recent-wins-${index}`} id={`rollbit-${index}`} casino={giveaway.casino} amount={giveaway.amount} type={giveaway.type} user={giveaway.user} date={giveaway.date} />
                            ))}
                        </Box>
                        <Typography
                            sx={{ cursor: 'pointer', color: 'primary.light', margin: '0 auto', marginTop: '1rem' }}
                            onClick={() => setRollbitHistoryModalOpen(true)}
                        >
                            View All
                        </Typography>
                    </Box>
                ) : (<Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                        marginTop: '1.5rem',
                        color: 'rgba(255, 255, 255, .8)',
                        fontSize: 18
                    }}
                >
                    No Giveaways have been rolled yet
                </Box>
                )}
            </Box>
            <Box display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center"  sx={{ paddingTop: '1rem', color: 'var(--text-secondary)', maxWidth: '450px', width: '100%', margin: '0 auto'}}>
                <Typography variant="span" sx={{ margin: '.5rem auto 1rem auto', fontWeight: 'bold', fontSize: '18px'}}>Giveaway Info</Typography>
                <Box display="flex" alignItems="center">
                    <InfoIcon sx={{ marginRight: '.5rem' }} />
                    <Typography>Every 20 Minutes a random Winner is drawn</Typography>
                </Box>
                <Box display="flex" alignItems="center" sx={{ marginTop: '.25rem'}}>
                    <InfoIcon sx={{ marginRight: '.5rem' }} />
                    <Typography>Giveaways are on <b>Stake</b></Typography>
                </Box>
                <Box display="flex" alignItems="center" sx={{ marginTop: '.25rem'}}>
                    <InfoIcon sx={{ marginRight: '.5rem' }} />
                    <Typography>Giveaways are only active when  <Typography to="/profile" component="a" sx={{ color: 'var(--primary-main)' }} href="https://www.twitch.tv/prodigyddk" target="href">ProdigyDDK</Typography> is live</Typography>
                </Box>
                <Box display="flex" alignItems="center" sx={{ marginTop: '.25rem'}}>
                    <InfoIcon sx={{ marginRight: '.5rem' }} />
                    <Typography variant="span">Prizes range from $1 - $1000. <Typography variant="span" sx={{ color: 'var(--primary-main)', cursor: 'pointer' }} onClick={() => setRollbitGiveawayOddsModalOpen(true)}>View Odds</Typography>
                    </Typography>
                </Box>
                
                
                
            </Box>
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" sx={{ paddingTop: '1rem', color: 'var(--text-secondary)'}}>
                <Typography variant="span" sx={{ marginTop: '2rem', fontWeight: 'bold', fontSize: '18px'}}>Requirements</Typography>
                <ul style={{ padding: '0'}}>
                    <li>Follow <Typography to="/profile" component="a" sx={{ color: 'var(--primary-main)' }} href="https://kick.com/prodigyddk" target="href">ProdigyDDK</Typography>
                    </li>
                    <li>Link your Kick account under <Link className='link' to="/profile">Profile</Link></li>
                    <li>Be active in Kick Chat</li>
                    <li>Set your Stake Username under <Typography to="/profile" component={Link} sx={{ color: 'var(--primary-main)' }}>Profile</Typography></li>
                </ul>
            </Box>
            <CommonGiveawayHistoryModal open={rollbitHistoryModalOpen} handleClose={() => setRollbitHistoryModalOpen(false)} />
            <CommonGiveawayOddsModal open={rollbitGiveawayOddsModalOpen} handleClose={() => setRollbitGiveawayOddsModalOpen(false)} />
        </Box>
    )
}

export default CommonGiveawaysPage
