import React from 'react'
import LoadingCircle from './LoadingCircle'

const Loading = () => {
  return (
    <div className='loading'>
        <LoadingCircle loadingText='Loading' />
    </div>
  )
}

export default Loading