import React, { useState } from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'

import YouTubeIcon from '@mui/icons-material/YouTube';
import TwitterIcon from '@mui/icons-material/Twitter';

import { ReactComponent as Twitch } from '../../icons/twitch.svg';
import { ReactComponent as Discord } from '../../icons/discord.svg';
import { ReactComponent as Instagram } from '../../icons/instagram.svg';
import { Link } from 'react-router-dom'

import { styled } from '@mui/material'

const FooterButton = styled(IconButton)(( { theme }) => ({
    margin: '0 .5rem',
    padding: 8,
    borderRadius: '50%',
    color: theme.palette.text.secondary,
    border: `2px solid ${theme.palette.text.disabled}`,
    transition: 'border .2s ease-in-out 0ms, opacity .2s ease-in-out, fill .2s ease-in-out',

    'svg': {
        height: '36px',
        width: '36px',
        display: 'inline-block',
        flexShrink: 0,
        userSelect: 'none',
        fill: 'currentColor',
        fontSize: '1.5em',
        transition: 'fill .3s cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },

    '&.twitch': {
        ':hover svg': {
            fill: 'rgb(182, 134, 247)',
            filter: 'drop-shadow(0 0 .33rem rgba(182, 134, 247, .5))'
        }

    },

    '&.youtube': {
        color: '#dd0000',
        borderColor: '#dd0000',

        ':hover svg': {
            fill: '#dd0000',
            filter: 'drop-shadow(0 0 .33rem #dd000080)',
        },
    },

    '&.twitter': {

        ':hover svg': {
            fill: '#1DA1F2',
            filter: 'drop-shadow(0 0 .33rem #1DA1F280)'
        }
    },

    '&.discord': {
        '&:hover svg': {
            fill: '#7289da',
            filter: 'drop-shadow(0 0 .33rem #7289da80)'
        },

        '&:hover + twitch': {
            borderColor: theme.palette.text.primary,
            opacity: '.2',
            'svg': {
                fill: theme.palette.text.primary
            }
        }
    },

    '&.inactive': {
        borderColor: theme.palette.text.primary,
        opacity: '.2',
        'svg': {
            fill: theme.palette.text.primary
        }
    }
}))



const Footer = () => {

    const [hoverIndex, setHoverIndex] = useState<null | number>(null);
    
    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            sx={{
                margin: { xs: 'auto 0 5rem 0', md: 'auto 0 2rem 0' },
                paddingTop: '4rem'
            }}
        >
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{ marginBottom: '.75rem' }}
            >
                <a href="https://www.youtube.com/c/ProdigyDDK" target="_blank" rel="noreferrer">
                    <FooterButton className={`youtube ${hoverIndex !== 0 && hoverIndex !== null && 'inactive'}`}
                        onMouseEnter={() => setHoverIndex(0)}
                        onMouseLeave={() => setHoverIndex(null)}
                    >
                        <YouTubeIcon />
                    </FooterButton>
                </a>
                <a href="https://www.twitch.tv/prodigyddk" target="_blank" rel="noreferrer">
                    <FooterButton
                        className={`twicth ${hoverIndex !== 1 && hoverIndex !== null && 'inactive'}`} sx={{ padding: '10px', color: '#6441a5', borderColor: '#6441a5' }}
                        onMouseEnter={() => setHoverIndex(1)}
                        onMouseLeave={() => setHoverIndex(null)}
                    >
                        <Twitch style={{ width: '32px', height: '32px' }} />
                    </FooterButton>
                </a>
                <a href="https://twitter.com/ProdigyDDK" target="_blank" rel="noreferrer">
                    <FooterButton 
                        className={`twitter ${hoverIndex !== 2 && hoverIndex !== null && 'inactive'}`} sx={{ color: '#1DA1F2', borderColor: '#1DA1F2' }}
                        onMouseEnter={() => setHoverIndex(2)}
                        onMouseLeave={() => setHoverIndex(null)}
                    >
                        <TwitterIcon />
                    </FooterButton>
                </a>
                <a href="https://www.instagram.com/prodigy.ddk/" target="_blank" rel="noreferrer">
                    <FooterButton
                        className={`instagram ${hoverIndex !== 3 && hoverIndex !== null && 'inactive'}`} sx={{ borderColor: '#C13584' }}
                        onMouseEnter={() => setHoverIndex(3)}
                        onMouseLeave={() => setHoverIndex(null)}
                    >
                        <Instagram />
                    </FooterButton>
                </a>
                <a href="https://discord.gg/EaMVs4Php4" target="_blank" rel="noreferrer">
                    <FooterButton
                        className={`discord ${hoverIndex !== 4 && hoverIndex !== null && 'inactive'}`} sx={{ color: '#7289DA', borderColor: '#7289DA' }}
                        onMouseEnter={() => setHoverIndex(4)}
                        onMouseLeave={() => setHoverIndex(null)}
                    >
                        <Discord />
                    </FooterButton>
                </a>

            </Box>
            <Box 
                display="flex"
                alignItems="center"
            >
                <Typography component={Link} to="/faq" sx={{ color: 'text.secondary', fontSize: '16px', opacity: '.9', '&:hover': { color: 'primary.main' }}}>
                    FAQ
                </Typography>
                <Typography sx={{ color: 'text.secondary', opacity: '.9', fontSize: '16px', margin: '0 .5rem'}}>&#8226;</Typography>
                <Typography component={Link} to="/privacy" sx={{ color: 'text.secondary',  fontSize: '16px', opacity: '.9', '&:hover': { color: 'primary.main' }}}>
                    Privacy
                </Typography>
                <Typography sx={{ color: 'text.secondary', opacity: '.9', fontSize: '16px', margin: '0 .5rem'}}>&#8226;</Typography>
                <Typography component="a" href="https://firstgrade-shop.de/collections/prodigy" sx={{ color: 'text.secondary',  fontSize: '16px', opacity: '.9', '&:hover': { color: 'primary.main' }}} target="_blank" rel="noreferrer">
                    Merch
                </Typography>
            </Box>


        </Box>

    )
}

export default Footer
