import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import GamdomInfoModal from './Casino/GamdomInfoModal';
import AuthModal from './Profile/AuthModal';

const ModalQuery = () => {
    const [authModalOpen, setAuthModalOpen] = useState(false);
    const [gamdomInfoModalOpen, setGamdomInfoModalOpen] = useState(false);
    const location = useLocation();

    const useQuery = () => new URLSearchParams(location.search);
    const query = useQuery();
  
    const modal = query.get('modal');

    useEffect(() => {
      if (modal) {
        if (modal === "auth") {
          setAuthModalOpen(true);
        } else if (modal === "gamdom") {
          setGamdomInfoModalOpen(true);
        }
      }
    }, [modal])

  

  return (
      <React.Fragment>
        {authModalOpen && <AuthModal open={authModalOpen} handleClose={() => setAuthModalOpen(false)} />}
        {gamdomInfoModalOpen && <GamdomInfoModal open={gamdomInfoModalOpen} handleClose={() => setGamdomInfoModalOpen(false)} />}
      </React.Fragment>
  )
}

export default ModalQuery