import React, { useEffect, useState } from 'react'
import Modal from '../Modal'
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';
import { socket } from '../../socket';
import { styled } from '@mui/material';

const BattleWinnerContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  color: 'var(--text-secondary)',
  background: 'linear-gradient(rgba(23, 20, 119, .15) 0%, rgba(85, 30, 119, .25) 100%)',

  '.avatar': {
    height: '64px',
    width: '64px',
    borderRadius: '50%',
    border: `2px solid transparent`,
    borderColor: theme.palette.success.light,
    boxShadow: `0 0 1rem ${theme.palette.success.light}a1`,
  },

  '.item': {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    background: 'var(--primary-bg-highlight)',
    borderRadius: '12px',
    padding: '1rem',
    boxShadow: `0 0 1rem rgb(0, 0, 0, .3)`,
    position: 'relative',
  },

  '.item::before': {
    content: '""',
    position: 'absolute',
    top: '0',
    left: '0',
    height: '100%',
    width: '100%',
    borderRadius: '12px',
    backgroundColor: 'rgba(23, 20, 119, .25)'
  },

  '.item img': {
    height: '48px',
    width: '48px',
    marginRight: '1rem',
  },

  '.item *': {
    zIndex: 1
  }
}))

const getRarityBorder = (rarity: string) => {
  rarity = rarity.toLowerCase();
  switch(rarity) {
      case "common": 
          return 'rgba(240, 240, 240, 0.33)'
      case "uncommon":
          return 'rgba(74, 134, 226, 0.33)'
      case "rare":
          return 'rgba(131, 64, 179, 0.9)'
      case "epic":
          return 'rgba(131, 64, 179, 0.9)'
      case "legendary":
          return 'rgba(255, 71, 82, 0.33)'
      default:
          return 'rgba(240, 240, 240, 0.33)'
  }
}

const BattleWinnerModal = ({ battleId }: { battleId: number | undefined }) => {

  const [battle, setBattle] = useState<any>(null);
  const [open, setOpen] = useState(false);
  const [amountOfNothingItem, setAmountOfNothingItem] = useState(0);

  const handleClose = () => {
    setOpen(false);
    setBattle(null);
  }

  useEffect(() => {
    
    
    if(!socket) return;
    socket.on("battle", (eventBattleId: string | null, event: string | null, data: any | null) => {
      if(!eventBattleId || !battleId || !event || !data) return;
      if (event === "battleFinished") {
        if(eventBattleId.toString() !== battleId.toString()) return;
        setBattle(data);
        setTimeout(() => {
          setOpen(true);
        }, 1000)
       
      }
  })
  }, [battleId])

  useEffect(() => {
    if(battle) {
      let amount = 0;
      battle.gameRounds.forEach((round: any) => {
        round.drops.forEach((drop: any) => {
          if(drop.item.value === 0) {
            amount++;
          }
        })
      })

      setAmountOfNothingItem(amount);
    }
  }, [battle])



  return (
    <React.Fragment>
    <Modal
      open={open}
      handleClose={handleClose}
      closeButton={true}
      width="800px"
      padding={false}

    >
        {open && (
          <BattleWinnerContainer padding="2rem 0 0 0">
            <Typography variant="h3" sx={{ marginBottom: '4rem', color: 'text.secondary' }}>
              Battle Winner
            </Typography>
            {battle.players.map((player: any, index: number) => (
              <React.Fragment key={`p-${index}`}>
                {player.isWinner && (
                  <Box className="player-win" display="flex" alignItems="center" flexDirection="column">
                    <img className='avatar' src={player.user.avatar} alt="" style={{ filter: 'drop-shadow(0 0 2rem rgb(50, 200, 50) ' }} />
                    <Typography variant="h5" sx={{ marginTop: '1rem', color: 'var(--text-primary)' }}>{player.user.display_name}</Typography>
                  </Box>
                )}
              </React.Fragment>
            ))}

            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              width="100%"
              padding="1rem"
              sx={{
                marginTop: '4rem',
              }}
            >
              <Typography variant="h4" sx={{ margin: '1rem 0 2rem 0' }}>Winner's Bag</Typography>

              <Box
                display="flex"
                flexDirection="column"
                width="100%"
                rowGap="1rem"
                maxHeight="400px"
                sx={{ overflowY: 'auto' }}
              >
                {battle.gameRounds.map((round: any, index: number) => (
                  <React.Fragment key={`gr-${index}`}>
                    {round.drops.map((drop: any, dropIndex: number) => (
                      <React.Fragment key={`drop-${index}-${dropIndex}`}>
                        {drop.item.value > 0 && (
                          <Box className="item" sx={{ borderLeft: '3px solid transparent', borderColor: getRarityBorder(drop.item.rarity) }}>
                            <img src={drop.item.image} alt="" />
                            <Typography variant="h6">{drop.item.name}</Typography>
                            <Box display="flex" alignItems="center" marginLeft="auto">

                              <img src={battle.players[dropIndex].user.avatar} alt="" style={{
                                height: '32px', width: '32px',
                                borderRadius: '50%', border: '2px solid var(--primary-bg-soft)',
                                boxShadow: '0 0 1rem rgb(0, 0, 0, .3)',
                                marginRight: '.5rem'
                              }} />
                              <Typography variant="h6">{battle.players[dropIndex].user.display_name}</Typography>
                            </Box>
                          </Box>
                        )}
                      </React.Fragment>

                    ))}
                  </React.Fragment>
                ))}
                {amountOfNothingItem > 0 && (
                  <Box className="item" sx={{ borderLeft: '3px solid transparent', borderColor: getRarityBorder(""), opacity: '.9' }}>
                    <img src={"https://api.prodigyddk.com/images/reward_empty_stare.png"} alt="" />
                    <Typography variant="h6">Nothing</Typography>
                    <Box display="flex" alignItems="center" marginLeft="auto">

                      <Typography variant="h6">x{amountOfNothingItem}</Typography>
                    </Box>
                  </Box>
                )}
              </Box>

            </Box>

          </BattleWinnerContainer>
        )}

    </Modal>
    </React.Fragment>
  )
}

export default BattleWinnerModal