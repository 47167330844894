import React, { useState } from 'react'
import Modal from '../Modal'
import Box from '@mui/material/Box'
import { TextField } from '@mui/material'
import Button from '@mui/material/Button'
import { apiFetch, fetchAPI } from '../../utils'
import { Item } from '../../shared/item.interface'
import { promocodeItemClaimed, promocodePointsClaimed } from '../Toasts/PromocodeToasts'
import { createErrorToast } from '../Toasts/ErrorToasts'

type Promocode = {
  _id: string
  code: string
  type: string
  amount?: number
  uses: number
  maxUses: number
  item?: Item
}

enum PromocodeType {
  ITEM = 'item',
  POINTS = 'points'
}

const ClaimPromocode = ({ open, handleClose }: { open: boolean, handleClose: () => void }) => {

  const [promocode, setPromocode] = useState("")

  async function claimPromocode() {
    try {
      const data = await apiFetch('/promocode/claim', 'POST', {
        body: JSON.stringify(
          { 
            code: promocode 
          })
      }) as { data: Promocode }

      if(data) {
        if(data.data.type === PromocodeType.ITEM && data.data.item) {
          promocodeItemClaimed(data.data.item)
        } else if (data.data.type === PromocodeType.POINTS && data.data.amount) {
          promocodePointsClaimed(data.data.amount)
        }
        
      }
    } catch (e) {
      createErrorToast((e as Error).message || "Something went wrong")
    }

  }



  return (
    <Modal
        open={open}
        handleClose={handleClose}
        closeButton={true}
        title="Claim Code"
        width="500px"
        actions={
          <Box display="flex" justifyContent="space-between">
            <Button variant="contained" color="secondary" onClick={claimPromocode}>Claim</Button>
            <Button variant="contained" onClick={() => handleClose()} sx={{ backgroundColor: 'var(--primary-bg-light)', '&:hover': { backgroundColor: 'var(--primary-bg-soft)' }}}>
              Close
            </Button>
          </Box>
        }
    >
      <Box>
        <TextField label="Promocode" value={promocode} onChange={(e) => setPromocode(e.target.value)} />
      </Box>
    </Modal>

  )
}

export default ClaimPromocode