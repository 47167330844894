import React, { useState } from 'react'
import { Box, Button, MenuItem, Select, Skeleton, Typography } from '@mui/material'
import { selectProfile } from '../../redux/profile/profileSlice'
import { useSelector } from 'react-redux'
import { ReactComponent as CoinIcon } from '../../icons/assets/coin.svg'
import useSWR from 'swr'
import { fetcher, isAPIImage } from '../../utils'
import { Case } from './types'
import { Link } from 'react-router-dom'
import RecentUnboxings from './RecentUnboxings'

enum ESortOptions {
    PRICE_ASC = 'price_asc',
    PRICE_DESC = 'price_desc',
    OPENED_ASC = 'opened_asc',
    OPENED_DESC = 'opened_desc',
}


const CaseSkeleton = () => {
    return (
        <Box display="flex" flexDirection="column" position="relative" gap="1rem" sx={{ padding: '1rem', backgroundColor: 'var(--primary-bg-soft)', borderRadius: '1rem' }}>
            <Skeleton variant="rounded" width="128px" height="96px" sx={{ position: 'absolute', left: '50%', top: '-48px', transform: 'translateX(-50%) rotate(5deg)' }}/>
            <Box display="flex" flexDirection="column" alignItems="center" gap=".25rem" flexGrow={1} sx={{ mt: '4rem' }}>
                <Skeleton variant="text" width="75%" height="1rem"/>
                <Skeleton variant="text" width="40%" height="1rem"/>
            </Box>
            <Skeleton variant="text" width="100%" height="4rem" sx={{ mt: 'auto' }} />
        </Box>
    )
}

const CaseContainer = ({ box }: { box: Case }) => {
    return (
        <Box display="flex" flexDirection="column" position="relative" gap="1.5rem" sx={{ boxShadow: '0 0 .5rem rgba(0,0,0, .5)', padding: '1rem', border: '1px solid var(--primary-border)', backgroundColor: 'var(--primary-bg-soft)', borderRadius: '1rem' }}>
            <Box component="img" src={isAPIImage(box.image)} alt={box.name} sx={{ position: 'absolute', left: '50%', height: '128px', width: 'auto', top: '-64px', transform: 'translateX(-50%)' }} />
            <Box display="flex" flexDirection="column" alignItems="center" gap=".25rem" flexGrow={1} sx={{ mt: '3.5rem' }}>
                <Typography component="span" sx={{ letterSpacing: '-.3px', fontSize: '18px' }}>{box.name}</Typography>
                <Typography component="span" sx={{ color: 'var(--text-secondary)', fontSize: '14px' }}>{Intl.NumberFormat("en-US").format(box.price)} <CoinIcon style={{ height: '14px', width: '14px' }} /></Typography>
            </Box>
            <Button component={Link} to={`/case/${box.unique_id}`} variant="contained" sx={{ height: '42px', mt: 'auto', borderRadius: '.5rem' }}>Open</Button>
        </Box>
    )
}


const CasesPage = () => {

    const profile = useSelector(selectProfile)

    const [sort, setSort] = useState<ESortOptions>(ESortOptions.PRICE_DESC)

    const { data, isLoading } = useSWR<Case[]>(`/cases?sort=${sort}`, fetcher)


  return (
    <Box
        display="flex"
        flexDirection="column"
        gap="2rem"
        width="100%"
    >
        <Box display="flex" flexDirection="column" className="page" maxWidth="1600px" flexGrow={1}>
            <Box display="flex" flexDirection="column" gap=".25rem" alignItems="center">
                <Typography variant="h3">Cases</Typography>
                {profile && (
                    <Typography sx={{ color: 'var(--text-secondary)' }}>You have  <CoinIcon style={{ height: '14px', width: '14px' }} /> <Typography component="span" sx={{ color: 'var(--text-primary)', fontWeight: 'bold' }}>{Intl.NumberFormat("en-US").format(profile.points)} Points</Typography></Typography>                
                )}
            </Box>

            <Box display="flex" sx={{ mt:'4rem', mb: '8rem' }}>
                    <Select value={sort} onChange={(e) => setSort(e.target.value as ESortOptions)} sx={{ marginLeft: 'auto' }}>
                        <MenuItem value={ESortOptions.PRICE_DESC}>Price: High to Low</MenuItem>
                        <MenuItem value={ESortOptions.PRICE_ASC}>Price: Low to High</MenuItem>
                        <MenuItem value={ESortOptions.OPENED_DESC}>Popularity: High to Low</MenuItem>
                        <MenuItem value={ESortOptions.OPENED_ASC}>Popularity: Low to High</MenuItem>
                    </Select>

            </Box>

            {isLoading ? (
                <Box display="grid" gap="2rem" sx={{ rowGap: '7rem', columnGap: '2rem', gridTemplateColumns: { xs: 'repeat(1, minmax(0, 1fr))', sm: 'repeat(2, minmax(0, 1fr))', md: 'repeat(3, minmax(0, 1fr))', lg: 'repeat(4, minmax(0, 1fr))', xl: 'repeat(5, minmax(0, 1fr))' }}}>
                    {new Array(10).fill(0).map((_, i) => (
                        <CaseSkeleton key={`case-skeleton-${i}`} />                  
                    ))}
                </Box>
            ) : !data ? (
                <></>
            ) : (
                <Box display="grid" gap="2rem" sx={{ rowGap: '7rem', columnGap: '2rem', gridTemplateColumns: { xs: 'repeat(1, minmax(0, 1fr))', sm: 'repeat(2, minmax(0, 1fr))', md: 'repeat(3, minmax(0, 1fr))', lg: 'repeat(4, minmax(0, 1fr))', xl: 'repeat(5, minmax(0, 1fr))' }}}>
                    {data.map((box, i) => (
                        <CaseContainer key={`case-${box._id}`} box={box} />                  
                    ))}
            </Box>
            )}
        
        </Box>

        <RecentUnboxings />
    </Box>
  )
}

export default CasesPage