import { Close } from '@mui/icons-material'
import { Box, Button, Drawer, IconButton, TextField, Typography } from '@mui/material'
import React from 'react'
import { SlotBattleTeamBet } from './types'
import { apiFetch, formatDate, isAPIImage } from '../../utils'
import { createErrorToast } from '../../components/Toasts/ErrorToasts'
import { createSuccessToast } from '../../components/Toasts/SuccessToasts'
import { ReactComponent as TicketIcon } from '../../icons/ticket.svg'
import { ReactComponent as CoinIcon } from '../../icons/assets/coin.svg'

const DrawerContainer = ({ bets, removeBet, clearBets, handleClose }: { bets: SlotBattleTeamBet[], clearBets: () => void, removeBet: (index: number) => void, handleClose: () => void }) => {

    const [bet, setBet] = React.useState<number>(0)

    async function createBet() {
        try {
            const data = await apiFetch('/slot-battle/bet', "POST", {
                body: JSON.stringify({
                    bet,
                    bets: bets.map((bet) => ({
                        slotbattleId: bet.slotbattleId,
                        teamId: bet.teamId,
                    }))
                })
            })

            if(data) {
                createSuccessToast("Bet created!")
                clearBets()
            }
        } catch(e) {
            if(e instanceof Error) {
                createErrorToast(e.message || "An error occurred")
            }           
        }
    }

    function getOdds() {
        const totalOdds = bets.reduce((acc, bet) => acc * (bet.odds || 0.01), 1)
        return 1 / totalOdds
    }
    
    return (
        <Box display="flex" flexDirection="column" sx={{ p: '1rem', pt: '0', height: '100%' }}>
                <Box display="flex" justifyContent="space-between" sx={{ borderBottom: '1px solid var(--primary-border)', mb: '1rem' }}>
                    <Typography variant="h6" sx={{ my: '1rem' }}>Bets</Typography>
                    <IconButton onClick={handleClose}>
                        <Close />
                    </IconButton>
                </Box>
                <Box display="flex" flexDirection="column" gap="1rem" flexGrow={1} overflow="auto">
                    {bets.length === 0 ? (
                        <Typography sx={{ fontStyle: 'italic', textAlign: 'center' }}>Add Bets</Typography>
                    ) : bets.map((bet, index) => (
                        <Button key={`drawer-bet-${index}`} 
                            sx={{ 
                                color: 'var(--text-primary) !important', 
                                transition: "background-color .2s ease-in-out",
                                '&:hover': { backgroundColor: 'var(--primary-bg-light)' },
                                display: 'flex',
                                gap: '1rem',
                                p: '1rem', 
                                borderRadius: '.5rem', 
                                backgroundColor: 'var(--primary-bg-soft)',
                                border: '1px solid var(--primary-border)',
                                justifyContent: 'flex-start'
                            }}
                            onClick={() => removeBet(index)}
                        >
                            <Box component="img" src={isAPIImage(bet.image)} alt={bet.name} sx={{ height: 'auto', width: '48px', borderRadius: '.5rem', border: '1px solid var(--primary-border)' }} />
                            <Box display="flex" flexDirection="column" alignItems="start" justifyContent="center" gap=".25rem" width="100%">
                                <Typography sx={{ fontSize: '14px', fontWeight: 'bold', textAlign: 'start' }}>{bet.name}</Typography>
                                <Box display="flex" gap="1rem" alignItems="center" justifyContent="space-between" width="100%">
                                    <Typography sx={{ p: '.25rem', backgroundColor: 'var(--primary-bg-light)', fontSize: '14px', fontWeight: 'bold' }}>{Intl.NumberFormat("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2}).format((bet.odds || 0.01) * 100)}%</Typography>
                                    <Typography sx={{ fontSize: '12px', color: 'var(--text-disabled)' }}>{formatDate(bet.eventAt)}</Typography>
                                </Box>
                                
                            </Box>
                            
                        </Button>
                    ))}
                    <Typography sx={{ fontSize: '14px', fontWeight: 'bold', color: 'var(--text-disabled)', mt: 'auto', mb: '1rem' }}>{bets.length} Slots</Typography>
                </Box>
                <Box display="flex" flexDirection="column" gap="1rem" sx={{ pt: '1rem', borderTop: '1px solid var(--primary-border)' }}>
                    <Box display="flex" gap="1rem" justifyContent="space-between">
                        <Box display="flex" flexDirection="column">                   
                            <Typography sx={{ fontSize: '14px', fontWeight: 'bold', letterSpacing: '-.3px', color: 'var(--text-primary)'}}>Payout Rate</Typography>
                            <Typography sx={{ color: 'var(--text-secondary)' }}>1 : {Intl.NumberFormat("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2}).format(getOdds())}</Typography>
                        </Box>
                        <Box display="flex" flexDirection="column" alignItems="flex-end">                   
                            <Typography sx={{ fontSize: '14px', fontWeight: 'bold', letterSpacing: '-.3px', color: 'var(--text-primary)', textAlign: 'end' }}>Payout</Typography>
                            <Box display="flex" gap=".25rem" alignItems="center">                               
                                <Typography sx={{ color: 'var(--text-secondary)', textAlign: 'end' }}>{Intl.NumberFormat("en-US", { minimumFractionDigits: 0, maximumFractionDigits: 0}).format(Math.min(100000, (bet || 1) * getOdds()))}</Typography>
                                <CoinIcon />
                            </Box>
                        </Box>
                    </Box>

                    <TextField variant="outlined" placeholder="Your Point bet..." sx={{ width: '100%' }} value={bet} onChange={(e) => setBet(parseInt(e.target.value) || 0)} />
                    <Box display="flex" gap="1rem">
                        <Button variant="contained" disabled={bets.length === 0} sx={{ width: '75%' }} onClick={() => createBet()}>Place Bet</Button>
                        <Button variant="contained" color="soft" sx={{ width: '25%' }} onClick={clearBets}>Clear</Button>

                    </Box>
                </Box>
            </Box>
    )
}

const BetDrawer = ({ bets, removeBet, clearBets, isOpen, handleOpen, handleClose }: { bets: SlotBattleTeamBet[], clearBets: () => void, removeBet: (index: number) => void, isOpen: boolean, handleOpen: () => void, handleClose: () => void }) => {

  return (
    <>
        <IconButton 
            sx={{ 
                transition: 'background-color .2s', 
                backgroundColor: 'var(--primary-main)', 
                '&:hover': { backgroundColor: 'var(--primary-dark)' }, 
                height: '64px', 
                width: '64px', 
                position: 'fixed', 
                bottom: { md: '1rem', xs: '5rem'}, 
                right: '1rem', 
                'svg': { 
                    height: '36px', 
                    width: '36px', 
                    fill: 'rgb(255,255,255) !important' 
                } 
            }}
            onClick={handleOpen}
        >
            <TicketIcon />
        </IconButton>
        <Drawer
            anchor="right"
            variant="temporary"
            onClose={handleClose}
            open={isOpen}
            disableScrollLock={true}
            PaperProps={{
                sx: {
                    backgroundColor: 'var(--primary-bg)',
                    width: '100%',
                    height: 'calc(100% - 80px)',
                    mt: '80px',
                }
            }}
            ModalProps={{
                keepMounted: true
            }}
            sx={{
                width: '100%',
                height: 'calc(100% - 80px)',     
                display: { xs: 'block', md: 'none' }
            }}
        >
            <DrawerContainer bets={bets} removeBet={removeBet} clearBets={clearBets} handleClose={handleClose} />
        </Drawer>
        <Drawer
            anchor="right"
            onClose={handleClose}
            variant="permanent"
            PaperProps={{
                sx: {
                    backgroundColor: 'var(--primary-bg)',
                    width: '340px',
                    borderLeft: '1px solid var(--primary-border)',
                    height: 'calc(100% - 80px)',
                    mt: '80px',
                    zIndex: 10,
                    transform: isOpen ? 'translateX(0px)' : 'translateX(350px)',
                    transition: 'transform .3s ease-in-out',
                    boxShadow: '0 0 1rem rgba(0,0,0, .5)'
                }
            }}
            sx={{
                flexShrink: 0,
                width: isOpen ? "340px" : "0px",
                height: 'calc(100% - 80px)',     
                transition: "width .3s ease-in-out",
                display: { xs: 'none', md: 'block' }
            }}
        >
            <DrawerContainer bets={bets} removeBet={removeBet} clearBets={clearBets} handleClose={handleClose} />
        </Drawer>
    </>
  )
}

export default BetDrawer