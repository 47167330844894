import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import React, { useState } from 'react'
import { createErrorToast } from '../../components/Toasts/ErrorToasts'
import { apiFetch } from '../../utils'
import { createSuccessToast } from '../../components/Toasts/SuccessToasts'
import TicketIcon from '@mui/icons-material/ConfirmationNumberOutlined';

const RaffleUseTicket = ({ raffleId, tickets, onTicketUse }: { raffleId: string, tickets: { _id: string, point_value: number }[], onTicketUse: (inventoryItemId: string) => void }) => {

    const [isOpen, setIsOpen] = useState(false)

    async function consumeTicket(ticketId: string) {
        try {
            const data = await apiFetch("/raffle/bet/ticket", "POST", {
                body: JSON.stringify({
                    raffleId,
                    inventoryItemId: ticketId
                })
            })

            if(data) {
                createSuccessToast("Joined raffle!")
                onTicketUse(ticketId)
            }

        } catch (e) {
            createErrorToast((e as Error).message || "Something went wrong")
        }
    }

  return (
    <>
        <Button disableRipple onClick={() =>  setIsOpen(true)} sx={{ gap: '.5rem', textTransform: 'none' }}>
            <TicketIcon /> Use Raffle Ticket
        </Button>
        <Dialog
            open={isOpen}
            onClose={() => setIsOpen(false)}
        >
            <DialogTitle sx={{ borderBottom: '1px solid var(--primary-border)', mb: '1rem' }}>
                Use Raffle Ticket
            </DialogTitle>
            <DialogContent>
                {tickets[0] ? (
                    <Typography>Use your Raffle Ticket and get an entry worth {Intl.NumberFormat("en-US").format(tickets[0].point_value)} Points!</Typography>
                ) : (
                    <Typography>You do not have any Tickets to use</Typography>
                )}
            </DialogContent>
            <DialogActions sx={{ justifyContent: "space-between", borderTop: '1px solid var(--primary-border)', p: '1rem'}}>
                <Button disabled={tickets.length === 0} variant="contained" onClick={() => tickets[0] && consumeTicket(tickets[0]._id)}>
                    Use
                </Button>
                <Button variant="contained" color="soft" onClick={() => setIsOpen(false)}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    </>
  )
}

export default RaffleUseTicket