
export interface User {
    _id: string
    twitchid: string
    display_name: string
    login: string
    avatar: string
    points: number
    first_joined: Date
    follow_date: Date
    permissions?: string[]  
    subscriptions?: unknown[]
    trade_url?: string
    eth_address?: string
    gamdom_username?: string
    discord_id?: string
    steam_id?: string
    kick_id?: number
    kick_username?: string
    clash_id?: string
    clashVerified?: boolean
    gamdom_id?: number
    gamdomVerified?: boolean
    hypedrop_id?: string
    hypedropVerified?: boolean
    roobetVerified?: boolean
    roobetUsername?: string
    roobetId?: string
    roobetVerifiedAt?: Date
    packdrawId?: string
    stakeUsername?: string
}


export interface IUser {
    _id: string
    twitchid: string
    display_name: string
    login: string
    avatar: string
    points: number
    first_joined: Date
    follow_date: Date
    permissions: string[]  
    subscriptions: unknown[]
    trade_url: string
    eth_address: string
    gamdom_username: string
    
    discord_id: string
    steam_id: string
    ip_flag?: boolean
    ip_whitelist?: boolean
    banned?: boolean
    other_flags?: any[]
    kick_id?: number
    kick_username?: string
    clash_id?: string
    clashVerified: boolean
    gamdom_id: number
    gamdomVerified: boolean
    hypedrop_id?: string
    hypedropVerified?: boolean
    roobetUsername?: string
    packdrawId?: string
    stakeUsername?: string
}

export interface ContentCreator {
    _id: string
    twitchid: string
    display_name: string
    login: string
    image: string
    live: boolean
    category: string
    socials: any
    twitch_url: string
    lastLiveDate: Date
    kickUrl: string;
}
 
export interface Profile {
    _id: string
    twitchid: string
    display_name: string
    login: string
    avatar: string
    permissions: string[]
    gamdom_username?: string
    kick_username?: string
}

export interface PublicProfile {
    _id: string
    display_name: string
    avatar: string
    kick_username?: string
}

export enum Permission {
    ADMIN = 'admin',
    CREATE_BATTLE = 'create_battle',
    VIEw_BATTLE = 'view_battle',
    CREATE_CASE = 'create_case',
    EDIT_CASE = 'edit_case',
    MANAGE_USER = 'manage_user',
    MANAGE_USERS = 'manage_users',
    CREATE_CASINO_GIVEAWAY = 'create_casino_giveaway',
    MANAGE_CASINO_GIVEAWAY = 'manage_casino_giveaway',
    CREATE_CSGO_GIVEAWAY = 'create_csgo_giveaway',
    MANAGE_CSGO_GIVEAWAY = 'manage_csgo_giveaway',
    CREATE_ITEM = 'create_item',
    EDIT_ITEM = 'edit_item',
    GIFT_ITEM = 'gift_item',
    MANAGE_GIFTED_ITEM = 'manage_gifted_item',
    MANAGE_SLOT_BET = 'manage_slot_bet',
    CREATE_SLOT_BET = 'create_slot_bet',
    SEND_MESSAGE = 'send_message',
    CREATE_PROMOCODE = 'create_promocode',
    UPLOAD_FILE = "upload_file",
    CREATE_RAFFLE = "create_raffle",
    MANAGE_RAFFLE = 'manage_raffle',
    VIEW_ADMIN_PAGE = "view_admin_page",
    MANAGE_REDEMPTIONS = "manage_redemptions",
    MANAGE_STORE_ITEMS = 'manage_store_items',
    GIVEAWAY_CASE = 'giveaway_case',
    MANAGE_BONUS_HUNT = 'manage_bonus_hunt',
    CREATE_CHALLENGE = 'create_challenge',
    MANAGE_CHALLENGE = 'manage_challenge',
    // VIP
    MANAGE_VIP = "manage_vip",
    EURO_2024 = "euro_2024"
}