import React from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import VisibilityIcon from '@mui/icons-material/Visibility';

import { styled } from '@mui/material'

const InventoryContainer = styled(Box)(( { theme }) => ({
    backgroundColor: 'var(--primary-bg-soft)',
    borderRadius: 12,
    padding: '.5rem 2rem',
    minWidth: 275,
    height: 140,
    boxShadow: '0 5px 10px rgba(0, 0, 0, .2)',
    position: 'relative'
}))

const ContainerFade = styled(Box)(( { theme }) => ({
    background: theme.palette.primary.main,
    borderRadius: 'inherit',
    position: 'absolute',
    width: '100%',
    height: '100%',
}))

const FadeLine = styled('span')(( { theme }) => ({
    height: '2px',
    width: '100%',
    position: 'absolute',
    top: '0',
}))

const rarityThemeColor = {
    '0': '#caab05',
    '1': '#eb4b4b',
    '2': '#d32ce6',
    '3': '#8747ff',
    '4': '#1155dd'
}

const BotInventoryBox = ({ rarity, odds, amount, rarityIndex, openInventory, openModal }) => {

    const openBotInventoryModal = () => {
        openInventory();
        openModal()
    }

    return (
        <InventoryContainer
            display="flex"
            alignItems="center"
            justifyContent="center"
        >
            <ContainerFade
                sx={{
                    background: `radial-gradient(at 50% 0%, ${rarityThemeColor[rarityIndex]}5A 10%, rgba(255,255,255,0) 90%)`,        
                }}
            />
            <FadeLine
                sx={{
                    background: `linear-gradient(90deg, rgba(0,0,0,0) 8%, ${rarityThemeColor[rarityIndex]}FF 50%, rgba(0,0,0,0) 92%)`,
                }}
            />
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-evenly"
                sx={{
                    height: '100%',
                    zIndex: 1
                }}
            >
                <Box
                    display="flex"
                    flexDirection="column"
                >
                    <Typography>
                        {rarity}
                    </Typography>
                    <Typography fontSize="14px" sx={{ color: 'var(--text-secondary)' }}>
                        {odds}%
                    </Typography>
                </Box>
                <Button
                    variant="text"
                    disableRipple
                    sx={{justifyContent: 'start', fontSize: '14px' }}
                    onClick={openBotInventoryModal}
                    startIcon={
                        <VisibilityIcon
                            sx={{
                                width: '16px',
                                height: '16px'
                            }}
                        />}
                >
                    <Typography
                        variant="span"
                    >
                        View
                    </Typography>
                </Button>

            </Box>

                <Typography
                    variant="h3"
                    sx={{
                        marginLeft: 'auto',
                        zIndex: 1
                    }}
                >
                    {amount || 0}
                </Typography>
        </InventoryContainer>
    )
}

export default BotInventoryBox
