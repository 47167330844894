import { Box, IconButton, Modal, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from '@mui/material'
import React from 'react'
import useSWR from 'swr'
import { fetcher, isAPIImage } from '../../utils'
import { SlotBattleBet } from './types'
import { useNavigate } from 'react-router-dom'
import { Close } from '@mui/icons-material'
import { LeaderboardSkeleton } from '../LeaderboardPage/Stake/Leaderboard'

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '700px',
    maxWidth: '95%',
    bgcolor: 'var(--primary-bg)',
    border: '2px solid var(--primary-border)',
    boxShadow: 24,
    padding: '1rem',
    borderRadius: '8px'
  }

const SlotBattleBetLeaderboard = () => {

    const navigate = useNavigate()
    const { data, isLoading } = useSWR<SlotBattleBet[]>('/slot-battles/bets/leaderboard?sortBy=multiplier', fetcher)

  return (
    <>
        <Modal
            open={true}
            onClose={() => navigate('/slot-battles')}
            aria-labelledby="bet-leaderboard-modal-title"
            aria-describedby="bet-leaderboard-modal"
        >
            <Box sx={style}>
                <Box display="flex" flexDirection="column" gap="1rem" position="relative">
                    <IconButton onClick={() => navigate('/slot-battles')} sx={{ position: 'absolute', top: '-.5rem', right: '-.5rem' }}>
                        <Close />
                    </IconButton>
                    <Typography variant="h5">Best Slot Battle Wins</Typography>
                    <Box sx={{ borderRadius: '.5rem', border: '1px solid var(--primary-border)' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ borderColor: 'var(--primary-border)', px: '8px' }}>User</TableCell>
                                    <TableCell sx={{ borderColor: 'var(--primary-border)', px: '8px' }}>Multi</TableCell>
                                    <TableCell sx={{ borderColor: 'var(--primary-border)', px: '8px' }}>Slots</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {isLoading ? (
                                <>
                                    {new Array(10).fill(0).map((_, i) => (
                                        <LeaderboardSkeleton key={`slot-battle-skeleton-${i}`} />
                                    ))}
                                </>
                                ) : !data ? (
                                 <>
                                    <TableCell>Something went wrong</TableCell>
                                 </>
                                ) : (
                                <>
                                    {data.map((bet) => (
                                        <TableRow key={bet._id}>
                                            <TableCell sx={{ borderColor: 'var(--primary-border)'}}>
                                                {bet.user && (
                                                    <Box display="flex" alignItems="center" gap=".5rem">
                                                        <Box component="img" src={bet.user.avatar} sx={{ borderRadius: '100%', height: '32px', width: '32px' }} />  
                                                        <Typography sx={{
                                                            maxWidth: '40%',
                                                            textOverflow: 'ellipsis',
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                        }} component="span">{bet.user.display_name}</Typography>                                               
                                                    </Box>
                                                )}
                                    
                                                
                                            </TableCell>
                                            <TableCell sx={{ borderColor: 'var(--primary-border)', px: '8px' }}>
                                                {Intl.NumberFormat("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(bet.multiplier || 0)}x
                                            </TableCell>
                                            <TableCell sx={{ borderColor: 'var(--primary-border)' }}>
                                                    <Box display="flex" flexWrap="wrap" alignItems="center" gap=".5rem">     
                                                        {bet.bets.map((bet) => (
                                                            <Tooltip title={bet.team.name} placement="top">
                                                                <Box component="img" src={isAPIImage(bet.team.image)} sx={{ border: '1px solid var(--primary-border)', borderRadius: '.5rem', height: '32px', width: '32px' }} />  
                                                            </Tooltip>
                                                        
                                                        ))}
                                                    </Box>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </>
                                )}
                            </TableBody> 
                        </Table>
                    </Box>
                </Box>
               
            </Box>
        </Modal>
    </>
  )
}

export default SlotBattleBetLeaderboard