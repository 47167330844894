import {
    Box,
    Skeleton,
    Typography,
} from "@mui/material";
import React from "react";
import { Challenge } from "./types";
import { checkIfAPIImage, fetcher, formatDate } from "../../utils";
import { Link } from "react-router-dom";
import useSWR from "swr";

const ChallengeSkeleton = () => {
    return (
        <Box
        display="flex"
        flexDirection="column"
        position="relative"
        sx={{
            backgroundColor: "var(--primary-bg-soft)",
            overflow: "hidden",
            borderRadius: ".5rem",
            border: "1px solid var(--primary-border)",
        }}
        >
            <Skeleton variant="rectangular" width="256px" height="256px" />
            <Box display="flex" flexDirection="column" gap=".25rem" sx={{padding: '1rem' }}>
                <Skeleton variant="text" width="100px" height="24px" />
                <Skeleton variant="text" width="140px" height="20px" />
                <Skeleton variant="text" width="140px" height="16px" sx={{ mt: '1rem' }} />
                <Skeleton variant="rectangular" width="200px" height="32px" sx={{ }} />
            </Box>

        </Box> 
    )
}

const ChallengeBox = ({challenge}: { challenge: Challenge }) => {

    return (
        <Box
            display="flex"
            flexDirection="column"
            position="relative"
            sx={{
                backgroundColor: "var(--primary-bg-soft)",
                overflow: "hidden",
                borderRadius: ".5rem",
                border: "1px solid var(--primary-border)",
                "&:hover": {
                    img: {
                        transform: "scale(1.03)",
                    },
                },
            }}
        >
            {new Date(challenge.createdAt) >
                new Date(Date.now() - 1000 * 60 * 60 * 24) && (
                    <Box
                        sx={{
                            opacity: ".95",
                            backgroundColor: "var(--primary-main)",
                            fontSize: ".8em",
                            borderRadius: ".25rem",
                            fontWeight: "bold",
                            textTransform: "uppercase",
                            position: "absolute",
                            top: "4px",
                            padding: ".25rem",
                            left: "4px",
                            zIndex: 10,
                        }}
                    >
                        New
                    </Box>
                )}

            <Box
                display="flex"
                alignItems="flex-end"
                sx={{ overflow: "hidden", minHeight: "256px", maxHeight: "256px" }}
            >
                <Box
                    sx={{
                        height: "100%",
                        width: "100%",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundImage: `url(${checkIfAPIImage(challenge.game.thumbnailUrl || "")})`,
                        transition: "transform 1s",
                        filter:
                            !challenge.isActive
                                ? "grayscale(100%)"
                                : "grayscale(0%)",
                        "&:hover": {
                            transform: "scale(1.03)",
                        },
                    }}
                />
            </Box>

            <Box
                display="flex"
                flexDirection="column"
                justifyContent="flex-end"
                gap="1rem"
                sx={{ p: "1rem", height: "100%" }}
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    height="100%"
                    gap=".25rem"
                    sx={{ mb: "1rem" }}
                >
                    <Typography sx={{ fontWeight: "bold" }}>
                        {challenge.game.name}
                    </Typography>
                    <Typography
                        component="span"
                        sx={{ fontSize: ".9em", color: "var(--text-secondary)" }}
                    >
                        Hit{" "}
                        <Typography component="b" sx={{ color: "var(--text-primary)" }}>
                            {Intl.NumberFormat("en-US").format(
                                challenge.targetMultiplier
                            )}
                            x
                        </Typography>{" "}
                        with min.{" "}
                        <Typography component="b" sx={{ color: "var(--text-primary)" }}>
                            $
                            {Intl.NumberFormat("en-US").format(
                                challenge.minBetUsd
                            )}
                        </Typography>{" "}
                        bet.
                    </Typography>
                </Box>

                {challenge.isActive && (
                    <Typography
                        sx={{
                            color: "var(--text-secondary)",
                            fontSize: ".8em",
                            mt: "auto",
                        }}
                    >
                        Created {formatDate(challenge.createdAt, "time")}
                    </Typography>
                )}
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                        boxShadow: "inset 0 0 1.5rem rgba(255,0,0,.1)",
                        border: "1px solid var(--primary-main)",
                        borderRadius: ".25rem",
                        p: ".5rem",
                    }}
                >
                    {challenge.awardUsd ? (
                        <Typography component="span" sx={{ fontWeight: 'bold' }}>${Intl.NumberFormat("en-US", { maximumFractionDigits: 2 }).format(challenge.awardUsd)}</Typography>

                    ) : (
                        <Typography component="span">{challenge.award} <Typography component="span" sx={{ textTransform: 'uppercase' }}>{challenge.currency}</Typography></Typography>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

const ChallengesPage = () => {

    const { data, isLoading } = useSWR<Challenge[]>("/challenges", fetcher);


    return (
        <Box display="flex" gap="2rem" width="100%">
            <Box
                display="flex"
                flexDirection="column"
                className="page"
                maxWidth="1600px"
                flexGrow={1}
                sx={{ px: { xs: "8px", md: "1rem" } }}
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    gap=".25rem"
                    sx={{ mb: "5rem" }}
                >
                    <Typography variant="h3">Challenges</Typography>
                    <Typography
                        component="span"
                        sx={{ color: "var(--text-secondary)", mb: ".5rem" }}
                    >
                        Complete challenges while you play on Stake
                    </Typography>
                    <Typography
                        component="span"
                        sx={{ fontWeight: "bold", fontSize: "1.2em" }}
                    >
                        <Typography
                            component={Link}
                            to="/casino"
                            sx={{
                                fontSize: "1em",
                                fontWeight: "bold",
                                color: "var(--primary-main)",
                            }}
                        >
                            DDK
                        </Typography>{" "}
                        users only!
                    </Typography>
                </Box>


                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    gap=".25rem"
                    sx={{ mb: "2rem" }}
                >
                    <Box
                        display="flex"
                        flexDirection="column"
                        width="100%"
                        sx={{ mb: "2rem" }}
                    >
                        <Typography variant="h4">Challenges</Typography>
                        <Typography sx={{ color: "var(--text-secondary)" }}>
                            Complete the challenge before anyone else!
                        </Typography>
                    </Box>

                    {isLoading && data?.length === 0 ? (
                        <Box
                            display="grid"
                            gap="1rem"
                            sx={{
                                gridTemplateColumns: {
                                    xs: `repeat(2, minmax(0, 1fr))`,
                                    sm: `repeat(3, minmax(0, 1fr))`,
                                    md: `repeat(4, minmax(0, 1fr))`,
                                    lg: `repeat(5, minmax(0, 1fr))`,
                                    xl: `repeat(6, minmax(0, 1fr))`,
                                },
                            }}
                        >
                            <ChallengeSkeleton />
                            <ChallengeSkeleton />
                            <ChallengeSkeleton />
                            <ChallengeSkeleton />
                            <ChallengeSkeleton />
                            <ChallengeSkeleton />
                        </Box>
                    ) : !data ? (
                        <Typography>Something went wrong</Typography>
                    ) : (
                        data && (
                            <Box
                                display="grid"
                                gap="1rem"
                                sx={{
                                    gridTemplateColumns: {
                                        xs: `repeat(2, minmax(0, 1fr))`,
                                        sm: `repeat(3, minmax(0, 1fr))`,
                                        md: `repeat(4, minmax(0, 1fr))`,
                                        lg: `repeat(5, minmax(0, 1fr))`,
                                        xl: `repeat(6, minmax(0, 1fr))`,
                                    },
                                }}
                            >
                                {data.map((challenge) => (
                                    <ChallengeBox
                                        key={challenge.id}
                                        challenge={challenge}

                                    />
                                ))     
                                }
                            </Box>
                        )
                    )}

                </Box>
            </Box>
        </Box>
    );
};

export default ChallengesPage;
