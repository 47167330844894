import React from 'react'
import Modal from '../Modal'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { API_URL } from '../../utils';


const GamdomHelp = ({ open, handleClose }: { open: boolean, handleClose: () => void }) => {
  return (
      <Modal
          open={open}
          handleClose={handleClose}
          closeButton={true}
          width="500px"
      >
          <Box
              display="flex"
              flexDirection="column"
          >
            <Typography variant="h5">
                Find your Gamdom User ID
            </Typography>
            <Box display="flex" flexDirection="column" marginTop="3rem" rowGap="1rem">
                <Typography>On Gamdom click on the "Support" tab.</Typography>
                <img src={`${API_URL}/images/gamdom/gamdom_1.png`} alt="" style={{ boxShadow: '0 0 1rem rgba(0, 0, 0, .2)' }} />
            </Box>
            <Box display="flex" flexDirection="column" marginTop="3rem" rowGap="1rem">
                <Typography>You will find your User ID bottom left of the page.</Typography>
                <img src={`${API_URL}/images/gamdom/gamdom_2.png`} alt="" style={{ width: '220px', height: 'auto', boxShadow: '0 0 1rem rgba(0, 0, 0, .2)' }} />
               
            </Box>  
          </Box>
      </Modal>
  )
}

export default GamdomHelp