import moment from 'moment';
import { createToast } from './components/Toasts/Toasts';
import { createErrorToast, createNotAuthorizedToast, createRateLimitedToast } from './components/Toasts/ErrorToasts';
import { Profile, User } from './shared/user.interface';
import { createCopiedToClipboardToast } from './components/Toasts/InteractionToast';

export const API_URL = process.env.NODE_ENV === 'production' ? 'https://api.prodigyddk.com' : 'http://localhost:5000';
export const FILE_URL = 'https://files.prodigyddk.com'

const shortDateOption: Intl.DateTimeFormatOptions = {year: 'numeric', month: 'numeric', day: 'numeric' };

const longDateOption: Intl.DateTimeFormatOptions = {year: 'numeric', month: 'long', day: 'numeric' };

const longDateWithTimeOption: Intl.DateTimeFormatOptions = {year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false };

export const fetcher = (path: string, req?: RequestInit) => fetch(`${API_URL}${path}`, {
    ...req, 
    headers: {
        'Content-Type': 'application/json',
    },
    credentials: 'include'
    }).then(async (res) => {
        if(!res.ok) throw new Error(res.statusText)
        const data = await res.json()
        return data
    })

    
export function apiFetch(path = "/", method: "GET" | "POST" = "GET", options?: RequestInit): Promise<any> {

        const url = new URL(path, `${API_URL}/`)
      
        return new Promise((resolve, reject) => {
            fetch(url, {
                ...options, 
                method,
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include'
            })
            .then(async (res) => {
      
        
                if(res.status === 429) {
                    return reject(new Error("Please wait a few seconds"))
                }

                const data = await res.json()
      
                if(!res.ok) {

                    if(data.message) return reject(new Error(data.message))
                    
                    return reject(new Error(undefined))
                }



                return resolve(data)
            })
        })
    }
export const fetchAPI = async (urlRoute = "/", method = "GET", body: any | null = null, searchParamsString: string | null = null, ignoreToasts = false) => {
    return new Promise((resolve, reject) => {

        let url = new URL(urlRoute, API_URL)

        if(searchParamsString && searchParamsString !== "") {
            const searchParams = new URLSearchParams(searchParamsString);

            for(const param of searchParams) url.searchParams.set(param[0], param[1])
           
        }


        fetch(url, {
            method: method,
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: body ? JSON.stringify(body) : null
        }).then((res) => {

            if(res.ok) {
                return res.json();
            }

            switch(res.status) {
                case 400: 
                    return res.json()

                case 401:
                    !ignoreToasts && createNotAuthorizedToast()
                    break;
                case 403:
                    !ignoreToasts && createErrorToast('No Permission')
                    break;
                case 429:
                    !ignoreToasts && createRateLimitedToast()
                    break;
                case 500:
                    return res.json()
                default:
                    break;
            }

            reject(new Error(`HTTP Error: ${res.status} ${res.statusText}`))
            return null;

        }).then((data) => {
            if(!data) return;
            if(data.success === false && data.message) {
                !ignoreToasts && createErrorToast(data.message)
                reject(new Error(data.error));
            }else if(data.error || data.success === false) {
                !ignoreToasts && createErrorToast(data.error)
                reject(new Error(data.error));
            } else if(data.message && data.success === undefined) {
                !ignoreToasts && createErrorToast(data.message)
                reject(new Error(data.message))
            } else {
                resolve(data);
            }
        })
    })
}

export const fetchBackend = async (urlRoute = "/", method = "GET", headers: HeadersInit, body: BodyInit, searchParamsString: string | null = null, ignoreToasts = false) => {
    return new Promise((resolve, reject) => {

        let url = new URL(urlRoute, API_URL)

        if(searchParamsString && searchParamsString !== "") {
            const searchParams = new URLSearchParams(searchParamsString);

            for(const param of searchParams) url.searchParams.set(param[0], param[1])
           
        }


        fetch(url, {
            method: method,
            headers: headers,
            credentials: 'include',
            body: body
        }).then((res) => {

            if(res.ok) {
                return res.json();
            }

            reject(new Error(`HTTP Error: ${res.status} ${res.statusText}`))
            return null;

        }).then((data) => {
            if(!data) return;
            if(data.success === false && data.message) {
                !ignoreToasts && createErrorToast(data.message)
                reject(new Error(data.error));
            }else if(data.error || data.success === false) {
                !ignoreToasts && createErrorToast(data.error)
                reject(new Error(data.error));
            } else if(data.message && data.success === undefined) {
                !ignoreToasts && createErrorToast(data.message)
                reject(new Error(data.message))
            } else {
                resolve(data);
            }
        })
    })
}

export const hasPermission = (profile: Profile | User | null, permission: string) => {
    if(!profile || !profile.permissions || (!profile.permissions.includes(permission) && !profile.permissions.includes('admin'))) return false;
    
    return true;
}

export const formatDate = (date: string | number | Date, option: "short" | "long" | "time" = "long") => {

    const _date = new Date(date);

    switch(option) {
        case "short": return new Intl.DateTimeFormat('en-US', shortDateOption).format(_date)
        case "long": return new Intl.DateTimeFormat('en-US', longDateOption).format(_date);
        case "time": return new Intl.DateTimeFormat('en-US', longDateWithTimeOption).format(_date);
        default: return new Intl.DateTimeFormat('en-US', shortDateOption).format(_date)
    }
}


/**
 * Gets the Time left in a string with Days, Hours and Minutes Left
 * @param {*} date 
 * @returns 
 */
export const getTimeLeftDHM = (date: string | number | Date, short = false) => {
    const _date = new Date(date);

    const timeleft = _date.getTime() - Date.now();

    const days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));

    if(short) return `${days}d ${hours}h ${minutes}m`;

    return `${days} Days ${hours} Hrs ${minutes} Mins`;
}

export const getTimeLeft = (date: string | number | Date, short = false) => {
    const _date = new Date(date);

    const timeleft = _date.getTime() - Date.now();

    const days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));


    if(short) {
        let s = "";
        if(days > 0) s += `${days}d `;
        if(hours > 0) s += `${hours}h `;
        s += `${minutes}m`;
        return s
    } 

    let s = "";
    if(days > 0) s += `${days} Days `;
    if(hours > 0) s += `${hours} Hours `;
    s += `${minutes} Mins`;
    return s;
}



export const getTimeAgo = (date: string | number | Date, suffix = true) => {
    let d = moment(date);
    if(!d) return ''
    return d.fromNow(suffix || true);
}

export const  readCookie = (name: any) => {
    return (name = new RegExp('(?:^|;\\s*)' + ('' + name).replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&') + '=([^;]*)').exec(document.cookie)) && name[1];
}

/**
 * Checks if the Image is an image from API or extern URL and returns the corrent string
 */
export const checkIfAPIImage = (imgUrl: string) => {

    if(!imgUrl) return imgUrl

    if(imgUrl.startsWith('/')){
        return `${API_URL}/images` + imgUrl
    }
    return imgUrl;
}

export const isAPIImage = (imgUrl: string) => {

    if(!imgUrl) return imgUrl

    if(imgUrl.startsWith('/')){
        return `${FILE_URL}` + imgUrl
    }
    return imgUrl;
}


export const randomNumberInBetween = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min + 1) + min)
}

/**
 * 
 * @param text What content should be copied to the clipbaord
 * @param descriptionText Description + ' copied'
 */
export const saveToClipboard = (text: string, descriptionText?: string) => {
    navigator.clipboard.writeText(text);
    createCopiedToClipboardToast(descriptionText && descriptionText + ' copied');
}

export function getClientSeed() {
    let clientSeed = localStorage.getItem("clientSeed")
  
    if(!clientSeed) {
      clientSeed = (Math.random() + 1).toString(36).substring(2)
      localStorage.setItem("clientSeed", clientSeed)
    }
  
    return clientSeed
  }

