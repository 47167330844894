import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { styled } from '@mui/material'
import { Link } from 'react-router-dom'

import { useSelector, useDispatch } from 'react-redux'
import {
    selectCsgoGiveawayInfo,
    selectCsgoBotInventory, setCsgoGiveawayInfo, setCsgoGiveawayRecentWins,
    setCsgoGiveawayBotInventory, setCsgoGiveawayTimeUntilNext
} from '../../redux/giveaway/csgoGiveawaySlice';

import CSGOGiveawayBox from '../../components/Giveaway/CSGOGiveawayBox'
import BotInventoryBox from '../../components/Giveaway/BotInventoryBox'

import { API_URL } from '../../utils'
import BotInventoryModal from '../../components/Giveaway/BotInventoryModal'
import { checkCsgoEligibilityToast } from '../../components/Toasts/GiveawayEligibilityToast'

const InfoValueText = styled(Typography)(({ theme }) => ({
    fontSize: '40px',
    fontWeight: 600
}))

const InfoDescriptionText = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.secondary
}))

const GiveawaysNotActiveText = styled(Typography)(({ theme }) => ({
    fontSize: '20px'
}))

const LinkText = styled('a')(({ theme }) => ({
    color: theme.palette.text.secondary,
    display: 'flex',
    alignItems: 'center',
    'svg': {
        marginRight: '.5rem'
    }
}))

const fetchCSGOGiveawayInfo = (dispatch) => {
    fetch(`${API_URL}/giveaway/csgo/info`, {
        method: 'GET',
        credentials: 'include'
    }).then(res => {
        return res.json();
    }).then(data => {
        if (data && !data.error) {

            dispatch(setCsgoGiveawayInfo(data));
            dispatch(setCsgoGiveawayTimeUntilNext(data.time_until_next));
        }
    })
}

const fetchCSGORecentWins = (dispatch) => {
    fetch(`${API_URL}/giveaway/csgo/recentwinners`, {
        method: 'GET',
        credentials: 'include'
    }).then(res => {
        return res.json();
    }).then(data => {
        if (data && !data.error) {
            dispatch(setCsgoGiveawayRecentWins(data));
        }
    })
}

const fetchCSGOBotInventory = (dispatch) => {
    fetch(`${API_URL}/giveaway/csgo/botinventory`, {
        method: 'GET',
        credentials: 'include'
    }).then(res => {
        return res.json();
    }).then(data => {
        if (data && !data.error) {
            dispatch(setCsgoGiveawayBotInventory(data));
        }
    })
}

const updateCountdownTime = (countdownEnd, setCountdownString) => {

    let distance = countdownEnd - Date.now();

    let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    let seconds = Math.floor((distance % (1000 * 60)) / 1000);

    let countString = ``

    if (minutes > 0) countString += `${minutes}m `
    if (seconds > 0) countString += `${seconds}s`;

    if (countString === ``) countString = 'Rolling soon';

    setCountdownString(countString)
}

const CSGOGiveawaysPage = () => {

    const dispatch = useDispatch();

    const csgoGiveawayInfo = useSelector(selectCsgoGiveawayInfo)
    const botInventory = useSelector(selectCsgoBotInventory)

    const [countdownString, setCountdownString] = useState(0);

    const [botInventoryModalOpen, setBotInventoryModalOpen] = useState(false);
    const [botInventoryModalRarityIndex, setBotInventoryModalRarityIndex] = useState(false);
    const [botInventoryModalItems, setBotInventoryModalItems] = useState([]);

    useEffect(() => {
        fetchCSGORecentWins(dispatch)
        fetchCSGOBotInventory(dispatch)

        fetchCSGOGiveawayInfo(dispatch)

    }, [dispatch])

    useEffect(() => {
        if (csgoGiveawayInfo.time_until_next <= 0) {
            return false;
        }
    
        let countdownEnd = Date.now() + csgoGiveawayInfo.time_until_next;
    
        const interval = setInterval(() => {
            updateCountdownTime(countdownEnd, setCountdownString)
        }, 1000)

        return () => {
            clearInterval(interval)
        }
    }, [csgoGiveawayInfo.time_until_next])


    const openBotInventoryModal = (rarityIndex) => {
        if (rarityIndex === 0) {
            setBotInventoryModalRarityIndex(0)
            setBotInventoryModalItems(botInventory.golds)
        } else if (rarityIndex === 1) {
            setBotInventoryModalRarityIndex(1)
            setBotInventoryModalItems(botInventory.coverts)
        } else if (rarityIndex === 2) {
            setBotInventoryModalRarityIndex(2)
            setBotInventoryModalItems(botInventory.classifieds)
        } else if (rarityIndex === 3) {
            setBotInventoryModalRarityIndex(3)
            setBotInventoryModalItems(botInventory.restricteds)
        } else {
            setBotInventoryModalRarityIndex(4)
            setBotInventoryModalItems(botInventory.milspecs)
        }

    }

    const checkGiveawayEligiblity = () => {
        checkCsgoEligibilityToast();
    }

    return (
        <div>
            <Box
                display="flex"
                flexDirection="column"
                sx={{
                    padding: '2rem 1rem 2rem 1rem',
                    backgroundColor: 'var(--primary-bg-soft)',
                    boxShadow: 'inset 0 0 12px rgba(20, 16, 24, .5)',
                }}
            >
                {csgoGiveawayInfo.active ? (
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <InfoValueText>
                                {countdownString ? countdownString : 'Getting Time Left...'}
                            </InfoValueText>
                            <InfoDescriptionText>
                                Until next Giveaway
                            </InfoDescriptionText>
                        </Box>

                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                            sx={{
                                marginTop: 2
                            }}
                        >
                            <InfoValueText>
                                {csgoGiveawayInfo.eligible_viewers.length}
                            </InfoValueText>
                            <InfoDescriptionText>
                                Eligible Viewers
                            </InfoDescriptionText>
                        </Box>
                        <Typography component="a" sx={{ cursor: 'pointer', color: 'primary.main', marginTop: '.5rem'}} onClick={() => checkGiveawayEligiblity()}>
                             Check Eligiblity
                        </Typography>
                    </Box>
                ) : (
                    <Box display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        textAlign="center"
                        sx={{ color: 'var(--text-secondary)'}}
                
                    >
                            <Typography variant="h4" marginBottom=".75rem">
                                Giveaways not active
                            </Typography>
                            <Typography marginBottom=".25rem">
                                Check back when Prodigy is live
                            </Typography>
                            <a href="https://kick.com/prodigyddk" style={{ color: 'var(--primary-main)' }}>

                                <Typography variant="span">
                                https://kick.com/prodigyddk
                                </Typography>
                            </a>
                    </Box>
                )}


                {csgoGiveawayInfo.recent_wins && csgoGiveawayInfo.recent_wins.length > 0 ? (
                    <Box
                        display="flex"
                        alignItems="center"
                        sx={{
                            marginTop: 4,
                            overflow: 'auto',
                            scrollbarColor: '#49547a #404761',
                            scrollbarWidth: 'thin',
                            paddingBottom: 2,
                            cursor: 'grab'
                        }}
                    >
                        {csgoGiveawayInfo.recent_wins.map((giveaway, index) => (
                            <CSGOGiveawayBox key={`csgo-recent-wins-${index}`} id={`csgo-${giveaway.item.assetid}`} item={giveaway.item} user={giveaway.user} date={giveaway.date} />
                        ))}
                    </Box>
                ) : (
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                            marginTop: '1.5rem',
                            color: 'rgba(255, 255, 255, .8)',
                            fontSize: 18
                        }}
                    >
                        No Giveaways have been rolled yet
                    </Box>
                )}

            </Box>
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                sx={{
                    marginTop: 6,
                    marginBottom: 4
                }}
            >
                <Typography variant="h3">
                    Inventory
                </Typography>
                {botInventory ? (
                    <Box
                        display="flex"
                        rowGap={2}
                        columnGap={2}
                        sx={{
                            marginTop: 4,
                            flexWrap: 'wrap',
                            justifyContent: 'center'
                        }}
                    >

                        <BotInventoryBox rarity="Golds" odds={botInventory.goldOdds}
                            amount={botInventory.amountOfGolds} rarityIndex={0} openInventory={() => openBotInventoryModal(0)} openModal={() => setBotInventoryModalOpen(true)} />
                        <BotInventoryBox rarity="Coverts" odds={botInventory.covertOdds}
                            amount={botInventory.amountOfCoverts} rarityIndex={1} openInventory={() => openBotInventoryModal(1)} openModal={() => setBotInventoryModalOpen(true)} />
                        <BotInventoryBox rarity="Classifieds" odds={botInventory.classifiedOdds}
                            amount={botInventory.amountOfClassifieds} rarityIndex={2} openInventory={() => openBotInventoryModal(2)} openModal={() => setBotInventoryModalOpen(true)} />
                        <BotInventoryBox rarity="Restricteds" odds={botInventory.restrictedOdds}
                            amount={botInventory.amountOfCoverts} rarityIndex={3} openInventory={() => openBotInventoryModal(3)} openModal={() => setBotInventoryModalOpen(true)} />
                        <BotInventoryBox rarity="Mil-Specs" odds={botInventory.milspecOdds}
                            amount={botInventory.amountOfMilspecs} openInventory={() => openBotInventoryModal(4)} openModal={() => setBotInventoryModalOpen(true)} rarityIndex={4} />
                    </Box>
                ) : null}

            </Box>
            <BotInventoryModal open={botInventoryModalOpen} handleClose={() => setBotInventoryModalOpen(false)} items={botInventoryModalItems} rarityIndex={botInventoryModalRarityIndex} />
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" sx={{ paddingTop: '1rem', color: 'rgba(255, 255, 255, .75)', borderTop: '1px solid rgba(255, 255, 255, .1)' }}>
                <Typography variant="span" sx={{ margin: '.5rem', fontWeight: 'bold', fontSize: '18px' }}>Giveaway Requirements</Typography>
                <Typography>Every 20 Minutes</Typography>
                <ul>
                    <li>Follow <Typography to="/profile" component="a" sx={{ color: 'var(--primary-main)' }} href="https://kick.com/prodigyddk" target="href">ProdigyDDK</Typography>
                    </li>
                    <li>Link your Kick account under <Link className='link' to="/profile">Profile</Link></li>
                    <li>Be active in Twitch Chat</li>
                    <li>Set Steam Tradelink under <Typography to="/profile" component={Link} sx={{ color: 'var(--primary-main)' }}>Profile</Typography></li>
                </ul>
            </Box>
        </div>
    )
}

export default CSGOGiveawaysPage
