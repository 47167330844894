import { PublicProfile } from "../../shared/user.interface";

export interface SlotBattleTeamBet {
    slotbattleId: string,
    teamId: string,
    name: string,
    image: string
    eventAt: string,
    odds?: number
    totalBet?: number
}

export enum SlotBattleBetStatus {
    OPEN = 'open',
    CANCELLED = 'cancelled',
    LOST = "lost",
    WON = "won"
}

export interface SlotBattleBet {
    _id: string
    bets: {
        teamId: string
        team: SlotBattleTeam
    }[],
    createdAt: string
    bet: number,
    payout: number,
    status: SlotBattleBetStatus
    user?: PublicProfile
    multiplier?: number
}

export interface SlotBattleSlot {
    name: string,
    image: string,
    game_code: string,
}

export interface SlotBattleTeam {
    _id: string
    name: string,
    image: string,
    game_code: string,
    rounds: SlotBattleRound[],
    isWinner?: boolean,
    isLoser?: boolean
    betSize?: number
    odds?: number,
    totalBet?: number
}

export interface SlotBattleRound {
    id: number,
    score: number,
    result: number,
    initialState: boolean;
}

export enum SlotBattleStatus {
    CREATED = "created",
    OPEN = "open_for_bets",
    CLOSED = "closed_for_bets",
    LIVE = "live",
    FINISHED = "finished",
    CANCELED = "canceled"
}

export interface SlotBattle {
    _id: string,
    teams: SlotBattleTeam[],
    rounds: SlotBattleRound[],
    status: SlotBattleStatus,
    maxBet: number,
    createdAt: string,
    betsClosedAt?: string,
    eventAt: string,
    streamerId: string,
    creatorId: string,
    streamer?: {
        display_name: string,
        image: string,
        kickUrl: string
    },
    creator?: PublicProfile,
}  