import React from 'react'
import { toast } from 'react-toastify';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { API_URL } from '../../utils';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

export const checkCasinoEligibilityToast = async () => {
    const promise = new Promise((resolve, reject) => {

        fetch(`${API_URL}/giveaway/casino/eligible`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
        }).then(res => {
            return res.json();
        }).then(data => {

            if (data && !data.error) {

                setTimeout(() => {
                    resolve(data);   
                }, 1000)
                            
            } else {
                setTimeout(() => {
                    reject();   
                }, 1000)
            }
        })
    })
    toast.promise(
        promise,
        {
            pending: "Checking your eligibility...",
            success: {
                render ({ data })  {
                    return <Box display="flex" flexDirection="column" justifyContent="center">
                                <Box display="flex" alignItems="center">
                                    {data.following ? <CheckIcon color="success" sx={{ marginRight: '.5rem'}} /> : <CloseIcon color="error" sx={{ marginRight: '.5rem'}} />} <Typography variant="span">{data.following ? 'Following' : 'Not Following'}</Typography>
                                </Box>
                                <Box display="flex" alignItems="center">
                                    {data.stakeUsername ? <CheckIcon color="success" sx={{ marginRight: '.5rem'}} /> : <CloseIcon color="error" sx={{ marginRight: '.5rem'}} />} <Typography variant="span">{data.stakeUsername ? 'Stake Username' : 'Stake Username not set'}</Typography>
                                </Box>
                                <Box display="flex" alignItems="center">
                                    {data.recent_message ? <CheckIcon color="success" sx={{ marginRight: '.5rem'}} /> : <CloseIcon color="error" sx={{ marginRight: '.5rem'}} />} <Typography variant="span">{data.recent_message ? 'Chat Activity' : 'Chat Activity'}</Typography>
                                </Box>
                        
                            </Box>
                },
                icon: false
            },
            error: "Could not check eligibility"
        }, {
            position: 'bottom-right',
            autoClose: 5000,
            progressStyle: {
                background: "#c8354f"
            }
        }
    )
}

export const checkCsgoEligibilityToast = async () => {
    const promise = new Promise((resolve, reject) => {

        fetch(`${API_URL}/giveaway/csgo/eligible`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
        }).then(res => {
            return res.json();
        }).then(data => {

            if (data && !data.error) {

                setTimeout(() => {
                    resolve(data);   
                }, 1000)
                            
            } else {
                setTimeout(() => {
                    reject();   
                }, 1000)
            }
        })
    })
    toast.promise(
        promise,
        {
            pending: "Checking your eligibility...",
            success: {
                render ({ data })  {
                    return <Box display="flex" flexDirection="column" justifyContent="center">
                                <Box display="flex" alignItems="center">
                                    {data.following ? <CheckIcon color="success" sx={{ marginRight: '.5rem'}} /> : <CloseIcon color="error" sx={{ marginRight: '.5rem'}} />} <Typography variant="span">{data.following ? 'Following' : 'Not Following'}</Typography>
                                </Box>
                                <Box display="flex" alignItems="center">
                                    {data.trade_url ? <CheckIcon color="success" sx={{ marginRight: '.5rem'}} /> : <CloseIcon color="error" sx={{ marginRight: '.5rem'}}/>} <Typography variant="span">{data.trade_url ? 'Steam Trade URL' : 'Steam Trade URL not set'}</Typography>
                                </Box>
                                <Box display="flex" alignItems="center">
                                    {data.recent_message ? <CheckIcon color="success" sx={{ marginRight: '.5rem'}} /> : <CloseIcon color="error" sx={{ marginRight: '.5rem'}} />} <Typography variant="span">{data.recent_message ? 'Chat Activity' : 'Chat Activity'}</Typography>
                                </Box>
                        
                            </Box>
                },
                icon: false
            },
            error: "Could not check eligibility"
        }, {
            position: 'bottom-right',
            autoClose: 5000,
            progressStyle: {
                background: "#c8354f"
            }
        }
    )
}
