import React, { ReactElement } from 'react'
import useSWR from 'swr';
import { fetcher } from '../../utils';
import { Box, Typography } from '@mui/material';
import { ReactComponent as BoxIcon } from '../../icons/boxopen.svg';
import { ReactComponent as Ticket } from '../../icons/ticket.svg';
import { ReactComponent as Gift } from '../../icons/gift.svg';
import { ReactComponent as Store } from '../../icons/store.svg';
import { ReactComponent as TargetXIcon } from '../../icons/assets/target_x.svg'
import { Link } from 'react-router-dom';
import { ArrowForward, ArrowRight } from '@mui/icons-material';

type StatsInfo = {
    casesOpened: number;
    valueOfCasesOpened: number;
    storeItemsBought: number;
    valueOfItemsBought: number;
    valueOfRaffles: number;
    valueOfCasinoGiveaways: number;
    amountOfRaffles: number;
    amountOfCasinoGiveaways: number;
    valueOfChallenges: number;
    amountOfChallenges: number;
  }

const StatBox = ({ icon, title, value, to }: { icon: ReactElement,  title: string, value: ReactElement, to: string }) => {
    return (
        <Box
            display="flex"
            flexDirection="column"
            sx={{
                boxShadow: '0 0 1rem rgba(0,0,0,.25)',
                p: '1rem',
                borderRadius: '.5rem',
                border: '1px solid var(--primary-border)',
                backgroundColor: 'var(--primary-bg-dark)',
                gap: '1rem',
                transition: 'transform .5s',
                '&:hover': {
                    transform: 'scale(1.01) translateY(-5px)'
                }
            }}
        >
            <Box display="flex" alignItems="center" gap=".5rem" sx={{  'svg': { fill: 'var(--primary-main)', height: '24px', width: '24px' }}}>
                {icon}
                <Typography sx={{ fontWeight: 'bold', fontSize: '20px' , color: 'var(--text-primary)' }}>
                    {title}
                </Typography>
            </Box>

            <Typography sx={{ mb: 'auto', fontSize: '.9em', color: 'var(--text-secondary)' }}>
                {value}
            </Typography>

            <Typography component={Link} to={to} sx={{ display: 'flex', alignItems: 'center', gap: '.25rem' }}>
                <Typography component="span">View {title}</Typography>           
                <ArrowForward />
            </Typography>
        </Box>
    )

}

const Stats = () => {

    const { data, isLoading } = useSWR<StatsInfo>('/stats', fetcher)

  return (
    <Box display="grid" gap="1rem" sx={{ gridTemplateColumns: { xs: `repeat(1, minmax(0, 1fr))`, sm: `repeat(2, minmax(0, 1fr))`, md: `repeat(3, minmax(0, 1fr))` }}}>
        {isLoading ? (
            <></>
        ) : !data ? (
            <Typography sx={{ fontStyle: 'italic'}}>Something went wrong...</Typography>
        ) : (
            <>
                <StatBox icon={<BoxIcon />} to="/cases" title="Cases" value={
                    <Typography component="span">Users have opened <Typography component="span" sx={{ fontWeight: 'bold', color: 'var(--primary-main)' }}>{data.casesOpened} Cases</Typography> and received over <Typography component="span" sx={{ fontWeight: 'bold', color: 'var(--primary-main)' }}>${Intl.NumberFormat("en-US").format(data.valueOfCasesOpened)}</Typography> in Rewards!</Typography>
                } />
                <StatBox icon={<Store />} to="/store" title="Store" value={
                    <Typography component="span">Users have bought <Typography component="span" sx={{ fontWeight: 'bold', color: 'var(--primary-main)' }}>{data.storeItemsBought} Items</Typography> from our store, adding up to <Typography component="span" sx={{ fontWeight: 'bold', color: 'var(--primary-main)' }}>${Intl.NumberFormat("en-US").format(data.valueOfItemsBought)}</Typography>!</Typography>
                } />
                <StatBox icon={<Ticket />} to="/giveaways/raffles" title="Raffles" value={
                    <Typography component="span">Users have won <Typography component="span" sx={{ fontWeight: 'bold', color: 'var(--primary-main)' }}>${Intl.NumberFormat("en-US").format(data.valueOfRaffles)}</Typography> from joining everyday, currently there have been <Typography component="span" sx={{ fontWeight: 'bold', color: 'var(--primary-main)' }}>{Intl.NumberFormat("en-US").format(data.amountOfRaffles)} Raffles</Typography>!</Typography>
                } />
                <StatBox icon={<Gift />} to="/giveaways" title="Giveaways" value={
                    <Typography component="span">Users have won <Typography component="span" sx={{ fontWeight: 'bold', color: 'var(--primary-main)' }}>${Intl.NumberFormat("en-US").format(data.valueOfCasinoGiveaways)}</Typography> from <Typography component="span" sx={{ fontWeight: 'bold', color: 'var(--primary-main)' }}>{Intl.NumberFormat("en-US").format(data.amountOfCasinoGiveaways)} Giveaways</Typography> while ProdigyDDK was live!</Typography>
                } />
                 <StatBox icon={<TargetXIcon />} to="/challenges" title="Challenges" value={
                    <Typography component="span">Users have won <Typography component="span" sx={{ fontWeight: 'bold', color: 'var(--primary-main)' }}>${Intl.NumberFormat("en-US").format(data.valueOfChallenges)}</Typography> from beating <Typography component="span" sx={{ fontWeight: 'bold', color: 'var(--primary-main)' }}>{Intl.NumberFormat("en-US").format(data.amountOfChallenges)} Challenges</Typography>!</Typography>
                } />
            </>

        )}
        
    </Box>
  )
}

export default Stats