import React, { useEffect } from 'react'
import Modal from '../Modal'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { ReactComponent as Twitch } from '../../icons/twitch.svg'
import { API_URL } from '../../utils';
import { useSelector } from 'react-redux';
import { selectProfile } from '../../redux/profile/profileSlice';

const AuthModal = ({ open, handleClose }) => {

    const profile = useSelector(selectProfile)
    
    useEffect(() => {
        if(open && profile) handleClose();
    }, [profile])

  return (
    <React.Fragment>
          <Modal
              open={open}
              handleClose={handleClose}
              width={"500px"}
              closeButton={true}
          >
              <Box display="flex" flexDirection="column" alignItems="center">
                  <Typography variant="h3" sx={{ color: "text.secondary"}}>Please sign in</Typography>
                  <Typography variant="h4" sx={{ color: "text.secondary", marginTop: '1rem', marginBottom: '2rem' }}>to continue</Typography>
                  <Button variant="contained" startIcon={<Twitch />} href={`${API_URL}/auth/twitch/user`} target="_blank" rel="noreferrer" >
                      Sign In
                  </Button>
              </Box>
          </Modal>
    </React.Fragment>
  )
}

export default AuthModal