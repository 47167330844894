import React, { useEffect, useRef, useState } from 'react'
import { Item, ItemRarity } from '../../shared/item.interface'
import { Box, Typography } from '@mui/material'
import { isAPIImage } from '../../utils'
import { getRarityColor } from './CaseTopUnboxings'
import { CaseItem } from './types'
import { ArrowDropDown } from '@mui/icons-material'

const WINNING_INDEX = 46
const CAROUSEL_ELEMENT_WIDTH = 116
const CAROUSEL_ITEM_CENTER = -2204
const ANIMATION_DURATION = 7000

const RANDOM_ANIMATIONS = [
    {
        transform: -2183,
        animationDuration: ANIMATION_DURATION,
        animationTimingFunction: 'cubic-bezier(.2,.33,.33,.98)'
    },  
    {
        transform: -2231,
        animationDuration: ANIMATION_DURATION,
        animationTimingFunction: 'cubic-bezier(.2,.33,.33,.98)'
    },
]


function getHighlightedUser(carouselEl: HTMLElement | null) {
    if (!carouselEl) return;
    const offsetX = carouselEl.clientWidth / 2;
    const value = parseFloat(
      window.getComputedStyle(carouselEl).getPropertyValue("transform").split(",")[4]
    )
    if (!value) return;
    const transform = value * -1 + offsetX;
    const activeIndex = Math.floor(transform / CAROUSEL_ELEMENT_WIDTH)
  
    return activeIndex;
  }

function generateCarouselItems(items: CaseItem[], winningItem: Item) {

    const carousel: {
        image: string,
        name: string,
        rarity: ItemRarity,
    }[] = []

    for(let i = 0; i < 55; i++) {
        if(i === WINNING_INDEX) {
            carousel.push({
                image: winningItem.image,
                name: winningItem.name,
                rarity: winningItem.rarity,
            })
        } else {
            const roll = Math.random() * 1000000;
            let ticket = 0;
            for(let item of items){
                let itemTickets = item.odds * 10000;
                if(roll >= ticket && roll < ticket + itemTickets){
                    carousel.push({
                        image: item.item.image,
                        name: item.item.name,
                        rarity: item.item.rarity,
                    })
                    break
                } else {
                    ticket += itemTickets;
                }
            }
        }
    }

    return carousel
}

const CarouselItem = ({ image, name, rarity, isWinningItem, showItemName, isHighlighted }: { image: string, name: string, rarity: ItemRarity, isWinningItem?: boolean, showItemName?: boolean, isHighlighted: boolean}) => {
    return (
        <Box display="flex" flexDirection="column"
            sx={{
                position: 'relative',
                opacity: isHighlighted ? 1 : .5,
                transform: isHighlighted ? 'scale(1.1)' : 'scale(.9)',
                transition: 'opacity 200ms ease-in-out, transform 1s cubic-bezier(.1,0,.1,1)',
            }}
        >
            <Box
                component="img"
                src={isAPIImage(image)}
                alt={name}
                sx={{ 
                    height: '100px',
                    width: '100px',
                    filter: `drop-shadow(0 0 .75rem ${getRarityColor(rarity.toLowerCase() as ItemRarity)})`,
                    transition: 'transform 500ms',  
                    ...isWinningItem && showItemName ? {
                        transform: 'translateY(-16px)',
                    } : {
                        transform: 'translate(0)',
                    }
                }}
            />
            {isWinningItem && (
                <Typography sx={{
                    color: 'var(--text-primary)',
                    fontWeight: 600,
                    textAlign: 'center',
                    marginTop: '.5rem',
                    transition: 'transform 500ms cubic-bezier(.1,0,.1,1), opacity 500ms ease',     
                    whiteSpace: 'nowrap',           
                    left: '50%',
                    position: 'absolute',
                    
                    ...showItemName ? {
                        transform: 'translate(-50%, 80px)',
                        opacity: 1,
                    } : {
                        opacity: 0,
                        transform: 'translate(-50%, 96px)',
                    }
                }}>
                    {name}
                </Typography>
            )}
        </Box>
    )
}   

const CaseUnboxing = ({ caseItems, unboxing, cleanupOpening, showItemWonModal }: { caseItems: CaseItem[], unboxing: { item: Item, inventoryItemId: string } | undefined, cleanupOpening: () => void, showItemWonModal: () => void }) => {

    const carouselRef = useRef(null)
    const [carousel, setCarousel] = useState<{ image: string, name: string, rarity: ItemRarity }[]>([])
    const [isTransitioning, setIsTransitioning] = useState(false)
    const [highlightedUser, setHighlightedUser] = useState<number>(0)
    const [showItemName, setShowItemName] = useState(false)
    const [transition, setTransition] = useState<{ 
            transform: number, 
            animationDuration: number,
            animationTimingFunction: string } 
            | undefined
        >(undefined)

    function onFinish() {
        showItemWonModal()
        cleanupOpening()
    }

    useEffect(() => {
        if(unboxing) {
            setTransition(RANDOM_ANIMATIONS[Math.floor(Math.random() * RANDOM_ANIMATIONS.length)])
            setCarousel(generateCarouselItems(caseItems, unboxing.item))
        }
    }, [unboxing, caseItems])

    useEffect(() => {
        if(carousel.length > 0) {
        
            const highlightedInterval = setInterval(() => {
                setHighlightedUser(prevState => getHighlightedUser(carouselRef.current) || prevState)
              }, 24)

            
            setIsTransitioning(true)

            return () => {
                clearInterval(highlightedInterval)         
            }
        }
    }, [carousel, transition])

    useEffect(() => {

       
        let animationEndTimeout: NodeJS.Timeout | undefined
        let showItemModalTimeout: NodeJS.Timeout | undefined = undefined
        let transitionFinishedTimeout: NodeJS.Timeout | undefined = undefined
        let animation: Animation | undefined = undefined

        if(carouselRef.current && transition) {

            const el = carouselRef.current as HTMLElement

            animation = el.animate([
                { transform: `translate(2200px, -50%)` },
                { transform: `translate(${transition?.transform || CAROUSEL_ITEM_CENTER}px, -50%)` }
            ], {
                duration: transition.animationDuration,
                easing: transition.animationTimingFunction,
                fill: "forwards",
            })

            animation.addEventListener('finish', (evt) => {
                
                animationEndTimeout = setTimeout(() => {
                    if(el) {
                        el.animate([
                            { transform: `translate(${transition?.transform || CAROUSEL_ITEM_CENTER}px, -50%)` },
                            { transform: `translate(${CAROUSEL_ITEM_CENTER}px, -50%)` },
                        ], {
                            duration: 1000,
                            easing: 'ease',
                            fill: 'forwards'
                        })                   
                    }
                }, 10)


                transitionFinishedTimeout = setTimeout(() => {
                    setShowItemName(true)
                }, 250)

                showItemModalTimeout  = setTimeout(() => {
                    onFinish()
                }, 1000)
            })
                            
            return () => {
                if(el)
                    el.removeEventListener('animationend', () => {})

                animation?.cancel()
                clearTimeout(showItemModalTimeout)
                clearTimeout(transitionFinishedTimeout)
                clearTimeout(animationEndTimeout)
            }
        }
    }, [carouselRef.current, isTransitioning, transition])

  return (
    <Box
        display="flex" 
        height="184px" 
        
        width="100%"
        position="relative"
        overflow="hidden"
        sx={{
            mt: '2rem',
            border: '1px solid var(--primary-border)',
            boxShadow: 'inset 0 0 .5rem rgba(0,0,0, .5)',
            borderRadius: '.5rem',
            background: 'rgba(0,0,0,.1)'

        }}
    >
        {carousel.length > 0 && (
        <ArrowDropDown 
            sx={{ 
                height: '42px', 
                width: '42px', 
                color: 'var(--text-secondary)', 
                position: 'absolute', 
                top: '-16px',
                left: '50%', 
                transform: 'translateX(-50%)'
            }} 
            />
        )}


        <Box sx={{
            position: 'absolute',
            height: '184px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%'
        }}>
            <Box
                ref={carouselRef}
                display="flex"
                position="absolute"
                alignItems="center"
                justifyContent="center"
                sx={{
                  top: '50%',
                  gap: '1rem',
                  transition: `transform 1000ms ease, opacity 2s ease`,
                  ...isTransitioning  ? {
                    opacity: 1
                  } : {
                    opacity: 0
                  }
                }}
            >
                {carousel.map((item, i) => (
                    <CarouselItem key={`carousel-item-${i}`} 
                        image={item.image} 
                        name={item.name} 
                        rarity={item.rarity} 
                        isHighlighted={i === highlightedUser} 
                        isWinningItem={i === WINNING_INDEX}
                        showItemName={showItemName}
                    />            
                ))}
            </Box>
        </Box>
    </Box>
  )
}

export default CaseUnboxing