import { setUserPoints, increaseUserPoints } from './redux/profile/profileSlice';
import { updateContentCreator } from './redux/contentCreators/contentCreatorsSlice';
import { addRecentDrop } from './redux/rewards/rewardsSlice';
import { updateActiveRaffle, addActiveRaffle, completeActiveRaffle, finishActiveRaffle } from './redux/raffle/raffleSlice';
import { addRollbitGiveawayRecentWin, setRollbitGiveawayTimeUntilNext, setRollbitGiveawayEligibleViewers, setRollbitGiveawayActive } from './redux/giveaway/rollbitGiveawaySlice'
import { createToast } from './components/Toasts/Toasts';
import { addMessage } from './redux/message/messageSlice';
import { addCsgoGiveawayRecentWin, setCsgoGiveawayActive, setCsgoGiveawayEligibleViewers, setCsgoGiveawayTimeUntilNext } from './redux/giveaway/csgoGiveawaySlice';
import { addVipGiveawayRecentWin, setVipGiveawayActive, setVipGiveawayEligibleViewers, setVipGiveawayTimeUntilNext } from './redux/giveaway/vipGiveawaySlice';
import { socket } from './socket';

export const initSocketEvents = (dispatch) => {

    socket.on('newCaseItemDrop', (data) => {
        dispatch(addRecentDrop(data));
    })

    socket.on('updateCase', (data) => {
        //
    })

    /**
     * CSGO Giveaway
     */

    socket.on('newCSGOGiveawayWinner', (data) => {
        dispatch(addCsgoGiveawayRecentWin(data));
    })

    socket.on('csgoGiveawayTimerUpdated', (data) => {
        dispatch(setCsgoGiveawayTimeUntilNext(data.time_until_next));
    })
 
    socket.on('csgoViewersUpdated', (data) => {
        dispatch(setCsgoGiveawayEligibleViewers(data.eligible_viewers));
    })

    socket.on('csgoGiveawayActive', (data) => {
        dispatch(setCsgoGiveawayActive(data.active));
    })

    /**
     * Rollbit Giveaway
     */

    socket.on('newCasinoGiveawayWinner', (data) => {
        dispatch(addRollbitGiveawayRecentWin(data));
    })

    socket.on('casinoGiveawayTimerUpdated', (data) => {
        dispatch(setRollbitGiveawayTimeUntilNext(data.time_until_next));
    })
 
    socket.on('casinoViewersUpdated', (data) => {
        dispatch(setRollbitGiveawayEligibleViewers(data.eligible_viewers));
    })

    socket.on('casinoGiveawayActive', (data) => {
        dispatch(setRollbitGiveawayActive(data.active));
    })
    
    /*
    * VIP Giveaway
    */

    socket.on('newRollbitVIPGiveawayWinner', (data) => {
        dispatch(addVipGiveawayRecentWin(data))
    })

    socket.on('rollbitVIPGiveawayTimerUpdated', (data) => {
        dispatch(setVipGiveawayTimeUntilNext(data.time_until_next));
    })
 
    socket.on('rollbitVIPViewersUpdated', (data) => {
        dispatch(setVipGiveawayEligibleViewers(data.eligible_viewers));
    })

    socket.on('rollbitVIPGiveawayActive', (data) => {
        dispatch(setVipGiveawayActive(data.active));
    })
    

    socket.on('contentCreatorUpdated', (data) => {
        dispatch(updateContentCreator(data));
    })

    socket.on('contentCreatorWentLive', (data) => {
        createToast(data, 'live_announcement')
    })

    socket.on('userPointsUpdated', (data) => {
        dispatch(setUserPoints(data.points));
    })

    socket.on('userPointsIncreased', (data) => {
        dispatch(increaseUserPoints(data.points));
    })

    // Raffles

    socket.on('activeRaffleUpdated', (data) => {
        dispatch(updateActiveRaffle(data))
    })

    socket.on('addActiveRaffle', (data) => {
        dispatch(addActiveRaffle(data))
    })

    socket.on('completeRaffle', (data) => {
        dispatch(finishActiveRaffle(data))
    })

    socket.on('newMessage', (data) => {
        dispatch(addMessage(data))
    })
}

