import { Box, Button, FormControl, FormLabel, Modal, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { createErrorToast } from '../../components/Toasts/ErrorToasts'
import { apiFetch } from '../../utils'
import { createSuccessToast } from '../../components/Toasts/SuccessToasts'
import { LoadingButton } from '@mui/lab'

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '500px',
    maxWidth: '95%',
    bgcolor: 'var(--primary-bg)',
    border: '2px solid var(--primary-border)',
    boxShadow: 24,
    padding: '1rem',
    borderRadius: '8px'
  }

const RaffleBetModal = ({ raffleId, maxBetPerUser, onBetPlaced }: { raffleId: string, maxBetPerUser: number, onBetPlaced?: (bet: number) => void }) => {

    const [isOpen, setIsOpen] = useState(false)
    const [betAmount, setBetAmount] = useState(0)
    const [isLoading, setIsLoading] = useState(false)

    async function createRaffleBet() {

        if(betAmount === 0) {
            return createErrorToast("You must bet at least 1 point!")
        }

        setIsLoading(true)

        try {
            const data = await apiFetch('/raffle/bet', "POST", {
                body: JSON.stringify({
                    raffleId,
                    bet: betAmount         
                })
            })


            if(data) {
                setIsOpen(false)
                setIsLoading(false)
                createSuccessToast("Bet placed")            
            }
        } catch (error) {
            if(error instanceof Error) {
                createErrorToast(error.message || "Something went wrong!")
            }

            setIsLoading(false)
        }
    }


  return (
    <>
        <Button variant="contained" fullWidth onClick={() => setIsOpen(true)}>Bet</Button>
        <Modal
                open={isOpen}
                onClose={() => setIsOpen(false)}       
            >
                <Box display="flex" flexDirection="column" gap="1rem" sx={{ ...style }}>
                    <Typography variant="h6" sx={{ fontWeight: 600, flexGrow: 1 }}>Bet on Raffle</Typography>
                    <FormControl fullWidth sx={{ gap: '4px'}}>
                        <FormLabel>Points</FormLabel>
                        <TextField placeholder='Your Point bet amount...' value={betAmount} onChange={(e) => setBetAmount(parseInt(e.target.value) || 0)} />
                        <Typography variant="caption" sx={{ color: 'var(--text-secondary)' }}>Max bet per user is {maxBetPerUser} Points.</Typography>
                    </FormControl>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        <LoadingButton variant="contained" loading={isLoading} onClick={() => createRaffleBet()}>Bet</LoadingButton>
                        <Button variant="contained" color="soft" onClick={() => setIsOpen(false)}>Close</Button>
                    </Box>
                    
                </Box>
        </Modal>
    </>
  )
}

export default RaffleBetModal