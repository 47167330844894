import React, { useState } from 'react'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Box, Typography, Modal } from '@mui/material'
import { Item } from '../../shared/item.interface';
import { ReactComponent as Coin } from '../../icons/assets/coin.svg'
import { createErrorToast } from '../../components/Toasts/ErrorToasts';
import { apiFetch } from '../../utils';
import { createSuccessToast } from '../../components/Toasts/SuccessToasts';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '400px',
    bgcolor: 'var(--primary-bg)',
    border: '2px solid var(--primary-border)',
    boxShadow: 24,
    padding: '1rem',
    borderRadius: '8px'
  }

const BuyItemModal = ({ storeItemId, price, item }: { storeItemId: string, price: number, item: Item }) => {

    const [isOpen, setIsOpen] = useState(false)

    async function buyItem() {
        try {
            const data = await apiFetch('/store/buy', "POST", {
                body: JSON.stringify({
                    itemId: storeItemId,
                })            
            })

            if(data) {
                createSuccessToast(data.message || "Item bought!")
                setIsOpen(false)
            }
        } catch (e) {
            if(e instanceof Error) {
                createErrorToast(e.message || "Something went wrong!")      
            }
        }
    }

  return (
    <>
        <Button variant="contained" startIcon={<ShoppingCartIcon />} onClick={() => setIsOpen(true)} sx={{ borderRadius: '.5rem', width: '100%' }}>
             Buy
        </Button>
        <Modal 
            open={isOpen}
            onClose={() => setIsOpen(false)}   
        >
            <Box display="flex" flexDirection="column" gap="2rem" alignItems="center" sx={{ ...style }}>
                <Typography variant="h6" sx={{ fontWeight: 600, flexGrow: 1, textAlign: 'start', width: '100%' }}>Buy Item</Typography>
                <Box component="img" src={item.image} alt={item.name} 
                        sx={{ height: '100px', width: '100px', filter: 'drop-shadow(.25rem .25rem .5rem rgba(0, 0, 0, .5))' }} />
                <Typography component="b" sx={{ fontSize: '20px' }}>{item.name}</Typography>
                <Typography>Are you sure you want to buy this Item for </Typography>
                <Typography component="b" sx={{ fontSize: '20px' }}><Coin /> {Intl.NumberFormat("en-US").format(price)}</Typography>
                <Box display="flex" gap="1rem" width="100%">
                    <Button variant="contained" onClick={buyItem} startIcon={<ShoppingCartIcon />} sx={{ borderRadius: '.5rem', flexGrow: 1 }}>Buy</Button>
                    <Button variant="contained" onClick={() => setIsOpen(false)} startIcon={<CloseIcon />} sx={{ 
                        backgroundColor: 'var(--primary-bg-light)', 
                        '&:hover': { backgroundColor: 'var(--primary-bg-soft)'}, 
                        borderRadius: '.5rem', 
                        flexGrow: 1 
                    }}>
                        Cancel
                    </Button>
                   
                </Box>
            </Box>
        </Modal>
    </>
  )
}

export default BuyItemModal