import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    recent_drops: [],
    top_unboxes: [],
    reward_cases: []
}

export const rewardsSlice = createSlice({
    name: 'rewards',
    initialState,
    reducers: {
        setRecentDrops: (state, action) => {
            state.recent_drops = action.payload
        },
        addRecentDrop: (state, action) => {
            state.recent_drops = [action.payload, ...state.recent_drops]
        },
        setTopUnboxes: (state, action) => {
            state.top_unboxes = action.payload
        },
        setRewardCases: (state, action) => {
            state.reward_cases = action.payload
        },
        addRewardCase: (state, action) => {
            state.reward_cases = [action.payload, ...state.reward_cases]
        },

        
    }
})


export const { setRecentDrops, addRecentDrop, setTopUnboxes, setRewardCases, addRewardCase } = rewardsSlice.actions

export const selectRecentDrops = state => state.rewards.recent_drops;
export const selectTopUnboxes = state => state.rewards.top_unboxes;
export const selectRewardCases = state => state.rewards.reward_cases;


export default rewardsSlice.reducer