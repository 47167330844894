import React from 'react'
import useSWR from 'swr'
import { CaseTopUnboxing, TopUnboxing } from './types'
import { fetcher, isAPIImage } from '../../utils'
import { Box, Skeleton, Typography } from '@mui/material'
import { getRarityColor } from './CaseTopUnboxings'
import { ItemRarity } from '../../shared/item.interface'

const UnboxingSkeleton = () => {
    return (
        <Box display="flex" alignItems="center" gap=".5rem" sx={{ backgroundColor: 'var(--primary-bg-soft)', padding: '.5rem', width: '200px', borderRadius: '.5rem', border: '1px solid var(--primary-border)' }}>
            <Box display="flex" flexDirection="column" flexGrow={1}>
                <Skeleton variant="text" width="100%" height="24px" />
                <Skeleton variant="text" width="100%" height="24px" />
            </Box>

            <Skeleton variant="circular" width="48px" height="48px" />
        </Box>
    )
}

const TopUnboxingContainer = ({ unboxing }: { unboxing: TopUnboxing }) => {
    return (
        <Box display="flex" alignItems="center" gap=".5rem" position="relative" sx={{ 
                backgroundColor: 'var(--primary-bg-soft)', 
                overflow: 'hidden', 
                padding: '.5rem', 
                width: '200px', 
                borderRadius: '.5rem', 
                border: '1px solid var(--primary-border)', 
                boxShadow: '0 0 .5rem rgba(0,0,0,.5)',
                cursor: 'pointer',
                '&:hover': {
                    '.case': {
                        transform: 'translateY(0)',
                    }
                }
            }}>
                <Box display="flex" flexDirection="column" maxWidth="124px">
                    <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>{unboxing.user.display_name}</Typography>
                    <Typography 
                        sx={{ 
                            color: 'var(--text-secondary)', 
                            fontSize: '12px',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                        }}>
                        {unboxing.item.name}
                    </Typography>
                </Box>
                <Box component="img" 
                    src={isAPIImage(unboxing.item.image)} 
                    alt={unboxing.item.name} 
                    sx={{ 
                        ml: 'auto', 
                        height: '48px',
                        width: '48px', 
                        filter: `drop-shadow(0 0 .5rem ${getRarityColor(unboxing.item.rarity.toLowerCase() as ItemRarity)})` }} 
                />
            {unboxing.case && (
            <Box className="case" display="flex" position="absolute" alignItems="center" sx={{ transition: 'transform 200ms ease', transform: 'translateY(100px)', p: '.5rem', top: '0', left: '0', backgroundColor: 'var(--primary-bg-soft)', width: '100%', height: '100%'  }}>
                    <Typography sx={{ 
                            fontWeight: 'bold', 
                            fontSize: '14px',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                        }}
                    >{unboxing.case.name}</Typography>
                    <Box component="img" src={isAPIImage(unboxing.case.image)} alt={unboxing.case.name} sx={{ ml: 'auto', height: '48px', width: '48px' }} />
            </Box>
            )}

        </Box>
    )
}

const UnboxingContainer = ({ unboxing }: { unboxing: CaseTopUnboxing }) => {
    return (
        <Box display="flex" alignItems="center" gap=".5rem" position="relative" sx={{ 
                backgroundColor: 'var(--primary-bg-soft)', 
                overflow: 'hidden', 
                padding: '.5rem', 
                width: '200px', 
                borderRadius: '.5rem', 
                border: '1px solid var(--primary-border)', 
                boxShadow: '0 0 .5rem rgba(0,0,0,.5)',
                cursor: 'pointer',
                '&:hover': {
                    '.case': {
                        transform: 'translateY(0)',
                    }
                }
            }}>
                <Box display="flex" flexDirection="column" maxWidth="124px">
                    <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>{unboxing.user.display_name}</Typography>
                    <Typography 
                        sx={{ 
                            color: 'var(--text-secondary)', 
                            fontSize: '12px',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                        }}>
                        {unboxing.item.name}
                    </Typography>
                </Box>
                <Box component="img" 
                    src={isAPIImage(unboxing.item.image)} 
                    alt={unboxing.item.name} 
                    sx={{ 
                        ml: 'auto', 
                        height: '48px',
                        width: '48px', 
                        filter: `drop-shadow(0 0 .5rem ${getRarityColor(unboxing.item.rarity.toLowerCase() as ItemRarity)})` }} 
                />
            <Box className="case" display="flex" position="absolute" alignItems="center" sx={{ transition: 'transform 200ms ease', transform: 'translateY(100px)', p: '.5rem', top: '0', left: '0', backgroundColor: 'var(--primary-bg-soft)', width: '100%', height: '100%'  }}>
                <Typography sx={{ 
                    fontWeight: 'bold', 
                    fontSize: '14px',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
            }}>{unboxing.case_name}</Typography>
                <Box component="img" src={isAPIImage(unboxing.case_image)} alt={unboxing.case_name} sx={{ ml: 'auto', height: '48px', width: '48px' }} />
            </Box>
        </Box>
    )
}

const RecentUnboxings = () => {

    const { data, isLoading } = useSWR<{topUnboxes: TopUnboxing[], recentHistory: CaseTopUnboxing[] }>(`/cases/recent`, fetcher)


  return (
    
        <Box display="flex" sx={{  padding: '1rem', borderTop: '1px solid var(--primary-border)', overflow: 'auto' }}>

            {isLoading ? (
                <Box display="flex" alignItems="center" gap=".5rem">
                    <UnboxingSkeleton />
                    
                    {new Array(8).fill(0).map((_, i) => (
                        <UnboxingSkeleton key={`recent-${i}`} />
                    ))}
                    
                </Box>
            ) : !data ? (
                <Box display="flex">
                    <Typography sx={{ fontStyle: 'italic' }}>Something went wrong, please try again</Typography>
                </Box>
            ) : (
                <Box display="flex" alignItems="center" gap=".5rem">
                    <Box display="flex" flexDirection="column">
                        <Typography sx={{ fontSize: '14px', color: 'var(--text-secondary)' }}>Top Unboxings</Typography>
                        <Box display="flex" alignItems="center" gap=".5rem">
                            {data!.topUnboxes.map((unboxing, i) => (
                                <TopUnboxingContainer key={`top-${i}`} unboxing={unboxing} />
                            ))}
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="column">
                        <Typography sx={{ fontSize: '14px', color: 'var(--text-secondary)' }}>Recent Unboxings</Typography>
                        <Box display="flex" alignItems="center" gap=".5rem">
                            {data!.recentHistory.map((unboxing, i) => (
                                <UnboxingContainer key={`top-${i}`} unboxing={unboxing} />
                            ))}
                        </Box>
                    </Box>
                </Box>
            )}
        </Box>
    
  )
}

export default RecentUnboxings