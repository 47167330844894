import React from 'react'
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled } from '@mui/material'
import Modal from '../Modal'

const InventoryTitle = styled(Box)(( { theme }) => ({
    paddingBottom: 16,
    fontSize: 24,
}))


const InventoryModal = styled(Modal)(( { theme }) => ({
    backgroundColor: 'rgba(0, 0, 0, .6)'
}))

const ModalContainer = styled(Box)(( { theme }) => ({
    backgroundColor: 'var(--primary-bg)',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 24,
    padding: 16,
    borderRadius: 16,
    minHeight: 200,
    maxHeight: '75%',
    overflowY: 'auto'
}))


const BotInventoryCardContainer = styled(Box)(( { theme }) => ({
    backgroundColor: 'var(--primary-bg-soft)',
    padding: '.5rem 1rem',
    margin: 12,
    borderRadius: 12,
    display: 'flex',
    flexDirection: 'column',
    width: 150,
    height: 200,

    'img': {
        filter: 'drop-shadow(0 0 0.5rem rgba(0,0,0, .5))',
        marginTop: 'auto'
    },

    '.item-name': {
        color: theme.palette.text.secondary,
        fontSize: '12px'
    },

    '.item-amount': {
        marginLeft: 'auto'
    }
}))

const titles = {
    0: 'Rares',
    1: 'Coverts',
    2: 'Classifieds',
    3: 'Restricteds',
    4: 'Mil-Specs'
}

const colorName = {
    0: 'gold',
    1: 'covert',
    2: 'classified',
    3: 'restricted',
    4: 'milspec'
}

const BotInventoryModal = ({ open, handleClose, items, rarityIndex }) => {



    return (
        <Modal
            open={open}
            handleClose={handleClose}
            width="950px"
        >
            <Box
                display="flex"
                flexDirection="column"
            >
                <InventoryTitle
                    display="flex"
                    alignitems="center"
                >
                    <Typography variant="span">Inventory</Typography> 
                    &nbsp;
                    <Typography variant="span" className={colorName[rarityIndex]}>{titles[rarityIndex]}</Typography>
                </InventoryTitle>
                {items && items.length > 0 ? (
                    <Box
                        display="flex"
                        flexWrap="wrap"
                        sx={{
                            marginLeft: '1.5rem'
                        }}
                    >
                        {items.map((item, index) => (
                            <BotInventoryCardContainer
                                sx={{
                                    borderBottom: `2px solid #${item.tags.rarity_color}`
                                }}
                            >
                                <Box>
                                    <Typography className="item-name">{item.item_name}</Typography>
                                    <Typography>{item.paint_name}</Typography>
                                </Box>

                                <img className="item-img" src={item.icon_url + '/96fx96f'} alt="item-img" />
                                <Typography variant="span" className="item-amount">{item.amount} x</Typography>
                            </BotInventoryCardContainer>
                        ))}
                    </Box>

                ) : (
                    <Typography>No Items Found</Typography>
                )}
            </Box>
        </Modal>
    )
}

export default BotInventoryModal
