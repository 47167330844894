import React, { useState, useEffect } from 'react'
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import OutlinedInput from '@mui/material/OutlinedInput';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material'
import { fetchAPI } from '../../utils';
import CloseIcon from '@mui/icons-material/Close';
import { ReactComponent as Kick } from '../../icons/sites/kick.svg';
import { ReactComponent as Twitch } from '../../icons/twitch.svg';
import { Profile } from '../../shared/user.interface';

type PopoverProps = {
  anchorEl: Element | null;
  setAnchorEl: (e: Element | null) => void;
  battleId: number | undefined;
}

const StyledPopover = styled(Popover)(({ theme }) => ({

  '.MuiPaper-root': {
    backgroundColor: 'var(--primary-bg-soft)',
    width: '400px',
    padding: '1rem',
    transform: "translateX(-37%) !important"
  },

  '.user-container': {

    display: 'flex',
    alignItems: 'center',
    padding: '.5rem',
    borderRadius: '6px',
    margin: '.25rem 0',
    transition: 'background-color .2s ease-in-out',

    '.avatar': {
      height: '32px',
      width: '32px',
      marginRight: '.75rem',
      borderRadius: '50%'
    },

    '&:hover': {
      backgroundColor: 'rgba(220, 220, 220, .05)'
    },
  },

  '.active': {
    backgroundColor: 'var(--secondary-dark)'
  },

}))

const AddPlayerPopover = ({ anchorEl, setAnchorEl, battleId }: PopoverProps) => {

  const [usernameInput, setUsernameInput] = useState("");
  const [users, setUsers] = useState<Array<Profile>>([]);
  const [selectedUser, setSelectedUser] = useState<null | number>(null);

  const [lookingUpKickNames, setLookingUpKickNames] = useState<boolean>(false);

  const open = Boolean(anchorEl);

  const toggleLookingUpKickNames = () => {
    setSelectedUser(null);
    setUsers([]);
    setLookingUpKickNames(prevState => !prevState)
  }

  useEffect(() => {

    const getUsers = async () => {
      setSelectedUser(null);

      let data: any = null;

      if(lookingUpKickNames) {
        data = await fetchAPI(`/admin/user/lookup/kick?username=${usernameInput}`, 'GET', null, null, true)
      } else {
        data = await fetchAPI(`/admin/user/lookup?username=${usernameInput}`, 'GET', null, null, true)
      }
      
      if(data) {
        setUsers(data);

      }

    }

    if(usernameInput !== "") {
      try {
        getUsers()
      } catch (e) { }
      
    }

  }, [usernameInput])

  const addPlayerToBattle = async () => {

    if(typeof selectedUser != "number" || (typeof selectedUser == "number" && !users[selectedUser]) ) return;

    const anchorRef = anchorEl;
    setAnchorEl(null);

    try {
      const data = await fetchAPI(`/battle/${battleId}/add`, 'POST', { userId: users[selectedUser]._id })

      if(data) {
        setUsernameInput("")
        setUsers([]);
      }
      
    } catch(e) {
      setAnchorEl(anchorRef);
    }

  }


  return (
    <StyledPopover
      id="add-player-popover"
      open={open}
      anchorEl={anchorEl}
      onClose={() => setAnchorEl(null)}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
    >
      <IconButton
        onClick={() => setAnchorEl(null)}
        sx={{
          position: 'absolute',
          top: '2px',
          right: '2px',
          color: 'text.secondary'
        }}
      >
        <CloseIcon />
      </IconButton>
      <Typography variant="h5" marginBottom="1rem">Add Player to Battle</Typography>
      <Typography marginBottom=".25rem">{!lookingUpKickNames ? 'Twitch Users' : 'Kick Users'}</Typography>
      <OutlinedInput
        placeholder={!lookingUpKickNames ? 'Twitch Username...' : 'Kick Username...'}
        fullWidth
        value={usernameInput}
        onChange={(e) => setUsernameInput(e.target.value)}
        
        endAdornment={
          <Tooltip title={lookingUpKickNames ? 'Switch to Twitch' : 'Switch to Kick'}>
            <IconButton onClick={() => toggleLookingUpKickNames()}>
              {lookingUpKickNames ? <Twitch /> : <Kick />}
            </IconButton>
          </Tooltip>

        }
      />
      <Box
        display="flex"
        flexDirection="column"
        marginTop="1rem"
      >
        {users.map((user: any, index: number) => (
          <button
            key={index}
            className={`user-container ${typeof selectedUser === "number" && index === selectedUser ? 'active' : ''}`}
            onClick={() => setSelectedUser(index)}
          >
            <img src={user.avatar} alt="" className='avatar' />
            <Typography>{user.display_name || user.kick_username}</Typography>
          </button>
        ))}
        {users.length > 0 && (
          <Button
            variant="contained"
            sx={{ marginTop: '1rem', marginRight: 'auto' }}
            onClick={() => addPlayerToBattle()}
          >
            Add
          </Button>
        )}

      </Box>

    </StyledPopover>
  )
}

export default AddPlayerPopover