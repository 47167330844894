import React from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { toast } from 'react-toastify';
import DLiveIcon from '../../icons/dliveicon.png';
import { ReactComponent as Discord } from '../../icons/discord.svg';
import { ReactComponent as Steam } from '../../icons/sites/steam.svg';
import CheckIcon from '@mui/icons-material/Check';


export const linkedDiscordAccountToast = () => {
    toast.dark(
        <Box display="flex" alignItems="center" sx={{ color: 'text.secondary' }}>
            <Discord style={{ height: '32px', width: '32px', marginRight: '1rem' }} />
            <Typography>
                Successfully linked your Discord Account
            </Typography>
        </Box>
        ,{
            position: 'bottom-right',
            progressStyle: {
                backgroundColor: "#4caf50"
            }
        }
    )
}

export const unlinkDiscordAccountToast = () => {
    toast.dark(
        <Box display="flex" alignItems="center" sx={{ color: 'text.secondary' }}>
            <Discord style={{ height: '32px', width: '32px', marginRight: '1rem' }} />
            <Typography>
                Successfully unlinked your Discord Account
            </Typography>
        </Box>
        ,{
            position: 'bottom-right',
            progressStyle: {
                backgroundColor: "#4caf50"
            }
        }
    )
}

export const linkedSteamAccountToast = () => {
    toast.dark(
        <Box display="flex" alignItems="center" sx={{ color: 'text.secondary' }}>
            <Steam style={{ height: '32px', width: '32px', marginRight: '1rem' }} />
            <Typography>
                Successfully linked your Steam Account
            </Typography>
        </Box>
        ,{
            position: 'bottom-right',
            progressStyle: {
                backgroundColor: "#4caf50"
            }
        }
    )
}

export const unlinkSteamAccountToast = () => {
    toast.dark(
        <Box display="flex" alignItems="center" sx={{ color: 'text.secondary' }}>
            <Steam style={{ height: '32px', width: '32px', marginRight: '1rem' }} />
            <Typography>
                Successfully unlinked your Steam Account
            </Typography>
        </Box>
        ,{
            position: 'bottom-right',
            progressStyle: {
                backgroundColor: "#4caf50"
            }
        }
    )
}


export const unlinkDLiveAccountToast = () => {
    toast.dark(
        <Box display="flex" alignItems="center" sx={{ color: 'text.secondary' }}>
            <img src={DLiveIcon} alt="" style={{ height: '32px', width: '32px', marginRight: '1rem' }} />
            <Typography>
                Successfully unlinked your DLive Account
            </Typography>
        </Box>
        ,{
            position: 'bottom-right',
            progressStyle: {
                backgroundColor: "#4caf50"
            }
        }
    )
}

export const gamdomAccountVerifiedToast = () => {
    toast.dark(
        <Box display="flex" flexDirection="column">
            <Box
                display="flex"
                alignItems="center"
            >
                <CheckIcon sx={{ marginRight: '.5rem', color: 'success.main'}} />
                <Typography variant="h6">Verified</Typography>
            </Box>
            <Typography variant="span" sx={{ color: 'text.secondary' }}>Sucessfully verified your Gamdom account</Typography>
            
        </Box>
        ,{
            position: 'bottom-right',
            progressStyle: {
                backgroundColor: "#4caf50"
            }
        }
    )
}