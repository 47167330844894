import { Item } from "../../shared/item.interface"
import { PublicProfile } from "../../shared/user.interface"

export enum RafflePrizeType {
    CASH = "cash",
    ITEM = "item"
}

export enum RaffleState {
    CREATED = "created",
    OPEN_FOR_BETS = "open_for_bets",
    BETS_CLOSED = "bets_closed",
    ROLLING = "rolling",
    FINISHED = "finished",
    CANCELED = "canceled",
    WAITING_FOR_EOS_BLOCK = "waiting_for_eos_block",
}

export type RaffleBet = {
    _id: string,
    raffleId: string,
    user: PublicProfile,
    bet: number,
    createdAt: Date,
    isWinner?: boolean,
    isTicket?: boolean,
    minTicket: number,
    maxTicket: number,
}

export type Raffle = {
    _id: string,
    name?: string
    createdAt: Date,
    finishAt?: string,
    prizeType: RafflePrizeType,
    item?: Item,
    cashPrize?: number,
    state: RaffleState,
    maxBetPerUser: number,
    creator?: PublicProfile,
    streamer?: {
        display_name: string,
        image: string,
        kickUrl: string,
    }
    serverSeed?: string,
    serverHash: string,
    eosBlock?: number,
    eosBlockId?: string,
    carousel?: { display_name: string, avatar: string }[]
    result?: {
        winningTicket?: number,
        winningBetId?: string,
        winnerId?: string,
        winner?: { display_name: string, avatar: string },
    }
}
