import React from 'react';
import { toast } from 'react-toastify';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

export const createErrorToast = (errorMessage: string) => {
    toast.dark(
        <Box display="flex" alignItems="center" sx={{ color: 'text.secondary' }}>
            <CancelOutlinedIcon sx={{ height: '32px', width: '32px', color: 'error.main', marginRight: '.75rem' }} />
                <Typography sx={{ fontSize: '16px' }}>
                    {errorMessage}
                </Typography>
        </Box>
        ,{
            position: 'bottom-right',
            progressStyle: {
                backgroundColor: "#d32f2f"
            }
        }
    )
}

export const createRateLimitedToast = () => {
    toast.dark(
        <Box display="flex" flexDirection="column" sx={{ color: 'text.secondary' }}>
            <Typography sx={{ fontSize: '20px', fontWeight: 'bold'}}>
                Slow down!
            </Typography>
            <Typography>
                Please try again in a few seconds.
            </Typography>
        </Box>
        ,{
            position: 'bottom-right',
            progressStyle: {
                backgroundColor: "#4caf50"
            }
        }
    )
}

export const createNotAuthorizedToast = () => {
    toast.dark(
        <Box display="flex" alignItems="center" sx={{ color: 'text.secondary' }}>
            <CancelOutlinedIcon sx={{ height: '32px', width: '32px', color: 'error.main', marginRight: '.75rem' }} />
            <Box display="flex" flexDirection="column">
                <Typography sx={{ fontSize: '20px', fontWeight: 'bold' }}>
                    Not Authorized
                </Typography>
                <Typography>
                    Please sign in.
                </Typography>
            </Box>

        </Box>
        ,{
            position: 'bottom-right',
            progressStyle: {
                backgroundColor: "#4caf50"
            }
        }
    )
}