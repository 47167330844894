import React, { useEffect, useState } from 'react'
import Modal from '../Modal'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import OutlinedInput from '@mui/material/OutlinedInput'
import { FormHelperText, styled, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { selectProfile } from '../../redux/profile/profileSlice'
import CheckIcon from '@mui/icons-material/Check';
import { ReactComponent as GamdomTower } from '../../icons/sites/gamdom_tower.svg';
import { fetchAPI } from '../../utils'
import { gamdomAccountVerifiedToast } from '../Toasts/LinkAccountToasts'

const CustomList = styled("ul")(( { theme }) => ({

    listStyle: 'none',
    color: theme.palette.text.secondary,

    'li::before': {
        content: '"⭐"',
        fontWeight: "bold",
        display: "inline-block",
        marginLeft: "-2.75rem",
        marginRight: ".5rem"
    },

    'li': {
        margin: '.5rem 0'
    }
}))


const VerifyModal = ({ open, handleClose, verified, setVerified }) => {

    const profile = useSelector(selectProfile)
    const [gamdomId, setGamdomId] = useState(null);

    useEffect(() => {
        if(profile && profile.gamdom_username) setGamdomId(profile.gamdom_username)
    }, [profile])

    const verifyGamdom = async () => {
        try {

            const data = await fetchAPI('/user/gamdom/verify', 'POST', { gamdomId: gamdomId });

            gamdomAccountVerifiedToast();
            setVerified(true);
            handleClose();

        } catch (e) {}
    }



    return (
        <React.Fragment>
            <Modal
                open={open}
                handleClose={handleClose}
                width="800px"
                title={"Verify your Gamdom account"}
                closeButton={true}
                zIndex={2000}
            >
                <Box display="flex" flexDirection="column">
                    <CustomList>
                        <li>Sign up on Gamdom under <Typography variant="span" sx={{ color: 'rgba(14,214,113,1)' }}>code DDK</Typography></li>
                        <li>Wager at least $1</li>
                        <li>New accounts have to wait 24-48 Hours</li>
                        <li>Enter your Gamdom User ID to verify</li>
                    </CustomList>
                    <FormHelperText variant="div" sx={{ fontSize: '16px', display: 'flex', alignItems: 'center' }}>
                        <span>Gamdom User ID</span>
                        <Typography sx={{ marginLeft: '.5rem', fontSize: '14px', color: 'primary.main'}}>Where?</Typography>
                    </FormHelperText>
                    <OutlinedInput
                        value={gamdomId}
                        onChange={(e) => setGamdomId(e.target.value)}
                        placeholder="1234...."
                        sx={{ width: '250px', fontSize: '18px' }}
                        startAdornment={<GamdomTower style={{ marginRight: '.5rem', height: '20px', width: '20px' }} />}
                    />
                    <Button 
                        variant="contained" 
                        startIcon={<CheckIcon />} 
                        color="success"
                        sx={{ marginRight: 'auto', marginTop: '2rem' }}
                        onClick={verifyGamdom}
                    >
                        Verify
                    </Button>
                </Box>
            </Modal>
        </React.Fragment>
    )
}

export default VerifyModal