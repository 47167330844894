import React from 'react'
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const LoadingCircle = ({ loadingText }: { loadingText: string }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '250px',

        'svg': {
          height: '64px',
          width: '64px'
        }
      }
      }

    >
      <CircularProgress thickness={4} disableShrink sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px' }} />
      <Typography>
        {loadingText}
      </Typography>
    </Box>
  )
}

export default LoadingCircle