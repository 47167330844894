import { Box, FormControl, MenuItem, Select, Typography } from '@mui/material'
import React, { SetStateAction } from 'react'
import useSWR from 'swr'
import { ContentCreator } from '../../shared/user.interface'
import { checkIfAPIImage, fetcher } from '../../utils'

const SelectStreamer = ({ streamerId, selectStreamerId } : { streamerId?: string, selectStreamerId: React.Dispatch<SetStateAction<string | undefined>>  }) => {

    const { data } = useSWR<ContentCreator[]>('/contentcreators', fetcher)

    if(!data) return <></>

  return (
    <FormControl>
        <Select
            id="streamer-select"
            onChange={(e) => selectStreamerId(e.target.value as string)}
            value={streamerId}
            sx={{ width: '200px', maxWidth: '100%' }}
            displayEmpty

        >
            <MenuItem value={undefined}>
                All Streamers
            </MenuItem>
            {data.map((streamer) => (
                <MenuItem value={streamer._id}>
                    <Box display="flex" alignItems="center" gap=".5rem">
                        <Typography component="img" src={checkIfAPIImage(streamer.image)} sx={{ height: '24px', width: '24px', borderRadius: '50%' }} />
                        <Typography>{streamer.display_name}</Typography>
                    </Box>
                </MenuItem>
            ))}
            
        </Select>
    </FormControl>
  )
}

export default SelectStreamer