import { createSlice } from "@reduxjs/toolkit";
import { Bounty } from "../../shared/bounty.interface";



const initialState: {
    bounties: Array<Bounty>;
    gamdomBountyHealth: boolean;
} = {
    bounties: [],
    gamdomBountyHealth: true,
}

export const bountiesSlice = createSlice({
    name: 'bounties',
    initialState,
    reducers: {
        setBounties: (state, action) => {
            state.bounties = action.payload
        },
        bountyCompleted: (state, action) => {
            state.bounties = state.bounties.map((bounty) => (
                bounty._id === action.payload._id ? { ...bounty, ...action.payload } : bounty
            ))
        },
        addBounty: (state, action) => {
            state.bounties = [action.payload, ...state.bounties]
        },
        setGamdomBountyHealth: (state, action) => {
            state.gamdomBountyHealth = action.payload
        }
    }
})

export const { setBounties, bountyCompleted, addBounty, setGamdomBountyHealth } = bountiesSlice.actions

export const selectBounties = (state: any) => state.bounties.bounties

export const selectGamdomBountyHealth = (state: any) => state.bounties.gamdomBountyHealth

export default bountiesSlice.reducer