import { Box, Typography } from '@mui/material'
import { ReactComponent as ResourceEmptyIcon } from '../icons/assets/empty_folder.svg'
import React from 'react'

const ResourceEmpty = ({ text }: { text: string}) => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" gap=".5rem" sx={{ color: 'var(--text-secondary)', 'svg': { fontSize: '5em', fill: 'var(--text-secondary)' }}}>
        <ResourceEmptyIcon />
        <Typography sx={{ fontSize: '1.5em', fontStyle: 'italic', fontWeight: 'bold' }}>{text}</Typography>
    </Box>
  )
}

export default ResourceEmpty