import React, { useEffect, useState } from 'react'
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled } from '@mui/material'
import { fetchAPI } from '../../../utils';
import { Profile } from '../../../shared/user.interface';
import { ReactComponent as Kick } from '../../../icons/sites/kick.svg';

const UserDropdown = styled(Box)(({ theme }) => ({

    backgroundColor: 'var(--primary-bg-light)',
    boxShadow: '0 0 1rem rgba(16, 16, 24, .5)',
    zIndex: '100',
    borderRadius: '.75rem',
    overflow: 'hidden',

    'button': {
        display: 'flex',
        alignItems: 'center',
        padding: '.5rem 1rem',
        transition: 'background-color .2s ease-in-out',
        '&:hover': {
            backgroundColor: 'var(--primary-main)'
        }
    }

  }))

type PopoverProps = {
    open: boolean;
    handleOpen: () => void;
    handleClose: () => void;
    username: string;
    selectUser: (user: Profile | undefined) => void;
}

const UserLookupPopover = ({ open, handleOpen, handleClose, username, selectUser }: PopoverProps) => {

    const [users, setUser] = useState<Array<Profile>>([]);

    const [selectedUserIndex, setSelectedUserIndex] = useState<undefined | number>(undefined);

    useEffect(() => {

    
        const getUsers = async () => {

            const data: any = await fetchAPI(`/admin/user/lookup?username=${username}`, 'GET', null, null, true)
            if (data) {
                handleOpen()
                setUser(data);
            }

        }
    
        if(username !== "") {
          try {
            getUsers()
          } catch (e) { }
          
        }
    
      }, [username])

    const userSelect = (user: Profile, index: number) => {
        selectUser(user);
        setSelectedUserIndex(index)
        handleClose()
    }

    if(!open || username === "") return (<></>)

    return (

        <UserDropdown
            display="flex"
            flexDirection="column"
            marginTop="1rem"
        >
            <Typography sx={{ padding: '.5rem', borderBottom: '1px solid var(--primary-border)' }}>Select User</Typography>
            <Box
                display="flex"
                flexDirection="column"
                rowGap=".5rem"
                sx={{ maxHeight: '200px', overflowY: 'auto' }}
            >

                {users.length === 0 && username === "" ? (
                    <Typography sx={{ padding: '.5rem'}}>Enter a username</Typography>
                ) : users.length === 0 && username !== ""  ? (
                    <Typography sx={{ padding: '.5rem' }}>No Results</Typography>
                ) : (
                        <>
                            {users.map((user: Profile, index: number) => (
                                <button
                                    key={index}
                                    className={`user-container ${typeof selectedUserIndex === "number" && index === selectedUserIndex ? 'active' : ''}`}
                                    onClick={() => userSelect(user, index)}
                                >
                                    <img src={user.avatar} alt="" className='avatar' />
                                    <Typography>{user.display_name}</Typography>
                                    {user.kick_username && (
                                        <Box display="flex" alignItems="center" sx={{ marginLeft: '.5rem' }}>(<Kick /> <Typography>{user.kick_username}</Typography>)</Box>
                                    )}
                                    
                                </button>
                            ))}
                        </>
                )}


            </Box>
        </UserDropdown>
    )
}

export default UserLookupPopover