import { createSlice } from "@reduxjs/toolkit";
import { IBattle } from "../../shared/battle.interface";

const initialState: {
    battles: Array<IBattle>
} = {
    battles: []
}

export const battlesSlice = createSlice({
    name: 'battle',
    initialState,
    reducers: {
        setBattles: (state, action) => {
            state.battles = action.payload
        },
        addBattle:  (state, action) => {
            state.battles = [action.payload, ...state.battles]
        },
        battlePlayerJoined: (state, action) => {
            const index = state.battles.findIndex((battle) => battle.battleId === action.payload.battleId)
            if(index !== -1) {
                state.battles[index].players.push({...action.payload.player})
            }
        },
        updateBattle: (state, action) => {
            const index = state.battles.findIndex((battle) => battle.battleId === action.payload.battleId)
            if(index !== -1) {
                state.battles[index] = action.payload
            }
        },
        removeBattle: (state, action) => {
            const index = state.battles.findIndex((battle) => battle.battleId === action.payload.battleId)
            if(index !== -1) {
                state.battles.splice(index, 1)
            }
        }
    }
})


export const { setBattles, updateBattle, battlePlayerJoined, addBattle, removeBattle } = battlesSlice.actions

export const selectBattles = (state: any) => state.battles.battles

export default battlesSlice.reducer