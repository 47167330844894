import React, { useState, useEffect } from 'react'
import { useMediaQuery } from 'react-responsive';
import { fetcher } from '../../../utils';
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Link } from 'react-router-dom';
import LoadingCircle from '../../../components/LoadingCircle';
import PackdrawGradient from '../../../icons/sites/packdraw_gradient.png'
import PackdrawText from '../../../icons/sites/packdraw_text.png'
import useSWR from 'swr';
import { LeaderboardSite, LeaderboardWager } from '../../../shared/leaderboard.interface';
import PreviousLeaderboard from '../Previous';

const packdrawColor = "rgb(191, 128, 246)";

const textGradient = "linear-gradient(160deg, #fbbbee 0%, #bf80f6 25%, #826fff, 75%, #42cfff 100%)"

const LeaderboardRow = ({ wager }: { wager: LeaderboardWager }) => {
  return (
    <Box component="tr" className={`${wager.placement === 1 ? "first" : wager.placement === 2 ? "second" : wager.placement === 3 ? "third" : ""}`}>
      <Box component="td" sx={{ textAlign: 'start', pl: '24px !important' }}>
        <Typography className={`placement`}>{wager.placement}</Typography>
      </Box>
      <Box component="td" sx={{ textAlign: 'start' }}>
        <Typography>{wager.name}</Typography>
      </Box>
      <Box component="td" sx={{ justifyContent: 'flex-end' }}>
        <Typography>${Intl.NumberFormat("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(wager.wagered)}</Typography>
      </Box>
      <Box component="td" sx={{ width: '120px', pr: '24px !important', textAlign: 'end' }}>
        <Typography>${Intl.NumberFormat("en-US").format(wager.prize)}</Typography>
      </Box>
    </Box>

  )
}

const GradientText = ({ children, sx }: { children: React.ReactNode, sx?: any }) => {
    return (
      <Typography
        component="span"
        sx={{
          color: packdrawColor,
          fontWeight: "600",
          background: textGradient,
          backgroundClip: "text",
          WebkitTextFillColor: "transparent",
          textShadow: "0 0 .25rem rgba(191, 128, 246, .5)",
          ...sx
        }}
      >
        {children}
      </Typography>
    );
}

const Timer = ({ timeLeft }: { timeLeft?: { days: number, hours: number, minutes: number } }) => {

    return (
      <GradientText sx={{ fontSize: '2.5em', display: 'flex', alignItems: 'center' }}>
        {!timeLeft || (timeLeft.minutes <= 0 && timeLeft.days <= 0 && timeLeft.hours <= 0)  ? (
          "New Weekly Leaderboard will start soon."
        ) : (
          <ul>
            <li style={{ display: 'inline-block' }}>{timeLeft.days} Days</li>
            <li style={{ display: 'inline-block', margin: '0 .5rem' }}>&bull;</li>
            <li style={{ display: 'inline-block' }}>{timeLeft.hours} Hrs</li>
            <li style={{ display: 'inline-block', margin: '0 .5rem' }}>&bull;</li>
            <li style={{ display: 'inline-block' }}>{timeLeft.minutes} Mins</li>          
          </ul>
        )}
      </GradientText>
    );
}  

function getTimeLeft(countdown?: number): undefined | { days: number, minutes: number, hours: number} {

    if(!countdown)
      return undefined

    if(countdown <= 0) return {
        days: 0,
        hours: 0,
        minutes: 0
    }

    const days = Math.floor(countdown / (1000 * 60 * 60 * 24))
    const hours = Math.floor((countdown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    const minutes = Math.floor((countdown % (1000 * 60 * 60)) / (1000 * 60))

    return {
        days,
        hours, 
        minutes
    };
}


const PackdrawLeaderboardPage = () => {

    const data = useSWR<{ startAt: string, endAt: string, wagers: LeaderboardWager[] }>('/leaderboard/packdraw', fetcher)

    const [countdown, setCountdown] = useState<number | undefined>(undefined)

    const isSmallDevice = useMediaQuery({ query: '(max-width: 800px)' })

    useEffect(() => {
        if (data.data?.endAt) {
          setCountdown(new Date(data.data.endAt).getTime() - Date.now());

            const interval = setInterval(() => {
              if(data.data?.endAt)
                setCountdown(new Date(data.data.endAt).getTime() - Date.now())
            }, 10000);

            return () => {
                clearInterval(interval)
            }
        }
    }, [data.data?.endAt])

    return (
      <Box
        className="page"
        display="flex"
        flexDirection="column"
        alignItems="center"
        textAlign="center"
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          marginBottom="5rem"
          width="100%"
        >
          <Box
            sx={{
              marginBottom: "2rem",
              marginTop: "2rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "auto",
              height: "100px",
              columnGap: ".25rem",
            }}
          >
            <img src={PackdrawGradient} alt="Packdraw Logo" style={{ height: '72px', width: 'auto', display: isSmallDevice ? 'none' : 'inherit' }} />
            <img src={PackdrawText} alt="Packdraw Logo" style={{ height: '100px', width: 'auto' }} />

          </Box>
          <Box display="flex" flexDirection="column" rowGap=".25rem">
            <GradientText sx={{ fontSize: "3.5em", letterSpacing: "-1px" }}>
              $20,000
            </GradientText>
            <Typography sx={{ fontSize: "2.5em", letterSpacing: "-1px" }}>
              Monthly
            </Typography>
            <GradientText sx={{ fontSize: "2.5em", letterSpacing: "-1px" }}>
              Leaderboard
            </GradientText>
          </Box>
          <Box
            display="flex"
            marginTop="4rem"
            flexDirection="column"
            alignItems="center"
            rowGap="1rem"
          >
            <Typography
              sx={{ fontSize: "1.5em", color: "var(--text-primary)" }}
            >
              <b>
                Wager under code{" "}
                <Typography
                  component={Link}
                  to="/casino"
                  sx={{
                    color: packdrawColor,
                    letterSpacing: "-1px",
                    fontWeight: "600",
                    fontSize: "1.25em",
                    "&:hover": { color: packdrawColor + "aa" },
                  }}
                >
                  DDK{" "}
                </Typography>
              </b>
            </Typography>
            <Typography sx={{ color: "var(--text-secondary)" }}>
              Leaderboard resets monthly every 1st at 00:00 UTC
            </Typography>
          </Box>
          {data.data?.endAt && (
            <Box marginTop="2rem">
              <Timer timeLeft={getTimeLeft(countdown)} />
            </Box>
          )}


          <Box marginTop="2rem" sx={{ width: { xs: '100%', lg: '900px' }}}>
            {data.isLoading ? (
              <Box sx={{ marginTop: "2rem", color: "var(--text-secondary)" }}>
                <LoadingCircle loadingText="Loading Leaderboard" />
              </Box>
            ) : !data.data || data.data?.wagers.length === 0 ? (
              <Box sx={{ marginTop: "2rem" }}>
                <Typography>
                  Start wagering now to place on the leaderboard
                </Typography>
              </Box>
            ) : (
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                sx={{ width: !isSmallDevice ? "900px" : "100%" }}
              >
                <Box display="flex" width="100%" sx={{ justifyContent: 'flex-end' }}>
                  <PreviousLeaderboard site={LeaderboardSite.PACKDRAW} />
                </Box>
                <Box sx={{ overflow: 'auto', width: '100%' }}> 
                <table className="leaderboard-table">
                  <thead>
                    <tr style={{ animationDelay: "0ms" }}>
                        <Box component="th" sx={{ color: packdrawColor, width: '120px', textAlign: 'start !important', pl: '24px !important'}}>#</Box>
                        <Box component="th" sx={{ color: packdrawColor, textAlign: 'start !important' }}>User</Box>
                        <Box component="th" sx={{ color: packdrawColor }}>Wagered</Box>
                        <Box component="th" sx={{ color: packdrawColor, width: '120px', textAlign: 'end !important', pr: '24px !important' }}>Prize</Box>
                    </tr>
                  </thead>
                  <tbody>
                    {data.data.wagers.map((wager, index) => (
                      <LeaderboardRow
                          key={`packdraw-leaderboard-row-${index}`}
                          wager={wager}
                      />
                    ))}
                  </tbody>
                </table>
                </Box>
                <Typography
                  sx={{
                    color: "var(--text-disabled)",
                    marginTop: ".25rem",
                    marginRight: "auto",
                    maxWidth: "100%",
                  }}
                >
                  * Data updates every ~30 minutes.
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    );
}

export default PackdrawLeaderboardPage