import { FormControl, MenuItem, Select } from '@mui/material'
import React, { SetStateAction, useEffect } from 'react'

const SelectDate = ({ setDate } : { setDate: React.Dispatch<SetStateAction<string | undefined>>  }) => {

    const [dateValue, setDateValue] = React.useState<number | undefined>(undefined)


    useEffect(() => {
        if(dateValue) {
            const date = new Date()
            date.setTime(date.getTime() - dateValue)
            setDate(date.toISOString())
        } else {
            setDate(undefined)
        }
    }, [dateValue, setDate])

  return (
    <FormControl>
        <Select
            id="date-select"
            onChange={(e) => setDateValue(e.target.value as number)}
            value={dateValue}
            sx={{ width: '200px', maxWidth: '100%' }}
            displayEmpty

        >
            <MenuItem value={undefined}>
                All Time
            </MenuItem>
            <MenuItem value={1000 * 60 * 60 * 24}>
                Last 24 Hours
            </MenuItem>
            <MenuItem value={1000 * 60 * 60 * 24 * 7}>
                Last 7 Days
            </MenuItem>
            <MenuItem value={1000 * 60 * 60 * 24 * 30}>
                Last 30 Days
            </MenuItem>
            
        </Select>
    </FormControl>
  )
}

export default SelectDate