import React, { useEffect } from 'react'
import useSWR from 'swr'
import { fetcher, } from '../../utils'
import { Link, useParams } from 'react-router-dom'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { Raffle, RafflePrizeType, RaffleState } from './types'
import RaffleBetModal from './RaffleBetModal'
import { socket } from '../../socket'
import RaffleWinner from './RaffleWinner'
import { ReactComponent as Coin } from '../../icons/assets/coin.svg'
import ArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import TicketIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import UserIcon from '@mui/icons-material/AccountCircleOutlined';
import { useSelector } from 'react-redux'
import { selectProfile } from '../../redux/profile/profileSlice'
import RaffleBets from './RaffleBets'
import { EmojiEvents } from '@mui/icons-material'
import RaffleUseTicket from './RaffleUseTicket'

type RaffleWithBets = Raffle & {
    totalBet: number
    userBet: number
}

export enum RaffleEvent {
    BET_CREATED = "raffleBetCreated",
    RAFFLE_ROLLED = "raffleRolled",
    RAFFLE_FINISHED = "raffleFinished",
    WAITING_FOR_EOS_BLOCK = "waitingForEosBlock"
}

const RaffleStateSection = ({ winningTicket, state, winner, eosBlock, } : { winningTicket?: number, state: RaffleState, winner?: { display_name: string, avatar: string }, eosBlock?: number,  }) => {
   
    return (
        <>
            {state === RaffleState.WAITING_FOR_EOS_BLOCK ? (
                <Typography sx={{ fontSize: '1.2em', fontStyle: 'italic', color: 'var(--text-secondary)', fontWeight: 'bold' }}>Waiting for EOS Block <Typography component="b" sx={{ color: 'var(--text-primary)' }}>{eosBlock || "undefined"}</Typography></Typography>
            ) : state === RaffleState.ROLLING ? (
                <></>
            ) : state === RaffleState.BETS_CLOSED ? (
                <Typography sx={{ fontSize: '1.2em', fontStyle: 'italic', color: 'var(--text-secondary)', fontWeight: 'bold' }}>Bets Closed</Typography>
            ) : state === RaffleState.FINISHED ? (
                <>
                    {winner ? (
                            <Box position="absolute" display="flex" flexDirection="column" justifyContent="center" alignItems="center" gap=".5rem" 
                                sx={{ transform: 'translateY(0)', bottom: '0', height: '100%',  width: '100%', backgroundColor: 'var(--primary-bg-dark)', zIndex: 10 }}
                            >                
                                <Box display="flex" alignItems="center" gap=".25rem" sx={{ color: 'var(--text-disabled)' }}>
                                    <EmojiEvents sx={{ height: '16px', width: '16px', fill: '#daa520' }} />
                                    <Typography sx={{ fontSize: '12px', letterSpacing: '-.5px', fontWeight: 'bold' }}>Winner</Typography>
                                </Box>

                                <Box display="flex" gap=".5rem" width="100%" alignItems="center" justifyContent="center">
                                    <Box component="img" alt={winner.display_name} src={winner.avatar} sx={{ height: '32px', width: '32px', borderRadius: '100%' }} />
                                    <Typography sx={{ fontWeight: 'bold', fontSize: '1.4em', letterSpacing: '.5px' }}>{winner.display_name}</Typography>
                                </Box> 
                                {winningTicket && (
                                    <Typography sx={{ fontSize: '.8em', color: 'var(--text-secondary)'}}>Ticket <b>{winningTicket}</b></Typography>  
                                )}    
                                                        
                            </Box>
                    ) : (
                        <Typography fontSize="1.6em" component="span" sx={{ color: 'var(--text-secondary)' }}>
                            Raffle Finished
                        </Typography>
                    )}
                </>
            ) : state === RaffleState.CANCELED ? (
                <Typography fontSize="1.6em" component="span" sx={{ color: 'var(--text-secondary)' }}>
                    Raffle Canceled
                </Typography>
            ): state === RaffleState.OPEN_FOR_BETS && (
                <Typography component="span" sx={{ color: 'var(--text-secondary)', fontStyle: 'italic' }}>
                    Bets Open
                </Typography>
            )}
        </>
    )
}

const RafflePage = () => {

    const profile = useSelector(selectProfile)
    const { id } = useParams()
    const { data, isLoading, mutate } = useSWR<RaffleWithBets>(`/raffle/${id}`, fetcher)

    const tickets = useSWR<{ _id: string, point_value: number }[]>(`/raffle/${id}/tickets`, fetcher)

    function onBetPlaced(bet: number) {
        mutate((prev) => {
            if(!prev) return prev
            return { ...prev, userBet: prev.userBet + bet } 
        }, {
            revalidate: false
        })
    }

    function onTicketUse(inventoryItemId: string) {
        tickets.mutate((prev) => {
            if(!prev) return prev 

            return prev.filter((ticket) => ticket._id !== inventoryItemId)
        })
    }   

    function updateTotalBet(bet: number) {
        mutate((prev) => {
            if(!prev) return prev
            return { ...prev, totalBet: prev.totalBet + bet} 
        }, {
            revalidate: false
        })
    }

    useEffect(() => {

        function raffleEvents(event: RaffleEvent, raffleId: string, data: any) {
            if(raffleId === id) {
                switch(event) {
                    case RaffleEvent.RAFFLE_FINISHED:
                        mutate((prev) => {
                            if(!prev) return prev
                            return { ...prev, state: RaffleState.FINISHED, carousel: undefined }
                        }, {
                            revalidate: false
                        })
                        break
                    case RaffleEvent.WAITING_FOR_EOS_BLOCK:
                        mutate((prev) => {
                            if(!prev) return prev

                            return { ...prev, state: RaffleState.WAITING_FOR_EOS_BLOCK, eosBlock: data.eosBlock }
                        }, {
                            revalidate: false
                        })
                        break
                    case RaffleEvent.RAFFLE_ROLLED:
                        mutate((prev) => {
                            if(!prev) return prev

                            console.log(data.winner.user)

                            return { ...prev, 
                                state: RaffleState.ROLLING, 
                                carousel: data.carousel, 
                                result: {
                                    ...prev?.result, 
                                    winner: data.winner.user,
                                },
                                finishAt: data.finishAt 
                            }
                        }, {
                            revalidate: false
                        })
                    break
                }
            }
        }

        socket.on('raffle', raffleEvents)
    
        return function cleanup() {
          socket.emit('unsubscribe', 'raffle', id)
          socket.off('raffle', raffleEvents) 
        }
      }, [])

      useEffect(() => {
        console.log(data?.result)
      }, [data?.result, data?.result?.winner])

    useEffect(() => {
        if(data) {
            socket.emit('subscribe', 'raffle', id) 
        }
    }, [data, id])


  return (
    <Box component="main">
        <Box className="page">
            <Link to="/giveaways/raffles" style={{ textDecoration: 'none', color: 'inherit' }}>
                <Button disableRipple startIcon={<ArrowLeftIcon sx={{ height: '24px', width: '24px' }} />}>
                    Raffles
                </Button>
            </Link>
        </Box>

        <Box display="flex" flexDirection="column" width="100%" sx={{ borderBottom: '2px solid var(--primary-border)' }}>
            {isLoading ? (
                <></>
            ) : !data ? (
                <></>
            ) : (
                <Box display="flex" alignItems="center" justifyContent="center" gap="2rem" width="100%" mx="auto" sx={{ flexDirection: { xs: 'column', sm: 'row' } }}>
                        <>
                            {data.prizeType === RafflePrizeType.ITEM && data.item ? (
                                <Box component="img" src={data.item.image} alt={data.item.name} sx={{ height: '256px', width: '256px', filter: 'drop-shadow(0 0 1rem rgb(255,0,0)' }} />
                            ) : data.prizeType === RafflePrizeType.CASH &&(
                                <Box display="flex" alignItems="center" justifyContent="center" sx={{ height: '256px', width: '256px' }}>
                                    <Coin style={{ height: '128px', width: '128px' }} />
                                </Box>
                            )}
                            <Box display="flex" flexDirection="column" gap="1rem" sx={{ textAlign: { xs: "center", sm: "start" }, paddingX: { xs: '1rem', md: '0' }}}>
                                {data.name && (
                                    <Typography sx={{
                                        fontSize: '1.6em',
                                        fontWeight: 'bold',
                                        maxWidth: '320px', 
                                        minWidth: '300px',                                     
                                        letterSpacing: '-1px', 
                                        color: 'var(--text-primary)',
                                        textOverflow: 'ellipsis',
                                        textAlign: { xs: 'center', sm: 'start' },
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap'
                                    }}>{data.name}</Typography>
                                )}
                                {data.prizeType === RafflePrizeType.ITEM && data.item ? (
                                    <Typography fontSize="1.6em" fontWeight="bold" sx={{ 
                                        maxWidth: '320px', 
                                        minWidth: '300px',                                     
                                        letterSpacing: '-1px', 
                                        color: 'var(--text-primary)',
                                        textOverflow: 'ellipsis',
                                        textAlign: { xs: 'center', sm: 'start' },
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap'
                                }}>{data.item.name}</Typography>
                                ) : data.prizeType === RafflePrizeType.CASH && (
                                    <Typography fontSize="1.6em" fontWeight="bold" sx={{ color: 'var(--text-primary)', minWidth: '300px', maxWidth: '320px' }}>${data.cashPrize} Crypto</Typography>
                                )}                                                                               

                               <Box display="flex" alignItems="center" gap="1rem">
                                    <Box display="flex" width="100%" flexDirection="column" alignItems="start" gap=".25rem" 
                                        sx={{ p: '1rem', backgroundColor: 'var(--primary-bg-soft)', border: '1px solid var(--primary-border)', borderRadius: '.5rem' }}>
                                        
                                        <Box display="flex" alignItems="center" gap=".25rem" sx={{ color: 'var(--text-disabled)' }}>
                                            <TicketIcon sx={{ height: '16px', width: '16px'}} />
                                            <Typography sx={{ fontSize: '12px', letterSpacing: '-.5px', fontWeight: 'bold' }}>Entries</Typography>
                                        </Box>
                                        <Typography sx={{ fontWeight: 'bold', letterSpacing: '.5px' }}>{data.totalBet}</Typography>
                                    </Box>
                                    {profile && (
                                        <Box display="flex" width="100%" flexDirection="column" alignItems="start" gap=".25rem" 
                                            sx={{ p: '1rem', backgroundColor: 'var(--primary-bg-soft)', border: '1px solid var(--primary-border)', borderRadius: '.5rem' }}>
                                            <Box display="flex" alignItems="center" gap=".25rem" sx={{ color: 'var(--text-disabled)' }}>
                                                <UserIcon sx={{ height: '16px', width: '16px'}} />
                                                <Typography sx={{ fontSize: '12px',  letterSpacing: '-.5px', fontWeight: 'bold'}}>
                                                    Your Entries
                                                </Typography>
                                            </Box>
                
                                            <Typography sx={{ fontWeight: 'bold', letterSpacing: '.5px' }}>{data.userBet}</Typography>                                       
                                        </Box>
                                    )}
   
                               </Box>

                               
                                {data.streamer && (
                                    <Box display="flex" width="100%" flexDirection="column" alignItems="start" gap=".25rem" 
                                        sx={{ p: '1rem', backgroundColor: 'var(--primary-bg-soft)', border: '1px solid var(--primary-border)', borderRadius: '.5rem' }}>
                                        
                                        <Box display="flex" alignItems="center" gap=".25rem" sx={{ color: 'var(--text-disabled)' }}>
                                            <TicketIcon sx={{ height: '16px', width: '16px'}} />
                                            <Typography sx={{ fontSize: '12px', letterSpacing: '-.5px', fontWeight: 'bold' }}>Streamer</Typography>
                                        </Box>
                                        <Typography component="a" href={data.streamer.kickUrl} target="_blank" rel="noreferrer" sx={{ letterSpacing: '.-5px', textDecoration: 'underline', color: 'var(--text-primary)' }}>
                                            <Box display="flex" gap=".5rem" alignItems="center">
                                                <Box component="img" alt={data.streamer.display_name} src={data.streamer.image} sx={{ height: '20px', width: '20px', borderRadius: '100%' }} />
                                                <Typography sx={{ fontWeight: 'bold', letterSpacing: '.5px' }}>{data.streamer.display_name}</Typography>
                                            </Box>
                                        </Typography>
                                        
                                    </Box>
                                )}            
            

                                {profile && data.state === RaffleState.OPEN_FOR_BETS && (
                                    <RaffleBetModal raffleId={data._id} maxBetPerUser={data.maxBetPerUser} onBetPlaced={onBetPlaced} />
                                )}

                                {tickets.data && tickets.data.length > 0 && (
                                    <RaffleUseTicket tickets={tickets.data} raffleId={data._id} onTicketUse={onTicketUse} />
                                )}
        
                               

                            </Box>
                            
                        </>
                </Box>
            )}

            {data && (
                <Box position="relative" display="flex" flexDirection="column" alignItems="center" justifyContent="center" sx={{ overflow: 'hidden', minHeight: data.state === RaffleState.ROLLING || data.state === RaffleState.FINISHED ? '112px' : "64px", mt: '2rem', fontSize: '.8em', backgroundColor: 'var(--primary-bg-dark)', width: '100%' }}>
                    <RaffleStateSection state={data.state} winner={data.result?.winner} eosBlock={data.eosBlock} winningTicket={data.result?.winningTicket} />
                    <RaffleWinner raffle={data}  />
                </Box>

                
            )}
        
        </Box>

        {data && (
            <RaffleBets id={data._id} updateTotalBet={updateTotalBet} />
        )}
        
    </Box>
  )
}

export default RafflePage