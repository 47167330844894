import { createSlice } from "@reduxjs/toolkit";
import { ContentCreator } from "../../shared/user.interface";

const initialState: {
    content_creators: Array<ContentCreator>;
} = {
    content_creators: []
}

export const contentCreatorsSlice = createSlice({
    name: 'content_creators',
    initialState,
    reducers: {
        setContentCreators: (state, action) => {
            state.content_creators = action.payload
        },
        updateContentCreator: (state, action) => {
            state.content_creators = state.content_creators.map((content, i) => (
                content._id === action.payload._id ? { ...action.payload } : content
            ))
        }
    }
})


export const { setContentCreators, updateContentCreator } = contentCreatorsSlice.actions

export const selectContentCreators = (state: any) => state.content_creators.content_creators

export default contentCreatorsSlice.reducer