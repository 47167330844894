import { Box, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import UserLookupPopover from './UserLookupPopover'
import { Profile } from '../../../shared/user.interface'

const LookupUser = ({ selectUser }: { selectUser: (user: Profile | undefined) => void }) => {

    const [username, setUsername] = useState("")

    const [isOpen, setIsOpen] = useState(false)
    const handleClose = () => setIsOpen(false)
    const handleOpen = () => setIsOpen(true)

  return (
    <>
        <Box display="flex" flexDirection="column" gap=".25rem">
            <Typography sx={{ fontSize: '14px' }}>Search for User</Typography>
            <TextField id="username-input" fullWidth placeholder="Username..." onClick={() => setIsOpen(false)} value={username} onChange={(e) => setUsername(e.target.value)} />
        </Box>
        <UserLookupPopover 
            open={isOpen}
            handleOpen={handleOpen}
            handleClose={handleClose}
            username={username}
            selectUser={(user) => selectUser(user)}
        />
    </>
  )
}

export default LookupUser