import React from 'react'
import { Box, Typography } from '@mui/material'
import { CaseTopUnboxing, TopUnboxing } from './types'
import { ReactComponent as Crown } from '../../icons/crown.svg';
import { ItemRarity } from '../../shared/item.interface';

export function getRarityColor(rarity: ItemRarity) {

  switch(rarity) {
    case 'common':
      return 'rgba(240, 240, 240, .4)'
    case 'uncommon':
      return 'rgba(33, 104, 156, .4)'
    case 'rare':
      return 'rgba(131, 64, 179, .4)'
    case 'epic':
      return 'rgba(131, 64, 179, .4)'
    case 'legendary':
      return 'rgba(212, 175, 55, .4)'
  }
}

function getPlacementColor(placement: number) {
  if (placement === 0) return '#daa520'
  else if (placement === 1) return '#c0c0c0'
  else if (placement === 2) return '#cd7f32'
}


const TopUnboxingBox = ({ placement, unboxing }: { placement: number, unboxing: TopUnboxing }) => {
    return (
      <Box display="flex" flexDirection="column" position="relative" gap="1rem" sx={{ 
          maxWidth: '256px', 
          width: '100%',
          padding: '1rem', 
          backgroundColor: 'var(--primary-bg-soft)', 
          borderRadius: '.5rem',
          background: `radial-gradient(circle at bottom, ${getPlacementColor(placement)}30 -30%, var(--primary-bg-soft) 100%)`,
          transform: `scale(${placement === 0 ? 1 : .9})`,
        }}>
        <Box component="img" src={unboxing.item.image} alt={unboxing.item.name} 
          sx={{ position: 'absolute', top: '-64px', height: '128px', width: '128px', left: '50%', transform: 'translateX(-50%)', 
          filter: `drop-shadow(0 0 .5rem ${getRarityColor(unboxing.item.rarity.toLowerCase() as ItemRarity)})` }} 
        />
  
        <Box display="flex" flexDirection="column" alignItems="center" gap=".25rem" sx={{ mt: '3rem' }}>
          <Typography sx={{
            fontWeight: 'bold',
            maxWidth: '90%',        
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          }}>
            {unboxing.item.name}
          </Typography>
          <Typography sx={{ color: 'var(--primary-main)', textTransform: 'capitalize', fontSize: '14px' }}>{unboxing.item.type}</Typography>
        </Box>

  
  
        <Box display="flex" alignItems="center" justifyContent="center" gap=".5rem" sx={{ pt: '1rem', borderTop: '1px solid var(--primary-border)' }}>
          <Crown style={{ height: '24px', width: '24px', fill: getPlacementColor(placement), filter: `drop-shadow(0 0 0.5rem ${getPlacementColor(placement)})` }} />
          <Typography sx={{ color: getPlacementColor(placement), fontWeight: 'bold' }}>{unboxing.user.display_name}</Typography>
        </Box>
  
      </Box>
    )
  }

const CaseTopUnboxings = ({ isLoading, topUnboxings }: { isLoading: boolean, topUnboxings?: TopUnboxing[] }) => {

  return (
    <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap="6rem"
        width="100%"
    >
        <Typography variant="h4" sx={{ color: 'var(--text-secondary)' }}>Top Unboxings</Typography>
        {isLoading ? (
            <></>
        ) : !topUnboxings || topUnboxings.length === 0 ? (
            <></>
        ) : (
            <Box display="flex" alignItems="center" justifyContent="center" gap="1rem" sx={{ rowGap: '4rem', width: '100%', flexDirection: { xs: 'column', md: 'row'}}}>
                {topUnboxings[1] && (
                  <TopUnboxingBox placement={1} unboxing={topUnboxings[1]} />
                )}

                {topUnboxings[0] && (
                  <TopUnboxingBox placement={0} unboxing={topUnboxings[0]} />
                )}
                {topUnboxings[2] && (
                  <TopUnboxingBox placement={2} unboxing={topUnboxings[2]} />
                )}
                
                
            </Box>
        )}
    </Box>
  )
}

export default CaseTopUnboxings