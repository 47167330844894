import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    activeRaffles: [],
    completedRaffles: []
}

export const raffleSlice = createSlice({
    name: 'raffles',
    initialState,
    reducers: {
        setActiveRaffles: (state, action) => {
            state.activeRaffles = [...action.payload]
        },
        addActiveRaffle: (state, action) => {
            state.activeRaffles = [action.payload, ...state.activeRaffles]
        },
        updateActiveRaffle: (state, action) => {
            state.activeRaffles = state.activeRaffles.map((item) => (
                item._id === action.payload._id ? {...item, ...action.payload} : item
            ))
        },
        completeActiveRaffle: (state, action) => {
            state.activeRaffles.forEach((item, index) => {
                if(item._id === action.payload._id) state.activeRaffles.splice(index, 1)
            })
            state.completedRaffles = [action.payload, ...state.completedRaffles]
        },
        finishActiveRaffle: (state, action) => {
            console.log('aws');
            console.log(action.payload);
            state.activeRaffles = state.activeRaffles.map((item) => (
                item._id === action.payload._id ? { ...action.payload } : item
            ))

        },
        setCompletedRaffles: (state, action) => {
            state.completedRaffles = [...action.payload]
        },
        addCompletedRaffle: (state, action) => {
            state.completedRaffles = [action.payload, ...state.completedRaffles]
        },
        
    }
})


export const { setActiveRaffles, addActiveRaffle, updateActiveRaffle, completeActiveRaffle, setCompletedRaffles, addCompletedRaffle, finishActiveRaffle } = raffleSlice.actions

export const selectActiveRaffles = state => state.raffles.activeRaffles
export const selectCompletedRaffles = state => state.raffles.completedRaffles

export default raffleSlice.reducer