import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import { EQueryToasts } from './ToastQueryList';
import { createToast } from './Toasts';
import { linkedDiscordAccountToast, linkedSteamAccountToast } from './LinkAccountToasts';

const ToastQuery = () => {
    const location = useLocation();

    const useQuery = () => new URLSearchParams(location.search);
    const query = useQuery();
  
    const success = query.get('success');
    const error = query.get('error');

    useEffect(() => {
      if (success) {
        if (success === "link_discord_success") {
          linkedDiscordAccountToast();
        } else if (success === "link_steam_success") {
          linkedSteamAccountToast();
        } else {
          createToast(EQueryToasts[success], 'success'); 
        }
        
      }
    }, [success])

    useEffect(() => {
        if(error){
          createToast(EQueryToasts[error], 'error'); 
        }
      }, [error])
  

  return (
    <></>
  )
}

export default ToastQuery