import { Item } from "./item.interface";
import { Profile, PublicProfile } from "./user.interface";


export interface IBattle {
    battleId: number;
    cases: Array<any>;
    creationDate: Date;
    startDate: Date;
    status: number;
    creator: any;
    slots: number;
    currentRound: number;
    totalRounds: number;
    players: Array<BattlePlayer | null>;
    gameRounds: Array<GameRound>;
    activeRound?: GameRound;
    cost: number;
    isGiveaway?: boolean;
    isPrivate?: boolean;

}

export interface CaseContent {
    item: string | Item;
    odds: number;
}

export interface BattleCase {
    name: string;
    case_id: string;
    unique_id: string;
    image: string;
    price: number;
    content: Array<CaseContent>;
}

export interface BattlePlayer {
    user: PublicProfile;
    value?: number;
    isWinner?: boolean;
}

export interface BattleItem {
    _id: string;
    name: string;
    image: string;
    value: number;
    rarity: string;
    type: string;
} 

export interface BattleDrop {
    id: number;
    nonce: number;
    item: BattleItem;
    ticket: number;
}

export interface GameRound {
    order: number;
    drops: Array<BattleDrop>;
    case: BattleCase;
}


export enum EBattleStatus {
    WAITING = 0,
    STARTED = 1,
    FINISHED = 2,
    CANCELED = 3
}

export const BattleStatus: {
    [key: string]: string
} = {
    "0": 'WAITING',
    "1": 'ONGOING',
    "2": 'FINISHED',
    "3": 'CANCELED'
}