import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { createErrorToast } from '../../components/Toasts/ErrorToasts'
import { apiFetch } from '../../utils'
import { createSuccessToast } from '../../components/Toasts/SuccessToasts'
import { BonusHuntSlotGuess } from './types'

const SlotGuessDialog = ({ bonusHuntId, name, bonusCount, onSlotGuessPlaced }: { bonusHuntId: number, name?: string, bonusCount: number, onSlotGuessPlaced(guess: BonusHuntSlotGuess): void }) => {

    const [isOpen, setIsOpen] = useState(false)
    const handleClose = () => setIsOpen(false)

    const [bet, setBet] = useState<string | undefined>(undefined)

    async function placeGuess() {

        if(!name)
            return createErrorToast("Make sure you have selected a slot")

        if(!bet)
            return createErrorToast("Make sure to place a bet first!")

        try {
            const data = await apiFetch(`/hunt/slot-guess`, "POST", {
                body: JSON.stringify({
                    huntId: bonusHuntId,
                    slotName: name,
                    bet: parseInt(bet)
                })
            })

            if(data) {
                createSuccessToast("Bet placed")
                onSlotGuessPlaced(data)
                handleClose()
            }
        } catch (e) {
            if((e as Error).message) {
                createErrorToast((e as Error).message)
            }
        }
    }

  return (
    <>
        <Button variant="contained" onClick={() => setIsOpen(true)}>
            Place Guess
        </Button>
        <Dialog
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="slot-guess"
        >
            <DialogTitle id="slot-guess-title" sx={{ p: '1rem', borderBottom: '1px solid var(--primary-border)', mb: '1rem' }}>Guess the Slot with the highest X win!</DialogTitle>
            <DialogContent>
                <Box display="flex" flexDirection="column">

                    <Box display="flex" flexDirection="column" gap=".25rem">
                        <Typography sx={{ fontSize: '.8em' }}>You are betting on:</Typography>
                        <Typography fontWeight="bold" fontSize="1.2em">{name}</Typography>
                    </Box>

                    <Box display="flex" flexDirection="column" gap=".25rem" sx={{ my: '1rem' }}>
                        <Typography sx={{ fontSize: '.8em' }}>Bet</Typography>
                        <TextField placeholder="Your bet..." value={bet} onChange={(e) => setBet(e.target.value)} sx={{ width: '100%' }} />
                    </Box>

                    <Typography>If you guess the correct slot, you will get an payout of {Intl.NumberFormat("en-US").format(bonusCount * 0.75)}x your bet.</Typography>
                    <Typography>You can only guess one slot per Bonus Hunt!</Typography>
                </Box>

            </DialogContent>
            <DialogActions sx={{ p: '1rem', borderTop: '1px solid var(--primary-border)' }}>
                <Button
                    variant="contained"
                    sx={{ marginRight: 'auto' }}
                    disabled={!bet}
                    onClick={placeGuess}
                >
                    Confirm
                </Button>
                <Button variant="contained" color="soft" onClick={handleClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    
    </>
  )
}

export default SlotGuessDialog